<script lang="ts" src="../blueprints/Spacer.ts"></script>

<template>
    <form-component-wrapper class="spacer-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default></template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
.spacer-component {
    min-height: 50px;
}
</style>
