import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    VisibleBlueprint,
    ReadonlyBlueprint,
    RequiredBlueprint,
    CustomErrorBlueprint,
    HasLabel,
    HasPlaceholder,
    HasAffix,
    HasHelp,
    HasWidth,
    AffixValue,
    AggregateBlueprint,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice,
    EntryFactory,
    Validatable,
    ValidationErrors,
    ValidEntry
} from '../Types';
import min from 'lodash/min';

export class PhoneEntry extends ValidEntry
{
    public type: string = Components.PHONE;
    public value?: string = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.value = data.value;
        }
    }

    public async collect(blueprint: PhoneContract, form: FormBuilder, preprocess: (name: string, entry: any) => Promise<void>): Promise<any>
    {
        await preprocess(blueprint.name, this);

        return {
            type: this.type,
            value: this.value || form.executeExpression(blueprint.defaultValue)
        };
    }

    public validate(blueprint: PhoneContract, form: FormBuilder): boolean
    {
        this.errors = {};

        const value = this.value || form.executeExpression(blueprint.defaultValue);

        if (!form.readonly(blueprint, true) && form.visible(blueprint, true))
        {
            if (form.required(blueprint) && (value == null || value.length == 0))
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${blueprint.label}]]]`];
            }
            else if (value && this.hasValidPattern(blueprint) && !this.getPattern(blueprint).test(value))
            {
                this.errors.value = ["[[[Podana wartość jest nieprawidłowa.]]]"];
            }
            else if (form.customError(blueprint))
            {
                this.errors.custom = [form.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }

    public getPattern(blueprint: PhoneContract): RegExp
    {
        return new RegExp(`^${blueprint.pattern}$`);
    }

    public hasValidPattern(blueprint: PhoneContract): boolean
    {
        try
        {
            if (blueprint.pattern)
            {
                this.getPattern(blueprint);

                return true;
            }
        }
        catch (e) { /**/ }

        return false;
    }
}

export const instanceOfPhoneEntry = (object: any): object is PhoneEntry =>
{
    return object && 'type' in object && object.type === Components.PHONE;
};

export interface PhoneContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasPlaceholder, HasAffix, HasHelp, HasWidth
{
    defaultValue: string;
    pattern: string;
}

export class PhoneType implements PhoneContract, Validatable, EntryFactory<PhoneEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public placeholder: string;
    public defaultValue: string;
    public pattern: string;
    public affix: AffixValue;
    public help: string;
    public width: number;
    public minWidth: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.PHONE;
        this.name = builder.name(Components.PHONE);
        this.label = '[[[Telefon]]]';
        this.showLabel = true;
        this.placeholder = '+__ _________';
        this.defaultValue = '';
        this.pattern = '(\\+[0-9]{1,3} )?[0-9]{4,14}';
        this.help = '';
        this.affix = { prepend: '', append: '' };
        this.width = min([3, builder.space(parent)]);
        this.minWidth = 1;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public createEntry(data: any): PhoneEntry
    {
        return new PhoneEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
