import { Form } from "@/helpers/Form";
import Pager from "@/helpers/Pager";
import type { FilterModel } from "../types";

export default {
    namespaced: true,
    state: {
        filter: Form.create<FilterModel>({
            districtsIds: [],
            citiesIds: []
        }),
        pager: new Pager(1, 20, 'Name', 'ASC')
    }
};
