<template>
    <div class="card">
        <h4 class="card-header">
            {{ $t('[[[Zresetuj hasło]]]') }}
        </h4>
        <div class="card-body">
            <ideo-alert :show="message.length > 0" :variant="messageVariant">
                {{ message }}
            </ideo-alert>
            <form @submit.prevent="onSubmit" @input="form.$errors.clear($event.target.name);">
                <h5>{{ $t('[[[Podaj swój adres email]]]') }}</h5>
                <ideo-form-group :invalid-feedback="form.$errors.first('email')" :state="form.$errors.state('email')">
                    <ideo-form-input v-model="form.email" type="text" name="email" :placeholder="$t('[[[Adres email]]]')" v-focus></ideo-form-input>
                </ideo-form-group>
                <h5>{{ $t('[[[Oraz nowe hasło]]]') }}</h5>
                <ideo-form-group :invalid-feedback="form.$errors.first('newPassword')" :state="form.$errors.state('newPassword')">
                    <ideo-form-input v-model="form.newPassword" type="password" name="newPassword" :placeholder="$t('[[[Hasło]]]')"></ideo-form-input>
                </ideo-form-group>
                <ideo-form-group :invalid-feedback="form.$errors.first('repeatPassword')" :state="form.$errors.state('repeatPassword')">
                    <ideo-form-input v-model="form.repeatPassword" type="password" name="repeatPassword" :placeholder="$t('[[[Powtórz hasło]]]')"></ideo-form-input>
                </ideo-form-group>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <ideo-button type="submit" variant="primary" :block="true" :disabled="!form.active()">
                            {{ $t('[[[Zmień hasło]]]') }}
                        </ideo-button>
                    </div>
                    <div class="col-12 col-sm-6">
                        <router-link :to="{name:'login'}" class="btn btn-link w-100">
                            {{ $t('[[[Wróć do logowania]]]') }}
                        </router-link>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import AuthService, { ResetModel } from '@/modules/core/auth/services/AuthService';
import {Form, FormType} from '@/helpers/Form';

@Options({})
export default class ResetPassword extends Vue
{
    public message: string = '';
    public messageVariant: string = 'danger';
    public form: FormType<ResetModel> = Form.create<ResetModel>({
        email: '',
        newPassword: '',
        repeatPassword: ''
    });

    public async onSubmit(): Promise<void>
    {
        this.message = '';

        try
        {
            this.form.wait();

            const result = await AuthService.setPassword(
                this.$route.params.token as string,
                this.form.email,
                this.form.newPassword,
                this.form.repeatPassword
            );

            this.form.email = '';
            this.form.newPassword = '';
            this.form.repeatPassword = '';
            this.messageVariant = 'success';
            this.message = result;
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => { this.messageVariant = 'danger'; this.message = ex.message; },
                422: (ex: any) => this.form.$errors.record(ex.data.errors)
            });
        }
        finally
        {
            this.form.continue();
        }
    }
}
</script>

<style scoped>

</style>
