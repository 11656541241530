<template>
    <div class="form-group">
        <label :for="uid">{{ label }}</label>
        <div class="row">
            <div class="col-6">
                <ideo-input-group :prepend="$t('[[[od]]]')" :append="append">
                    <input type="number" :min="rangeMin" :max="rangeMax" :step="step" :id="`${uid}-min`" class="form-control" :value="minValue" @input="updateMin" @focus="focus" @blur="blur">
                </ideo-input-group>
            </div>
            <div class="col-6">
                <ideo-input-group :prepend="$t('[[[do]]]')" :append="append">
                    <input type="number" :min="modelValue.min" :max="rangeMax" :step="increment" :id="`${uid}-max`" class="form-control" :value="maxValue" @input="updateMax" @focus="focus" @blur="blur">
                </ideo-input-group>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { increment } from '../types/Numeric';
import { MinMaxValue } from '../Types';
import max from 'lodash/max';

@Options({
    name: 'field-minmax'
})
export default class FieldMinMax extends Vue
{
    @Prop() public label!: string;
    @Prop() public modelValue!: MinMaxValue;
    @Prop() public append: string;
    @Prop({ default: false }) public naturals: boolean;
    @Prop({ default: 0 }) public step: number;
    @Prop({ default: 0 }) public precision: number;
    @Prop({ default: () => Number.MIN_SAFE_INTEGER + 1 }) public min: number;
    @Prop({ default: () => Number.MAX_SAFE_INTEGER - 1 }) public max: number;

    public focused: boolean = false;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public get increment(): number
    {
        return increment(this.step, this.precision);
    }

    public get rangeMin(): number
    {
        return this.naturals === true ? Math.max(0, this.min) : this.min;
    }

    public get rangeMax(): number
    {
        return this.max;
    }

    public get minValue(): string
    {
        return (this.modelValue.min || 0).toFixed(this.precision);
    }

    public get maxValue(): string
    {
        return (this.modelValue.max || 0).toFixed(this.precision);
    }

    public updateMin(e: any): void
    {
        const value = parseFloat(e.target.value || 0);

        this.update({
            min: max([this.rangeMin, value]),
            max: max([this.modelValue.max, value])
        });
    }

    public updateMax(e: any): void
    {
        const value = parseFloat(e.target.value || 0);

        this.update({
            min: this.modelValue.min,
            max: max([this.modelValue.min, value])
        });
    }

    public update(value: MinMaxValue): void
    {
        if (!this.focused)
        {
            this.triggerInput(value);
        }
    }

    public focus(e: any): void
    {
        this.focused = true;
    }

    public blur(e: any): void
    {
        this.focused = false;

        if ((e.target.id as string).endsWith('min'))
            this.updateMin(e);

        if ((e.target.id as string).endsWith('max'))
            this.updateMax(e);
    }

    @Emit('update:modelValue')
    public triggerInput(value: MinMaxValue): MinMaxValue
    {
        return value;
    }
}
</script>
