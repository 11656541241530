<template>
    <div class="form-group">
        <label :for="uid">{{ label }}</label>
        <div class="input-group">
            <input type="text" class="form-control" readonly="readonly" :id="uid" :value="display">
            <span class="input-group-text pointer" @click="openModal()">
                <i class="fas fa-cog"></i>
            </span>
        </div>
        <small class="form-text text-danger" v-if="invalidFeedback && invalidFeedback(modelValue)">
            {{ $t(invalidFeedback(modelValue)) }}
        </small>
        <ideo-modal ref="field-category" :title="$t('[[[Kategorie]]]')" size="lg" header-class="d-block" body-class="py-0" footer-class="d-block" centered scrollable>
            <template #modal-header>
                <div class="d-flex flex-nowrap">
                    <ideo-button variant="success" :disabled="!permissions.canCreate" @click="openForm()" class="me-2">
                        <i class="fas fa-plus"></i> {{ $t('[[[Dodaj kategorię]]]') }}
                    </ideo-button>
                    <data-sorting :pager="pager" :options="sortingOptions" class="w-auto mb-0 ms-auto" :short="phone" @change="loadData()"></data-sorting>
                    <ideo-button variant="danger" :title="$t('[[[Zamknij]]]')" @click="closeModal()" class="ms-2">
                        <i class="fas fa-times"></i>
                    </ideo-button>
                </div>
            </template>
            <template #default>
                <div class="py-1" :class="{'category-list': items.length > 0}">
                    <list-view layout="table" class="mx-n3" :items="items" :pager="pager" @change="loadData()" :row-click="(item) => selectCategory(item)">
                        <template #row="{item}">
                            <list-view-header :title="$t('[[[Nazwa kategorii]]]')" sort="Name">
                                {{ item.name }}
                            </list-view-header>
                            <list-view-buttons width="50">
                                <list-view-button :title="$t('[[[Edytuj]]]')" :disabled="!permissions.canEdit" @click="openForm(item)">
                                    <i class="fas fa-pencil-alt me-1"></i> {{ $t('[[[Edytuj]]]') }}
                                </list-view-button>
                                <list-view-button :title="$t('[[[Usuń]]]')" :disabled="!permissions.canDelete" :message="$t('[[[Potwierdzenie usunięcia]]]')" @confirm="deleteCategory">
                                    <i class="fas fa-times me-2"></i> {{ $t('[[[Usuń]]]') }}
                                </list-view-button>
                            </list-view-buttons>
                        </template>
                    </list-view>
                </div>
            </template>
            <template #modal-footer>
                <div class="d-flex flex-nowrap mt-auto">
                    <pagination :pager="pager" @change="loadData()" class="mx-auto"></pagination>
                </div>
            </template>
        </ideo-modal>
        <ideo-modal ref="field-category-form" :title="$t('[[[Dodaj kategorię]]]')" centered>
            <template #default>
                <ideo-form-group :label="$t('[[[Nazwa]]]')" :invalid-feedback="form.$errors.first('name')" :state="form.$errors.state('name')" required>
                    <ideo-form-input v-model="form.name" type="text" name="name"></ideo-form-input>
                </ideo-form-group>
            </template>
            <template #modal-footer="{ ok, cancel }">
                <ideo-button variant="success" @click="saveCategory(ok)">
                    {{ $t('[[[Zapisz]]]') }}
                </ideo-button>
                <ideo-button variant="light" @click="closeForm(cancel)">
                    {{ $t('[[[Anuluj]]]') }}
                </ideo-button>
            </template>
        </ideo-modal>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Ref } from '@/helpers/Decorators';
import { Option } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import {Form, FormType} from '@/helpers/Form';
import IdeoModal from '@/components/bootstrap/modal/IdeoModal.vue';
import DynamicFormsService, { CategoryFilterModel, CategoryItemModel, CategoryModel } from '@/modules/core/forms/services/DynamicFormsService';

@Options({
    name: 'field-category'
})
export default class FieldCategory extends Vue
{
    @Prop()
    public label: string;

    @Prop()
    public modelValue: any;

    @Prop()
    public invalidFeedback: (value: any) => string;

    @Ref('field-category')
    public fieldCategory: () => IdeoModal;

    @Ref('field-category-form')
    public fieldCategoryForm: () => IdeoModal;

    public filter: FormType<CategoryFilterModel> = Form.create<CategoryFilterModel>({
        name: ''
    });

    public pager: Pager = new Pager(1, 10, 'Name', 'ASC');
    public items: CategoryItemModel[] = [];
    public category: CategoryItemModel = null;
    public form: FormType<CategoryModel> = Form.create<CategoryModel>({
        id: 0,
        name: '',
        position: 99999
    });

    public permissions: any = {
        canCreate: true,
        canEdit: true,
        canDelete: true
    };

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public get display(): string
    {
        return this.category?.name || '';
    }

    public get sortingOptions(): Option[]
    {
        return [
            { value: 'Name', text: '[[[Nazwa kategorii]]]' },
            { value: 'Position', text: '[[[Pozycja]]]' }
        ];
    }

    public async created(): Promise<void>
    {
        await this.loadCategory();
    }

    public openModal(): void
    {
        this.fieldCategory().show();
        this.loadData();
    }

    public closeModal(): void
    {
        this.fieldCategory().hide();
        this.loadCategory();
    }

    @Emit('update:modelValue')
    public selectCategory(model: CategoryItemModel): number
    {
        this.category = model;
        this.fieldCategory().hide();

        return model.id;
    }

    public async loadCategory(): Promise<void>
    {
        try
        {
            if (this.modelValue != null && this.modelValue > 0)
            {
                const response = await DynamicFormsService.fetchCategory(this.modelValue);

                this.category = response.result;
                this.permissions = response.meta;
            }
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message)
            });
        }
    }

    public async loadData(): Promise<void>
    {
        this.items = [];

        try
        {
            const response = await DynamicFormsService.getCategories(this.filter.data(), this.pager);

            this.pager.setTotalRows(response.totalRows);
            this.items = response.items;
        }
        catch (ex)
        {
            this.pager.setTotalRows(0);
            this.items = [];
            this.$log.debug(ex);
        }
    }

    public openForm(model: CategoryItemModel = {} as any): void
    {
        this.form.reset();
        this.form.withData(model);
        this.fieldCategoryForm().show();
    }

    public closeForm(cancel: any): void
    {
        this.form.reset();
        cancel();
    }

    public async saveCategory(ok: any): Promise<void>
    {
        try
        {
            this.form.wait();

            if (!this.form.id)
            {
                await DynamicFormsService.addCategory(this.form.data());
                this.$alert.success(this.$t('[[[Kategoria została dodana.]]]'));
            }
            else
            {
                await DynamicFormsService.updateCategory(this.form.data());
                this.$alert.success(this.$t('[[[Kategoria została zaktualizowana.]]]'));
            }

            this.loadData();
            ok();
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => this.form.$errors.record(ex.data.errors)
            });
        }
        finally
        {
            this.form.continue();
        }
    }

    public async deleteCategory(item: CategoryItemModel): Promise<void>
    {
        try
        {
            await DynamicFormsService.removeCategory(item.id);
            this.$alert.success(this.$t('[[[Kategoria została usunięta.]]]'));
            await this.loadData();
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message)
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.input-group {
    .form-control {
        background-color: var(--form-control-bg) !important;
        border: 1px solid var(--form-control-border) !important;
        border-right: none !important;
    }
    .input-group-text {
        background-color: var(--form-control-bg) !important;
        border: 1px solid var(--form-control-border) !important;
        border-left: none !important;
        color: var(--form-control-border) !important;
    }
}
.category-list {
    min-height: 150px;
}
</style>
