<script lang="ts" src="../blueprints/Text.ts"></script>

<template>
    <form-component-wrapper class="text-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <label :for="blueprint.id" v-if="blueprint.showLabel">{{ $t(blueprint.label) }} <var class="text-danger" v-if="required">*</var></label>
                <ideo-input-group :prepend="blueprint.affix.prepend" :append="blueprint.affix.append">
                    <input type="text" class="form-control" v-model="value" :id="blueprint.id" :placeholder="blueprint.placeholder" :readonly="readonly" v-if="isTextField">
                    <textarea class="form-control" v-model="value" :id="blueprint.id" :placeholder="blueprint.placeholder" :readonly="readonly" :rows="blueprint.rows" v-if="isMultiline"></textarea>
                    <input type="password" class="form-control" v-model="value" :id="blueprint.id" :placeholder="blueprint.placeholder" :readonly="readonly" v-if="isPassword">
                </ideo-input-group>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <field-text v-model="blueprint.label" :label="$t('[[[Etykieta]]]')" />
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-choice v-model="blueprint.fieldType" :options="fieldTypeOptions" :label="$t('[[[Typ pola]]]')" />
            <field-condition v-model="blueprint.defaultValue" :form="form" :label="$t('[[[Wartość domyślna]]]')" type="text" />
            <field-text v-model="blueprint.placeholder" :label="$t('[[[Tekst zastępczy]]]')" />
            <field-affix v-model="blueprint.affix" />
            <field-minmax v-model="blueprint.characters" :label="$t('[[[Ilość znaków]]]')" :naturals="true" />
            <field-numeric v-model="blueprint.rows" :label="$t('[[[Ilość wierszy]]]')" :naturals="true" :min="2" :max="20" v-if="isMultiline" />
            <field-select v-model="blueprint.validation" :options="validationOptions" :label="$t('[[[Walidacja]]]')" v-if="isTextField" />
            <field-text v-model="blueprint.validationRule" :label="$t('[[[Wyrażenie regularne]]]')" v-if="isRegExpValidation" />
            <field-text v-model="blueprint.validationMessage" :label="$t('[[[Komunikat walidacji]]]')" v-if="isRegExpValidation" />
            <field-textarea v-model="blueprint.help" :label="$t('[[[Pomoc]]]')" />
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-readonly :form="form" :blueprint="blueprint" :label="$t('[[[Tylko do odczytu]]]')" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
</style>
