import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { Blueprint } from '../Types';
import { FormEntry } from '../types/Form';
import { PageContract } from '../types/Page';
import { RowContract } from '../types/Row';
import last from 'lodash/last';

@Options({
    name: 'row-blueprint'
})
export default class RowBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: PageContract;
    @Prop({ default: null }) public blueprint!: RowContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public get design(): boolean
    {
        return this.form.designMode();
    }

    public get last(): boolean
    {
        return last(this.parent.components) == this.blueprint;
    }

    private get selected(): boolean
    {
        return this.form.isRowSelected(this.blueprint);
    }

    public selectPlaceholder(component: Blueprint = null): void
    {
        this.form.selectPlaceholder(this.blueprint, component);
    }

    public addComponent(type: string, before: Blueprint): void
    {
        this.form.addComponent(this.blueprint, type, before);
    }

    public dropComponent(component: Blueprint, before: Blueprint): void
    {
        this.form.cut(component);
        this.form.paste(this.blueprint, before);
    }

    @Watch('selected')
    private onSelectedChanged(selected: boolean): void
    {
        if (selected == true)
        {
            this.form.offsetTop(this);
        }
        else
        {
            if (this.blueprint.components.length == 0 && !this.selected && !this.last)
            {
                this.form.removeComponent(this.blueprint);
            }
        }
    }

    @Watch('blueprint.components')
    private onComponentsChanged(components: Blueprint[], old: Blueprint[]): void
    {
        if (components.length == 0 && old.length > 0)
        {
            this.form.removeComponent(this.blueprint);
        }
    }
}
