import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { Blueprint } from '../Types';
import { FormContract, FormEntry } from '../types/Form';
import { PageContract } from '../types/Page';
import { RowContract } from '../types/Row';
import properties from '../properties';
import last from 'lodash/last';

@Options({
    name: 'page-blueprint',
    components: {
        ...properties
    }
})
export default class PageBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: FormContract;
    @Prop({ default: null }) public blueprint!: PageContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public created(): void
    {
        if (this.blueprint.components.length == 0)
        {
            this.form.addRow(this.blueprint);
        }
    }

    public get design(): boolean
    {
        return this.form.designMode();
    }

    public get selected(): boolean
    {
        return this.form.isPageSelected(this.blueprint) && this.design;
    }

    public get visible(): boolean
    {
        return this.design || (this.form.visible(this.blueprint) && this.parent.currentPage == this.pageNumber);
    }

    public get rows(): RowContract[]
    {
        return this.blueprint.components as RowContract[];
    }

    public get last(): RowContract
    {
        return last(this.rows);
    }

    public get isFirst(): boolean
    {
        const components = this.parent.components.filter(c => this.design || this.form.visible(c));

        return components.indexOf(this.blueprint) == 0;
    }

    public get isLast(): boolean
    {
        const components = this.parent.components.filter(c => this.design || this.form.visible(c));

        return components.indexOf(this.blueprint) == components.length - 1;
    }

    public get showHeader(): boolean
    {
        return this.design || this.blueprint.showTitle || !!this.blueprint.description;
    }

    public get showFooter(): boolean
    {
        return this.parent.components.length > 1 || this.parent.showPageNumbers;
    }

    public get pageNumber(): number
    {
        return this.parent.components.indexOf(this.blueprint) + 1;
    }

    public get pageNumberLabel(): string
    {
        return this.$t('[[[Strona %0|||{0}]]]', this.pageNumber);
    }

    protected showLine(index: number): boolean
    {
        const next = index < this.rows.length - 1 ? this.rows[index + 1] : { components: [] } as RowContract;

        return this.rows[index].components.length > 0 && next.components.length > 0;
    }

    public get toolbar(): boolean
    {
        return this.selected && this.parent.components.length > 1;
    }

    public selectPage(): void
    {
        this.form.selectPage(this.blueprint);
    }

    public addRow(before: RowContract): void
    {
        this.form.addRow(this.blueprint, before);
        this.form.selectPlaceholder(this.blueprint, null);
    }

    public addComponent(type: string, before: RowContract): void
    {
        const row = this.form.addRow(this.blueprint, before);

        this.form.addComponent(row, type);
    }

    public dropComponent(component: Blueprint, before: RowContract): void
    {
        const row = this.form.addRow(this.blueprint, before);

        this.form.cut(component);
        this.form.paste(row);
    }

    private pageValid(): boolean
    {
        const entries = this.form.descendants(this.blueprint)
            .map(p =>
            {
                return { blueprint: p, entry: this.entry.find(p.name) };
            })
            .filter(p => p.entry != null);

        entries.forEach(p => p.entry.validate(p.blueprint, this.form));

        return entries.filter(p => !p.entry.valid()).length == 0;
    }

    public nextPage(): void
    {
        if (this.pageValid())
        {
            const components = this.parent.components.filter(c => this.form.visible(c));
            let index = components.indexOf(this.blueprint);

            if (index < components.length - 1)
            {
                this.parent.currentPage = this.parent.components.indexOf(components[++index]) + 1;
            }
        }
    }

    public prevPage(): void
    {
        const components = this.parent.components.filter(c => this.form.visible(c));
        let index = components.indexOf(this.blueprint);

        if (index > 0)
        {
            this.parent.currentPage = this.parent.components.indexOf(components[--index]) + 1;
        }
    }

    @Emit('submit')
    public submit(): void
    {
    }

    @Watch('last.components.length')
    private onRowsChanged(length: number): void
    {
        if (length && length > 0)
        {
            this.form.addRow(this.blueprint);
        }
    }

    @Watch('selected')
    private onSelectedChanged(blueprint: boolean): void
    {
        if (blueprint == true)
        {
            this.form.offsetTop(this);
        }
    }
}
