<script lang="ts" src="../blueprints/Personal.ts"></script>

<template>
    <form-component-wrapper class="personal-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <label :for="blueprint.id" v-if="blueprint.showLabel">{{ $t(blueprint.label) }} <var class="text-danger" v-if="required">*</var></label>
                <div class="row g-0">
                    <div class="col-2 pe-2" v-if="blueprint.hasTitle">
                        <input type="text" class="form-control" v-model="title" :placeholder="$t('[[[Tytuł]]]')" :readonly="readonly">
                    </div>
                    <div class="col pe-2">
                        <input type="text" class="form-control" v-model="givenName" :placeholder="$t('[[[Imię]]]')" :readonly="readonly">
                    </div>
                    <div class="col pe-2" v-if="blueprint.hasMiddleName">
                        <input type="text" class="form-control" v-model="middleName" :placeholder="$t('[[[Drugie imię]]]')" :readonly="readonly">
                    </div>
                    <div class="col">
                        <input type="text" class="form-control" v-model="surname" :placeholder="$t('[[[Nazwisko]]]')" :readonly="readonly">
                    </div>
                </div>
                <form-error-message :entry="entryData" name="title" />
                <form-error-message :entry="entryData" name="givenName" />
                <form-error-message :entry="entryData" name="middleName" />
                <form-error-message :entry="entryData" name="surname" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <field-text v-model="blueprint.label" :label="$t('[[[Etykieta]]]')" />
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <h6>{{ $t('[[[Pole zawiera]]]') }}</h6>
            <field-checkbox v-model="blueprint.hasTitle" :label="$t('[[[Tytuł]]]')" />
            <field-checkbox v-model="blueprint.hasMiddleName" :label="$t('[[[Drugie imię]]]')" />
            <field-textarea v-model="blueprint.help" :label="$t('[[[Pomoc]]]')" />
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-readonly :form="form" :blueprint="blueprint" :label="$t('[[[Tylko do odczytu]]]')" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
</style>
