import { Option, Pagination, Resource, Statement } from "@/helpers/Interfaces";
import Pager from "@/helpers/Pager";
import { $t } from "@/plugins/localization";
import axios, { AxiosResponse } from "axios";
import { merge } from "lodash";
import { DateTime } from "luxon";
import { Resource as FileResource } from '@/modules/core/files/services/StorageService';
import { FormModel as CostFormModel } from "@/modules/renter/cost_reports/services/CostReportsService";
import { ChatRoomsListItemModel } from "@/modules/renter/chat/services/ChatService";
import { DepartmentEnum as UserDepartmentEnum } from "@/modules/core/users/services/UsersService";
import fileNameConverter from "@/modules/renter/common/helpers/fileNameConverter";
import type { TaskModel } from "../../dashboard/types";

/**
 * TasksService
 */
export default class TasksService
{
    /**
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(pager: Pager, filter: FilterModel, taskType: string): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/renters/tasks', {
            params: merge({ taskType }, filter, pager.data())
        })).data;
    }

    /**
     * @param pager Pager
     * @param publicId string
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getListByProperty(pager: Pager, publicId: string): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>(`admin/renters/tasks/property/${publicId}`, {
            params: merge({}, pager.data())
        })).data;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<AssigneeItemModel[]>
     */
    public static async getAssignees(publicId: string): Promise<AssigneeItemModel[]>
    {
        return (await axios.get<AssigneeItemModel[]>(`admin/renters/tasks/${publicId}/assignees`)).data;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(publicId: string): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/renters/tasks/${publicId}`)).data;
    }

    /**
     * @param model FormModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async create(model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`admin/renters/tasks`, model)).data;
    }

    /**
     * @param publicId string
     * @param model FormModel
     *
     * @returns Promise<Statement>
     */
    public static async update(publicId: string, model: FormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/renters/tasks/${publicId}`, model)).data;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<Statement>
     */
    public static async remove(publicId: string): Promise<Statement>
    {
        return (await axios.delete(`admin/renters/tasks/${publicId}`)).data;
    }

    /**
     * @param publicId string
     * @param resource FileResource
     *
     * @returns Promise<Statement>
     */
    public static async updatePhotos(publicId: string, resource: FileResource[]): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/renters/tasks/${publicId}/photos`, resource)).data;
    }

    /**
     * @param file File
     * @param container string
     * @param secured boolean
     *
     * @returns Promise<UploadResource>
     */
    public static async uploadPhoto(file: File, container: string, payload: any = null, secured: boolean = false): Promise<UploadResource>
    {
        const data = fileNameConverter(file, container, payload, secured);

        const result = await axios.post<UploadResource>('admin/renters/tasks/uploadPhotos', data);

        return result.data;
    }

    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<AxiosResponse>
     */
    public static async getRaport(filter: FilterModel, pager: Pager, taskType: string): Promise<AxiosResponse>
    {
        return (await axios.get<Blob>('admin/renters/tasks/excel', {
            params: merge({ taskType },filter, pager.data()),
            responseType: "blob"
        }));
    }

    /**
     * @param tasksIds number
     *
     * @returns Promise<Statement>
    */
    public static async removeMultipleTasks(tasksIds: number[]): Promise<Statement>
    {
        return await axios.delete('admin/renters/tasks/bulkOperation', { data: { tasksIds: tasksIds } });
    }

    /**
     * @param tasksIds UpdateMultipleTasksModel
     *
     * @returns Promise<Statement>
    */
    public static async updateMultipleTasks(tasksMultipleUpdateData: UpdateMultipleTasksModel): Promise<Statement>
    {
        return (await axios.put('admin/renters/tasks/bulkOperation', { ...tasksMultipleUpdateData })).data;
    }

    /**
     * @param publicId string
     * @param month number
     * @param year number
     */
    public static async getCalendarTasksAndReservations(publicId: string, month: number, year: number): Promise<Resource<TasksAndReservations>>
    {
        return (await axios.get('admin/renters/properties/calendarWidget', { params: { publicId, month, year } })).data;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<ListItemModel>
     */
    public static async fetchLastFiveTasks(publicId: string): Promise<TaskModel[]>
    {
        return (await axios.get<TaskModel[]>('admin/renters/tasks/lastPropertyTasks', {
            params: {
                propertyPublicId: publicId,
                numberTasks: 5
            }
        })).data;
    }
}

export interface ListItemModel
{
    id: number;
    publicId: string;
    propertyPublicId: string;
    propertyName: string;
    closedByUser?: string;
    closedByUserId?: number;
    completedByUserId?: number;
    createdByUserId: number;
    issues: string[];
    assignedUsersIds: number[];
    dateCreatedUtc: DateTime;
    dateModifiedUtc?: DateTime;
    dateStartedUtc?: DateTime;
    dateCompleted?: DateTime;
    district?: string;
    dueDate?: DateTime;
    title: string;
    status: string;
    priority: string;
    department: string;
    taskChats: ChatRoomsListItemModel[];
    reportLink: string;
    cityName: string;
    overallTime: string;
}

export interface FilterModel
{
    propertyPublicId: string;
    assignedUserPublicId: string;
    createdByUserPublicId: string;
    completedByUserPublicId: string;
    departments: Array<string> | string;
    priority: Array<string> | string;
    status: Array<string> | string;
    dueDateFromUtc: DateTime;
    dueDateToUtc: DateTime;
    dateCreatedFromUtc: DateTime;
    dateCreatedToUtc: DateTime;
    dateCompletedFromUtc: DateTime;
    dateCompletedToUtc: DateTime;
    citiesIds: string[] | string;
    districtsIds: string[] | string;
    tagsIds: string[] | string;
}

export interface FormModel
{
    id: number;
    publicId: string;
    propertyId: number;
    propertyPublicId: string;
    customPropertyPublicId: string;
    costs: CostFormModel[];
    assignedUsersIds: number[];
    issues: string[];
    completedByUserId?: number;
    department: string;
    priority: string;
    templateChecklistId?: number;
    templateChecklistPublicId: string;
    title: string;
    description: string;
    dateCompleted?: DateTime;
    dueDate?: DateTime;
    photos?: FileResource[];
    status: string;
    reportLink: string;
    requestedByDepartment?: UserDepartmentEnum;
    dateStartedUtc: DateTime;
    canStart: boolean;
    maintainerComment: string;
    taskChats: ChatRoomsListItemModel[];
    reservationPublicId: string;
    comment?: string;
    datePausedUtc: DateTime;
    dateResumedUtc: DateTime;
    overallTime: string;
    additionalWageAmount: number | string;
    additionalWageComment: string
    tagsIds?: number[];
    completedByUserFullName: string,
    createdByUserFullName: string
}

interface UploadedResource
{
    upload?: File;
}

export interface UploadResource extends UploadedResource
{
    id: number;
    resourceType: string;
    dateCreatedUtc: DateTime;
    dateModifiedUtc: DateTime;
    createdBy: string;
    modifiedBy: string;
    scope: string;
    folderId?: number;
    name: string;
    contentType: string;
    contentLength: number;
    url: string;
    isImage: boolean;
    isSecured: boolean;
    meta: Record<string, string>;
    content: Record<string, Content>;
    media: string[];
}

export interface TaskLinkModel
{
    id: number;
    publicId: string;
    title: string;
}

export interface Content
{
    name: string;
    lead: string;
    alt: string;
}

export interface AssigneeItemModel
{
    id: number;
    givenName: string;
    surname: string;
    email: string;
}

export interface UpdateMultipleTasksModel
{
    isClosed: boolean,
    tasksIds: number[],
    date?: string,
    priority?: string,
    assignedUsersIds?: string[] | number[],
}

export interface TasksAndReservations {
    reservations: ReservationsObject[],
    tasks: TasksObject[]
}

export interface TasksObject {
    dateUtc: DateTime
}

export interface ReservationsObject {
    arrivalDateUtc: DateTime,
    departureDateUtc: DateTime,
}

export interface DateCalendarEmitProps {
    month: number,
    year: number
}

export interface UserItemModel
{
    id: number;
    publicId: string;
    givenName: string;
    surname: string;
    email: string;
}

export interface TaskMetaPermissions {
    canComplete: boolean,
    canDelete: boolean,
    canEdit: boolean,
    canEditFinishedTask: boolean
}

enum PriorityEnum {
    Watch = 'Watch',
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
    Urgent = 'Urgent'
}

const PriorityOptions = (): Option[] => ([
    { value: PriorityEnum.Watch, text: $t('[[[Sprawdzić]]]') },
    { value: PriorityEnum.Low, text: $t('[[[Niski]]]') },
    { value: PriorityEnum.Medium, text: $t('[[[Średni]]]') },
    { value: PriorityEnum.High, text: $t('[[[Wysoki]]]') },
    { value: PriorityEnum.Urgent, text: $t('[[[Pilne]]]') }
]);

const PriorityName = (value: PriorityEnum): string => PriorityOptions().find(p => p.value === value)?.text || PriorityEnum[value];

export {
    PriorityEnum,
    PriorityOptions,
    PriorityName
};

enum DepartmentEnum {
    Cleaning = 'Cleaning',
    Inspection = 'Inspection',
    Maintenance = 'Maintenance',
    Coordinator = 'Coordinator',
    MainCoordinator = 'MainCoordinator',
}

const DepartmentOptions = (): Option[] => ([
    { value: DepartmentEnum.Cleaning, text: $t('[[[Sprzątanie]]]') },
    { value: DepartmentEnum.Inspection, text: $t('[[[Inspekcja]]]') },
    { value: DepartmentEnum.Maintenance, text: $t('[[[Konserwacja]]]') }
]);

const DepartmentName = (value: DepartmentEnum): string => DepartmentOptions().find(d => d.value === value)?.text || DepartmentEnum[value];

export {
    DepartmentEnum,
    DepartmentOptions,
    DepartmentName
};

enum StatusEnum {
    Created = 'Created',
    Expired = 'Expired',
    InProgress = 'InProgress',
    Done = 'Done',
    Deleted = 'Deleted',
    Paused = 'Paused',
    Closed = 'Closed'
}

enum DayTypeEnum {
    First = 'first',
    Last = 'last'
}

const StatusOptions = (): Option[] => ([
    { value: StatusEnum.Created, text: $t('[[[Utworzone]]]') },
    { value: StatusEnum.Expired, text: $t('[[[Przeterminowane]]]') },
    { value: StatusEnum.InProgress, text: $t('[[[W trakcie]]]') },
    { value: StatusEnum.Done, text: $t('[[[Wykonane]]]') },
    { value: StatusEnum.Deleted, text: $t('[[[Usunięte]]]') },
    { value: StatusEnum.Paused, text: $t('[[[Zapauzowane]]]') },
    { value: StatusEnum.Closed, text: $t('[[[Zamknięte]]]') }
]);

const StatusName = (value: StatusEnum): string => StatusOptions().find(s => s.value === value)?.text || StatusEnum[value];

export {
    StatusEnum,
    DayTypeEnum,
    StatusOptions,
    StatusName
};
