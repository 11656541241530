<template>
    <li :id="container" :class="{'active': isActive}" @mouseover.stop="mouseOver" @mouseout.stop="mouseOut">
        <span class="d-flex align-items-baseline">
            <i class="fa fa-fw" v-if="!hasChildren"></i>
            <i class="fal fa-fw fa-plus" v-if="isCollapsed" @click="onToggle(node)"></i>
            <i class="fal fa-fw fa-minus" v-if="isExpanded" @click="onToggle(node)"></i>
            <i :class="icon('fad fa-fw fa-file fa-swap')" v-if="!hasChildren"></i>
            <i :class="icon('fad fa-fw fa-folder')" v-if="isCollapsed"></i>
            <i :class="icon('fad fa-fw fa-folder-open fa-swap-opacity')" v-if="isExpanded"></i>
            <a href="#" @click="onClick(node)" class="flex-fill" :class="{'text-dark': isActive}">{{ node.name }}</a>
            <i class="fa fa-fw fa-cog" :id="target" v-show="hover" @click.stop.prevent="togglePopover" v-if="hasMenu"></i>
        </span>
        <ideo-tree-nodes :pages="pages" :expanded="expanded" :parent="node" :menu="menu" :move="move" @toggle="onToggle" @click="onClick" @sort="onSort" />
        <ideo-popover ref="popover" :show="contextMenu" :target="target" :container="container" placement="bottomright">
            <div class="dropdown">
                <div class="dropdown-menu">
                    <template v-for="(item, index) in menuItems" :key="index">
                        <div class="dropdown-divider" v-if="item.divider"></div>
                        <button type="button" :id="menuItemId(index)" class="dropdown-item" :class="item.class" @click="onMenuItem(node, item, !item.confirm)" v-else>
                            <i class="me-2 icon" :class="item.icon"></i> {{ item.name }}
                            <confirmation v-if="item.confirm" :target="menuItemId(index)" :message="item.confirm" :value="item" @confirm="onMenuItem(node, item, true)" placement="right" />
                        </button>
                    </template>
                </div>
            </div>
        </ideo-popover>
    </li>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Ref } from '@/helpers/Decorators';
import { TreeNode } from '../utils';
import IdeoPopover from '../popover/IdeoPopover.vue';

@Options({
    name: 'ideo-tree-node'
})
export default class IdeoTreeNode extends Vue
{
    public hover: boolean = false;
    public contextMenu: boolean = false;
    public menuItems: any[] = [];

    @Prop()
    public node!: TreeNode;

    @Prop()
    public pages!: TreeNode[];

    @Prop()
    public expanded!: number[];

    @Prop()
    public menu: (node: TreeNode) => Promise<any[]>;

    @Prop()
    public move: (e: any) => boolean;

    @Ref('popover')
    public popover: () => IdeoPopover;

    public get hasChildren(): boolean
    {
        return this.node.hasChildren;
    }

    public get isExpanded(): boolean
    {
        return this.node.hasChildren && this.expanded.indexOf(this.node.id) >= 0;
    }

    public get isCollapsed(): boolean
    {
        return this.node.hasChildren && this.expanded.indexOf(this.node.id) === -1;
    }

    public get isActive(): boolean
    {
        return this.$route.params.id === this.node.id.toString();
    }

    public get target(): string
    {
        return `node-menu-${this.node.id}`;
    }

    public get container(): string
    {
        return `node-container-${this.node.id}`;
    }

    public get hasMenu(): boolean
    {
        return this.menu != null;
    }

    public mounted(): void
    {
        this.$events.$on('tree::popover::hide', this.hidePopover);
    }

    public unmounted(): void
    {
        this.$events.$off('tree::popover::hide', this.hidePopover);
    }

    public async getMenu(node: TreeNode): Promise<any[]>
    {
        if (this.menu)
            return await this.menu(node);
        else
            return [];
    }

    public menuItemId(index: number): string
    {
        return `dropdown-${this.node.id}-item-${index}`;
    }

    public icon(icon: string): string
    {
        return this.node.icon ? this.node.icon : icon;
    }

    public mouseOver(event: MouseEvent): void
    {
        this.hover = true;
    }

    public mouseOut(event: MouseEvent): void
    {
        if (this.contextMenu == false)
        {
            this.hover = false;
        }
    }

    public async togglePopover(): Promise<void>
    {
        if (this.contextMenu == false)
        {
            this.$events.$emit('tree::popover::hide', this.node);
        }

        this.contextMenu = !this.contextMenu;

        if (this.contextMenu == true)
        {
            this.menuItems = await this.getMenu(this.node);

            if (this.menuItems.length == 0)
            {
                this.$alert.info(this.$t('[[[Ten element nie zawiera menu kontekstowego.]]]'));
                this.contextMenu = false;
            }
        }
    }

    public hidePopover(node: TreeNode): void
    {
        if (node === null || node != this.node)
        {
            this.menuItems = [];
            this.contextMenu = false;
            this.hover = false;
        }
    }

    @Emit('toggle')
    public onToggle(node: TreeNode): TreeNode
    {
        return node;
    }

    @Emit('click')
    public onClick(node: TreeNode): TreeNode
    {
        return node;
    }

    public onMenuItem(node: TreeNode, item: any, confirmed: boolean): void
    {
        if (confirmed)
        {
            this.$events.$emit('tree::popover::hide', node);
            this.togglePopover();
            item.click(node);
        }
    }

    @Emit('delete')
    public onDelete(node: TreeNode): TreeNode
    {
        this.$events.$emit('tree::popover::hide', node);

        return node;
    }

    @Emit('sort')
    public onSort(pages: TreeNode[]): TreeNode[]
    {
        return pages;
    }
}
</script>
