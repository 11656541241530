<template>
    <component :is="tagName" v-bind="attrs()" :class="classNames" @click="onClick">
        <slot name="default" v-if="loaded"></slot>
        <placeholder :width="[minWidth, maxWidth]" :height="14" v-else></placeholder>
    </component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Inject } from '@/helpers/Decorators';
import { only } from '@/helpers/Utils';

@Options({
    name: 'list-view-label',
    inheritAttrs: false
})
export default class ListViewLabel extends Vue
{
    @Inject('loaded')
    public loaded: boolean;

    @Inject('layout')
    public layout: string;

    @Inject('row-click')
    public rowClick: (item: any) => void;

    @Inject('index')
    public index: number;

    @Inject('item')
    public item: any;

    public get table(): boolean
    {
        return this.layout == 'table';
    }

    public get tagName(): string
    {
        return this.table ? 'td' : 'div';
    }

    public get classNames(): string
    {
        return this.table ? 'pointer' : 'd-inline';
    }

    public get minWidth(): number
    {
        return parseInt(this.$attrs.width as string) || 25;
    }

    public get maxWidth(): number
    {
        return parseInt(this.$attrs.width as string) || 50;
    }

    public onClick(): void
    {
        if (this.rowClick && this.table)
        {
            this.rowClick(this.item);
        }
    }

    public attrs(): Record<string, any>
    {
        return only(this.$attrs, 'class', 'style');
    }
}
</script>
