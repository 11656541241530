import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';

export default {
    namespaced: true,
    state: {
        jobs: {
            filter: Form.create({
                jobName: ''
            }),
            pager: new Pager(1, 20, 'JobType', 'ASC')
        },
        history: {
            filter: Form.create({
                id: '',
                dateFromUtc: null,
                dateDueUtc: null,
                stage: null
            }),
            pager: new Pager(1, 20, 'metadata.occurrence', 'DESC')
        }
    }
};
