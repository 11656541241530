<script lang="ts" src="../blueprints/Boolean.ts"></script>

<template>
    <form-component-wrapper class="boolean-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <label :for="blueprint.id" v-if="blueprint.showLabel">{{ $t(blueprint.label) }} <var class="text-danger" v-if="required">*</var></label>
                <div class="pt-1">
                    <ideo-form-radio-group v-model="value" :options="booleanValueOptions" v-if="isRadioField"></ideo-form-radio-group>
                    <ideo-form-checkbox v-model="value" v-if="isCheckboxField">{{ placeholderText }}</ideo-form-checkbox>
                    <ideo-form-checkbox v-model="value" switch v-if="isToggleField">{{ placeholderText }}</ideo-form-checkbox>
                </div>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <field-text v-model="blueprint.label" :label="$t('[[[Etykieta]]]')" />
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-choice v-model="blueprint.fieldType" :options="fieldTypeOptions" :label="$t('[[[Typ pola]]]')" />
            <field-select v-model="defaultValue" :options="booleanValueOptions" :label="$t('[[[Domyślna wartość]]]')" />
            <field-text v-model="blueprint.placeholder" :label="$t('[[[Opis]]]')" :placeholder="$t('[[[Akceptacja]]]')" v-if="!isRadioField" />
            <div class="form-group mb-0" v-if="isRadioField">
                <label>{{ $t('[[[Wartości]]]') }}</label>
                <div class="row">
                    <div class="col">
                        <field-text v-model="blueprint.falseValueText" :placeholder="$t('[[[Nie]]]')" />
                    </div>
                    <div class="col">
                        <field-text v-model="blueprint.trueValueText" :placeholder="$t('[[[Tak]]]')" />
                    </div>
                </div>
            </div>
            <field-textarea v-model="blueprint.help" :label="$t('[[[Pomoc]]]')" />
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-readonly :form="form" :blueprint="blueprint" :label="$t('[[[Tylko do odczytu]]]')" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
</style>
