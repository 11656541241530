import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint } from '../Types';
import { FormEntry } from '../types/Form';
import { AddressContract, instanceOfAddressEntry, AddressEntry } from '../types/Address';
import properties from '../properties';

@Options({
    name: 'address-blueprint',
    components: {
        ...properties
    }
})
export default class AddressBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: AddressContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public entryData: AddressEntry = new AddressEntry();

    public created(): void
    {
        this.entryData = this.form.initEntry(this.blueprint, this.entryData, instanceOfAddressEntry);
    }

    public get country(): string
    {
        return this.entryData.country;
    }

    public set country(value: string)
    {
        this.entryData.country = value;
    }

    public get province(): string
    {
        return this.entryData.province;
    }

    public set province(value: string)
    {
        this.entryData.province = value;
    }

    public get city(): string
    {
        return this.entryData.city;
    }

    public set city(value: string)
    {
        this.entryData.city = value;
    }

    public get zip(): string
    {
        return this.entryData.zip;
    }

    public set zip(value: string)
    {
        this.entryData.zip = value;
    }

    public get street(): string
    {
        return this.entryData.street;
    }

    public set street(value: string)
    {
        this.entryData.street = value;
    }

    public get building(): string
    {
        return this.entryData.building;
    }

    public set building(value: string)
    {
        this.entryData.building = value;
    }

    public get apartment(): string
    {
        return this.entryData.apartment;
    }

    public set apartment(value: string)
    {
        this.entryData.apartment = value;
    }

    public get readonly(): boolean
    {
        return this.form.readonly(this.blueprint);
    }

    public get required(): boolean
    {
        return this.form.required(this.blueprint);
    }

    public get fieldsCount(): number
    {
        return (
            Number(this.blueprint.hasCountry) +
            Number(this.blueprint.hasProvince) +
            Number(this.blueprint.hasCity) +
            Number(this.blueprint.hasZip) +
            Number(this.blueprint.hasStreet) +
            Number(this.blueprint.hasBuilding) +
            Number(this.blueprint.hasApartment)
        );
    }

    public fieldsDisabled(fieldEnabled: boolean): boolean
    {
        return fieldEnabled && this.fieldsCount <= 1;
    }
}
