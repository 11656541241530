<template>
    <div class="form-group">
        <label :for="uid" v-if="label">{{ label }}</label>
        <ideo-datetime :type="type" :id="uid" :modelValue="modelValue" @update:modelValue="update" />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { DateTime } from 'luxon';

@Options({
    name: 'field-datetime'
})
export default class FieldDateTime extends Vue
{
    @Prop() public label: string;
    @Prop() public modelValue: string;
    @Prop({ default: 'date' }) public type: string;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    @Emit('update:modelValue')
    public update(value: DateTime | null): DateTime | null
    {
        return value;
    }
}
</script>
