<template>
    <div>
        Dashboard
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'dashboard'
})
export default class Dashboard extends Vue
{
}
</script>
