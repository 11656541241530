import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    VisibleBlueprint,
    ReadonlyBlueprint,
    RequiredBlueprint,
    CustomErrorBlueprint,
    HasLabel,
    HasPlaceholder,
    HasAffix,
    HasHelp,
    HasWidth,
    MinMaxValue,
    AffixValue,
    AggregateBlueprint,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice,
    EntryFactory,
    Validatable,
    ValidationErrors,
    ValidEntry
} from '../Types';
import min from 'lodash/min';

export enum NumericFieldTypes {
    Integer = 'Integer',
    Decimal = 'Decimal',
    Percent = 'Percent'
}

export class NumericEntry extends ValidEntry
{
    public type: string = Components.NUMERIC;
    public value?: number = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.value = data.value;
        }
    }

    public async collect(blueprint: NumericContract, form: FormBuilder, preprocess: (name: string, entry: any) => Promise<void>): Promise<any>
    {
        await preprocess(blueprint.name, this);

        return {
            type: this.type,
            value: this.value || form.executeExpression(blueprint.defaultValue)
        };
    }

    public validate(blueprint: NumericContract, form: FormBuilder): boolean
    {
        this.errors = {};

        const value = this.value || form.executeExpression(blueprint.defaultValue);
        const precision = blueprint.fieldType == NumericFieldTypes.Integer ? 0 : blueprint.precision;

        if (!form.readonly(blueprint, true) && form.visible(blueprint, true))
        {
            if (form.required(blueprint) && value === null)
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${blueprint.label}]]]`];
            }
            else if (blueprint.range.min !== null && (value === null || value < blueprint.range.min))
            {
                this.errors.value = [`[[[Wybrana liczba jest mniejsza niż dozwolona %0.|||${blueprint.range.min.toFixed(precision)}]]]`];
            }
            else if (blueprint.range.max !== null && (value == null || blueprint.range.max < value))
            {
                this.errors.value = [`[[[Wybrana liczba jest większa niż dozwolona %0.|||${blueprint.range.max.toFixed(precision)}]]]`];
            }
            else if (form.customError(blueprint))
            {
                this.errors.custom = [form.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }
}

export const instanceOfNumericEntry = (object: any): object is NumericEntry =>
{
    return object && 'type' in object && object.type === Components.NUMERIC;
};

export interface NumericContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasPlaceholder, HasAffix, HasHelp, HasWidth
{
    fieldType: NumericFieldTypes;
    defaultValue: string;
    range: MinMaxValue;
    precision: number;
    step: number;
}

export class NumericType implements NumericContract, Validatable, EntryFactory<NumericEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public placeholder: string;
    public fieldType: NumericFieldTypes;
    public defaultValue: string;
    public range: MinMaxValue;
    public precision: number;
    public step: number;
    public affix: AffixValue;
    public help: string;
    public width: number;
    public minWidth: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.NUMERIC;
        this.name = builder.name(Components.NUMERIC);
        this.label = '[[[Liczba]]]';
        this.showLabel = true;
        this.placeholder = '';
        this.defaultValue = null;
        this.range = { min: 0, max: 1000000 };
        this.precision = 0;
        this.step = 1;
        this.affix = { prepend: '', append: '' };
        this.help = '';
        this.fieldType = NumericFieldTypes.Integer;
        this.width = min([3, builder.space(parent)]);
        this.minWidth = 1;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public createEntry(data: any): NumericEntry
    {
        return new NumericEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}

export const increment = (step: number, precision: number): number =>
{
    if (step === 0)
        return 1 / Math.max(Math.pow(10, precision), 1);
    else
        return step;
};
