import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { Option } from '@/helpers/Interfaces';
import { FormBuilder } from '../Utils';
import { FormContract, FormEntry, instanceOfFormType, ProgressChoice } from '../types/Form';
import { PageContract } from '../types/Page';
import properties from '../properties';
import { reactive } from 'vue';

@Options({
    name: 'form-blueprint',
    components: {
        ...properties
    }
})
export default class FormBlueprint extends Vue
{
    @Prop({ default: false }) public design: boolean;
    @Prop({ default: false }) public internal: boolean;
    @Prop({ default: null, required: true }) public model: FormContract;
    @Prop({ default: () => { return {}; } }) public value: FormEntry;

    public get blueprint(): FormContract
    {
        return reactive(this.model);
    }

    public get entry(): FormEntry
    {
        return reactive(this.value);
    }

    public get form(): FormBuilder
    {
        if (!instanceOfFormType(this.blueprint))
            throw new Error(this.$t('Form.vue: blueprint property is not of type FormType.'));

        return reactive(new FormBuilder(this.blueprint, this.entry, this.internal, this.design)) as FormBuilder;
    }

    public get pages(): PageContract[]
    {
        return (this.blueprint.components as PageContract[]).filter(p => this.design || this.form.visible(p));
    }

    public get selected(): boolean
    {
        return this.form.isFormSelected(this.blueprint);
    }

    public get progressTypeOptions(): Option<ProgressChoice>[]
    {
        return [
            { value: ProgressChoice.Steps, text: '[[[Kroki]]]' },
            { value: ProgressChoice.Bar, text: '[[[Pasek]]]' },
            { value: ProgressChoice.None, text: '[[[Brak]]]' }
        ];
    }

    public get progressChoice(): typeof ProgressChoice
    {
        return ProgressChoice;
    }

    public created(): void
    {
        if (this.blueprint.components.length == 0)
        {
            this.form.addPage(this.blueprint);
        }
    }

    public addPage(before: PageContract): void
    {
        this.form.addPage(this.blueprint, before);
    }

    public isValid(): boolean
    {
        return this.form.isValid();
    }

    public selectForm(): void
    {
        this.form.selectForm(this.blueprint);
    }

    public currentPage(page: PageContract): boolean
    {
        return this.blueprint.components[this.blueprint.currentPage - 1] == page;
    }

    @Emit('submit')
    public submit(): void
    {
    }

    @Watch('selected')
    private onSelectedChanged(selected: boolean): void
    {
        if (selected == true)
        {
            this.form.offsetTop(this);
        }
    }

    @Watch('blueprint')
    private onBlueprintChanged(selected: boolean): void
    {
        if (this.design == true)
        {
            this.$nextTick(() =>
            {
                this.selectForm();
            });
        }
    }
}
