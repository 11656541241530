import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint } from '../Types';
import { FormEntry } from '../types/Form';
import { SignatureContract } from '../types/Signature';
import properties from '../properties';

@Options({
    name: 'signature-blueprint',
    components: {
        ...properties
    }
})
export default class SignatureBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: SignatureContract;
    @Prop({ default: null }) public entry!: FormEntry;
}
