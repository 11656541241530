<template>
    <div class="form-group">
        <label :for="uid">{{ label }}</label>
        <div class="row g-0 pb-2">
            <div class="col-2 p-2 text-end">
                {{ $t('[[[od]]]') }}
            </div>
            <div class="col-10">
                <ideo-datetime :type="type" :id="`${uid}-min`" :modelValue="modelValue.min" @update:modelValue="updateMin" />
            </div>
        </div>
        <div class="row g-0">
            <div class="col-2 p-2 text-end">
                {{ $t('[[[do]]]') }}
            </div>
            <div class="col-10">
                <ideo-datetime :type="type" :id="`${uid}-max`" :modelValue="modelValue.max" @update:modelValue="updateMax" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { PeriodValue } from '../Types';
import { DateTime } from 'luxon';

@Options({
    name: 'field-period'
})
export default class FieldPeriod extends Vue
{
    @Prop() public label!: string;
    @Prop() public modelValue!: PeriodValue;
    @Prop({ default: 'date' }) public type: string;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public maxDate(firstDate: DateTime | null, secondDate: DateTime | null): DateTime
    {
        if (firstDate === null)
            return secondDate;

        if (secondDate === null)
            return firstDate;

        return DateTime.min(firstDate, secondDate);
    }

    public updateMin(value: DateTime | null): void
    {
        this.update({
            min: value,
            max: this.modelValue.max
        });
    }

    public updateMax(value: DateTime | null): void
    {
        this.update({
            min: this.modelValue.min,
            max: value
        });
    }

    @Emit('update:modelValue')
    public update(value: PeriodValue): PeriodValue
    {
        if (value.min !== null && value.max !== null && value.min > value.max)
        {
            value.max = value.min;
        }

        return value;
    }
}
</script>
