<template>
    <component :is="layoutName" v-bind="attrs()" :pager="pager" :headers="headers" :rows="rows" :show-header="showHeader" @change="onSorting" :multiple="multiple">``
        <template #row="{item, index}">
            <slot name="row" :item="item" :index="index"></slot>
        </template>
    </component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Provide, Watch } from '@/helpers/Decorators';
import Pager from '@/helpers/Pager';
import CardLayout from './layouts/CardLayout.vue';
import FlexLayout from './layouts/FlexLayout.vue';
import TableLayout from './layouts/TableLayout.vue';
import { only } from '@/helpers/Utils';
import { isObject } from 'lodash';
import ListMultipleElements from '@/helpers/List';

@Options({
    name: 'list-view',
    inheritAttrs: false,
    components: {
        'list-view-card-layout': CardLayout,
        'list-view-flex-layout': FlexLayout,
        'list-view-table-layout': TableLayout
    }
})
export default class ListView extends Vue
{
    public paging: boolean = false;

    @Provide('loaded')
    public loaded: boolean = true;

    @Prop({ default: (): any[] => [] })
    public items!: any[];

    @Prop({ default: () => new Pager(1) })
    public pager: Pager;

    @Prop({ default: true })
    public showHeader: boolean;

    @Prop({ default: true })
    public preload: boolean;

    @Prop({
        default: 'auto',
        validator: (value: string) => ['auto', 'table', 'flex', 'card'].includes(value)
    })
    public layout: string;

    @Prop({ default: () => '[[[Brak wyników]]]' })
    @Provide('empty-label')
    public emptyLabel: string;

    @Prop()
    @Provide('row-click')
    public rowClick: (item: any) => void;

    @Prop()
    @Provide('row-class')
    public rowClass: (item: any) => Record<string, boolean> | string[] | string;

    @Prop({ default: null }) public multiple: ListMultipleElements<any> | null;

    @Provide('layout')
    public get currentLayout(): string
    {
        if (this.layout == 'auto')
        {
            return this.mobile ? 'flex' : 'table';
        }

        return this.layout;
    }

    public get selectMultipleActive(): boolean
    {
        return this.multiple !== null && this.items.length !== 0 && this.currentLayout === 'table';
    }

    public get layoutName(): string
    {
        return `list-view-${this.currentLayout}-layout`;
    }

    public get headers(): any[]
    {
        if ('row' in this.$slots)
        {
            const data = this.getProxy();
            const cols = this.$slots.row({ item: data }).filter((p: any) => isObject(p.type) && p.type.name.startsWith('list-view-'));

            if (this.selectMultipleActive)
                return [{ checkbox: true }, ...cols.map((p: any) => p.props || {})];
            else
                return cols.map((p: any) => p.props || {});
        }

        return [];
    }

    public get rows(): any[]
    {
        if (this.preload == true && this.loaded == false)
        {
            const data = this.getProxy();

            return Array.from(Array(5), () => data);
        }
        else
        {
            return this.items;
        }
    }

    public getProxy(): typeof Proxy
    {
        const base = {
            [Symbol.toPrimitive]: (hint: string): any =>
            {
                switch (hint)
                {
                    case 'string': return '__PROXY__';
                    case 'number': return 0;
                    default: return null;
                }
            },
            [Symbol.toStringTag]: (): any => '',
            '__v_isRef': null as any
        };
        const handler: ProxyHandler<any> = {
            get: (target, property, receiver) =>
            {
                return Reflect.has(target, property) ? Reflect.get(target, property, receiver) : new Proxy(base, handler);
            },
            has: (target, property) =>
            {
                return Reflect.has(target, property);
            }
        };

        return new Proxy(base, handler);
    }

    public attrs(): Record<string, any>
    {
        return only(this.$attrs, 'class', 'style');
    }

    @Watch('pager.pageIndex')
    @Watch('pager.pageSize')
    @Watch('pager.sorting')
    @Watch('pager.order')
    public onPagerChanged(pager: Pager): void
    {
        this.paging = true;
    }

    @Watch('items')
    public onItemsChanged(items: any[], old: any[]): void
    {
        if (this.paging == true)
        {
            this.paging = false;
            this.loaded = true;
        }

        if (this.loaded == true && items.length == 0)
        {
            this.loaded = false;
        }
        else if (this.loaded == false)
        {
            this.loaded = true;
        }

        this.multiple?.update(this.items);
    }

    public mounted(): void
    {
        this.multiple?.update(this.items);
    }

    @Emit('change')
    public onSorting(header: any): void
    {
        const sort = header.sort || '';

        if (this.pager.sorting === sort)
        {
            this.pager.order = this.pager.order === 'ASC' ? 'DESC' : 'ASC';
        }
        else
        {
            this.pager.sort(header.sort || '');
        }
    }
}
</script>
