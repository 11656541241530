import { App, Plugin } from "vue";
import { AxiosInstance } from 'axios';
import VueProgressBar from '@aacassandra/vue3-progressbar';
import { RouteLocationNormalized as Route, Router } from 'vue-router';

interface Progress
{
    start(): void;
    finish(): void;
    wait(): void;
    resume(): void;
}

export class ProgressHelper implements Progress
{
    private axios: AxiosInstance;
    private router: Router;
    private options: any;
    private progress: any;
    private started: boolean = false;
    private waiting: number = 0;
    private timeout: any;
    private delay: number = 500;

    public constructor(app: App<any>, options: any)
    {
        const vue = app.config.globalProperties;

        this.axios = vue.axios;
        this.router = vue.$router;
        this.options = options;

        this.initProgress(app);
        this.initAxios();
        this.initRouter();
    }

    private initProgress(app: App<any>): void
    {
        app.use(VueProgressBar, this.options);
        this.progress = app.config.globalProperties.$Progress;
    }

    private initAxios(): void
    {
        this.axios.interceptors.request.use(config =>
        {
            this.wait();
            this.start();

            return config;
        });

        this.axios.interceptors.response.use(
            response =>
            {
                this.finish();
                this.resume();

                return response;
            },
            error =>
            {
                this.fail();
                this.resume();

                return Promise.reject(error);
            }
        );
    }

    private initRouter(): void
    {
        this.router.beforeEach((to: Route, from: Route, next: any) =>
        {
            this.start();
            next();
        });
        this.router.afterEach((to: Route, from: Route) =>
        {
            this.finish();
        });
    }

    private clear(): void
    {
        if (this.timeout)
        {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    private fail(): void
    {
        this.clear();

        this.timeout = setTimeout(() =>
        {
            this.progress.finish();
            this.progress.fail();
            this.started = false;
        },
        this.delay);
    }

    public start(): void
    {
        this.clear();

        if (this.started === false)
        {
            this.progress.start();
            this.started = true;
        }
    }

    public finish(): void
    {
        this.clear();

        this.timeout = setTimeout(() =>
        {
            this.progress.finish();
            this.started = false;
        },
        this.delay);
    }

    public wait(): void
    {
        if (this.waiting <= 0)
        {
            document.querySelector('body').classList.add('waiting');
        }

        this.waiting++;
    }

    public resume(): void
    {
        this.waiting--;

        if (this.waiting <= 0)
        {
            document.querySelector('body').classList.remove('waiting');
            this.waiting = 0;
        }
    }
}

const ProgressPlugin: Plugin =
{
    install(app, options)
    {
        const vue = app.config.globalProperties;

        if (!vue.$router)
        {
            throw new Error("Vue:router must be set.");
        }

        if (!vue.axios)
        {
            throw new Error("Vue:axios must be set.");
        }

        app.config.globalProperties.$progress = new ProgressHelper(app, options);
    }
};

export default ProgressPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $progress: Progress;
    }
}
