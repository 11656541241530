<script lang="ts" src="../blueprints/Numeric.ts"></script>

<template>
    <form-component-wrapper class="numeric-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <label :for="blueprint.id" v-if="blueprint.showLabel">{{ $t(blueprint.label) }} <var class="text-danger" v-if="required">*</var></label>
                <ideo-input-group :prepend="prepend" :append="append">
                    <input type="number" class="form-control" :min="min" :max="max" :step="step" :value="display" @input="update" :id="blueprint.id" :placeholder="blueprint.placeholder" :readonly="readonly">
                </ideo-input-group>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <field-text v-model="blueprint.label" :label="$t('[[[Etykieta]]]')" />
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-choice v-model="blueprint.fieldType" :options="fieldTypeOptions" :label="$t('[[[Typ pola]]]')" />
            <field-condition v-model="blueprint.defaultValue" :form="form" :label="$t('[[[Wartość domyślna]]]')" type="number" />
            <field-text v-model="blueprint.placeholder" :label="$t('[[[Tekst zastępczy]]]')" />
            <field-affix v-model="blueprint.affix" :append="!isPercent" />
            <div class="row">
                <div class="col-6">
                    <field-numeric v-model="blueprint.step" :label="$t('[[[Wielkość kroku]]]')" :precision="precision" :min="0" :append="append" />
                </div>
                <div class="col-6">
                    <field-numeric v-model="blueprint.precision" :label="$t('[[[Miejsca po przecinku]]]')" :min="0" :max="10" v-if="!isInteger" />
                </div>
            </div>
            <field-minmax v-model="blueprint.range" :label="$t('[[[Zasięg]]]')" :step="step" :precision="precision" :append="append" />
            <field-textarea v-model="blueprint.help" :label="$t('[[[Pomoc]]]')" />
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-readonly :form="form" :blueprint="blueprint" :label="$t('[[[Tylko do odczytu]]]')" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
</style>
