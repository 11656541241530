import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    VisibleBlueprint,
    HasLabel,
    HasHelp,
    HasWidth,
    AggregateBlueprint,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice,
    Validatable,
    ValidationErrors
} from '../Types';
import { min } from 'lodash';

export interface SignatureContract extends Blueprint, VisibleBlueprint, HasLabel, HasHelp, HasWidth
{
}

export class SignatureType implements SignatureContract, Validatable
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public help: string;
    public width: number;
    public minWidth: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.SIGNATURE;
        this.name = builder.name(Components.SIGNATURE);
        this.label = '[[[Podpis]]]';
        this.help = '';
        this.showLabel = true;
        this.width = min([3, builder.space(parent)]);
        this.minWidth = 1;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
