import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';
import { FilterModel } from "../services/ChatService";

export default {
    namespaced: true,
    state: {
        filter: Form.create<FilterModel>({
            name: '',
            propertyName: '',
            status: ['Active']
        }),
        pager: new Pager(1, 20),
        users: {
            filter: Form.create({
                name: ''
            }),
            pager: new Pager(1, 20, 'Id', 'ASC')
        },
        messages: {
            filter: Form.create({
                authorName: '',
                dateFromUtc: null,
                dateToUtc: null,
                message: ''
            }),
            pager: new Pager(1, 20, 'DateCreatedUtc', 'DESC')
        },
        removeNotification: null as string
    }
};
