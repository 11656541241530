import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint } from '../Types';
import { FormEntry } from '../types/Form';
import { BooleanFieldTypes, BooleanContract, BooleanEntry, instanceOfBooleanEntry } from '../types/Boolean';
import properties from '../properties';
import { Option } from '@/helpers/Interfaces';

@Options({
    name: 'boolean-blueprint',
    components: {
        ...properties
    }
})
export default class BooleanBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: BooleanContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public entryData: BooleanEntry = new BooleanEntry();

    public created(): void
    {
        this.entryData = this.form.initEntry(this.blueprint, this.entryData, instanceOfBooleanEntry);
    }

    public get value(): boolean
    {
        return this.entryData.value === null
            ? this.blueprint.defaultValue
            : this.entryData.value;
    }

    public set value(value: boolean)
    {
        this.entryData.value = value;
    }

    public get booleanValueOptions(): Option<boolean>[]
    {
        return [
            { value: true, text: this.trueValueText },
            { value: false, text: this.falseValueText }
        ];
    }

    public get defaultValue(): any
    {
        return this.blueprint.defaultValue;
    }

    public set defaultValue(value: any)
    {
        this.blueprint.defaultValue = JSON.parse(value);
    }

    public get falseValueText(): string
    {
        return this.blueprint.falseValueText || this.$t('[[[Nie]]]');
    }

    public get trueValueText(): string
    {
        return this.blueprint.trueValueText || this.$t('[[[Tak]]]');
    }

    public get placeholderText(): string
    {
        return this.blueprint.placeholder || this.$t('[[[Akceptacja]]]');
    }

    public get fieldTypeOptions(): Option<BooleanFieldTypes>[]
    {
        return [
            { value: BooleanFieldTypes.Radio, text: '[[[Radio]]]' },
            { value: BooleanFieldTypes.Checkbox, text: '[[[Checkbox]]]' },
            { value: BooleanFieldTypes.Toggle, text: '[[[Przełącznik]]]' }
        ];
    }

    public get fieldType(): typeof BooleanFieldTypes
    {
        return BooleanFieldTypes;
    }

    public get isRadioField(): boolean
    {
        return this.blueprint.fieldType == BooleanFieldTypes.Radio;
    }

    public get isCheckboxField(): boolean
    {
        return this.blueprint.fieldType == BooleanFieldTypes.Checkbox;
    }

    public get isToggleField(): boolean
    {
        return this.blueprint.fieldType == BooleanFieldTypes.Toggle;
    }

    public get required(): boolean
    {
        return this.form.required(this.blueprint);
    }

    @Watch('blueprint.defaultValue')
    protected onDefaultValueChanged(value: boolean, old: boolean): void
    {
        if (value != old)
        {
            this.entryData.value = this.defaultValue;
        }
    }
}
