<template>
    <div>
        <select class="form-control" v-model="locale" @change="manualLocaleChange()">
            <option v-for="(locale, i) in locales" :value="locale.value" :key="i">
                {{ $t(locale.text) }}
            </option>
        </select>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import AuthService from '@/modules/core/auth/services/AuthService';
import { Watch } from '@/helpers/Decorators';
import { Option } from '@/helpers/Interfaces';

@Options({})
export default class Locales extends Vue
{
    public locale: string = null;

    public formatLocales(locale: string): string
    {
        return locale.slice(0, 2).toUpperCase();
    }

    public get locales(): Option<string>[]
    {
        return Object.entries(this.$i18n.languages()).map(([key, value]) => ({ value: key, text: value }));
    }

    public get preferedLanguage(): string
    {
        return this.$auth.user()?.preferedLanguage;
    }

    public mounted(): void
    {
        this.locale =  this.preferedLanguage != null ? this.preferedLanguage : this.$i18n.locale();
    }

    @Watch('locale')
    public localeChange(locale: string): void
    {
        this.$i18n.setLocale(locale);
    }

    public manualLocaleChange(): void
    {
        AuthService.updatePreferedLanguage(this.locale);
    }
}
</script>

<style lang="scss" scoped>
select.form-control {
    width: 95%;
    margin-left: 5px;
}
</style>
