import { DateTime } from 'luxon';

export enum Components {
    FORM = 'form',
    PAGE = 'page',
    ROW = 'row',

    TEXT = 'text',
    CHOICE = 'choice',
    BOOLEAN = 'boolean',
    DATE = 'date',
    NUMERIC = 'numeric',
    ATTACHMENTS = 'attachments',
    CONTENT = 'content',
    SECTION = 'section',
    SPACER = 'spacer',
    LINE = 'line',

    EMAIL = 'email',
    URL = 'url',
    ADDRESS = 'address',
    PHONE = 'phone',
    PERSONAL = 'personal',
    SIGNATURE = 'signature',
    LIKERT = 'likert',
    DICTIONARY = 'dictionary'
}

export interface FormBuilderContract
{
}

// --------------------------------------------------

export enum AlwaysChoice {
    Always = 'Always'
}
export enum NeverChoice {
    Never = 'Never'
}
export enum InternallyChoice {
    Internally = 'Internally'
}
export enum WhenChoice {
    When = 'When'
}

// --------------------------------------------------

export interface MinMaxValue
{
    min: number;
    max: number;
}

export interface PeriodValue
{
    min?: DateTime;
    max?: DateTime;
}

export interface ChoiceOption
{
    value: string;
    text: string;
    selected: boolean;
}

export interface AffixValue
{
    prepend: string;
    append: string;
}

// --------------------------------------------------

export interface Blueprint
{
    id: string;
    type: string;
    name: string;
}

export const instanceOfBlueprint = (object: any): object is Blueprint =>
{
    return object && 'id' in object && 'type' in object && 'name' in object;
};

export interface AggregateBlueprint extends Blueprint
{
    components: Blueprint[];
}

export const instanceOfAggregateBlueprint = (object: any): object is AggregateBlueprint =>
{
    return instanceOfBlueprint(object) && 'components' in object;
};

export interface VisibleBlueprint extends Blueprint
{
    visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    visibleWhen: string;
}

export const instanceOfVisibleBlueprint = (object: any): object is VisibleBlueprint =>
{
    return instanceOfBlueprint(object) && 'visible' in object;
};

export interface ReadonlyBlueprint extends Blueprint
{
    readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    readonlyWhen: string;
}

export const instanceOfReadonlyBlueprint = (object: any): object is ReadonlyBlueprint =>
{
    return instanceOfBlueprint(object) && 'readonly' in object;
};

export interface RequiredBlueprint extends Blueprint
{
    required: AlwaysChoice | NeverChoice | WhenChoice;
    requiredWhen: string;
}

export const instanceOfRequiredBlueprint = (object: any): object is RequiredBlueprint =>
{
    return instanceOfBlueprint(object) && 'required' in object;
};

export interface CustomErrorBlueprint extends Blueprint
{
    customError: NeverChoice | WhenChoice;
    customErrorWhen: string;
    customErrorMessage: string;
}

export const instanceOfCustomErrorBlueprint = (object: any): object is CustomErrorBlueprint =>
{
    return instanceOfBlueprint(object) && 'customError' in object;
};

export interface ValidationErrors extends Record<string, string[]>
{
}

export interface Validatable
{
    errors: ValidationErrors;
    validate(): Record<string, ValidationErrors>;
}

export const instanceOfValidatable = (object: any): object is Validatable =>
{
    return object && 'validate' in object && typeof object.validate === 'function';
};

export const validateComponents = (components: Blueprint[]): Record<string, ValidationErrors> =>
{
    let errors = {};

    components.forEach(component =>
    {
        if (instanceOfValidatable(component))
            errors = { ...errors, ...component.validate() };
    });

    return errors;
};

export const setValidationErrors = (blueprint: Blueprint, errors: Record<string, string[]>): void =>
{
    if (instanceOfValidatable(blueprint))
    {
        const items = {};

        Object.entries(errors).forEach(([key, messages]) =>
        {
            const name = `${blueprint.name.toLowerCase()}.`;

            if (key.startsWith(name))
            {
                items[key.substring(name.length)] = messages;
            }
        });

        blueprint.errors = items;
    }

    if (instanceOfAggregateBlueprint(blueprint))
    {
        blueprint.components.forEach(component =>
        {
            setValidationErrors(component, errors);
        });
    }
};

export interface HasTitle extends Blueprint
{
    title: string;
    showTitle: boolean;
}

export const instanceOfHasTitle = (object: any): object is HasTitle =>
{
    return object && 'title' in object && 'showTitle' in object;
};

export interface HasDescription extends Blueprint
{
    description: string;
}

export const instanceOfHasDescription = (object: any): object is HasDescription =>
{
    return object && 'description' in object;
};

export interface HasLabel extends Blueprint
{
    label: string;
    showLabel: boolean;
}

export const instanceOfHasLabel = (object: any): object is HasLabel =>
{
    return object && 'label' in object && 'showLabel' in object;
};

export interface HasPlaceholder extends Blueprint
{
    placeholder: string;
}

export const instanceOfHasPlaceholder = (object: any): object is HasPlaceholder =>
{
    return object && 'placeholder' in object;
};

export interface HasHelp extends Blueprint
{
    help: string;
}

export const instanceOfHasHelp = (object: any): object is HasHelp =>
{
    return object && 'help' in object;
};

export interface HasWidth extends Blueprint
{
    width: number;
    minWidth: number;
    exceptWidth?: number[];
}

export const instanceOfHasWidth = (object: any): object is HasWidth =>
{
    return object && 'width' in object && 'minWidth' in object;
};

export interface HasAffix extends Blueprint
{
    affix: AffixValue;
}

export const instanceOfHasAffix = (object: any): object is HasAffix =>
{
    return object && 'affix' in object;
};

export interface HasRepetitions extends Blueprint
{
    repeatable: boolean;
    itemLabel: string;
    itemsRange: MinMaxValue;
}

export const instanceOfHasRepetitions = (object: any): object is HasRepetitions =>
{
    return object && 'repeatable' in object;
};

export interface EntryFactory<T>
{
    createEntry(data: any): T;
}

export const instanceOfEntryFactory = (object: any): object is EntryFactory<any> =>
{
    return object && 'createEntry' in object && typeof object.createEntry === 'function';
};

// --------------------------------------------------

export abstract class ValidEntry
{
    public abstract type: string;
    public errors: ValidationErrors;

    public abstract collect(blueprint: Blueprint, form: FormBuilderContract, preprocess: (name: string, entry: any) => Promise<void>): Promise<ValidEntry>;
    public abstract validate(blueprint: Blueprint, form: FormBuilderContract): boolean;

    public constructor()
    {
        this.errors = {};
    }

    public valid(): boolean
    {
        return Object.keys(this.errors).length == 0;
    }

    public errorMessage(name: string): string
    {
        return name in this.errors && this.errors[name].length > 0 ? this.errors[name][0] : null;
    }
}

export const instanceOfValidEntry = (object: any): object is ValidEntry =>
{
    return object && typeof object === 'object' && 'validate' in object && typeof object.validate === 'function';
};
