import { Plugin } from 'vue';
import { createToastInterface, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

export interface Alerts
{
    close(): void;
    success(message: string, options?: any): void;
    info(message: string, options?: any): void;
    warning(message: string, options?: any): void;
    danger(message: string, options?: any): void;
    error(message: string, options?: any): void;
    show(message: any, options?: any): void;
}

class MessagesHelper implements Alerts
{
    private toast: any;

    public constructor(toast: any)
    {
        this.toast = toast;
    }

    public close(): void
    {
        this.toast.clear();
    }

    public success(message: string, options: any = {}): void
    {
        this.toast.success(message, options);
    }

    public info(message: string, options: any = {}): void
    {
        this.toast.info(message, options);
    }

    public warning(message: string, options: any = {}): void
    {
        this.toast.warning(message, options);
    }

    public danger(message: string, options: any = {}): void
    {
        this.toast.error(message, options);
    }

    public error(message: string, options: any = {}): void
    {
        this.toast.error(message, options);
    }

    public show(message: any, options: any = {}): void
    {
        this.toast(message, options);
    }
}

const AlertsPlugin: Plugin =
{
    install(app, options)
    {
        const toast = createToastInterface({
            // eventBus: new Vue(),
            position: POSITION.TOP_CENTER,
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
        });

        app.config.globalProperties.$alert = new MessagesHelper(toast);
    }
};

export default AlertsPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $alert: Alerts;
    }
}
