<template>
    <div class="theme-mode d-flex align-self-center">
        <button type="button" class="btn d-flex" @click="changeMode(switchToTheme.id)">
            <i :class="switchToTheme.icon" />
        </button>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { $t } from '@/plugins/localization';

interface Theme {
    name: string;
    icon: string;
    id: number;
}

@Options({})
export default class ThemeMode extends Vue
{
    public themesList: Theme[] = [
        {
            name: $t('[[[Default mode]]]'),
            icon: 'fa-solid fa-lightbulb-on',
            id: 0
        },
        {
            name: $t('[[[Dark mode]]]'),
            icon: 'fa-solid fa-lightbulb-slash',
            id: 1
        }
    ];

    public changeMode(id: number): void
    {
        this.$store.commit('common/theme/changeMode', id);
    }

    public get switchToTheme(): Theme
    {
        return this.themesList.find(({ id }) => id !== this.themeModeId);
    }

    public get themeModeId(): number
    {
        return this.$store.state.common.theme.themeId;
    }
}
</script>

<style lang="scss">
.theme-dropdown .dropdown-menu {
    margin-top: 10px !important;
}
.btn-dropdown-white {
    box-shadow: none !important;
    background: transparent !important;
}
</style>
