<template>
    <div class="account d-flex">
        <div class="d-flex align-self-center">
            <ideo-dropdown ref="menu" variant="light" toggle-class="btn-dropdown-white" right no-caret>
                <template #button-content>
                    <a href="javascript:;" class="user-avatar">
                        <!-- <ideo-img :src="$filters.image(facePictureUrl, 'ico')" rounded="circle" :blank="!facePictureUrl" width="36" height="36" blank-color="#777" :alt="$t('[[[Zdjęcie użytkownika]]]')" /> -->
                        <i class="fa-solid fa-circle-user"></i>
                    </a>
                </template>
                <ideo-dropdown-item @click.prevent="profile()">
                    <i class="fa fa-fw fa-user me-2"></i> <span class="ms-2">{{ $t('[[[Profil użytkownika]]]') }}</span>
                </ideo-dropdown-item>
                <ideo-dropdown-divider></ideo-dropdown-divider>
                <ideo-dropdown-item @click.prevent="password()">
                    <i class="fa fa-fw fa-wrench me-2"></i> <span class="ms-2">{{ $t('[[[Zmiana hasła]]]') }}</span>
                </ideo-dropdown-item>
                <ideo-dropdown-item @click.prevent="notifications()">
                    <i class="fa fa-fw fa-bell me-2"></i> <span class="ms-2">{{ $t('[[[Powiadomienia]]]') }}</span>
                </ideo-dropdown-item>
                <ideo-dropdown-item @click.prevent="loginPlaces()">
                    <i class="fa fa-fw fa-key mr-2"></i> <span class="ml-2">{{ $t('[[[Miejsce logowania]]]') }}</span>
                </ideo-dropdown-item>
                <ideo-dropdown-item @click.prevent="logout()">
                    <i class="fa fa-arrow-right-from-bracket"></i> <span class="ml-2">{{ $t('[[[Wyloguj się]]]') }}</span>
                </ideo-dropdown-item>
                <substitutions />
                <div class="dropdown-divider"></div>
                <locales />
            </ideo-dropdown>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Substitutions from '@/views/partials/layout/Substitutions.vue';
import Locales from '@/views/partials/layout/Locales.vue';
import IdeoDropdown from '@/components/bootstrap/dropdown/IdeoDropdown.vue';
import { Ref } from '@/helpers/Decorators';

@Options({
    components: {
        'substitutions': Substitutions,
        'locales': Locales
    }
})
export default class Account extends Vue
{
    @Ref()
    public menu: () => IdeoDropdown;

    public get facePictureUrl(): string
    {
        return this.$auth.user()?.facePicture ? this.$auth.user().facePicture.url : null;
    }

    public mounted(): void
    {
        window.addEventListener('click', this.click);
    }

    public unmounted(): void
    {
        window.removeEventListener('click', this.click);
    }

    public profile(): void
    {
        this.redirect({ name: 'core-account-profile' });
        this.click();
    }

    public password(): void
    {
        this.redirect({ name: 'core-account-password' });
        this.click();
    }

    public notifications(): void
    {
        this.redirect({ name: 'core-account-notifications' });
        this.click();
    }

    public loginPlaces(): void
    {
        this.redirect({ name: 'core-account-devices' });
        this.click();
    }

    public logout(): void
    {
        this.$auth.logout();
        this.click();
    }

    public click(): void
    {
        this.menu().hide();
    }
}
</script>

<style lang="scss">
.account .dropdown-menu {
    width: 180px;
    white-space: normal;
}
</style>
