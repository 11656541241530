import { Form } from "@/helpers/Form";
import type { FilterModel } from "../types/index";

export default {
    namespaced: true,
    state: {
        filter: Form.create<FilterModel>({
            searchDateUtc: null,
            citiesIds: [],
            districtPublicId: null,
            regionPublicId: null,
            showAllCleaningTask: false
        })
    }
};
