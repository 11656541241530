import * as yup from 'yup';
export * as Validator from 'yup';

export default class ValidationSchema
{
    public string(): yup.StringSchema
    {
        return yup.string();
    }

    public number(): yup.NumberSchema
    {
        return yup.number();
    }

    public boolean(): yup.BooleanSchema
    {
        return yup.boolean();
    }

    public date(): yup.DateSchema
    {
        return yup.date();
    }

    public array(schema: yup.AnySchema): yup.ArraySchema<yup.AnySchema>
    {
        return yup.array().of(schema);
    }

    public object(shape: any): yup.ObjectSchema<any>
    {
        return yup.object().shape(shape);
    }
}

yup.setLocale({
    mixed: {
        test: '[[[Wprowadzona wartość jest nieprawidłowa]]]',
        required: '[[[To pole jest wymagane]]]'
    },
    string: {
        email: '[[[Nieprawidłowy adres email]]]',
        url: '[[[Nieprawidłowy adres URL]]]',
        min: '[[[Wprowadzona wartość jest za krótka]]]',
        max: '[[[Wprowadzona wartość jest za długa]]]',
        matches: '[[[Wprowadzona wartość nie jest poprawna]]]'
    },
    number: {
        min: '[[[Wprowadzona wartość jest za niska]]]',
        max: '[[[Wprowadzona wartość jest za wysoka]]]',
        lessThan: '[[[Wprowadzona wartość jest za wysoka]]]',
        moreThan: '[[[Wprowadzona wartość jest za niska]]]',
        positive: '[[[Wartość musi być liczbą dodatnią]]]',
        negative: '[[[Wartość musi być liczbą ujemną]]]',
        integer: '[[[Wartość musi być liczbą całkowitą]]]'
    }
});
