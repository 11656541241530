import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint } from '../Types';
import { FormEntry } from '../types/Form';
import { UrlContract, instanceOfUrlEntry, UrlEntry } from '../types/Url';
import properties from '../properties';

@Options({
    name: 'url-blueprint',
    components: {
        ...properties
    }
})
export default class UrlBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: UrlContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public entryData: UrlEntry = new UrlEntry();

    public created(): void
    {
        this.entryData = this.form.initEntry(this.blueprint, this.entryData, instanceOfUrlEntry);
    }

    public get value(): string
    {
        return this.entryData.value === null
            ? this.form.executeExpression(this.blueprint.defaultValue)
            : this.entryData.value;
    }

    public set value(value: string)
    {
        this.entryData.value = value;
    }

    public get readonly(): boolean
    {
        return this.form.readonly(this.blueprint);
    }

    public get required(): boolean
    {
        return this.form.required(this.blueprint);
    }
}
