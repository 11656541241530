import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    VisibleBlueprint,
    ReadonlyBlueprint,
    RequiredBlueprint,
    CustomErrorBlueprint,
    HasLabel,
    HasHelp,
    HasWidth,
    AggregateBlueprint,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice,
    EntryFactory,
    Validatable,
    ValidationErrors,
    ValidEntry
} from '../Types';
import min from 'lodash/min';

export class AddressEntry extends ValidEntry
{
    public type: string = Components.ADDRESS;
    public country?: string = null;
    public province?: string = null;
    public city?: string = null;
    public zip?: string = null;
    public street?: string = null;
    public building?: string = null;
    public apartment?: string = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.country = data.country;
            this.province = data.province;
            this.city = data.city;
            this.zip = data.zip;
            this.street = data.street;
            this.building = data.building;
            this.apartment = data.apartment;
        }
    }

    public async collect(blueprint: AddressContract, form: FormBuilder, preprocess: (name: string, entry: any) => Promise<void>): Promise<any>
    {
        await preprocess(blueprint.name, this);

        return {
            type: this.type,
            country: this.country,
            province: this.province,
            city: this.city,
            zip: this.zip,
            street: this.street,
            building: this.building,
            apartment: this.apartment
        };
    }

    public validate(blueprint: AddressContract, form: FormBuilder): boolean
    {
        this.errors = {};

        if (!form.readonly(blueprint, true) && form.visible(blueprint, true))
        {
            if (form.required(blueprint))
            {
                if (blueprint.hasCountry && (this.country == null || this.country.length == 0))
                    this.errors.country = [`[[[Pole "%0" jest wymagane.|||${blueprint.getCountryLabel()}]]]`];

                if (blueprint.hasProvince && (this.province == null || this.province.length == 0))
                    this.errors.province = [`[[[Pole "%0" jest wymagane.|||${blueprint.getProvinceLabel()}]]]`];

                if (blueprint.hasCity && (this.city == null || this.city.length == 0))
                    this.errors.city = [`[[[Pole "%0" jest wymagane.|||${blueprint.getCityLabel()}]]]`];

                if (blueprint.hasZip && (this.zip == null || this.zip.length == 0))
                    this.errors.zip = [`[[[Pole "%0" jest wymagane.|||${blueprint.getZipLabel()}]]]`];

                if (blueprint.hasStreet && (this.street == null || this.street.length == 0))
                    this.errors.street = [`[[[Pole "%0" jest wymagane.|||${blueprint.getStreetLabel()}]]]`];

                if (blueprint.hasBuilding && (this.building == null || this.building.length == 0))
                    this.errors.building = [`[[[Pole "%0" jest wymagane.|||${blueprint.getBuildingLabel()}]]]`];
            }
            else if (form.customError(blueprint))
            {
                this.errors.custom = [form.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }
}

export const instanceOfAddressEntry = (object: any): object is AddressEntry =>
{
    return object && 'type' in object && object.type === Components.ADDRESS;
};

export interface AddressContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasHelp, HasWidth
{
    hasCountry: boolean;
    hasProvince: boolean;
    hasCity: boolean;
    hasZip: boolean;
    hasStreet: boolean;
    hasBuilding: boolean;
    hasApartment: boolean;
    countryLabel?: string;
    provinceLabel?: string;
    cityLabel?: string;
    zipLabel?: string;
    streetLabel?: string;
    buildingLabel?: string;
    apartmentLabel?: string;
    getCountryLabel(): string;
    getProvinceLabel(): string;
    getCityLabel(): string;
    getZipLabel(): string;
    getStreetLabel(): string;
    getBuildingLabel(): string;
    getApartmentLabel(): string;
}

export class AddressType implements AddressContract, Validatable, EntryFactory<AddressEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public hasCountry: boolean;
    public hasProvince: boolean;
    public hasCity: boolean;
    public hasZip: boolean;
    public hasStreet: boolean;
    public hasBuilding: boolean;
    public hasApartment: boolean;
    public countryLabel?: string;
    public provinceLabel?: string;
    public cityLabel?: string;
    public zipLabel?: string;
    public streetLabel?: string;
    public buildingLabel?: string;
    public apartmentLabel?: string;
    public help: string;
    public width: number;
    public minWidth: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.ADDRESS;
        this.name = builder.name(Components.ADDRESS);
        this.label = '[[[Adres]]]';
        this.showLabel = true;
        this.hasCountry = true;
        this.hasProvince = true;
        this.hasCity = true;
        this.hasZip = true;
        this.hasStreet = true;
        this.hasBuilding = true;
        this.hasApartment = true;
        this.help = '';
        this.width = min([3, builder.space(parent)]);
        this.minWidth = 3;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public getCountryLabel(): string { return this.countryLabel || '[[[Kraj]]]'; }
    public getProvinceLabel(): string { return this.provinceLabel || '[[[Województwo]]]'; }
    public getCityLabel(): string { return this.cityLabel || '[[[Miejscowość]]]'; }
    public getZipLabel(): string { return this.zipLabel || '[[[Kod pocztowy]]]'; }
    public getStreetLabel(): string { return this.streetLabel || '[[[Ulica]]]'; }
    public getBuildingLabel(): string { return this.buildingLabel || '[[[Numer domu]]]'; }
    public getApartmentLabel(): string { return this.apartmentLabel || '[[[Numer mieszkania]]]'; }

    public createEntry(data: any): AddressEntry
    {
        return new AddressEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
