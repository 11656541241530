<script lang="ts" src="../blueprints/Page.ts"></script>

<template>
    <form-component :class="{'page-component': design, 'selected': selected}" @click.stop="selectPage()" :form="form" :blueprint="blueprint" v-if="visible">
        <design-status :form="form" :blueprint="blueprint" />
        <div class="header" v-if="showHeader">
            <h2 v-if="blueprint.showTitle || design" :class="{'text-secondary': !blueprint.showTitle}">
                {{ $t(blueprint.title) || pageNumberLabel }}
            </h2>
            <p class="description" v-if="blueprint.description">{{ blueprint.description }}</p>
        </div>
        <template v-for="(component, index) in rows" :key="component.id">
            <design-ruller :title="$t('[[[Wstaw kontrolkę]]]')" :form="form" :before="component" @insert="addRow" @add="addComponent" @drop="dropComponent" v-if="component.components.length > 0" />
            <component :is="form.designer(component.type)" :form="form" :parent="blueprint" :blueprint="rows[index]" :entry="entry"></component>
        </template>
        <div class="page-component-footer" v-if="showFooter">
            <hr class="mt-0">
            <div class="row" :class="{'g-0': design}">
                <div class="col-5 text-start">
                    <button class="btn btn-light" :disabled="design" @click.stop.prevent="prevPage()" v-if="!isFirst">
                        {{ blueprint.prevButtonText || $t('[[[Wstecz]]]') }}
                    </button>
                </div>
                <div class="col-2 text-center align-self-center">
                    <span v-if="parent.showPageNumbers">{{ pageNumberLabel }}</span>
                </div>
                <div class="col-5 text-end">
                    <button class="btn btn-primary" :disabled="design" @click.stop.prevent="nextPage()" v-if="!isLast">
                        {{ blueprint.nextButtonText || $t('[[[Dalej]]]') }}
                    </button>
                    <button class="btn btn-primary" :disabled="design" @click.stop.prevent="submit()" v-if="isLast && parent.showSendButton">
                        {{ parent.sendButtonText || $t('[[[Wyślij]]]') }}
                    </button>
                </div>
            </div>
        </div>
        <design-toolbar v-if="design && toolbar">
            <div class="btn-toolbar m-auto">
                <div class="btn-group">
                    <button type="button" class="btn btn-secondary" :title="$t('[[[Przesuń do góry]]]')" :disabled="isFirst" @click.stop.prevent="form.moveUp(blueprint)">
                        <i class="far fa-arrow-up"></i>
                    </button>
                    <button type="button" class="btn btn-secondary" :title="$t('[[[Przesuń na dół]]]')" :disabled="isLast" @click.stop.prevent="form.moveDown(blueprint)">
                        <i class="far fa-arrow-down"></i>
                    </button>
                </div>
                <div class="btn-group">
                    <button type="button" class="btn btn-danger" :title="$t('[[[Usuń]]]')" @click.stop.prevent="form.removeComponent(blueprint)">
                        <i class="far fa-trash"></i>
                    </button>
                </div>
            </div>
        </design-toolbar>
        <portal to="form-toolbox" v-if="selected">
            <design-panel :form="form" :key="blueprint.name">
                <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
                <field-text v-model="blueprint.title" :label="$t('[[[Tytuł]]]')" />
                <field-checkbox v-model="blueprint.showTitle" :label="$t('[[[Pokaż tytuł strony]]]')" />
                <field-textarea v-model="blueprint.description" :label="$t('[[[Opis]]]')" />
                <field-text v-model="blueprint.prevButtonText" :label="$t('[[[Nazwa przycisku ``Wstecz``]]]')" :placeholder="$t('[[[Wstecz]]]')" />
                <field-text v-model="blueprint.nextButtonText" :label="$t('[[[Nazwa przycisku ``Dalej``]]]')" :placeholder="$t('[[[Dalej]]]')" />
                <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            </design-panel>
        </portal>
    </form-component>
</template>

<style lang="scss" scoped>
.page-component {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 10px 0 10px 0;
    border: 2px solid var(--border-color);
    box-shadow: 3px 3px 0px 0px rgba($gray-900, 0.05);

    &.selected {
        border-color: $success;
        box-shadow: 3px 3px 0px 0px rgba($success, 0.15);
    }

    .header {
        padding: 10px 10px 5px 10px;
    }
    .page-component-footer {
        padding: 5px 10px 10px 10px;
    }
    .description {
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align: justify;
    }
}
.header {
    border: none;
}
</style>
