<template>
    <div class="form-group">
        <label :for="uid" v-if="label">{{ label }}</label>
        <ideo-input-group :append="append">
            <input type="number" :min="rangeMin" :max="rangeMax" :step="increment" :id="uid" class="form-control" :placeholder="placeholder" :value="display" @input="update" @focus="focus" @blur="blur">
        </ideo-input-group>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { increment } from '../types/Numeric';

@Options({
    name: 'field-numeric'
})
export default class FieldNumeric extends Vue
{
    @Prop() public label: string;
    @Prop() public modelValue: number;
    @Prop() public placeholder: string;
    @Prop() public append: string;
    @Prop({ default: false }) public naturals: boolean;
    @Prop({ default: false }) public nullable: boolean;
    @Prop({ default: 0 }) public step: number;
    @Prop({ default: 0 }) public precision: number;
    @Prop({ default: () => Number.MIN_SAFE_INTEGER + 1 }) public min: number;
    @Prop({ default: () => Number.MAX_SAFE_INTEGER - 1 }) public max: number;

    public focused: boolean = false;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public get display(): string
    {
        return this.modelValue !== null ? this.modelValue.toFixed(this.precision) : null;
    }

    public get increment(): number
    {
        return increment(this.step, this.precision);
    }

    public get rangeMin(): number
    {
        return this.naturals === true ? Math.max(0, this.min) : this.min;
    }

    public get rangeMax(): number
    {
        return this.max;
    }

    @Emit('update:modelValue')
    public triggerInput(value: number): number
    {
        return value;
    }

    public update(e: any): void
    {
        if (!this.focused)
        {
            if (e.target.value !== '' || this.nullable === false)
                this.triggerInput(parseFloat(e.target.value || 0));
            else
                this.triggerInput(null);

            this.$forceUpdate();
        }
    }

    public focus(e: any): void
    {
        this.focused = true;
    }

    public blur(e: any): void
    {
        this.focused = false;
        this.update(e);
    }
}
</script>
