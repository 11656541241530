import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    VisibleBlueprint,
    ReadonlyBlueprint,
    RequiredBlueprint,
    CustomErrorBlueprint,
    HasLabel,
    HasHelp,
    HasWidth,
    PeriodValue,
    AggregateBlueprint,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice,
    EntryFactory,
    ValidationErrors,
    Validatable,
    ValidEntry
} from '../Types';
import min from 'lodash/min';
import { DateTime } from 'luxon';
import { formatDateTime } from '@/helpers/Utils';

export enum DateFieldTypes {
    DateTime = 'DateTime',
    Date = 'Date'
}

export class DateEntry extends ValidEntry
{
    public type: string = Components.DATE;
    public value?: DateTime = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.value = data.value;
        }
    }

    public async collect(blueprint: DateContract, form: FormBuilder, preprocess: (name: string, entry: any) => Promise<void>): Promise<any>
    {
        await preprocess(blueprint.name, this);

        return {
            type: this.type,
            value: this.value || form.executeExpression(blueprint.defaultValue)
        };
    }

    public validate(blueprint: DateContract, form: FormBuilder): boolean
    {
        this.errors = {};

        const value = this.value || form.executeExpression(blueprint.defaultValue);
        const dateTime = blueprint.fieldType == DateFieldTypes.DateTime;

        if (!form.readonly(blueprint, true) && form.visible(blueprint, true))
        {
            if (form.required(blueprint) && value == null)
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${blueprint.label}]]]`];
            }
            else if (blueprint.period.min !== null && (value == null || value < blueprint.period.min))
            {
                const date = formatDateTime(blueprint.period.min, dateTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd');

                this.errors.value = [`[[[Wybrana data jest mniejsza niż dozwolona %0.|||${date}]]]`];
            }
            else if (blueprint.period.max !== null && (value == null || blueprint.period.max < value))
            {
                const date = formatDateTime(blueprint.period.max, dateTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd');

                this.errors.value = [`[[[Wybrana data jest większa niż dozwolona %0.|||${date}]]]`];
            }
            else if (form.customError(blueprint))
            {
                this.errors.custom = [form.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }
}

export const instanceOfDateEntry = (object: any): object is DateEntry =>
{
    return object && 'type' in object && object.type === Components.DATE;
};

export interface DateContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasHelp, HasWidth
{
    fieldType: DateFieldTypes;
    defaultValue: string;
    period: PeriodValue;
}

export class DateType implements DateContract, Validatable, EntryFactory<DateEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public fieldType: DateFieldTypes;
    public defaultValue: string;
    public period: PeriodValue;
    public help: string;
    public width: number;
    public minWidth: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.DATE;
        this.name = builder.name(Components.DATE);
        this.label = '[[[Data]]]';
        this.showLabel = true;
        this.defaultValue = null;
        this.period = { min: null, max: null };
        this.help = '';
        this.fieldType = DateFieldTypes.DateTime;
        this.width = min([3, builder.space(parent)]);
        this.minWidth = 1;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public createEntry(data: any): DateEntry
    {
        return new DateEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
