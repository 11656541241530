import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Pagination, Statement, Resource, EntityVersion } from '@/helpers/Interfaces';
import { PublicationEnum } from '@/helpers/Enums';
import { DateTime } from 'luxon';
import { FormContract, FormType } from '@/components/forms/types/Form';

/**
 * DynamicFormsService
 */
export default class DynamicFormsService
{
    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/forms', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/forms/${id}`)).data;
    }

    /**
     * @param pager Pager
     * @param categoryId number | null
     * @param search string
     *
     * @returns Promise<Pagination<FormOptionModel>>
     */
    public static async getOptions(pager: Pager, categoryId: number | null, id: number | null, search: string = null): Promise<Pagination<FormOptionModel>>
    {
        return (await axios.get<Pagination<FormOptionModel>>('admin/forms/options', {
            params: merge({}, pager.data(), { category: categoryId, id: id, search: search })
        })).data;
    }

    /**
     * @param pager Pager
     * @param categoryId number | null
     * @param search string
     *
     * @returns Promise<Pagination<FormOptionModel>>
     */
    public static async getOptionsPublished(pager: Pager, categoryId: number | null, id: number | null, search: string = null): Promise<Pagination<FormOptionModel>>
    {
        return (await axios.get<Pagination<FormOptionModel>>('admin/forms/options/published', {
            params: merge({}, pager.data(), { category: categoryId, id: id, search: search })
        })).data;
    }

    /**
     * @param versionId number
     *
     * @returns Promise<FormType>
     */
    public static async fetchDefinition(versionId: number): Promise<FormType>
    {
        return (await axios.get<FormType>(`admin/forms/definition/${versionId}`)).data;
    }

    /**
     * @param model UpsertModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async create(model: UpsertModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`admin/forms`, model)).data;
    }

    /**
     * @param id number
     * @param model UpsertModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async update(id: number, model: UpsertModel): Promise<Resource<FormModel>>
    {
        return (await axios.put<Resource<FormModel>>(`admin/forms/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/forms/${id}`)).data as Statement;
    }

    /**
     * @param id number
     * @param status PublicationEnum
     *
     * @returns Promise<Statement>
     */
    public static async status(id: number, status: PublicationEnum): Promise<Statement>
    {
        return (await axios.patch(`admin/forms/${id}/status`, {
            status: status
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Pagination<EntityVersion>>
     */
    public static async getVersions(id: number, pager: Pager): Promise<Pagination<EntityVersion>>
    {
        return (await axios.get<Pagination<EntityVersion>>(`admin/forms/${id}/versions`, {
            params: pager.data()
        })).data;
    }

    /**
     * @param filter CategoryFilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<CategoryItemModel>>
     */
    public static async getCategories(filter: CategoryFilterModel, pager: Pager): Promise<Pagination<CategoryItemModel>>
    {
        return (await axios.get<Pagination<CategoryItemModel>>(`admin/forms/categories`, {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<CategoryModel>>
     */
    public static async fetchCategory(id: number): Promise<Resource<CategoryModel>>
    {
        return (await axios.get<Resource<CategoryModel>>(`admin/forms/categories/${id}`)).data;
    }

    /**
     * @param model CategoryFormModel
     *
     * @returns Promise<CategoryModel>
     */
    public static async addCategory(model: CategoryModel): Promise<CategoryModel>
    {
        return (await axios.post<CategoryModel>(`admin/forms/categories`, model)).data;
    }

    /**
     * @param model FormModel
     *
     * @returns Promise<Statement>
     */
    public static async updateCategory(model: CategoryModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/forms/categories/${model.id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async removeCategory(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/forms/categories/${id}`)).data as Statement;
    }
}

export interface ListItemModel
{
    id: number;
    dateCreatedUtc: DateTime;
    dateModifiedUtc: DateTime;
    createdBy: string;
    modifiedBy: string;
    title: string;
    category: string;
    status: string;
    isArchived: boolean;
    ownerId: number;
    resourceId: number;
}

export interface FilterModel
{
    categoryId?: number;
    userId: number;
    status: string;
    title: string;
    dateFromUtc?: DateTime;
    dateDueUtc?: DateTime;
    showArchived: boolean;
    onlyMine: boolean;
}

export interface UpsertModel
{
    id: number;
    definition: FormContract;
    isArchived: boolean;
}

export interface FormModel extends UpsertModel
{
    status: PublicationEnum;
    ownerId: number;
    resourceId: number;
}

export interface FormOptionModel
{
    value: number;
    text: string;
    isDisabled: boolean;
}

export interface CategoryFilterModel
{
    name: string;
}

export interface CategoryItemModel
{
    id: number;
    name: string;
    position: number;
}

export interface CategoryModel
{
    id: number;
    name: string;
    position: number;
}
