<script lang="ts" src="../blueprints/Form.ts"></script>

<template>
    <form-component :class="{'form-component': true, 'design': design, 'selected': selected}" @click.stop="selectForm()" :form="form" :blueprint="blueprint">
        <div class="form-component-header" :class="{'design': design}">
            <h1 v-if="blueprint.showTitle || design" :class="{'text-secondary': !blueprint.showTitle}">
                {{ $t(blueprint.title) || '...' }}
            </h1>
            <p class="description" v-if="blueprint.description">{{ blueprint.description }}</p>
            <ol class="list-inline" v-if="pages.length > 1 && blueprint.progressType == progressChoice.Steps">
                <li
                    class="list-inline-item border-bottom border-2 px-5 py-2 my-0 me-1"
                    :class="{
                        'border-primary text-primary': currentPage(page),
                        'border-secondary text-muted': !currentPage(page)
                    }"
                    v-for="(page, index) in pages" :key="page.id"
                >
                    <span class="font-weight-bold">{{ index + 1 }}.</span>
                    <span class="ms-2" v-if="blueprint.showPageTitles">{{ page.title || $t('[[[Strona %0|||{0}]]]', index + 1) }}</span>
                </li>
            </ol>
            <ul class="nav nav-pills nav-justified border border-secondary rounded-pill" v-if="pages.length > 1 && blueprint.progressType == progressChoice.Bar">
                <li class="nav-item p-1" v-for="(page, index) in pages" :key="page.id">
                    <span class="nav-link rounded-pill p-1" :class="{'bg-light text-primary': currentPage(page)}">
                        <span v-if="blueprint.showPageTitles">{{ page.title || $t('[[[Strona %0|||{0}]]]', index + 1) }}</span>
                    </span>
                </li>
            </ul>
        </div>
        <template v-for="(component, index) in blueprint.components" :key="component.id">
            <design-ruller :title="$t('[[[Wstaw stronę]]]')" :form="form" :before="component" @insert="addPage" />
            <component :is="form.designer(component.type)" :form="form" :parent="blueprint" :blueprint="blueprint.components[index]" :entry="entry" @submit="submit()" />
        </template>
        <div class="form-component-footer" v-if="design">
            <div class="d-grid">
                <button class="btn btn-light no-shadow" :title="$t('[[[Dodaj stronę]]]')" @click.stop.prevent="form.addPage(blueprint)">
                    <i class="fa fa-plus me-2"></i>
                </button>
            </div>
        </div>
        <portal to="form-toolbox" v-if="design && selected">
            <design-panel :form="form" :key="blueprint.name">
                <h3>{{ $t('[[[Formularz]]]') }}</h3>
                <hr>
                <field-text v-model="blueprint.title" :label="$t('[[[Tytuł]]]')" :invalid-feedback="() => form.errorMessage(blueprint, 'title')" />
                <field-checkbox v-model="blueprint.showTitle" :label="$t('[[[Pokaż tytuł formularza]]]')" />
                <field-category v-model="blueprint.category" :label="$t('[[[Kategoria]]]')" :invalid-feedback="() => form.errorMessage(blueprint, 'category')" />
                <field-textarea v-model="blueprint.description" :label="$t('[[[Opis]]]')" />
                <field-checkbox v-model="blueprint.showPageNumbers" :label="$t('[[[Pokaż numery stron]]]')" />
                <field-checkbox v-model="blueprint.showSendButton" :label="$t('[[[Pokaż przycisk ``Wyślij``]]]')" />
                <field-text v-model="blueprint.sendButtonText" :label="$t('[[[Nazwa przycisku ``Wyślij``]]]')" :placeholder="$t('[[[Wyślij]]]')" v-if="blueprint.showSendButton" />
                <field-choice v-model="blueprint.progressType" :options="progressTypeOptions" :label="$t('[[[Pasek postępu]]]')" v-if="blueprint.components.length > 1" />
                <field-checkbox v-model="blueprint.showPageTitles" :label="$t('[[[Pokaż tytuły stron]]]')" v-if="blueprint.components.length > 1 && blueprint.progressType != progressChoice.None" />
            </design-panel>
        </portal>
    </form-component>
</template>

<style lang="scss" scoped>
.form-component {
    &.design {
        padding: 10px;
        margin: -10px;
        border: 2px solid transparent;
    }
    &.selected {
        border-color: $success;
    }
    .form-component-header {
        margin-bottom: 20px;

        &.design {
            margin: 0 10px 20px 10px;
        }
    }
    .form-component-footer {
        margin: 5px 0 0 0;
    }
    .description {
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align: justify;
    }
}
</style>
