import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    VisibleBlueprint,
    ReadonlyBlueprint,
    RequiredBlueprint,
    CustomErrorBlueprint,
    HasLabel,
    HasHelp,
    HasWidth,
    AggregateBlueprint,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice,
    EntryFactory,
    Validatable,
    ValidationErrors,
    ValidEntry
} from '../Types';
import min from 'lodash/min';

export class PersonalEntry extends ValidEntry
{
    public type: string = Components.PERSONAL;
    public title?: string = null;
    public givenName?: string = null;
    public middleName?: string = null;
    public surname?: string = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.title = data.title;
            this.givenName = data.givenName;
            this.middleName = data.middleName;
            this.surname = data.surname;
        }
    }

    public async collect(blueprint: PersonalContract, form: FormBuilder, preprocess: (name: string, entry: any) => Promise<void>): Promise<any>
    {
        await preprocess(blueprint.name, this);

        return {
            type: this.type,
            title: this.title,
            givenName: this.givenName,
            middleName: this.middleName,
            surname: this.surname
        };
    }

    public validate(blueprint: PersonalContract, form: FormBuilder): boolean
    {
        this.errors = {};

        if (!form.readonly(blueprint, true) && form.visible(blueprint, true))
        {
            if (form.required(blueprint))
            {
                if (blueprint.hasTitle && (this.title == null || this.title.length == 0))
                    this.errors.title = ['[[[Pole "Tytuł" jest wymagane.]]]'];

                if (this.givenName == null || this.givenName.length == 0)
                    this.errors.givenName = ['[[[Pole "Imię" jest wymagane.]]]'];

                if (blueprint.hasMiddleName && (this.middleName == null || this.middleName.length == 0))
                    this.errors.middleName = ['[[[Pole "Drugie imię" jest wymagane.]]]'];

                if (this.surname == null || this.surname.length == 0)
                    this.errors.surname = ['[[[Pole "Nazwisko" jest wymagane.]]]'];
            }
            else if (form.customError(blueprint))
            {
                this.errors.custom = [form.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }
}

export const instanceOfPersonalEntry = (object: any): object is PersonalEntry =>
{
    return object && 'type' in object && object.type === Components.PERSONAL;
};

export interface PersonalContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasHelp, HasWidth
{
    hasTitle: boolean;
    hasMiddleName: boolean;
}

export class PersonalType implements PersonalContract, Validatable, EntryFactory<PersonalEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public hasTitle: boolean;
    public hasMiddleName: boolean;
    public help: string;
    public width: number;
    public minWidth: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.PERSONAL;
        this.name = builder.name(Components.PERSONAL);
        this.label = '[[[Dane osobowe]]]';
        this.showLabel = true;
        this.hasTitle = false;
        this.hasMiddleName = false;
        this.help = '';
        this.width = min([3, builder.space(parent)]);
        this.minWidth = 3;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public createEntry(data: any): PersonalEntry
    {
        return new PersonalEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
