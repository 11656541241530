import { Form } from "@/helpers/Form";
import Pager from "@/helpers/Pager";
import { FilterModel as TimeSheetFilterModel, UserStatus } from "../services/TimeSheetService";
import { FilterModel as AbsencesFilterModel } from "../services/AbsencesService";
import { FilterModel as EmployeesListFilterModel } from "../services/EmployeesService";
import { FilterCitiesStatisticsModel, FilterCountryStatisticsModel, FilterRegionsStatisticsModel, ListFilterModel} from "../services/StatisticsService";
import { DateTime } from "luxon";

const firstDayOfThisMonth = DateTime.utc().startOf('month');

export default {
    namespaced: true,
    state: {
        timeSheet: {
            filter: Form.create<TimeSheetFilterModel>({
                regionPublicId: null,
                cityPublicId: null,
                userPublicId: null,
                department: null,
                districtIds: [],
                range: UserStatus.All,
            }),
            pager: new Pager(1, 20, 'GivenName', 'ASC'),
            refetchEmployees: false
        },
        absences: {
            filter: Form.create<AbsencesFilterModel>({
                dateFromUtc: null,
                dateToUtc: null
            }),
            pager: new Pager(1, 20, 'UserFullName', 'ASC')
        },
        hk: {
            pagerMissing: new Pager(1, 10, 'surname', 'ASC'),
            pagerHours: new Pager(1, 10, 'user.surname', 'ASC'),
            pagerMonth: new Pager(1, 10, 'user.surname', 'ASC'),
            pagerTasks: new Pager(1, 10, 'user.surname', 'ASC'),
            pagerMixed: new Pager(1, 10, 'user.surname', 'ASC'),
        },
        maintenance: {
            pagerMissing: new Pager(1, 10, 'surname', 'ASC'),
            pagerHours: new Pager(1, 10, 'user.surname', 'ASC'),
            pagerMonth: new Pager(1, 10, 'user.surname', 'ASC'),
        },
        coordinator: {
            pagerMissing: new Pager(1, 10, 'surname', 'ASC'),
            pagerMonth: new Pager(1, 10, 'user.surname', 'ASC'),
        },
        driver: {
            pagerMissing: new Pager(1, 10, 'surname', 'ASC'),
            pagerHours: new Pager(1, 10, 'user.surname', 'ASC'),
        },
        employees: {
            filter: Form.create<EmployeesListFilterModel>({
                searchDate: firstDayOfThisMonth
            }),
            pager: new Pager(1, 20, 'UserFullName', 'ASC')
        },
        statistics: {
            pagerEmployees: new Pager(1, 20, 'UserFullName', 'ASC'),
            filterEmployees: Form.create<ListFilterModel>({
                searchDate: firstDayOfThisMonth,
                cityPublicId: null,
                regionPublicId: null,
                department: 'default',
                userPublicId: null,
            }),
            filterCitiesStatistics: Form.create<FilterCitiesStatisticsModel>({
                searchDate: firstDayOfThisMonth,
                cityPublicId: null
            }),
            filterRegionsStatistics: Form.create<FilterRegionsStatisticsModel>({
                searchDate: firstDayOfThisMonth,
                regionPublicId: null
            }),
            filterCountryStatistics: Form.create<FilterCountryStatisticsModel>({
                searchDate: firstDayOfThisMonth
            })
        },
        statisticsViewPermissions: {},
    }
};
