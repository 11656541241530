import { Plugin, reactive } from 'vue';
import trim from 'lodash/trim';
import { RouteLocationNormalizedLoaded as Route } from 'vue-router';
import { Permissions } from '@/plugins/permissions';

export class SitemapOptions
{
    public sitemap: any;
}

export class SitemapBuilder
{
    private permissions: Permissions = null;
    private sitemap: any = null;

    public constructor(permissions: Permissions, options: SitemapOptions)
    {
        this.permissions = permissions;
        this.sitemap = options.sitemap;
    }

    public purge(): void
    {
        this.permissions.purge();
    }

    protected inspect(items: any[], namespace: string = ''): Record<string, boolean>
    {
        let permissions: Record<string, boolean> = {};

        for (let i = 0; i < items.length; i++)
        {
            const item = items[i];
            const ns = item.namespace || namespace;

            if (item.auth && item.auth.all)
            {
                (item.auth.all as string[]).forEach(p => { permissions[trim(`${ns}.${p}`, '.')] = false; });
            }

            if (item.auth && item.auth.any)
            {
                (item.auth.any as string[]).forEach(p => { permissions[trim(`${ns}.${p}`, '.')] = false; });
            }

            if (item.children)
            {
                permissions = Object.assign(permissions, this.inspect(item.children, ns));
            }
        }

        return permissions;
    }

    protected async verify(permissions: Record<string, boolean>): Promise<Record<string, boolean>>
    {
        return await this.permissions.get(Object.keys(permissions));
    }

    protected clone(items: any[]): any[]
    {
        return JSON.parse(JSON.stringify(items));
    }

    protected apply(items: any[], permissions: Record<string, boolean>, namespace: string = ''): any[]
    {
        for (let i = 0; i < items.length; i++)
        {
            const item = items[i];
            const ns = item.namespace || namespace;

            item.parent = item.parent || null;
            item.allowed = item.parent ? item.parent.allowed : true;
            item.visible = item.visible == undefined ? true : item.visible;
            item.children = item.children == undefined ? [] : item.children;

            if (item.allowed && item.auth && item.auth.all && item.auth.all.length > 0)
            {
                item.allowed = this.all((item.auth.all as string[]).map(p => trim(`${ns}.${p}`, '.')), permissions);
            }

            if (item.allowed && item.auth && item.auth.any && item.auth.any.length > 0)
            {
                item.allowed = this.any((item.auth.any as string[]).map(p => trim(`${ns}.${p}`, '.')), permissions);
            }

            if (item.children.length > 0)
            {
                item.children.forEach((p: any) => { p.parent = item; });
                this.apply(item.children, permissions, ns);
            }

            item.visible = item.allowed && this.visible(item);
        }

        return items;
    }

    protected all(required: string[], permissions: Record<string, boolean>): boolean
    {
        return required.every(p => permissions[p] == true);
    }

    protected any(required: string[], permissions: Record<string, boolean>): boolean
    {
        return required.some(p => permissions[p] == true);
    }

    protected visible(node: any): boolean
    {
        return (node.route || node.url) ? node.visible : node.visible && this.visibleChildren(node);
    }

    protected visibleChildren(node: any): boolean
    {
        return node.children && node.children.some((p: any) => this.visible(p));
    }

    public async build(): Promise<any[]>
    {
        let permissions = this.inspect(this.sitemap.items);

        permissions = await this.verify(permissions);

        let items = this.clone(this.sitemap.items);

        items = this.apply(items, permissions);

        return items;
    }
}

export interface Sitemap
{
    extraCrumb: string;
    purge(): void;
    all(): Promise<any[]>;
    find(route: Route): Promise<any>;
    crumbs(route: Route): Promise<any[]>;
    path(node: any): any[];
    active(node: any, route: Route, recursive: boolean): boolean;
    url(node: any, route: Route): any;
}

class SitemapHelper implements Sitemap
{
    private reactiveData = reactive({ extraCrumb: '' });
    private builder: SitemapBuilder;
    private sitemap: Promise<any[]>;

    public constructor(builder: SitemapBuilder)
    {
        this.builder = builder;
    }

    public get extraCrumb(): string
    {
        return this.reactiveData.extraCrumb;
    }

    public set extraCrumb(value: string)
    {
        this.reactiveData.extraCrumb = value;
    }

    public purge(): void
    {
        this.builder.purge();
        this.sitemap = null;
    }

    public async all(): Promise<any[]>
    {
        if (!this.sitemap)
        {
            this.sitemap = this.builder.build();
        }

        return await this.sitemap;
    }

    public async find(route: Route): Promise<any>
    {
        const find = (nodes: any[], route: Route): any =>
        {
            let result: any = null;

            for (let i = 0; i < nodes.length; i++)
            {
                if (this.active(nodes[i], route))
                {
                    result = nodes[i];
                }
                else if (nodes[i].children)
                {
                    result = result || find(nodes[i].children, route);
                }

                if (result != null)
                {
                    break;
                }
            }

            return result;
        };

        return find(await this.all(), route);
    }

    public async crumbs(route: Route): Promise<any[]>
    {
        const node = await this.find(route);

        return this.path(node);
    }

    public path(node: any): any[]
    {
        const nodes: any[] = [];

        while (node)
        {
            nodes.push(node);
            node = node.parent;
        }

        return nodes.reverse();
    }

    public active(node: any, route: Route, recursive: boolean = false): boolean
    {
        if (node.url === route.fullPath)
        {
            return true;
        }
        else if (node.route === route.name)
        {
            return true;
        }
        else if (recursive == true && node.children.length > 0)
        {
            for (let i = 0; i < node.children.length; i++)
            {
                if (this.active(node.children[i], route, recursive))
                {
                    return true;
                }
            }
        }

        return false;
    }

    public url(node: any, route: Route): any
    {
        if (node.visible && node.allowed)
        {
            if (node.route)
            {
                return { name: node.route };
            }
            else if (node.url)
            {
                return node.url;
            }
        }

        return route.fullPath;
    }
}

const SitemapPlugin: Plugin =
{
    install(app, options)
    {
        const vue = app.config.globalProperties;

        if (!vue.$permissions)
        {
            throw new Error("Vue:permissions must be set.");
        }

        if (!options || !options.sitemap)
        {
            throw new Error("SitemapOptions.sitemap must be set.");
        }

        const builder = new SitemapBuilder(vue.$permissions, options);

        vue.$sitemap = new SitemapHelper(builder);
    }
};

export default SitemapPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $sitemap: Sitemap;
    }
}
