<template>
    <select class="form-select" v-model="innerValue">
        <slot name="first"></slot>
        <slot name="default">
            <ideo-form-select-option v-for="(option, i) in options" :value="optionValue(option)" :key="i" :disabled="isDisabled(option)">
                {{ option[textField] }}
            </ideo-form-select-option>
        </slot>
    </select>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import IdeoFormSelectOption from './IdeoFormSelectOption.vue';

@Options({
    name: 'ideo-form-select',
    components: {
        'ideo-form-select-option': IdeoFormSelectOption
    }
})
export default class IdeoFormSelect extends Vue
{
    public currentValue: any = null;

    @Prop()
    public modelValue: any;

    @Prop({ default: 'value' })
    public valueField: string;

    @Prop({ default: 'text' })
    public textField: string;

    @Prop({ default: 'disabled' })
    public disabledField: string;

    @Prop({ default: (): any => [] })
    public options: Record<string, any>[];

    public get innerValue(): any
    {
        return this.modelValue;
    }

    public set innerValue(value: any)
    {
        this.$emit('update:modelValue', value);
    }

    public isDisabled(option: any): boolean
    {
        if (this.disabledField in option)
        {
            return option[this.disabledField];
        }

        return false;
    }

    public optionValue(option: Record<string, any>): any
    {
        return option[this.valueField];
    }
}
</script>
