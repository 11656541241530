<template>
    <div class="c-app" :class="themeStyle">
        <div class="d-flex h-100 justify-content-center align-items-center" v-if="!$auth.ready()">
            <loader></loader>
        </div>
        <router-view v-else />
        <vue-progress-bar></vue-progress-bar>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Watch } from '@/helpers/Decorators';
import Loader from '@/components/common/Loader.vue';
import Version from './Version.vue';
import axios from 'axios';

@Options({
    components: {
        'loader': Loader
    }
})
export default class App extends Vue
{
    public appHash: string = '';
    public frequency: number = 2 * 60 * 1000;
    public themeStyles: string[] = ['default-theme', 'c-dark-theme'];

    public async created(): Promise<void>
    {
        this.onHashChanged('1.2.2', '1.2.1');

        if (import.meta.env.PROD)
        {
            await this.checkVersion(`${location.origin}/manifest.webmanifest`);
        }
    }

    public async loadManifest(url: string): Promise<string>
    {
        try
        {
            const http = axios.create();
            const time = new Date();

            time.setSeconds(0);
            time.setMilliseconds(0);

            return (await http.get(`${url}?t=${time.getTime()}`)).data.hash;
        }
        catch (ex)
        {
            this.handleException(ex, {
                0: (ex: any) => this.$alert.danger(ex.message)
            });

            return '';
        }
    }

    public async checkVersion(url: string): Promise<void>
    {
        this.appHash = await this.loadManifest(url);

        setInterval(async () =>
        {
            this.appHash = await this.loadManifest(url);
        },
        this.frequency);
    }

    @Watch('appHash')
    public onHashChanged(value: string, old: string): void
    {
        // Chwilowo zakomentowane ze wzgledu na zadanie #135697
        // if (value != old && old)
        // {
        //     const content = {
        //         component: Version,
        //         props: {
        //             message: this.$t('[[[Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk "Aktualizuj". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.]]]'),
        //             update: this.$t('[[[Aktualizuj]]]')
        //         },
        //         listeners: {
        //             reload: () =>
        //             {
        //                 location.reload(true);
        //             }
        //         }
        //     };

        //     this.$alert.show(content, {
        //         type: "error",
        //         timeout: false,
        //         closeButton: false,
        //         closeOnClick: false,
        //         draggable: false
        //     });
        // }
    }

    public get themeStyle(): string
    {
        const id = this.$store.state.common.theme.themeId;

        return this.themeStyles[id];
    }
}
</script>

<style lang="scss">
    // Import Main styles for this application
    // @import '@coreui/coreui-pro/dist/css/coreui.min.css';
    @import '@/assets/scss/style';

    // @import "@coreui/coreui-pro/scss/coreui";
</style>
