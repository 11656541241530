<template>
    <div v-bind="attrs()" class="mx-n3 mt-n3 mb-2">
        <list-view-utils-flex v-for="(item, index) in rows" :key="`row-${index}`" :index="index" :item="item">
            <slot name="row" :item="item" :index="index"></slot>
        </list-view-utils-flex>
        <div v-if="rows.length == 0">
            <div class="text-center p-3 mb-3 bg-light text-muted">{{ $t(emptyLabel) }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Inject, Prop } from '@/helpers/Decorators';
import Pager from '@/helpers/Pager';
import Flex from '../utils/Flex.vue';
import { only } from '@/helpers/Utils';

@Options({
    name: 'list-view-flex-layout',
    inheritAttrs: false,
    components: {
        'list-view-utils-flex': Flex
    }
})
export default class ListViewFlexLayout extends Vue
{
    @Inject('empty-label')
    public emptyLabel: boolean;

    @Prop()
    public pager!: Pager;

    @Prop()
    public headers!: any[];

    @Prop()
    public rows!: any[];

    public attrs(): Record<string, any>
    {
        return only(this.$attrs, 'class', 'style');
    }
}
</script>
