import { Plugin } from 'vue';
import VueMq from 'vue3-mq';

const MediaPlugin: Plugin =
{
    install(app, options)
    {
        app.use(VueMq, {
            breakpoints: {
                xs: 640, // Telefon (do 639px)
                sm: 960, // Mały tablet (do 959px)
                md: 1366, // Duży tablet (do 1365px)
                lg: 1600, // Laptop (do 1599px)
                xl: Infinity // Desktop
            }
        });
    }
};

export default MediaPlugin;
