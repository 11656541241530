/* eslint-disable no-console */
import { Plugin } from 'vue';
import axios from 'axios';
import Qs from 'qs';
import { requestToken, convertDates, datesToString } from '@/helpers/Utils';
import isObject from 'lodash/isObject';
import * as Sentry from "@sentry/vue";

const AxiosPlugin: Plugin =
{
    install(app)
    {
        const vue = app.config.globalProperties;

        axios.defaults.baseURL = import.meta.env.VITE_APP_API_URL;
        axios.defaults.headers = {
            ...axios.defaults.headers,
            ...{
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        };
        axios.interceptors.request.use(config =>
        {
            const url = new URL(`${config.baseURL}/${config.url}`);

            config.headers.common['Accept-Language'] = vue.$i18n.locale() || '';
            config.headers.common['Request-Token'] = requestToken(url.pathname, vue.$auth.token());

            if (vue.$auth.token())
            {
                config.headers.Authorization = `Bearer ${vue.$auth.token()}`;
            }

            if (vue.$auth.impersonating())
            {
                config.headers.common['Access-Impersonate'] = vue.$auth.impersonatingValue();
            }

            config.paramsSerializer = params =>
            {
                return Qs.stringify(datesToString(params), {
                    arrayFormat: "brackets",
                    encode: false
                });
            };

            return config;
        });
        axios.interceptors.response.use(
            response =>
            {
                if (response && response.headers && response.headers['content-type']?.includes('json') && isObject(response.data))
                {
                    response.data = convertDates(response.data);
                }

                return response;
            },
            error =>
            {
                const ex = {
                    code: 500,
                    message: 'Unexpected exception occured.',
                    data: null as any,
                    inner: error
                };

                if (error.response)
                {
                    ex.code = error.response.status;
                    ex.data = (typeof error.response.data === 'string') ? { message: error.response.data } : error.response.data || {};
                    ex.message = 'message' in ex.data ? ex.data.message : error.message;
                }
                else
                {
                    ex.message = error.message;
                }

                if (ex.code === 404)
                {
                    Sentry.withScope((scope) =>
                    {
                        const currentPath = vue.$route.fullPath;
                        const roles = vue.$auth.store?.state?.auth?.identity?.departments || 'undefined - nie został zinicjalizowany';
                        const userNickname = vue.$auth.store?.state?.auth?.identity?.userName || 'undefined - nie został zinicjalizowany';
                        const userPublicId = vue.$auth.store?.state?.auth?.identity?.publicId || 'undefined - nie został zinicjalizowany';

                        scope.setExtra('currentPath', currentPath);
                        scope.setExtra('roles', roles);
                        scope.setExtra('userNickname', userNickname);
                        scope.setExtra('userPublicId', userPublicId);
                        Sentry.captureException(error);
                    }
                    );
                }

                if (ex.code === 401 && !error.config.url.startsWith('auth/'))
                {
                    window.location.replace('/auth/login');
                    // vue.$auth.logout();
                }

                if (ex.code === 403)
                {
                    if (error.response.config.method === "get")
                        vue.$router.replace({ name: 'error-403' });
                    else
                        vue.$alert.danger(vue.$i18n.translate('[[[Nie masz wymaganych uprawnień do wykonania wybranej operacji.]]]'));
                }

                if (ex.code === 404)
                {
                    vue.$alert.danger(vue.$i18n.translate('[[[Błąd 404. Nie znaleziono żądanego zasobu.]]]'));
                }

                if (ex.code === 500)
                {
                    console.log('500');

                    if (error.message && error.message == "Network Error")
                    {
                        vue.$alert.danger(vue.$i18n.translate('[[[Wystąpił błąd połączenia. Upewnij się że masz włączony internet i spróbuj ponownie.]]]'));
                    }
                    else
                    {
                        vue.$alert.danger(vue.$i18n.translate('[[[Wystąpił błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.]]]'));
                    }

                    if (ex.data && ex.data.stackTrace)
                    {
                        console.log(ex.data.message);
                        console.log(ex.data.stackTrace);
                    }
                }

                if (ex.code === 503)
                {
                    vue.$router.replace({ name: 'error-503' });
                }

                return Promise.reject(ex);
            }
        );

        vue.axios = axios;
        vue.$http = axios;
    }
};

export default AxiosPlugin;
export { axios };
