import { FormBuilder } from '../Utils';
import { Components, AggregateBlueprint, Blueprint, HasWidth } from '../Types';

export interface SpacerContract extends Blueprint, HasWidth
{
}

export class SpacerType implements SpacerContract
{
    public id: string;
    public type: string;
    public name: string;
    public width: number;
    public minWidth: number;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.SPACER;
        this.name = builder.name(Components.SPACER);
        this.width = builder.space(parent);
        this.minWidth = 1;
    }
}
