<template>
    <teleport to="body" v-if="isMounted && isVisible">
        <div class="modal-backdrop" :class="backdropClasses"></div>
        <div class="modal" v-bind="$attrs" :class="[modalClasses, themeStyle]" @click="onOutOfFocus">
            <div class="modal-dialog" :class="dialogClasses">
                <div class="modal-content" @click.stop>
                    <div class="modal-header" :class="headerClasses" v-if="!flag(hideHeader)">
                        <slot name="modal-header">
                            <h5 class="modal-title">{{ title }}</h5>
                            <button type="button" class="close" @click.stop="onClose">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </slot>
                    </div>
                    <div class="modal-body" :class="bodyClasses">
                        <slot name="default"></slot>
                    </div>
                    <div class="modal-footer" :class="footerClasses" v-if="!flag(hideFooter)">
                        <slot name="modal-footer" :ok="() => onOk()" :cancel="() => onClose()">
                            <button type="button" class="btn btn-secondary" @click.stop="onClose">{{ $t('[[[Zamknij]]]') }}</button>
                            <button type="button" class="btn btn-primary" @click.stop="onOk">{{ $t('[[[Ok]]]') }}</button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { normalizeClasses } from '@/helpers/Utils';

@Options({
    name: 'ideo-modal',
    inheritAttrs: false
})
export default class IdeoModal extends Vue
{
    public isMounted: boolean = false;
    public isVisible: boolean = false;
    public themeStyles: string[] = ['default-theme', 'c-dark-theme'];

    @Prop({ default: false })
    public modelValue: boolean;

    @Prop()
    public title: string;

    @Prop({ default: false })
    public static: boolean;

    @Prop({ default: false })
    public centered: boolean;

    @Prop({ default: false })
    public scrollable: boolean;

    @Prop({
        default: 'md',
        validator: (value: string) => ['sm', 'md', 'lg', 'xl'].includes(value)
    })
    public size: string;

    @Prop({ default: false })
    public hideHeader: boolean;

    @Prop({ default: false })
    public hideFooter: boolean;

    @Prop({ default: () => ({}) })
    public modalClass: Record<string, boolean> | string[] | string;

    @Prop({ default: () => ({}) })
    public backdropClass: Record<string, boolean> | string[] | string;

    @Prop({ default: () => ({}) })
    public headerClass: Record<string, boolean> | string[] | string;

    @Prop({ default: () => ({}) })
    public bodyClass: Record<string, boolean> | string[] | string;

    @Prop({ default: () => ({}) })
    public footerClass: Record<string, boolean> | string[] | string;

    public get backdropClasses(): Record<string, boolean>
    {
        return {
            'd-none': !this.isVisible,
            ...normalizeClasses(this.backdropClass)
        };
    }

    public get modalClasses(): Record<string, boolean>
    {
        return {
            'd-block': this.isVisible,
            ...normalizeClasses(this.modalClass)
        };
    }

    public get dialogClasses(): Record<string, boolean>
    {
        return {
            'modal-dialog-centered': this.flag(this.centered),
            'modal-dialog-scrollable': this.flag(this.scrollable),
            [`modal-dialog-${this.size}`]: this.size != 'md'
        };
    }

    public get headerClasses(): Record<string, boolean>
    {
        return normalizeClasses(this.headerClass);
    }

    public get bodyClasses(): Record<string, boolean>
    {
        return normalizeClasses(this.bodyClass);
    }

    public get footerClasses(): Record<string, boolean>
    {
        return normalizeClasses(this.footerClass);
    }

    public get themeStyle(): string
    {
        const id = this.$store.state.common.theme.themeId;

        return this.themeStyles[id];
    }

    public get visible(): boolean
    {
        return this.isVisible;
    }

    public mounted(): void
    {
        this.isMounted = true;
    }

    public flag(value: any): boolean
    {
        return value !== false;
    }

    @Emit('ok')
    public onOk(): void
    {
        this.hide();
    }

    @Emit('close')
    public onClose(): void
    {
        this.hide();
    }

    public onOutOfFocus(): void
    {
        if (!this.flag(this.static))
        {
            this.hide();
        }
    }

    public toggle(): void
    {
        if (this.isVisible)
            this.hide();
        else
            this.show();
    }

    @Emit('shown', { delay: true })
    public show(): void
    {
        this.isVisible = true;
        this.updateModel();
    }

    @Emit('hidden', { delay: true })
    public hide(): void
    {
        this.isVisible = false;
        this.updateModel();
    }

    @Watch('modelValue', { immediate: true })
    public onModelValueChange(value: boolean, old: boolean): void
    {
        if (value != old)
        {
            this.isVisible = value;
        }
    }

    @Emit('update:modelValue')
    public updateModel(): boolean
    {
        return this.isVisible;
    }
}
</script>

<style lang="scss">
.modal-backdrop {
    opacity: .5;
    z-index: 10112 !important;
}
.modal {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    z-index: 10113 !important;
}
.modal-dialog-sm {
    max-width: 300px !important;
}
.modal-dialog-lg {
    max-width: 800px !important;
}
.modal-dialog-xl {
    max-width: 1140px !important;
}
</style>
