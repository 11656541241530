import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint } from '../Types';
import { FormEntry } from '../types/Form';
import { DateContract, DateFieldTypes, DateEntry, instanceOfDateEntry } from '../types/Date';
import properties from '../properties';
import { Option } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';

@Options({
    name: 'date-blueprint',
    components: {
        ...properties
    }
})
export default class DateBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: DateContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public entryData: DateEntry = new DateEntry();

    public created(): void
    {
        this.entryData = this.form.initEntry(this.blueprint, this.entryData, instanceOfDateEntry);
    }

    public get value(): DateTime | null
    {
        return this.entryData.value === null
            ? this.form.executeExpression(this.blueprint.defaultValue)
            : this.entryData.value;
    }

    public set value(value: DateTime | null)
    {
        this.entryData.value = value;
    }

    public get fieldTypeOptions(): Option<DateFieldTypes>[]
    {
        return [
            { value: DateFieldTypes.DateTime, text: '[[[Data i czas]]]' },
            { value: DateFieldTypes.Date, text: '[[[Data]]]' }
        ];
    }

    public get fieldType(): typeof DateFieldTypes
    {
        return DateFieldTypes;
    }

    public get dateType(): string
    {
        return this.blueprint.fieldType.toLowerCase();
    }

    public get isDateTime(): boolean
    {
        return this.blueprint.fieldType == DateFieldTypes.DateTime;
    }

    public get readonly(): boolean
    {
        return this.form.readonly(this.blueprint);
    }

    public get required(): boolean
    {
        return this.form.required(this.blueprint);
    }
}
