import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import StorageService from '@/modules/core/files/services/StorageService';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint } from '../Types';
import { FormEntry } from '../types/Form';
import { ContentContract } from '../types/Content';
import properties from '../properties';

@Options({
    name: 'content-blueprint',
    components: {
        ...properties
    }
})
export default class ContentBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: ContentContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public content(): string
    {
        let match = null as any;
        let content = this.blueprint.content;
        const regex = /"(#\(storage\):(.+?))"/gm;

        while ((match = regex.exec(this.blueprint.content)) !== null)
        {
            if (match.index === regex.lastIndex)
                regex.lastIndex++;

            content = content.split(match[1]).join(StorageService.previewResource(match[2]));
        }

        return content || '<p>Lorem ipsum...</p>';
    }
}
