import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint } from '../Types';
import { FormEntry } from '../types/Form';
import { PersonalContract, instanceOfPersonalEntry, PersonalEntry } from '../types/Personal';
import properties from '../properties';

@Options({
    name: 'personal-blueprint',
    components: {
        ...properties
    }
})
export default class PersonalBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: PersonalContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public entryData: PersonalEntry = new PersonalEntry();

    public created(): void
    {
        this.entryData = this.form.initEntry(this.blueprint, this.entryData, instanceOfPersonalEntry);
    }

    public get title(): string
    {
        return this.entryData.title;
    }

    public set title(value: string)
    {
        this.entryData.title = value;
    }

    public get givenName(): string
    {
        return this.entryData.givenName;
    }

    public set givenName(value: string)
    {
        this.entryData.givenName = value;
    }

    public get middleName(): string
    {
        return this.entryData.middleName;
    }

    public set middleName(value: string)
    {
        this.entryData.middleName = value;
    }

    public get surname(): string
    {
        return this.entryData.surname;
    }

    public set surname(value: string)
    {
        this.entryData.surname = value;
    }

    public get readonly(): boolean
    {
        return this.form.readonly(this.blueprint);
    }

    public get required(): boolean
    {
        return this.form.required(this.blueprint);
    }
}
