/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
    namespaced: true,
    state: {
        themeId: 0,
        toggleMenu: false,
        minimizeMenu: false,
        defaultSettings: true
    },
    getters: {
        getSettings(state: any)
        {
            return state;
        }
    },
    mutations: {
        changeMode(state: any, id: number)
        {
            state.themeId = id;
        },
        toggleMenu(state: any, toggle: boolean)
        {
            state.toggleMenu = toggle;
            state.defaultSettings = false;
        },
        minimizeMenu(state: any, minimize: boolean)
        {
            state.minimizeMenu = minimize;
            state.defaultSettings = false;
        }
    }
};
