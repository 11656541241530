<template>
    <div class="ideo-form-color--wrapper">
        <ideo-form-input v-model="currentValue" type="text" name="color" @input="updateModel" />
        <LvColorPicker ref="colorPicker" :value="currentValue ?? ''" without-input @input="updateModel" />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from "@/helpers/Decorators";
import LvColorPicker from 'lightvue/color-picker';

@Options({
    name: "ide-form-color",
    inheritAttrs: false,
    components: {
        LvColorPicker
    }
})
export default class IdeoFormColor extends Vue
{
    public currentValue: string = null;

    @Prop()
    public value: string;

    @Prop()
    public modelValue: string;

    public get componentKey(): string
    {
        return this.currentValue;
    }

    @Emit('input')
    @Emit('update:modelValue')
    public updateModel(value: string): string
    {
        this.currentValue = value;

        return this.currentValue;
    }

    @Watch('value', { immediate: true, deep: true })
    @Watch('modelValue', { immediate: true, deep: true })
    public async onModelChanged(value: any[] | any): Promise<void>
    {
        if (value !== undefined)
        {
            this.currentValue = value;

            if (this.$refs.colorPicker)
            {
                this.$refs.colorPicker.localValue = value;
            }

            await this.$nextTick();
        }
    }
}
</script>

<style lang="scss" scoped>
    .ideo-form-color--wrapper {
        position: relative;

        > input {
            padding-right: 38px;
        }

        > div {
            position: absolute;
            right: 0;
            top: 2px;

        }
    }
</style>
