import OneSignalVuePlugin from '@onesignal/onesignal-vue3';
import { Plugin } from 'vue';


const OneSignalPlugin: Plugin =
{
    install(app)
    {
        OneSignalVuePlugin.install(app, {
            appId: import.meta.env.VITE_APP_ONESIGNAL,
            allowLocalhostAsSecureOrigin: !import.meta.env.PROD,
        });
    }
};

export default OneSignalPlugin;
