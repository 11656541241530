<template>
    <button type="button" :class="customClasses" :disabled="disabled">
        <slot name="default"></slot>
    </button>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';

@Options({
    name: 'ideo-button'
})
export default class IdeoButton extends Vue
{
    @Prop({
        default: 'secondary',
        validator: (value: string) => [
            'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link',
            'outline-primary', 'outline-secondary', 'outline-success', 'outline-danger', 'outline-warning', 'outline-info', 'outline-light', 'outline-dark'
        ].includes(value)
    })
    public variant: string;

    @Prop({
        default: 'md',
        validator: (value: string) => ['sm', 'md', 'lg'].includes(value)
    })
    public size: string;

    @Prop({ default: false })
    public block: boolean;

    @Prop({ default: false })
    public pill: boolean;

    @Prop({ default: false })
    public squared: boolean;

    @Prop({ default: false })
    public disabled: boolean;

    public get customClasses(): Record<string, boolean>
    {
        return {
            'btn': true,
            [`btn-${this.variant}`]: true,
            'w-100': this.block !== false,
            'rounded-pill': this.pill !== false,
            'rounded-0': this.squared !== false,
            'btn-sm': this.size == 'sm',
            'btn-lg': this.size == 'lg'
        };
    }
}
</script>
