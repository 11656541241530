<template>
    <img :src="source" :width="width" :height="height" :class="customClasses">
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { isString } from 'lodash';

const BLANK_TEMPLATE =
  '<svg width="%{w}" height="%{h}" ' +
  'xmlns="http://www.w3.org/2000/svg" ' +
  'viewBox="0 0 %{w} %{h}" preserveAspectRatio="none">' +
  '<rect width="100%" height="100%" style="fill:%{f};"></rect>' +
  '</svg>';

const BLANK_TEMPLATE_WITH_INITIALS =
  '<svg width="%{w}" height="%{h}" ' +
  'xmlns="http://www.w3.org/2000/svg" ' +
  'viewBox="0 0 %{w} %{h}" preserveAspectRatio="none">' +
  '<rect width="100%" height="100%" style="fill:%{f};"></rect>' +
  '<text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" ' +
  'style="fill:%{textColor}; font-size: %{fontSize}; font-family: Arial;">' +
  'AB_INITIALS</text>' +
  '</svg>';

@Options({
    name: 'ideo-image'
})
export default class IdeoImage extends Vue
{
    @Prop({ default: false })
    public blank: boolean;

    @Prop({ default: 'transparent' })
    public blankColor: string;

    @Prop({ default: false })
    public block: boolean;

    @Prop({ default: false })
    public center: boolean;

    @Prop({ default: false })
    public fluid: boolean;

    @Prop({ default: false })
    public fluidGrow: boolean;

    @Prop({ default: undefined })
    public height: number;

    @Prop({ default: false })
    public left: boolean;

    @Prop({ default: false })
    public right: boolean;

    @Prop({ default: false, validator: (value: any) => [false, true, 'top', 'right', 'bottom', 'left', 'circle', '0'].includes(value) })
    public rounded: string | boolean;

    @Prop()
    public src: string;

    @Prop({ default: false })
    public thumbnail: boolean;

    @Prop({ default: undefined })
    public width: number;

    // For avatar initials if there's no image
    @Prop({ default: ''})
    public fullName: string;

    public get source(): string
    {
        return this.flag(this.blank) ? this.blankImgSrc() : this.src;
    }

    public get customClasses(): Record<string, boolean>
    {
        return {
            'img-thumbnail': this.flag(this.thumbnail),
            'img-fluid': this.flag(this.fluid) || this.flag(this.fluidGrow),
            'float-start': this.flag(this.left),
            'float-end': this.flag(this.right),
            'mx-auto': this.flag(this.center),
            'd-block': this.flag(this.block) || this.flag(this.center),
            'w-100': this.flag(this.fluidGrow),
            'rounded': this.flag(this.rounded),
            [`rounded-${this.rounded}`]: isString(this.rounded) && this.rounded.length > 0
        };
    }

    public flag(value: any): boolean
    {
        return value !== false;
    }

    public getInitials(): string
    {
        const [givenName, surname] = this.fullName.split(' ');

        return `${givenName ? givenName[0] : ''}${surname ? surname[0] : ''}`;
    }

    public blankImgSrc(): string
    {
        const src = encodeURIComponent(
            this.fullName
                ? BLANK_TEMPLATE_WITH_INITIALS
                    .replace('AB_INITIALS', this.getInitials() ?? '')
                    .replace('%{w}', (this.width || 1).toString())
                    .replace('%{h}', (this.height || 1).toString())
                    .replace('%{f}', this.blankColor)
                    .replace(/%\{textColor\}/g, '#fff')
                    .replace(/%\{fontSize\}/g, this.height ? `${this.height / 2}` : '18')
                : BLANK_TEMPLATE.replace('%{w}', (this.width || 1).toString())
                    .replace('%{h}', (this.height || 1).toString())
                    .replace('%{f}', this.blankColor)
        );

        return `data:image/svg+xml;charset=UTF-8,${src}`;
    }
}
</script>
