<template>
    <div class="layout" :class="{'collapsed': collapsed, 'minimize': minimize, 'stop-transitions': stopTransitions }">
        <header class="c-header">
            <div class="d-none d-sm-block" :class="{ 'brand-minimized': minimize, 'brand': !minimize, 'brand-hovered': collapsed && hovered }" @click="$router.push({name:'renter-dashboard'})">
                <template v-if="(!mobile && !tablet)">
                    <img v-show="!collapsed || hovered" :src="logo.default" class="logo" alt="">
                    <img v-show="collapsed && minimize" :src="logo.mini" class="logo_mini" alt="">
                </template>
                <template v-else-if="tablet">
                    <img :src="logo.tablet" alt="" class="logo_mini">
                </template>
                <template v-else>
                    <img :src="logo.mobile" alt="" class="logo_mini">
                </template>
            </div>
            <button type="button" class="toggler header-toggler mr-lg-auto" @click="toggleMenu()" aria-label="Menu" :disabled="blockMenu">
                <i class="fal fa-bars"></i>
            </button>
            <theme-selector class="ms-auto ms-sm-0" />
            <notifications />
            <account />
            <div :class="['c-subheader', { 'block-menu' : blockMenu }]">
                <breadcrumbs />
            </div>
        </header>
        <section :class="[{'drawer': drawer}, {'block-menu' : blockMenu}]">
            <nav class="scroll dark" @mouseenter="hovered = true" @mouseleave="hovered = false">
                <menu-component :collapsed="!drawer" />
                <div class="copy">
                    Ideo CORE {{ systemVersion }}
                </div>
            </nav>
            <div class="content scroll" @click="hide()">
                <div class="wrapper">
                    <router-view v-slot="{ Component }">
                        <transition name="fade" mode="out-in">
                            <component :is="Component" />
                        </transition>
                    </router-view>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { RouteLocationNormalized as Route } from 'vue-router';
import AuthService from '@/modules/core/auth/services/AuthService';
import Account from '@/views/partials/layout/Account.vue';
import Notifications from '@/views/partials/layout/Notifications.vue';
import Breadcrumbs from '@/views/partials/navigation/Breadcrumbs.vue';
import Menu from '@/views/partials/navigation/Menu.vue';
import ThemeMode from "@/views/partials/layout/ThemeMode.vue";
import { Listen } from '@/plugins/signalr';

const LOGO = {
    // Default
    0: {
        default: '/static/img/hirenterslogo.png',
        mini: '/static/img/short_hirenterslogo.png',
        tablet: '/static/img/short_hirenterslogo.png',
        mobile: '/static/img/short_hirenterslogo.png'
    },
    // Dark mode
    1: {
        default: '/static/img/hirenterslogo.png',
        mini: '/static/img/short_hirenterslogo.png',
        tablet: '/static/img/short_hirenterslogo.png',
        mobile: '/static/img/short_hirenterslogo.png'
    }
};

@Options({
    components: {
        'account': Account,
        'notifications': Notifications,
        'breadcrumbs': Breadcrumbs,
        'menu-component': Menu,
        'theme-selector': ThemeMode
    }
})
export default class DefaultLayout extends Vue
{
    public toggle: boolean = false;
    public hovered: boolean = false;
    public minimize: boolean = true;
    public search: boolean = false;
    public width: number = 0;
    public stopTransitions = false;
    public blockMenu: boolean = false;

    public get drawer(): boolean
    {
        return this.drawerMobile || this.drawerLaptop || this.drawerDesktop;
    }

    public get drawerMobile(): boolean
    {
        return this.mobile && this.toggle == true;
    }

    public get drawerLaptop(): boolean
    {
        return this.laptop && (this.toggle == false || this.hovered == true || this.blockMenu == true);
    }

    public get drawerDesktop(): boolean
    {
        return this.desktop && (this.toggle == false || this.hovered == true);
    }

    public get collapsed(): boolean
    {
        return this.collapsedMobile || this.collapsedLaptop || this.collapsedDesktop;
    }

    public get collapsedMobile(): boolean
    {
        return this.mobile && this.toggle == false;
    }

    public get collapsedLaptop(): boolean
    {
        return this.laptop && this.toggle == true;
    }

    public get collapsedDesktop(): boolean
    {
        return this.desktop && this.toggle == true;
    }

    public get systemVersion(): string
    {
        return this.$auth.user()?.systemVersion;
    }

    public get menuState(): any
    {
        return this.$store.state.common.theme;
    }

    public get themeMode(): number
    {
        return this.$store.state.common.theme.themeId;
    }

    public get logo(): any
    {
        return LOGO[this.themeMode];
    }

    public async created(): Promise<void>
    {
        this.resize();

        // Sprawdzenie daty wygaśnięcia hasła
        await AuthService.passwordExpires();
    }

    public mounted(): void
    {
        window.addEventListener('resize', this.resize);

        this.$router.afterEach((to: Route, from: Route) =>
        {
            if (to.path != from.path && !this.blockMenu)
            {
                if (this.mobile)
                {
                    this.toggle = false;
                }

                this.hovered = false;
            }

        });
    }

    public unmounted(): void
    {
        window.removeEventListener('resize', this.resize);
    }

    public toggleMenu(): void
    {
        this.toggle = !this.toggle;
        this.$store.commit('common/theme/toggleMenu', this.toggle);
    }

    public hide(): void
    {
        if (this.mobile === true && !this.blockMenu)
        {
            this.toggle = false;
        }
    }

    public resize(): void
    {
        this.width = window.screen.width;

        if (this.desktop && this.menuState.defaultSettings)
        {
            this.toggle = false;
            this.minimize = true;
        }
        else if (this.laptop && this.menuState.defaultSettings)
        {
            this.toggle = false;
            this.minimize = true;
        }
        else if (this.mobile)
        {
            this.toggle = false;
            this.minimize = false;
        }
        else
        {
            this.toggle = this.menuState.toggleMenu;
            this.minimize = true;
        }

        this.stopTransitions = true;

        setTimeout(() =>
        {
            this.stopTransitions = false;
        },
        200);
    }

    @Listen('authentication')
    public async logout(deviceId: string): Promise<void>
    {
        const device = await AuthService.getDeviceInfo();

        if (device.deviceId == deviceId)
        {
            this.$auth.logout();
        }
    }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
    opacity: 0
}
</style>

<style lang="scss">
$transition: 0.3s ease;
$sidemenu-width: 256px;
$sidemenu-width-collapsed: 0px;
$sidemenu-mini-width: 56px;
$icon-color: $gray-400;
$content-padding: 15px;
$toolbar-height: 56px;
$subheader-height: 49px;

.layout {
    height: 100dvh;
    width: 100vw;
    position: relative;
    overflow: hidden;

    > header {
        min-width: 320px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        display: flex;
        height: $toolbar-height;
        background-color: var(--primary);
        border: 0;

        img{
            &.logo{
                max-width: 200px;
            }
            &.logo_mini{
                max-width: 48px;
            }
        }
        i.fa-solid{
            font-size: 18px;
            color: white;
        }
        .btn i{
            margin: 0;
            width: initial;
            height: initial;
        }
        .header-toggler {
            transform: translate(4px, -1px);
            min-height: $toolbar-height;
            color: $white;
            font-size: 20px;
            min-width: 50px;
        }

        .toggler {
            > .bars {
                border-top: 2px solid $icon-color;
                border-bottom: 2px solid $icon-color;
                display: block;
                width: 26px;
                height: 20px;
                cursor: pointer;

                &:before {
                    content: "\a0";
                    display: block;
                    margin-top: 7px;
                    border-top: 2px solid $icon-color;
                    line-height: 1px;
                }
            }
        }
        .brand, .brand-minimized {
            position: relative;
            display: block;
            width: $sidemenu-width;
            height: $toolbar-height;
            margin: 0;
            background: var(--primary-menu-color);
            transition: width $transition;
            cursor: pointer;

            img {
                display: block;
                margin: 5px;
            }
        }
        .brand {
            z-index: 1000;
        }

        .search {
            margin-right: auto;

            .btn-group {
                height: 100%;

                > a {
                    display: flex;
                    align-items: center !important;
                    height: 100%;
                    padding: 0px;

                    i {
                        padding: 0 10px 0px 10px;
                        margin-left: 10px;
                        color: $icon-color;
                        font-size: 18px;
                    }
                }
            }
            input {
                width: 250px;
                border: 1px solid transparent;

                &:focus {
                    box-shadow: none !important;
                    border-bottom: 1px solid var(--border-color);
                }
                &::placeholder {
                    color: $icon-color;
                }
            }
        }
    }
    > section {
        min-width: 320px;
        position: absolute;
        top: $toolbar-height;
        left: 0;
        right: 0;
        bottom: 0;

        > nav {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            z-index: 10011;
            width: $sidemenu-width;
            display: flex;
            flex-direction: column;
            background-color: var(--primary-menu-color);
            color: #ffffff;
            overflow-x: hidden;
            transition: width $transition;

            .copy {
                // background: var(--secondary-menu-color);
                width: $sidemenu-width;
                margin-top: auto;
                padding: 15px;
                text-align: center;
                white-space: nowrap;
                font-size: 12px;
                color: $icon-color;
                transition: width $transition;
            }
        }
        > .content {
            position: absolute;
            left: $sidemenu-width;
            right: 0;
            top: $subheader-height;
            bottom: 0;
            transition: left $transition;
            background: var(--body-bg);

            > .wrapper {
                position: relative;
                min-height: 100%;
                padding: $content-padding $content-padding $content-padding;
                @include media-breakpoint-down(lg) {
                    padding: $content-padding;
                }
                background: var(--body-bg);
                display: flex;

                > * {
                    width: 100%;
                }
            }
        }
    }

    .c-subheader {
        display: flex;
        max-width: 100%;
        left: $sidemenu-width;
        height: $subheader-height;
        padding: 0 15px;
        margin-top: 0 !important;
        // box-shadow: 0 2px 2px 0 rgba(60,75,100,.14), 0 3px 1px -2px rgba(60,75,100,.12), 0 1px 5px 0 rgba(60,75,100,.2);
        border-top: 1px solid var(--border-color) !important;
        border-bottom: 1px solid var(--border-color) !important;
        clip-path: inset(0 0 -1000px 0);
        transition: left $transition, max-width $transition;
        z-index: 1;
        order: 5;
        position: relative;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        min-height: 48px;
        background: white;
    }

    &.collapsed {
        &.minimize {
            @include media-breakpoint-up(lg) {
                .c-subheader {
                    max-width: calc(100vw - 56px);
                    left: $sidemenu-mini-width;
                    transition: left $transition, max-width $transition;
                }
                > section {
                    > nav {
                        width: $sidemenu-mini-width;
                    }
                    > .content {
                        left: $sidemenu-mini-width;
                        transition: left $transition;
                    }
                }
            }
            .brand-minimized {
                width: $sidemenu-mini-width;
                &.brand-hovered {
                    width: $sidemenu-width;
                }
            }
            .copy {
                /*display: none;*/
                opacity: 0;
                visibility: hidden;
                width: $sidemenu-mini-width;
                transition: width $transition;
            }
        }
        .c-subheader {
            left: 0;
            transition: left $transition , max-width $transition;
        }
    }

    &:not(.collapsed) {
        .c-subheader:not(.block-menu) {
            margin-left: -1 * $sidemenu-mini-width;

            @include media-breakpoint-up(md) {
                margin-left: 0;
                max-width: calc(100vw - 256px);
            }
        }
    }

    @include media-breakpoint-down(sm) {
        > header {
            .brand {
                width: $sidemenu-width-collapsed;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        > header {
            .toggler {
                order: 1;
                border: none;
            }
            .block-pin {
                order: 1;
                min-width: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                &.active i {
                    color: var(--hover-color);
                    font-size: 18px;
                }
            }
            .brand {
                background-color: var(--brand-bg-md);
                margin: 0 auto;
                max-height: $toolbar-height - 1px;
                transform: translateY(2px);
            }

            .brand, .search, .callout, .account, .logout, .notifications, .theme-mode, .locale {
                order: 2;
            }

            .search {
                display: none;
            }
        }
        > section {
            > nav {
                left: -1 * $sidemenu-width;
                transition: $transition;

                .copy {
                    display: none;
                }
            }
            > .content {
                left: 0;
                transition: $transition;
            }
            &.drawer {
                > nav {
                    left: 0
                }
                > .content {
                    left: $sidemenu-width;
                    right: -1 * $sidemenu-width;

                    > .wrapper {
                        pointer-events: none;
                    }
                }
                &.block-menu {
                    > .content {
                        left: $sidemenu-width;
                        right: 0;

                        > .wrapper {
                            pointer-events: unset;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .block-pin {
            display: none;
        }
        &.collapsed {
            > header {
                .brand {
                    width: $sidemenu-width-collapsed;
                }
            }
            > section {
                nav {
                    width: $sidemenu-width-collapsed;

                    .copy {
                        opacity: 0;
                        visibility: hidden;
                        width: $sidemenu-width-collapsed;
                        transition: width $transition;
                    }
                }
                .content {
                    left: $sidemenu-width-collapsed;
                }
                &.drawer {
                    > nav {
                        width: $sidemenu-width;
                        transition: $transition;

                        .copy {
                            opacity: 1;
                            visibility: visible;
                            width: $sidemenu-width;
                            transition: width $transition;
                        }
                    }
                    > .content > .wrapper {
                        pointer-events: none;
                    }
                }
            }
        }
    }
}
.sidebar-minimizer {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    border: 0;
    transition: transform $transition;
    &:before {
        display: block;
        width: 50px;
        height: 50px;
        content: "";
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12.5px;
        background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0nIzhhOTNhMicgZD0nTTkuMTQ4IDIuMzUybC00LjE0OCA0LjE0OCA0LjE0OCA0LjE0OHEwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJsLTEuMjk3IDEuMjk3cS0wLjE0OCAwLjE0OC0wLjM1MiAwLjE0OHQtMC4zNTItMC4xNDhsLTUuNzk3LTUuNzk3cS0wLjE0OC0wLjE0OC0wLjE0OC0wLjM1MnQwLjE0OC0wLjM1Mmw1Ljc5Ny01Ljc5N3EwLjE0OC0wLjE0OCAwLjM1Mi0wLjE0OHQwLjM1MiAwLjE0OGwxLjI5NyAxLjI5N3EwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJ6Jy8+PC9zdmc+);
        transition: $transition;
    }

    &.minimize {
        transform: rotate(180deg)
    }
}
.c-header{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 0;
    min-height: 56px;
    background: $white;
    border-bottom: 1px solid #c0cadd;
}
</style>
