<template>
    <ideo-popover ref="popover" class="popover" :id="id" :target="target" :placement="placement" triggers="blur click">
        <div class="text-nowrap">
            <b class="me-2">{{ message }}</b>
            <ideo-button-group class="rounded overflow-hidden" size="sm">
                <ideo-button variant="danger" @mousedown.stop.prevent="confirm()">
                    {{ $t('[[[Tak]]]') }}
                </ideo-button>
                <ideo-button variant="secondary" @mousedown.stop.prevent="cancel()">
                    {{ $t('[[[Nie]]]') }}
                </ideo-button>
            </ideo-button-group>
        </div>
    </ideo-popover>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Ref } from '@/helpers/Decorators';
import IdeoPopover from '@/components/bootstrap/popover/IdeoPopover.vue';

@Options({
    name: 'confirmation'
})
export default class Confirmation extends Vue
{
    @Prop()
    public id: string;

    @Prop()
    public value!: number | Record<string, any>;

    @Prop()
    public target!: any;

    @Prop()
    public message!: string;

    @Prop({ default: 'left' })
    public placement: string;

    @Ref('popover')
    public popover: () => IdeoPopover;

    public open(target?: HTMLElement): void
    {
        this.popover().open(target);
    }

    public close(): void
    {
        this.popover().close();
    }

    @Emit('confirm')
    public confirm(): number | Record<string, any>
    {
        this.close();

        return this.value;
    }

    @Emit('cancel')
    public cancel(): void
    {
        this.close();
    }
}
</script>

<style lang="scss" scoped>
.popover {
    border-radius: 4px;
}

.popover-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
</style>
