import { RouteLocationRaw } from "vue-router";

export interface NotificationNavigationType{
    message?: string;
    navigation?: string;
    publicId?: string
}

export const NotificationNavigation = ({ navigation, publicId }: NotificationNavigationType): RouteLocationRaw =>
{
    if (navigation)
    {
        if (!publicId)
            return { name: routes[navigation] };
        else if (navigation === 'PropertySettings')
            return { name: directRoutes[navigation], params: { publicId, isSettingsActive: 'true' } };
        else
            return { name: directRoutes[navigation], params: { publicId } };
    }
    else
        return {};
};

enum routes{
    MyTasks = 'renter-tasks-management',
    NextStocktakes = 'renter-stocktakes-planned',
    MyRoutes = 'renter-drivers-routes-management',
    Note = 'renter-notes',
    Cost = 'renter-cost-reports',
    Synchronization = 'core-scheduler',
    Property = 'renter-properties',
    Task = 'renter-tasks',
    TasksList = 'renter-tasks-expired',
    MyChatRooms = 'chat-myrooms',
    Salaries = 'renter-salaries-hk'
}

enum directRoutes{
    MyTasks = 'renter-tasks-management-run',
    Note = 'renter-notes-edit',
    Cost = 'renter-cost-reports-management',
    Property = 'renter-properties-edit',
    PropertySettings = 'renter-properties-info-item',
    Task = 'renter-tasks-edit',
    MyChatRooms = 'chat-myrooms-chat',
    Reservation = 'renter-reservations-details'
}
