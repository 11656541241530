/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
    namespaced: true,
    state: {
        filters: {},
        pager: {},
        routeKey: null
    },
    mutations: {
        saveFilters(state: any, filters: any)
        {
            state.filters[filters.filterId] = filters.content;
        },
        savePager(state: any, pager: any)
        {
            state.pager[pager.pagerId] = pager.content;
        },
        saveRouteKey(state: any, routeKey: any)
        {
            state.routeKey = routeKey;
        }
    }
};
