import databases from '@/modules/saas/databases/store';
import storages from '@/modules/saas/storages/store';
import tenants from '@/modules/saas/tenants/store';

export default {
    saas: {
        namespaced: true,
        modules: {
            databases: databases,
            storages: storages,
            tenants: tenants
        }
    }
};
