import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint } from '../Types';
import { FormEntry } from '../types/Form';
import { PhoneContract, instanceOfPhoneEntry, PhoneEntry } from '../types/Phone';
import properties from '../properties';

@Options({
    name: 'phone-blueprint',
    components: {
        ...properties
    }
})
export default class PhoneBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: PhoneContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public entryData: PhoneEntry = new PhoneEntry();

    public created(): void
    {
        this.entryData = this.form.initEntry(this.blueprint, this.entryData, instanceOfPhoneEntry);
    }

    public get value(): string
    {
        return this.entryData.value === null
            ? this.form.executeExpression(this.blueprint.defaultValue)
            : this.entryData.value;
    }

    public set value(value: string)
    {
        this.entryData.value = value;
    }

    public get readonly(): boolean
    {
        return this.form.readonly(this.blueprint);
    }

    public get required(): boolean
    {
        return this.form.required(this.blueprint);
    }
}
