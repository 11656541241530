<script lang="ts" src="../blueprints/Row.ts"></script>

<template>
    <form-component class="row-component row" :class="{'g-0': design}" :form="form" :blueprint="blueprint">
        <template v-for="(component, index) in blueprint.components" :key="component.id">
            <design-placeholder :title="$t('Wstaw kontrolkę')" :form="form" :parent="blueprint" :before="component" @select="selectPlaceholder(component)" @add="addComponent" @drop="dropComponent" />
            <component :is="form.designer(component.type)" :form="form" :parent="blueprint" :blueprint="blueprint.components[index]" :entry="entry"></component>
        </template>
        <design-placeholder :title="$t('Wstaw kontrolkę')" :form="form" :parent="blueprint" :last="true" @select="selectPlaceholder()" @add="addComponent" @drop="dropComponent" />
    </form-component>
</template>

<style lang="scss" scoped>
</style>
