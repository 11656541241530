import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    VisibleBlueprint,
    ReadonlyBlueprint,
    RequiredBlueprint,
    CustomErrorBlueprint,
    HasLabel,
    HasHelp,
    HasWidth,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice,
    AggregateBlueprint,
    EntryFactory,
    Validatable,
    ValidationErrors,
    ValidEntry
} from '../Types';

export enum LikertTypes {
    Satisfaction = 'Satisfaction', // Very Unsatisfied, Unsatisfied, Neutral, Satisfied, Very Satisfied
    Agreement = 'Agreement', // Strongly Disagree, Disagree, Neutral, Agree, Strongly Agree
    Importance = 'Importance', // Not at all Important, Somewhat Important, Neutral, Important, Very Important
    Comparison = 'Comparison', // Much Worse, Somewhat Worse, About the Same, Somewhat Better, Much Better
    Statement = 'Statement', // Definitely Not, Probably Not, Not Sure, Probably Yes, Definitely Yes
    Scale = 'Scale', // 1, 2, 3, 4, 5
    Custom = 'Custom'
}

export class LikertEntry extends ValidEntry
{
    public type: string = Components.LIKERT;
    public values?: Record<number, number> = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.values = data.value;
        }
    }

    public async collect(blueprint: LikertContract, form: FormBuilder, preprocess: (name: string, entry: any) => Promise<void>): Promise<any>
    {
        await preprocess(blueprint.name, this);

        return {
            type: this.type,
            values: this.values || this.defaultValues(blueprint)
        };
    }

    public validate(blueprint: LikertContract, form: FormBuilder): boolean
    {
        this.errors = {};

        const values = this.values || this.defaultValues(blueprint);

        if (!form.readonly(blueprint, true) && form.visible(blueprint, true))
        {
            if (form.required(blueprint) && (values == null || Object.keys(values).length != blueprint.questions.length))
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${blueprint.label}]]]`];
            }
            else if (form.customError(blueprint))
            {
                this.errors.custom = [form.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }

    private defaultValues(blueprint: LikertContract): Record<number, number>
    {
        const result = {} as Record<number, number>;

        if (blueprint.defaultValue !== null && `${blueprint.defaultValue}` !== "")
        {
            blueprint.questions.forEach(q => { result[q] = parseInt(`${blueprint.defaultValue}`); });
        }

        return result;
    }
}

export const instanceOfLikertEntry = (object: any): object is LikertEntry =>
{
    return object && 'type' in object && object.type === Components.LIKERT;
};

export interface LikertContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasHelp, HasWidth
{
    fieldType: LikertTypes;
    questions: string[];
    choices: string[];
    defaultValue: number;
    allowNoChoice: boolean;
    noChoiceLabel: string;
    defaultChoices(type: LikertTypes): void;
}

export class LikertType implements LikertContract, Validatable, EntryFactory<LikertEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public help: string;
    public width: number;
    public minWidth: number;
    public fieldType: LikertTypes;
    public questions: string[];
    public choices: string[];
    public defaultValue: number;
    public allowNoChoice: boolean;
    public noChoiceLabel: string;
    public layout: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.LIKERT;
        this.name = builder.name(Components.LIKERT);
        this.label = '[[[Ankieta]]]';
        this.showLabel = true;
        this.questions = [
            '[[[Pytanie 1]]]',
            '[[[Pytanie 2]]]',
            '[[[Pytanie 3]]]'
        ];
        this.choices = [];
        this.layout = 0;
        this.defaultValue = null;
        this.allowNoChoice = false;
        this.noChoiceLabel = '[[[Brak odpowiedzi]]]';
        this.help = '';
        this.fieldType = LikertTypes.Satisfaction;
        this.width = 6;
        this.minWidth = 6;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};

        this.defaultChoices(this.fieldType);
    }

    public defaultChoices(type: LikertTypes): void
    {
        switch (type)
        {
            case LikertTypes.Satisfaction:
                this.choices = ['[[[Bardzo niezadowolony]]]', '[[[Niezadowolony]]]', '[[[Obojętny]]]', '[[[Zadowolony]]]', '[[[Bardzo zadowolony]]]'];
                break;
            case LikertTypes.Agreement:
                this.choices = ['[[[Zdecydowanie się nie zgadzam]]]', '[[[Raczej się nie zgadzam]]]', '[[[Nie mam zdania]]]', '[[[Raczej się zgadzam]]]', '[[[Zdecydowanie się zgadzam]]]'];
                break;
            case LikertTypes.Importance:
                this.choices = ['[[[Zdecydowanie nieważne]]]', '[[[Nieważne]]]', '[[[Neutralne]]]', '[[[Ważne]]]', '[[[Zdecydowanie ważne]]]'];
                break;
            case LikertTypes.Comparison:
                this.choices = ['[[[Bardzo źle]]]', '[[[Źle]]]', '[[[Średnio]]]', '[[[Dobrze]]]', '[[[Bardzo dobrze]]]'];
                break;
            case LikertTypes.Statement:
                this.choices = ['[[[Zdecydowanie nie]]]', '[[[Nie]]]', '[[[Nie wiem]]]', '[[[Tak]]]', '[[[Zdecydowanie tak]]]'];
                break;
            case LikertTypes.Scale:
                this.choices = ['1', '2', '3', '4', '5'];
                break;
            case LikertTypes.Custom:
                this.choices = ['[[[Odpowiedź 1]]]', '[[[Odpowiedź 2]]]', '[[[Odpowiedź 3]]]', '[[[Odpowiedź 4]]]', '[[[Odpowiedź 5]]]'];
                break;
        }
    }

    public createEntry(data: any): LikertEntry
    {
        return new LikertEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        if (this.questions.filter(q => q == null || q.length == 0).length > 0)
            this.errors.questions = ['[[[Treści pytań są wymagane]]]'];

        if (this.choices.filter(q => q == null || q.length == 0).length > 0)
            this.errors.choices = ['[[[Treści odpowiedzi są wymagane]]]'];

        return {
            [this.name]: this.errors
        };
    }
}
