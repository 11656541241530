import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    AggregateBlueprint,
    HasTitle,
    HasDescription,
    Validatable,
    ValidationErrors,
    validateComponents,
    instanceOfBlueprint,
    VisibleBlueprint,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice
} from '../Types';

export interface PageContract extends AggregateBlueprint, VisibleBlueprint, HasTitle, HasDescription
{
    prevButtonText?: string;
    nextButtonText?: string;
}

export class PageType implements PageContract, Validatable
{
    public id: string;
    public type: string;
    public name: string;
    public title: string;
    public showTitle: boolean;
    public description: string;
    public components: Blueprint[];
    public prevButtonText?: string;
    public nextButtonText?: string;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.PAGE;
        this.name = builder.name(Components.PAGE);
        this.title = '';
        this.showTitle = true;
        this.description = '';
        this.components = [];
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return validateComponents(this.components);
    }
}

export const instanceOfPageType = (object: any): object is PageType =>
{
    return object && 'type' in object && object.type === Components.PAGE && instanceOfBlueprint(object);
};
