import { Option, Pagination, Resource, Statement } from "@/helpers/Interfaces";
import Pager from "@/helpers/Pager";
import axios, { AxiosResponse } from "axios";
import merge from 'lodash/merge';
import { FilterModel, Property, Route, RouteListItem, RoutesListFilterModel, RouteStatusType, CommissionsListItem, CommissionsFilterModel, CommissionFormModel } from "../types";
import { DateTime } from "luxon";
import { $t } from "@/plugins/localization";

export default class DriversRoutesService
{
    /**
     * @param cityPublicId string
     *
     * @returns Promise<Array<Property>>
     */
    public static async getProperties(dateUtc: DateTime, filters: FilterModel): Promise<Array<Property>>
    {
        return ((await axios.get(`admin/renters/routes/properties`, {
            params: { dateUtc, ...filters }
        })).data).result;
    }

    /**
     * @param cityPublicId string
     *
     * @returns Promise<Pagination<Resource<RouteListItem>>>
     */
    public static async getRoutes(dateUtc: DateTime, filters: FilterModel): Promise<Pagination<Resource<RouteListItem>>>
    {
        return (await axios.get(`admin/renters/routes/map`, {
            params: { dateUtc, ...filters }
        })).data;
    }

    /**
     * @param cityPublicId string
     *
     * @returns Promise<Pagination<Resource<RouteListItem>>>
     */
    public static async getRoutesList(filters: RoutesListFilterModel, pager: Pager): Promise<Pagination<Resource<RouteListItem>>>
    {
        return (await axios.get(`admin/renters/routes`,{ params: merge( filters, pager.data()) })).data;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<Route>
     */
    public static async fetch(publicId: string): Promise<Route>
    {
        return ((await axios.get(`admin/renters/routes/${publicId}`)).data).result;
    }

    /**
     * @param publicId string
     *
     * @param model route
     *
     * @returns Promise<Route>
     */
    public static async update(publicId: string, model: Route): Promise<Route>
    {
        return ((await axios.put(`admin/renters/routes/${publicId}`, model)).data).result;
    }

    /**
     * @param model route
     *
     * @returns Promise<Route>
     */
    public static async create(model: Route): Promise<Route>
    {
        return ((await axios.post(`admin/renters/routes`, model)).data).result;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<Route>
     */
    public static async remove(publicId: string): Promise<Route>
    {
        return ((await axios.delete(`admin/renters/routes/${publicId}`)).data).result;
    }

    /**
     *
     * @returns Promise<Route>
     */
    public static async getMyRoutes(dateUtc: DateTime): Promise<Pagination<Resource<RouteListItem>>>
    {
        return (await axios.get(`admin/renters/routes/myRoutes`, { params: { dateUtc } })).data;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<Route>
     */
    public static async startRoute(publicId: string, counterAssignment: number): Promise<Route>
    {
        return ((await axios.put(`admin/renters/routes/${publicId}/startRoute`, null, {
            params: {
                counterAssignment
            }
        })).data).result;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<Route>
     */
    public static async checkCheckpoint(publicId: string): Promise<Route>
    {
        return ((await axios.put(`admin/renters/routes/continueRoute/${publicId}`)).data).result;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<Route>
     */
    public static async finishRoute(publicId: string, counterAssignment: number): Promise<Route>
    {
        return ((await axios.put(`admin/renters/routes/${publicId}/finishRoute`, null, {
            params: {
                counterAssignment
            }
        } )).data).result;
    }

    /**
    *
    * @returns Promise<Pagination<Resource<RouteListItem>>>
    */
    public static async getArchivedRoutesList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<RouteListItem>>>
    {
        return (await axios.get(`admin/renters/routes/completedRoutes`, { params: merge(filter, pager.data()) })).data;
    }

    /**
    *
    * @returns Promise<Pagination<Resource<RouteListItem>>>
    */
    public static async updateRouteOrder(publicId: string, model: Route): Promise<Route>
    {
        return ((await axios.put(`admin/renters/routes/${publicId}/changeRouteOrder`, model)).data).result;
    }

    /**
     * @param publicId string
     * @param comment string
     *
     * @returns Promise<Route>
     */
    public static async updateRouteComment(publicId: string, comment: string): Promise<Route>
    {
        return ((await axios.put(`admin/renters/routes/${publicId}/updateComment`, comment, {
            headers: { 'Content-Type': 'application/json' }
        })).data).result;
    }

    public static async pauseRoute(publicId: string): Promise<Resource<Route>>
    {
        return (await axios.put(`admin/renters/routes/${publicId}/pauseRoute`)).data;
    }

    public static async continueRoute(publicId: string): Promise<Resource<Route>>
    {
        return (await axios.put(`admin/renters/routes/${publicId}/resumeRoute`)).data;
    }

    public static async getRoutesExcel(dateFromUtc: DateTime, dateToUtc: DateTime): Promise<AxiosResponse>
    {
        return (await axios.get<Blob>(`admin/renters/routes/excel`, {
            params: {
                dateFromUtc,
                dateToUtc
            },
            responseType: "blob"
        }));
    }

    /**
    *
    * @returns Promise<Pagination<Resource<CommissionsListItem>>>
    */
    public static async getCommissionsList(filter: CommissionsFilterModel, pager: Pager): Promise<Pagination<Resource<CommissionsListItem>>>
    {
        return (await axios.get(`admin/renters/commissions`, { params: merge(filter, pager.data()) })).data;
    }

    /**
     * @param model FormModel
     *
     * @returns Promise<Resource<CommissionFormModel>>
    */
    public static async createCommission(model: CommissionFormModel): Promise<Resource<CommissionFormModel>>
    {
        return (await axios.post(`admin/renters/commissions`, model)).data;
    }

    /**
    *
    * @returns Promise<Pagination<Resource<CommissionsListItem>>>
    */
    public static async getCommission(publicId: string): Promise<Pagination<Resource<CommissionsListItem>>>
    {
        return (await axios.get(`admin/renters/commissions/${publicId}`)).data;
    }

    /**
     * @param publicId string
     * @param model CommissionFormModel
     *
     * @returns Promise<Statement>
    */
    public static async updateCommission(publicId: string, model: CommissionFormModel): Promise<Statement>
    {
        return (await axios.put(`admin/renters/commissions/${publicId}`, model)).data;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<Statement>
    */
    public static async removeCommission(publicId: string): Promise<Statement>
    {
        return (await axios.delete(`admin/renters/commissions/${publicId}`)).data;
    }
}

enum TaskTypeEnum {
    Cleaning = 'Cleaning',
    Inspection = 'Inspection',
    Maintenance = 'Maintenance',
    All = 'All'
}

enum OrderDirection {
    Up = 'up',
    Down = 'down'
}

enum RouteStatus {
    Created = 'Created',
    InProgress = 'InProgress',
    Done = 'Done',
}

const RouteStatusOptions = (): Option[] => ([
    { value: RouteStatus.Created, text: $t('[[[Oczekujące]]]') },
    { value: RouteStatus.InProgress, text: $t('[[[W trakcie]]]') },
    { value: RouteStatus.Done, text: $t('[[[Zakończone]]]') },
]);

const TaskTypeOptions = (): Option[] => ([
    { value: TaskTypeEnum.Cleaning, text: $t('[[[Sprzątanie]]]') },
    { value: TaskTypeEnum.Inspection, text: $t('[[[Inspekcja]]]') },
    { value: TaskTypeEnum.Maintenance, text: $t('[[[Konserwacja]]]') },
    { value: TaskTypeEnum.All, text: $t('[[[Wszystkie]]]') },
]);

const RouteStatusName = (value: RouteStatus): string => RouteStatusOptions().find(s => s.value === value)?.text || RouteStatus[value];

const TaskTypeName = (value: TaskTypeEnum): string => TaskTypeOptions().find(s => s.value === value)?.text || TaskTypeEnum[value];

const RouteStatusFilterOptions = (): RouteStatusType[] => ([
    { value: true, text: $t('[[[Zakończone]]]') },
    { value: false, text: $t('[[[Niezakończone]]]') },
    { value: null, text: $t('[[[Wszystkie]]]') },
]);

export { TaskTypeEnum, OrderDirection, TaskTypeOptions, TaskTypeName, RouteStatus, RouteStatusName, RouteStatusFilterOptions };
