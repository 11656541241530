import { FormBuilder } from '../Utils';
import { Components, AggregateBlueprint, Blueprint, Validatable, ValidationErrors, validateComponents, instanceOfBlueprint } from '../Types';

export interface RowContract extends AggregateBlueprint
{
}

export class RowType implements RowContract, Validatable
{
    public id: string;
    public type: string;
    public name: string;
    public components: Blueprint[];
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.ROW;
        this.name = builder.name(Components.ROW);
        this.components = [];
        this.errors = {};
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return validateComponents(this.components);
    }
}

export const instanceOfRowType = (object: any): object is RowType =>
{
    return object && 'type' in object && object.type === Components.ROW && instanceOfBlueprint(object);
};
