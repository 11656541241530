<template>
    <div class="form-group">
        <label :for="uid">{{ label }}</label>
        <div>
            <div class="btn-group w-100">
                <button
                    class="text-truncate"
                    v-for="option in options"
                    :key="option.value" type="button"
                    :class="{
                        'btn btn-primary': option.value == modelValue,
                        'btn btn-light': option.value != modelValue
                    }"
                    :title="$t(option.text)"
                    @click="update(option.value)"
                >
                    {{ $t(option.text) }}
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { Option } from '@/helpers/Interfaces';

@Options({
    name: 'field-choice'
})
export default class FieldChoice extends Vue
{
    @Prop() public label: string;
    @Prop() public options: Option<any>[];
    @Prop() public modelValue: any;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    @Emit('update:modelValue')
    public update(value: any): any
    {
        return value;
    }
}
</script>

<style lang="scss" scoped>
.btn {
    text-transform: inherit;
    font-weight: normal;
    padding-left: 6px;
    padding-right: 6px;
}
</style>
