<template>
    <div class="d-flex justify-content-center py-5">
        <loader />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Loader from '@/components/common/Loader.vue';

@Options({
    components: {
        'loader': Loader
    }
})
export default class AuthAzure extends Vue
{
    public get message(): string
    {
        return this.$store.state.core.auth.message;
    }

    public set message(value: string)
    {
        this.$store.state.core.auth.message = value;
    }

    public get azure(): boolean
    {
        return import.meta.env.VITE_APP_AZURE_AUTH == "true";
    }

    public async mounted(): Promise<void>
    {
        if (this.azure)
        {
            try
            {
                await this.$auth.refresh(true);
                await this.$auth.fetch();

                this.redirect({ name: "renter-dashboard" });
            }
            catch (ex)
            {
                this.handleException(ex, {
                    400: (ex: any) => { this.message = ex.message; },
                    401: (ex: any) => { this.message = "[[[Logowanie zakończyło się niepowodzeniem.]]]"; }
                });

                await this.$auth.logout();
            }
        }
        else
        {
            await this.$auth.logout();
        }
    }
}
</script>

<style scoped>
</style>
