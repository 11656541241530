<template>
    <responsive v-bind="attrs()">
        <table class="table table-data table-stripped table-hover mb-0">
            <colgroup>
                <col v-for="(header, index) in headers" :key="`col-${index}`" :style="getStyle(header)">
            </colgroup>
            <thead v-if="showHeader">
                <tr>
                    <th v-for="(header, index) in headers" :key="`th-${index}`" :class="{'buttons': header.buttons || false}">
                        <a href="" @click.stop.prevent="onSorting(header)" v-if="header.sort || false">
                            <i :class="getIcon(header)"></i>
                            {{ header.title || '' }}
                        </a>
                        <div v-else-if="multiple && index === 0" class="d-flex justify-content-center">
                            <ideo-form-checkbox @change="(state: boolean) => multiple?.selectAllItems(state)" :checked="multiple?.isAllSelected()" />
                        </div>
                        <span v-else>
                            {{ header.title || '' }}
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody v-if="rows.length > 0">
                <list-view-utils-row v-for="(item, index) in rows" :key="`row-${index}`" :index="index" :item="item">
                    <td v-if="multiple">
                        <ideo-form-checkbox v-if="multiple.item(index)" @click.stop v-model="multiple.item(index).selected" :disabled="multiple.item(index).disabled" />
                        <ideo-form-checkbox v-else :disabled="true" />
                    </td>
                    <slot name="row" :item="item" :index="index"></slot>
                </list-view-utils-row>
            </tbody>
            <tbody v-else>
                <td :colspan="headers.length" class="px-0">
                    <div class="text-center p-3 bg-light text-muted">{{ $t(emptyLabel) }}</div>
                </td>
            </tbody>
        </table>
    </responsive>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Inject, Prop } from '@/helpers/Decorators';
import Pager from '@/helpers/Pager';
import Row from '../utils/Row.vue';
import { only } from '@/helpers/Utils';
import ListMultipleElements from '@/helpers/List';

@Options({
    name: 'list-view-table-layout',
    inheritAttrs: false,
    components: {
        'list-view-utils-row': Row
    }
})
export default class ListViewTableLayout extends Vue
{
    @Inject('empty-label')
    public emptyLabel: string;

    @Prop()
    public pager!: Pager;

    @Prop()
    public headers!: any[];

    @Prop()
    public rows!: any[];

    @Prop()
    public showHeader!: boolean;

    @Prop({ default: null })
    public multiple: ListMultipleElements<any> | null;

    public getStyle(header: any): string
    {
        return header.width ? `width:${header.width || 0}${(header.width || 0).toString().endsWith('%') ? '' : 'px'}` : '';
    }

    public getIcon(header: any): string
    {
        return "fa fa-fw fa-sort" + (this.pager.sorting !== (header.sort || '') ? '' : this.pager.order.toLowerCase() === 'asc' ? '-up' : '-down');
    }

    @Emit('change')
    public onSorting(header: any): any
    {
        return header;
    }

    public attrs(): Record<string, any>
    {
        return only(this.$attrs, 'class', 'style');
    }
}
</script>
