import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint, ChoiceOption } from '../Types';
import { FormEntry } from '../types/Form';
import { ChoiceContract, ChoiceFieldTypes, ChoiceEntry, instanceOfChoiceEntry } from '../types/Choice';
import properties from '../properties';
import { Option } from '@/helpers/Interfaces';

@Options({
    name: 'choice-blueprint',
    components: {
        ...properties
    }
})
export default class ChoiceBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: ChoiceContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public entryData: ChoiceEntry = new ChoiceEntry();

    public created(): void
    {
        this.entryData = this.form.initEntry(this.blueprint, this.entryData, instanceOfChoiceEntry);
    }

    public get value(): string[]
    {
        return this.entryData.values;
    }

    public set value(values: string[])
    {
        this.entryData.values = values;
    }

    public get fieldTypeOptions(): Option<ChoiceFieldTypes>[]
    {
        return [
            { value: ChoiceFieldTypes.Dropdown, text: '[[[Lista rozwijana]]]' },
            { value: ChoiceFieldTypes.Radio, text: '[[[Pojedynczy wybór]]]' },
            { value: ChoiceFieldTypes.Checkbox, text: '[[[Wielokrotny wybór]]]' }
        ];
    }

    public get fieldType(): typeof ChoiceFieldTypes
    {
        return ChoiceFieldTypes;
    }

    public get layoutOptions(): Option<number>[]
    {
        return [
            { value: 0, text: '[[[Obok siebie]]]' },
            { value: 1, text: '[[[Jedna kolumna]]]' },
            { value: 2, text: '[[[Dwie kolumny]]]' },
            { value: 3, text: '[[[Trzy kolumny]]]' },
            { value: 4, text: '[[[Cztery kolumny]]]' }
        ];
    }

    public get isDropdownField(): boolean
    {
        return this.blueprint.fieldType == ChoiceFieldTypes.Dropdown;
    }

    public get isRadioList(): boolean
    {
        return this.blueprint.fieldType == ChoiceFieldTypes.Radio;
    }

    public get isCheckboxList(): boolean
    {
        return this.blueprint.fieldType == ChoiceFieldTypes.Checkbox;
    }

    public get readonly(): boolean
    {
        return this.form.readonly(this.blueprint);
    }

    public get required(): boolean
    {
        return this.form.required(this.blueprint);
    }

    public checked(option: ChoiceOption): boolean
    {
        if (this.value === null)
        {
            if (this.isCheckboxList == false && this.blueprint.defaultValue)
            {
                return this.form.executeExpression(this.blueprint.defaultValue) == option.value;
            }

            return option.selected;
        }

        return this.value.includes(option.value);
    }

    public checkedValue(option: ChoiceOption): any
    {
        return this.checked(option) ? option.value : null;
    }

    public selectedValue(): any
    {
        return this.blueprint.options.filter(p => this.checked(p)).map(p => p.value).find(p => true) || null;
    }

    public change(e: any): void
    {
        const value = e.target.value;
        let values = [].concat(this.value || []);

        if (this.isCheckboxList == false)
        {
            values = [value];
        }

        this.update(values.filter(p => p !== null && p != ''));
    }

    protected update(value: string[]): void
    {
        this.value = value;
    }

    @Watch('blueprint.defaultValue')
    protected onDefaultValueChanged(value: string, old: string): void
    {
        this.blueprint.options.forEach(p => { p.selected = false; });
    }
}
