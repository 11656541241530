import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint, Blueprint } from '../Types';
import { FormEntry } from '../types/Form';
import { RowContract } from '../types/Row';
import { SectionContract } from '../types/Section';
import properties from '../properties';
import last from 'lodash/last';

@Options({
    name: 'section-blueprint',
    components: {
        ...properties
    }
})
export default class SectionBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: SectionContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public created(): void
    {
        if (this.blueprint.components.length == 0)
        {
            this.form.addRow(this.blueprint);
        }
    }

    public get design(): boolean
    {
        return this.form.designMode();
    }

    public get readonly(): boolean
    {
        return !this.design && this.form.readonly(this.blueprint);
    }

    public get rows(): RowContract[]
    {
        return this.blueprint.components as RowContract[];
    }

    protected get last(): RowContract
    {
        return last(this.rows);
    }

    protected showLine(index: number): boolean
    {
        const next = index < this.rows.length - 1 ? this.rows[index + 1] : { components: [] } as RowContract;

        return this.rows[index].components.length > 0 && next.components.length > 0;
    }

    public addRow(before: RowContract): void
    {
        this.form.addRow(this.blueprint, before);
    }

    public addComponent(type: string, before: RowContract): void
    {
        const row = this.form.addRow(this.blueprint, before);

        this.form.addComponent(row, type);
    }

    public dropComponent(component: Blueprint, before: RowContract): void
    {
        const row = this.form.addRow(this.blueprint, before);

        this.form.cut(component);
        this.form.paste(row);
    }

    @Watch('last.components.length')
    public onRowsChanged(length: number): void
    {
        if (length && length > 0)
        {
            this.form.addRow(this.blueprint);
        }
    }
}
