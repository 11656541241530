import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint } from '../Types';
import { FormEntry } from '../types/Form';
import { TextContract, TextFieldTypes, TextEntry, instanceOfTextEntry, ValidationTypes } from '../types/Text';
import properties from '../properties';
import { Option } from '@/helpers/Interfaces';

@Options({
    name: 'text-blueprint',
    components: {
        ...properties
    }
})
export default class TextBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: TextContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public entryData: TextEntry = new TextEntry();

    public created(): void
    {
        this.entryData = this.form.initEntry(this.blueprint, this.entryData, instanceOfTextEntry);
    }

    public get value(): string
    {
        return this.entryData.value === null
            ? this.form.executeExpression(this.blueprint.defaultValue)
            : this.entryData.value;
    }

    public set value(value: string)
    {
        this.entryData.value = value;
    }

    public get fieldTypeOptions(): Option<TextFieldTypes>[]
    {
        return [
            { value: TextFieldTypes.Text, text: '[[[Tekst]]]' },
            { value: TextFieldTypes.Multiline, text: '[[[Treść]]]' },
            { value: TextFieldTypes.Password, text: '[[[Hasło]]]' }
        ];
    }

    public get fieldType(): typeof TextFieldTypes
    {
        return TextFieldTypes;
    }

    public get validationOptions(): Option<ValidationTypes>[]
    {
        return [
            { value: ValidationTypes.None, text: '[[[Brak]]]' },
            { value: ValidationTypes.Alphabetic, text: '[[[Litery]]]' },
            { value: ValidationTypes.Numeric, text: '[[[Cyfry]]]' },
            { value: ValidationTypes.Alphanumeric, text: '[[[Litery i cyfry]]]' },
            { value: ValidationTypes.RegularExpression, text: '[[[Wyrażenie regularne]]]' }
        ];
    }

    public get validationType(): typeof ValidationTypes
    {
        return ValidationTypes;
    }

    public get isTextField(): boolean
    {
        return this.blueprint.fieldType == TextFieldTypes.Text;
    }

    public get isMultiline(): boolean
    {
        return this.blueprint.fieldType == TextFieldTypes.Multiline;
    }

    public get isPassword(): boolean
    {
        return this.blueprint.fieldType == TextFieldTypes.Password;
    }

    public get isRegExpValidation(): boolean
    {
        return this.isTextField && this.blueprint.validation == ValidationTypes.RegularExpression;
    }

    public get readonly(): boolean
    {
        return this.form.readonly(this.blueprint);
    }

    public get required(): boolean
    {
        return this.form.required(this.blueprint);
    }
}
