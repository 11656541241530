import { RouteLocationRaw } from "vue-router";

export default (): boolean | RouteLocationRaw =>
{
    const superUser = sessionStorage.getItem('superUser') || false;

    if (!superUser)
    {
        console.log("%cYou are trying to get into dangerous section in your app. If you are sure what you're doing add to sessionStorage 'superUser' item to true","font-size: 20px");

        return { name: 'error-403' };
    }
    else
    {
        return true;
    }
};

export const isVisibleForSuperUser = (): boolean =>
{
    return sessionStorage.getItem('superUser') ? true : false;
};