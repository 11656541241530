<template>
    <div v-if="renter" class="loader-renter">
        <img src="/static/img/short_hirenterslogo.png" />
        <span class="loader-dots"></span>
    </div>
    <div v-else class="loader"></div>
</template>

<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'loader'
})
export default class Loader extends Vue
{
    @Prop({ default: true }) public renter: boolean;
}
</script>

<style scoped lang="scss">
.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px !important;
    height: 120px !important;
    animation: spin 2s linear infinite;
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}
.loader-renter {
    width: 120px !important;
    height: 120px !important;
    animation: appear .1s ease-in forwards;
    position: relative;
    @keyframes appear {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
    .loader-dots {
        width: 120px;
        height: 16px;
        background:
            radial-gradient(circle 8px at 8px center, var(--primary) 100%, transparent 0),
            radial-gradient(circle 8px at 8px center, var(--primary) 100%, transparent 0);
        background-size: 16px 16px;
        background-repeat: no-repeat;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%,-50%);
        animation: ballX 1s linear infinite;

        &:before {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: var(--primary);
            inset:0;
            margin:auto;
            animation: moveX 1s cubic-bezier(0.5,300,0.5,-300) infinite;
        }
    }
    @keyframes ballX {
        0%,25%,50%,75%, 100%  {background-position: 25% 0,75% 0}
        40%     {background-position: 25% 0,85% 0}
        90%     {background-position: 15% 0,75% 0}
    }
    @keyframes moveX {
        100% {transform:translate(0.15px)}
    }
}
</style>
