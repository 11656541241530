import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    AggregateBlueprint,
    VisibleBlueprint,
    ReadonlyBlueprint,
    RequiredBlueprint,
    CustomErrorBlueprint,
    HasLabel,
    HasPlaceholder,
    HasHelp,
    HasWidth,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice,
    EntryFactory,
    Validatable,
    ValidationErrors,
    ValidEntry
} from '../Types';
import min from 'lodash/min';

export enum BooleanFieldTypes {
    Radio = 'Radio',
    Checkbox = 'Checkbox',
    Toggle = 'Toggle'
}

export class BooleanEntry extends ValidEntry
{
    public type: string = Components.BOOLEAN;
    public value?: boolean = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.value = data.value;
        }
    }

    public async collect(blueprint: BooleanContract, form: FormBuilder, preprocess: (name: string, entry: any) => Promise<void>): Promise<any>
    {
        await preprocess(blueprint.name, this);

        return {
            type: this.type,
            value: this.value || blueprint.defaultValue
        };
    }

    public validate(blueprint: BooleanContract, form: FormBuilder): boolean
    {
        this.errors = {};

        const value = this.value || blueprint.defaultValue;

        if (!form.readonly(blueprint, true) && form.visible(blueprint, true))
        {
            if (form.required(blueprint) && (value === null || value === false))
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${blueprint.label}]]]`];
            }
            else if (form.customError(blueprint))
            {
                this.errors.custom = [form.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }
}

export const instanceOfBooleanEntry = (object: any): object is BooleanEntry =>
{
    return object && 'type' in object && object.type === Components.BOOLEAN;
};

export interface BooleanContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasPlaceholder, HasHelp, HasWidth
{
    fieldType: BooleanFieldTypes;
    defaultValue: boolean;
    trueValueText: string;
    falseValueText: string;
}

export class BooleanType implements BooleanContract, Validatable, EntryFactory<BooleanEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public placeholder: string;
    public help: string;
    public width: number;
    public minWidth: number;
    public fieldType: BooleanFieldTypes;
    public defaultValue: boolean;
    public trueValueText: string;
    public falseValueText: string;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.BOOLEAN;
        this.name = builder.name(Components.BOOLEAN);
        this.label = '[[[Akceptacja]]]';
        this.showLabel = true;
        this.placeholder = '';
        this.defaultValue = false;
        this.help = '';
        this.fieldType = BooleanFieldTypes.Radio;
        this.width = min([3, builder.space(parent)]);
        this.minWidth = 1;
        this.trueValueText = '';
        this.falseValueText = '';
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public createEntry(data: any): BooleanEntry
    {
        return new BooleanEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
