import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    VisibleBlueprint,
    ReadonlyBlueprint,
    RequiredBlueprint,
    CustomErrorBlueprint,
    HasLabel,
    HasPlaceholder,
    HasAffix,
    HasHelp,
    HasWidth,
    AffixValue,
    AggregateBlueprint,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice,
    EntryFactory,
    ValidationErrors,
    Validatable,
    ValidEntry
} from '../Types';
import min from 'lodash/min';

export class EmailEntry extends ValidEntry
{
    public type: string = Components.EMAIL;
    public value?: string = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.value = data.value;
        }
    }

    public async collect(blueprint: EmailContract, form: FormBuilder, preprocess: (name: string, entry: any) => Promise<void>): Promise<any>
    {
        await preprocess(blueprint.name, this);

        return {
            type: this.type,
            value: this.value || form.executeExpression(blueprint.defaultValue)
        };
    }

    public validate(blueprint: EmailContract, form: FormBuilder): boolean
    {
        this.errors = {};

        const value = this.value || form.executeExpression(blueprint.defaultValue);
        const re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

        if (!form.readonly(blueprint, true) && form.visible(blueprint, true))
        {
            if (form.required(blueprint) && (value == null || value.length == 0))
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${blueprint.label}]]]`];
            }
            else if (value != null && !re.test(value))
            {
                this.errors.value = [`[[[Pole "%0" nie zawiera prawidłowego adresu email.|||${blueprint.label}]]]`];
            }
            else if (form.customError(blueprint))
            {
                this.errors.custom = [form.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }
}

export const instanceOfEmailEntry = (object: any): object is EmailEntry =>
{
    return object && 'type' in object && object.type === Components.EMAIL;
};

export interface EmailContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasPlaceholder, HasAffix, HasHelp, HasWidth
{
    defaultValue: string;
}

export class EmailType implements EmailContract, Validatable, EntryFactory<EmailEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public placeholder: string;
    public defaultValue: string;
    public affix: AffixValue;
    public help: string;
    public width: number;
    public minWidth: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.EMAIL;
        this.name = builder.name(Components.EMAIL);
        this.label = '[[[Email]]]';
        this.showLabel = true;
        this.placeholder = '';
        this.defaultValue = '';
        this.help = '';
        this.affix = { prepend: '', append: '' };
        this.width = min([3, builder.space(parent)]);
        this.minWidth = 1;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public createEntry(data: any): EmailEntry
    {
        return new EmailEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
