<template>
    <div class="form-group">
        <ideo-form-checkbox v-model="selected" :disabled="disabled">{{ label }}</ideo-form-checkbox>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';

@Options({
    name: 'field-checkbox'
})
export default class FieldCheckbox extends Vue
{
    @Prop() public label: string;
    @Prop() public modelValue: boolean;
    @Prop({ default: false }) public disabled: boolean;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public get selected(): any
    {
        return this.modelValue;
    }

    public set selected(value: any)
    {
        this.triggerInput(value);
    }

    @Emit('update:modelValue')
    public triggerInput(value: any): any
    {
        return value;
    }
}
</script>
