<template>
    <div class="form-group">
        <label :for="uid">{{ label }}</label>
        <div>
            <ideo-form-radio-group v-model="selected" :options="items" stacked></ideo-form-radio-group>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { Option } from '@/helpers/Interfaces';

@Options({
    name: 'field-radiolist'
})
export default class FieldRadioList extends Vue
{
    @Prop() public label: string;
    @Prop() public options: Option<any>[];
    @Prop() public modelValue: any;
    @Prop({ default: false }) public inline: boolean;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public get items(): Option<any>[]
    {
        return this.options.map(p =>
        {
            return { value: p.value, text: this.$t(p.text) };
        });
    }

    public get selected(): any
    {
        return this.modelValue;
    }

    public set selected(value: any)
    {
        this.triggerInput(value);
    }

    @Emit('update:modelValue')
    public triggerInput(value: any): any
    {
        return value;
    }
}
</script>
