import { $t } from "@/plugins/localization";

export enum AppTitleEnum {
    Default = 'Hi! Renters',
    NewMessage = 'NewMessage'
}

const setAppTitle = (title: AppTitleEnum | string, sound: boolean = false): void =>
{
    const playSound = async (notificationSound: HTMLAudioElement): Promise<void> =>
    {
        try
        {
            await notificationSound.play();
        }
        catch (ex)
        {
            console.warn(ex);
        }
    };

    if (sound)
    {
        const notificationSound = new Audio('/static/sounds/new-message.mp3');

        playSound(notificationSound);
    }

    if (title === AppTitleEnum.Default)
        document.title = 'Hi! Renters';

    else if (title === AppTitleEnum.NewMessage)
        document.title = $t('[[[Masz nową wiadomość!]]]');

    else
        document.title = title;
};


export default setAppTitle;