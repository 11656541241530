import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    VisibleBlueprint,
    ReadonlyBlueprint,
    RequiredBlueprint,
    CustomErrorBlueprint,
    HasLabel,
    HasPlaceholder,
    HasHelp,
    HasWidth,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice,
    AggregateBlueprint,
    EntryFactory,
    ValidationErrors,
    Validatable,
    ValidEntry
} from '../Types';
import min from 'lodash/min';

export class DictionaryEntry extends ValidEntry
{
    public type: string = Components.DICTIONARY;
    public value?: string = null;
    public text?: string = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.value = data.value;
            this.text = data.text;
        }
    }

    public async collect(blueprint: DictionaryContract, form: FormBuilder, preprocess: (name: string, entry: any) => Promise<void>): Promise<any>
    {
        await preprocess(blueprint.name, this);

        return {
            type: this.type,
            value: this.value,
            text: this.text
        };
    }

    public validate(blueprint: DictionaryContract, form: FormBuilder): boolean
    {
        this.errors = {};

        if (!form.readonly(blueprint, true) && form.visible(blueprint, true))
        {
            if (form.required(blueprint) && (this.value == null || this.value.length == 0))
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${blueprint.label}]]]`];
            }
            else if (form.customError(blueprint))
            {
                this.errors.custom = [form.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }
}

export const instanceOfDictionaryEntry = (object: any): object is DictionaryEntry =>
{
    return object && 'type' in object && object.type === Components.DICTIONARY;
};

export interface DictionaryContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasPlaceholder, HasHelp, HasWidth
{
    sourceId: number;
}

export class DictionaryType implements DictionaryContract, Validatable, EntryFactory<DictionaryEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public placeholder: string;
    public sourceId: number;
    public help: string;
    public width: number;
    public minWidth: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.DICTIONARY;
        this.name = builder.name(Components.DICTIONARY);
        this.label = '[[[Wybór]]]';
        this.showLabel = true;
        this.placeholder = '';
        this.sourceId = null;
        this.help = '';
        this.width = min([3, builder.space(parent)]);
        this.minWidth = 1;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public createEntry(data: any): DictionaryEntry
    {
        return new DictionaryEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        if (!this.sourceId)
            this.errors.sourceId = ['[[[Wymagane jest wybranie źródła danych]]]'];

        return {
            [this.name]: this.errors
        };
    }
}
