export default (file: File, container: string, payload: any = null, secured: boolean = false): FormData =>
{
    const data = new FormData();

    const convertedFileName = file.name.replace(/\(|\)/g, '_').replace(/—/g, '').replace(/\s/g, '_');
    const convertedFile = new File([file], convertedFileName, { type: file.type });

    data.append('container', container);
    data.append('scope', "public");
    data.append('payload', JSON.stringify(payload));
    data.append('secured', secured ? "true" : "false");
    data.append('file', convertedFile);

    return data;
};