import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint, Blueprint } from '../Types';
import { FormEntry } from '../types/Form';

@Options({
    name: 'line-blueprint'
})
export default class LineBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: Blueprint;
    @Prop({ default: null }) public entry!: FormEntry;
}
