<template>
    <section>
    </section>
    <section class="container">
        <h2>Polityka prywatności aplikacji mobilnej Hi!Renters Mobile</h2>
        <ol>
            <li>Niniejszy dokument stanowi Politykę Prywatności aplikacji mobilnej Hi!Renters Mobile (dalej Aplikacja)..</li>
            <li>Aplikacja jest narzędziem wykorzystywanym przez <strong>RENTERS spółkę z ograniczoną odpowiedzialnością z siedzibą w Warszawie</strong>, ul. Jana Sebastiana Bacha 26A, 02-743 Warszawa, wpisaną do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego pod numerem KRS 0000678215 NIP: 5213782041, REGON: 367317066 (dalej Renters), do zlecania oraz monitorowania realizacji zleceń związanych z obsługą apartamentów zarządzanych przez Renters w ramach usług najmu krótkoterminowego.</li>
            <li>Aplikacja instalowana jest na urządzeniach mobilnych, w szczególności na telefonach komórkowych i tabletach i po uprzednim pobraniu Aplikacji przez użytkownika zapośrednictwem sklepu Play (Android), sklepu App Store (iOS).</li>
            <li>Korzystanie z Aplikacji wymaga od użytkownika posiadania urządzenia mobilnego z systemem Android lub iOS oraz połączenia z Internetem w formie włączonego pakietu transmisji danych lub włączonego połączenia wi-fi.</li>
            <li>Dostęp do Aplikacji mogą uzyskać wyłącznie pracownicy lub współpracownicy Renters, po utworzeniu dla nich konta użytkownika przez przedstawiciela Renters. W celu utworzenia konta użytkownik przekazuje Renters swoje imię i nazwisko, adres e-mail oraz numer telefonu.</li>
            <li>Korzystanie z Aplikacji wymaga zalogowania się użytkownika na podstawie loginu i hasła otrzymanego od Renters.</li>
            <li>Aplikacja nie przechowuje żadnych danych osobowych, które mogłyby umożliwić osobie trzeciej identyfikację konkretnego użytkownika Aplikacji. Nie są również udostępniane przez Renters jakimkolwiek osobom trzecim.</li>
            <li>Renters udostępnia Aplikację użytkownikom będącym pracownikami lub współpracownikami Renters wykonującymi na rzecz Renters określone prace (sprzątacze, konserwatorzy, inspektorzy) lub realizującymi usługi transportu/dostawy (kierowcy/dostawcy).</li>
            <li>Użytkownik świadczący na rzecz Renters prace po zalogowaniu do Aplikacji może sprawdzić listę zadań, które zostały mu zlecone do wykonania w ramach zawartej z Renters umowy, wraz ze szczegółowymi informacjami: adres realizacji, nazwa i opis zadania do wykonania, informacje o sposobie dostępu do apartamentu, w którym prace mają być wykonywane (piętro, kod do klatki, kod do sejfu z kluczami, opis apartamentu, zdjęcia). Użytkownik oznacza w aplikacji rozpoczęcie pracy przy danym zadaniu oraz jej zakończenie (na potrzeby zliczenia i potwierdzenia czasu pracy przy danym zadaniu). Jednocześnie użytkownik ma możliwość przesłania przedstawicielowi Renters za pomocą Aplikacji informacji (zdjęcia, tekst), jeśli w trakcie realizacji zadania ujawni usterki, braki wyposażenia lub inne zdarzenia w apartamencie.</li>
            <li>Renters przetwarza informacje zebrane zgodnie z pkt. 9 powyżej zbiorczo (przygotowanie raportu całkowitego czasu pracy użytkownika w zadanym przedziale czasu), oraz wyrywkowo (sprawdzenie lokalizacji użytkownika w momencie oznaczenia zadania jako rozpoczęte/zakończone celem ujawnienia w celu weryfikacji prawidłowego realizowania obowiązków wynikających z umowy i faktycznego przebywania użytkownika w miejscu realizacji zleconego zadania).</li>
            <li>Użytkownik realizujący na rzecz Renters usługi transportu/dostawy po zalogowaniu do Aplikacji może sprawdzić listę tras (zbiór punktów na mapie ułożonych w kolejności, w jakiej mają zostać wykonane usługi transportu/dostawy), wraz ze szczegółowymi informacjami: adres realizacji, nazwa i opis zadania do wykonania, informacje o sposobie dostępu do apartamentu (piętro, kod do klatki, kod do sejfu z kluczami, opis apartamentu, zdjęcia). Użytkownik oznacza w Aplikacji rozpoczęcie danej trasy, wprowadza obowiązkowo wskazanie licznika przebiegu pojazdu, którego używa do realizacji trasy a następnie po dojechaniu do poszczególnych punktów oznacza, że zostały one osiągnięte. Po zakończeniu całej trasy w danym dniu realizacji usługi, użytkownik wprowadza końcowe wskazanie licznika przebiegu pojazdu, którym wykonał swoje zadanie. W trakcie realizacji trasy (od oznaczenia rozpoczęcia, do oznaczenia zakończenia). Aplikacja w regularnych odstępach czasu sprawdza lokalizację użytkownika za pomocą GPS i zlicza łącznie przejechany przez użytkownika dystans.</li>
            <li>Renters przetwarza informacje zebrane zgodnie z pkt. 11 zbiorczo (raport łącznego dystansu przejechanego przez danego użytkownika w okresie miesiąca świadczenia usług) oraz wyrywkowo (porównanie wskazań wpisanych manualnie przez użytkownika jako stan licznika pojazdu używanego do wykonywania usługi na początek/koniec jej realizacji z wynikiem obliczonym przez Aplikacje na podstawie danych pozyskanych z GPS).</li>
            <li>Użytkownik Aplikacji zobowiązany jest do zachowania w ścisłej tajemnicy wszelkich informacji przekazywanych przez Renters na potrzeby realizacji usług wykonywanych z wykorzystaniem Aplikacji.</li>
            <li>
                Aplikacja może korzystać z następujących uprawnień:
                <ul>
                    <li>dostęp do sieci Internet – w większości przypadków wymagany do aktualizacji danych oraz wyświetlania części danych podczas przeglądania, w trybie online. Dodatkowo w tym trybie działają usługi Google Analytics, które umożliwiają anonimowe monitorowanie zachowania użytkownika podczas korzystania z Aplikacji,</li>
                    <li>dostęp do technologii GPS – poprzez zapisywanie lokalizacji użytkownika w regularnych odstępach czasu, tylko w trakcie realizacji zadań z użyciem Aplikacji (od momentu oznaczenia przez użytkownika rozpoczęcia zadania, do momentu oznaczenia zakończenia realizacji danego zadania),</li>
                    <li>komunikacja z Usługami Google (Google Play Services) – wykorzystywana do poprawnego działania geolokalizacji oraz możliwości bezpośredniego komunikowania się z aplikacjami Google takim jak Google Maps,</li>
                    <li>powiadomienia Push – aplikacja może wysyłać powiadomienia użytkownikowi za pomocą technologii push. Użytkownik może zablokować ich otrzymywanie.</li>
                </ul>
            </li>
            <li>W przypadku braku akceptacji zasad niniejszej Polityki Prywatności prosimy nie instalować Aplikacji lub ją odinstalować. Trwałe usunięcie Aplikacji z urządzenia mobilnego jest równoznaczne z zakończeniem korzystania z Aplikacji.</li>
            <li>Polityka Prywatności ma jedynie charakter uzupełniający w stosunku do polityki prywatności Google Play Store, Apple Appstore. Renters nie ponosi jakiejkolwiek odpowiedzialności za politykę prywatności realizowaną przez podmioty zarządzające aplikacjami Google Play store, Apple.</li>
            <li>W przypadku pytań lub wątpliwości dotyczących niniejszej Polityki prosimy o kontakt mailowy za pośrednictwem poczty elektronicznej na adres: kadry@renters.pl</li>
        </ol>
    </section>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    name: 'privacy-policy',
})
export default class PrivacyPolicy extends Vue
{
}
</script>

<style scoped lang="scss">
h2 {
    margin-bottom: 24px;
    color: #f48232;
    font-size: 22px;
    text-align: center;

    @media (min-width: 758px) {
        font-size: 30px;
    }
}

li {
    margin-bottom: 12px;
    font-size: 16px;
    color: #787a7c;

    @media (min-width: 758px) {
        font-size: 18px;
    }
}

.container {
    margin-top: 30px;
    margin-bottom: 40px;
}
</style>
