<script lang="ts" src="../blueprints/Content.ts"></script>

<template>
    <form-component-wrapper class="content-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="content" v-html="content()">
            </div>
        </template>
        <template #properties>
            <field-editor v-model="blueprint.content" :label="$t('[[[Treść]]]')" />
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
</style>
