import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    VisibleBlueprint,
    ReadonlyBlueprint,
    RequiredBlueprint,
    CustomErrorBlueprint,
    HasLabel,
    HasPlaceholder,
    HasAffix,
    HasHelp,
    HasWidth,
    MinMaxValue,
    AffixValue,
    AggregateBlueprint,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice,
    EntryFactory,
    Validatable,
    ValidationErrors,
    ValidEntry
} from '../Types';
import min from 'lodash/min';

export enum TextFieldTypes {
    Text = 'Text',
    Multiline = 'Multiline',
    Password = 'Password'
}

export enum ValidationTypes {
    None = 'None',
    Alphabetic = 'Alphabetic',
    Numeric = 'Numeric',
    Alphanumeric = 'Alphanumeric',
    RegularExpression = 'RegularExpression'
}

// --------------------------------------------------

export interface HasValidation extends Blueprint
{
    validation: ValidationTypes;
    validationRule: string;
    validationMessage: string;
}

export const instanceOfHasValidation = (object: any): object is HasValidation =>
{
    return object && 'validation' in object && 'validationRule' in object && 'validationMessage' in object;
};

export const validator = (blueprint: HasValidation): (value: string) => string =>
{
    const validate = (regexp: RegExp, value: string, message: string): string =>
    {
        return regexp.test(value) ? null : message;
    };
    let rule = (value: string): string => validate(new RegExp("^.*$", "g"), value, null);

    if (instanceOfHasValidation(blueprint))
    {
        switch (blueprint.validation)
        {
            case ValidationTypes.Alphabetic:
                rule = (value: string) => validate(new RegExp("^[a-z]*$", "gi"), value, '[[[Pole może zawierać jedynie litery.]]]');
                break;
            case ValidationTypes.Numeric:
                rule = (value: string) => validate(new RegExp("^[0-9]*$", "g"), value, '[[[Pole może zawierać jedynie cyfry.]]]');
                break;
            case ValidationTypes.Alphanumeric:
                rule = (value: string) => validate(new RegExp("^[a-z0-9]*$", "gi"), value, '[[[Pole może zawierać jedynie litery i cyfry.]]]');
                break;
            case ValidationTypes.RegularExpression:
                rule = (value: string) => validate(new RegExp(blueprint.validationRule, "gi"), value, blueprint.validationMessage || '[[[Podano nieprawidłowe dane.]]]');
                break;
        }
    }

    return rule;
};

// --------------------------------------------------

export class TextEntry extends ValidEntry
{
    public type: string = Components.TEXT;
    public value?: string = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.value = data.value;
        }
    }

    public async collect(blueprint: TextContract, form: FormBuilder, preprocess: (name: string, entry: any) => Promise<void>): Promise<any>
    {
        await preprocess(blueprint.name, this);

        return {
            type: this.type,
            value: this.value || form.executeExpression(blueprint.defaultValue)
        };
    }

    public validate(blueprint: TextContract, form: FormBuilder): boolean
    {
        this.errors = {};

        let message = null;
        const value = this.value || form.executeExpression(blueprint.defaultValue);

        if (!form.readonly(blueprint, true) && form.visible(blueprint, true))
        {
            if (form.required(blueprint) && (value || '').length == 0)
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${blueprint.label}]]]`];
            }
            else if ((value || '').length < blueprint.characters.min)
            {
                this.errors.value = [`[[[Nie podano wymaganej ilości znaków: %0.|||${blueprint.characters.min}]]]`];
            }
            else if (blueprint.characters.max != null && (value || '').length > blueprint.characters.max)
            {
                this.errors.value = [`[[[Przekroczono dozwoloną ilość znaków: %0.|||${blueprint.characters.max}]]]`];
            }
            else if ((message = validator(blueprint)(value)) != null)
            {
                this.errors.value = [message];
            }
            else if (form.customError(blueprint))
            {
                this.errors.custom = [form.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }
}

export const instanceOfTextEntry = (object: any): object is TextEntry =>
{
    return object && 'type' in object && object.type === Components.TEXT;
};

export interface TextContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasPlaceholder, HasAffix, HasHelp, HasWidth, HasValidation
{
    fieldType: TextFieldTypes;
    defaultValue: string;
    characters: MinMaxValue;
    rows: number;
}

export class TextType implements TextContract, Validatable, EntryFactory<TextEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public placeholder: string;
    public fieldType: TextFieldTypes;
    public defaultValue: string;
    public characters: MinMaxValue;
    public rows: number;
    public affix: AffixValue;
    public help: string;
    public width: number;
    public minWidth: number;
    public validation: ValidationTypes;
    public validationRule: string;
    public validationMessage: string;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.TEXT;
        this.name = builder.name(Components.TEXT);
        this.label = '[[[Tekst]]]';
        this.showLabel = true;
        this.placeholder = '';
        this.defaultValue = '';
        this.characters = { min: 0, max: 50 };
        this.rows = 2;
        this.help = '';
        this.affix = { prepend: '', append: '' };
        this.fieldType = TextFieldTypes.Text;
        this.validation = ValidationTypes.None;
        this.validationRule = '';
        this.validationMessage = '';
        this.width = min([3, builder.space(parent)]);
        this.minWidth = 1;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public createEntry(data: any): TextEntry
    {
        return new TextEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
