<script lang="ts" src="../blueprints/Choice.ts"></script>

<template>
    <form-component-wrapper class="choice-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <label :for="blueprint.id" v-if="blueprint.showLabel">{{ $t(blueprint.label) }} <var class="text-danger" v-if="required">*</var></label>
                <select class="form-select" :id="blueprint.id" :value="selectedValue()" @change="change" :readonly="readonly" v-if="isDropdownField">
                    <option :value="null">{{ blueprint.placeholder || '&nbsp;' }}</option>
                    <option v-for="(option, i) in blueprint.options" :key="i" :value="option.value">{{ option.text }}</option>
                </select>
                <div class="row pt-1" :class="{[`row-cols-${blueprint.layout}`]: blueprint.layout > 0}" v-else>
                    <div v-for="(option, i) in blueprint.options" :key="i" :class="{'d-inline ms-3': blueprint.layout == 0, 'col': blueprint.layout > 0}">
                        <ideo-form-radio :value="option.value" :checked="checkedValue(option)" @change="change" v-if="isRadioList">{{ option.text }}</ideo-form-radio>
                        <ideo-form-checkbox :value="option.value" :checked="checkedValue(option)" @change="change" v-if="isCheckboxList">{{ option.text }}</ideo-form-checkbox>
                    </div>
                </div>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <field-text v-model="blueprint.label" :label="$t('[[[Etykieta]]]')" />
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-choice v-model="blueprint.fieldType" :options="fieldTypeOptions" :label="$t('[[[Typ pola]]]')" />
            <field-options v-model="blueprint.options" :multiple="isCheckboxList" :can-select="!blueprint.defaultValue || isCheckboxList" :label="$t('[[[Opcje]]]')" />
            <field-condition v-model="blueprint.defaultValue" :form="form" :label="$t('[[[Wartość domyślna]]]')" type="text" v-if="!isCheckboxList" />
            <field-text v-model="blueprint.placeholder" :label="$t('[[[Tekst zastępczy]]]')" v-if="isDropdownField" />
            <field-radio v-model="blueprint.layout" :options="layoutOptions" :label="$t('[[[Układ]]]')" v-if="!isDropdownField" />
            <field-textarea v-model="blueprint.help" :label="$t('[[[Pomoc]]]')" />
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-readonly :form="form" :blueprint="blueprint" :label="$t('[[[Tylko do odczytu]]]')" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
</style>
