import {
    Blueprint,
    AggregateBlueprint,
    CustomErrorBlueprint,
    instanceOfAggregateBlueprint,
    instanceOfHasWidth,
    instanceOfReadonlyBlueprint,
    instanceOfVisibleBlueprint,
    instanceOfRequiredBlueprint,
    instanceOfCustomErrorBlueprint,
    NeverChoice,
    AlwaysChoice,
    WhenChoice,
    InternallyChoice,
    HasWidth,
    Components,
    instanceOfEntryFactory,
    instanceOfValidatable,
    FormBuilderContract
} from './Types';
import { FormType, FormContract, FormEntry, instanceOfFormType } from './types/Form';
import { PageType, PageContract, instanceOfPageType } from './types/Page';
import { RowType, RowContract, instanceOfRowType } from './types/Row';

import { AddressType } from './types/Address';
import { AttachmentsType } from './types/Attachments';
import { BooleanType } from './types/Boolean';
import { ChoiceType } from './types/Choice';
import { ContentType } from './types/Content';
import { DateType } from './types/Date';
import { DictionaryType } from './types/Dictionary';
import { EmailType } from './types/Email';
import { LikertType } from './types/Likert';
import { LineType } from './types/Line';
import { NumericType } from './types/Numeric';
import { PersonalType } from './types/Personal';
import { PhoneType } from './types/Phone';
import { SectionType } from './types/Section';
import { SignatureType } from './types/Signature';
import { SpacerType } from './types/Spacer';
import { TextType } from './types/Text';
import { UrlType } from './types/Url';

import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import cloneDeepWith from 'lodash/cloneDeepWith';
import uuidv4 from 'uuid/v4';
import { DateTime } from 'luxon';

const MAX_ROW_SPACE = 6;

const types = {
    FormType,
    PageType,
    RowType,

    AddressType,
    AttachmentsType,
    BooleanType,
    ChoiceType,
    ContentType,
    DateType,
    DictionaryType,
    EmailType,
    LikertType,
    LineType,
    NumericType,
    PersonalType,
    PhoneType,
    SectionType,
    SignatureType,
    SpacerType,
    TextType,
    UrlType
};

// --------------------------------------------------

interface Clipboard
{
    form: FormContract;
    page: PageContract;
    row: RowContract;
    placeholder: Blueprint;
    component: Blueprint;
    copy: Blueprint;
    cut: Blueprint;
    top: number;
    offset: number;
    height: number;
    toolbox: [number, number];
}

export class FormBuilder implements FormBuilderContract
{
    private blueprint: FormType = null;
    private entry: FormEntry = null;
    private internal: boolean = false;
    private design: boolean = false;
    private clipboard: Clipboard = {
        form: null,
        page: null,
        row: null,
        placeholder: null,
        component: null,
        copy: null,
        cut: null,
        top: 0,
        offset: 0,
        height: 0,
        toolbox: [0, 0]
    };

    public constructor(blueprint: FormType = null, entry: FormEntry = null, internal: boolean = false, design: boolean = false)
    {
        this.init(blueprint, entry, internal, design);
    }

    public init(blueprint: FormType = null, entry: FormEntry = null, internal: boolean = false, design: boolean = false): FormBuilder
    {
        this.blueprint = blueprint || new FormType(this, null);
        this.entry = entry || new FormEntry();
        this.internal = internal;
        this.design = design;

        return this;
    }

    // --------------------------------------------------

    public initEntry(blueprint: Blueprint, entry: any, typeCheck: (entry: any) => boolean): any
    {
        if (!(blueprint.name in this.entry && typeCheck(this.entry[blueprint.name])))
        {
            this.entry[blueprint.name] = entry;
        }

        return this.entry[blueprint.name];
    }

    public renameEntry(name: string, old: string): void
    {
        if (old && old.length > 0 && old in this.entry)
        {
            this.entry[name] = this.entry[old];

            delete this.entry[old];
        }
    }

    public removeEntry(blueprint: Blueprint): void
    {
        if (blueprint.name in this.entry)
        {
            delete this.entry[blueprint.name];
        }
    }

    public getBlueprint(): FormType
    {
        return this.blueprint;
    }

    public getEntry(): FormEntry
    {
        return this.entry;
    }

    public designMode(): boolean
    {
        return this.design;
    }

    public internalMode(): boolean
    {
        return this.internal || this.design;
    }

    public isValid(): boolean
    {
        const result = this.blueprint.validate();
        const entries = Object.entries(result).filter(([name, errors]) => Object.keys(errors).length > 0);

        if (entries.length > 0)
        {
            const [entry] = entries;
            const [name] = entry;
            const blueprint = this.find(name);

            if (instanceOfFormType(blueprint))
                this.selectForm(blueprint);
            else if (instanceOfPageType(blueprint))
                this.selectPage(blueprint);
            else if (instanceOfRowType(blueprint))
                this.selectRow(blueprint);
            else
                this.selectComponent(blueprint);

            return false;
        }

        return true;
    }

    public errorMessage(blueprint: Blueprint, property: string): string
    {
        if (instanceOfValidatable(blueprint) && property in blueprint.errors && blueprint.errors[property].length > 0)
        {
            return blueprint.errors[property][0];
        }

        return null;
    }

    // --------------------------------------------------

    public names(type: string = null, except: Blueprint = null): string[]
    {
        const names = (components: Blueprint[]): string[] =>
        {
            return components.reduce((value: string[], item: Blueprint) =>
            {
                if (item != except && (type == null || item.type == type))
                {
                    value.push(item.name);
                }

                if (instanceOfAggregateBlueprint(item) && item.components.length > 0)
                {
                    value.push(...names(item.components));
                }

                return value;
            },
            []);
        };

        return names(this.blueprint.components);
    }

    public name(type: string, names: string[] = null): string
    {
        let count = 0;
        let name: string = null;

        names = names || this.names(type);

        do
        {
            name = upperFirst(`${type}${(count + 1).toString()}`);
            count++;
        }
        while (names.includes(name));

        return name;
    }

    public exists(name: string): boolean
    {
        return this.names().includes(name);
    }

    public unique(name: string, except: Blueprint): boolean
    {
        return !this.names(null, except).includes(name);
    }

    public designer(type: string): string
    {
        return `${type}-blueprint`;
    }

    public typeName(type: string): string
    {
        return upperFirst(camelCase(`${type}-type`));
    }

    private getProxy(): FormBuilder
    {
        return new Proxy(this, {
            get(form: FormBuilder, property: string)
            {
                return form.find(property);
            }
        });
    }

    public find(name: string): Blueprint
    {
        const find = (blueprint: Blueprint, name: string): Blueprint =>
        {
            let result: Blueprint = null;

            if (blueprint.name == name)
            {
                result = blueprint;
            }
            else if (instanceOfAggregateBlueprint(blueprint))
            {
                for (let i = 0; i < blueprint.components.length; i++)
                {
                    result = result || find(blueprint.components[i], name);
                }
            }

            return result;
        };

        return find(this.blueprint, name);
    }

    public parent(component: Blueprint, type: string = null): AggregateBlueprint
    {
        const find = (parent: AggregateBlueprint, component: Blueprint): AggregateBlueprint =>
        {
            let result: AggregateBlueprint = null;

            if (parent.components.includes(component))
            {
                if (type === null || type == parent.type)
                {
                    result = parent;
                }
            }
            else
            {
                for (let i = 0; i < parent.components.length; i++)
                {
                    const item = parent.components[i];

                    if (instanceOfAggregateBlueprint(item))
                    {
                        result = find(item, component) || result;
                    }
                }
            }

            return result;
        };

        return find(this.blueprint, component);
    }

    public descendants(component: AggregateBlueprint): Blueprint[]
    {
        const descendants = (components: Blueprint[]): Blueprint[] =>
        {
            const result = [] as Blueprint[];

            components.forEach(c =>
            {
                result.push(c);

                if (instanceOfAggregateBlueprint(c) && c.components.length > 0)
                {
                    result.push(...descendants(c.components));
                }
            });

            return result;
        };

        return descendants(component.components);
    }

    public next(component: Blueprint): Blueprint
    {
        const parent = this.parent(component);
        const index = parent.components.indexOf(component);

        return index < parent.components.length - 1 ? parent.components[index + 1] : null;
    }

    public prev(component: Blueprint): Blueprint
    {
        const parent = this.parent(component);
        const index = parent.components.indexOf(component);

        return index > 0 ? parent.components[index - 1] : null;
    }

    public rowSpace(row: RowContract): number
    {
        const parent = this.parent(row);

        if (parent != null && instanceOfHasWidth(parent))
            return parent.width;

        return MAX_ROW_SPACE;
    }

    public space(parent: RowContract): number
    {
        return this.rowSpace(parent) - parent.components.reduce((sum, component) => sum + (component as any).width, 0);
    }

    public available(parent: RowContract, except: Blueprint = null): number
    {
        return this.rowSpace(parent) - parent.components.filter(p => p != except).reduce((sum, component) => sum + (component as any).minWidth, 0) - (except != null ? (except as any).width : 0);
    }

    public autoarrange(parent: RowContract): void
    {
        for (let i = 0; i < parent.components.length; i++)
        {
            const item = parent.components[i];

            if (instanceOfHasWidth(item))
            {
                item.width = item.minWidth;
            }
        }

        while (this.space(parent) > 0)
        {
            for (let i = 0; i < parent.components.length; i++)
            {
                const item = parent.components[i];

                if (this.space(parent) > 0 && instanceOfHasWidth(item))
                {
                    this.enlarge(item);
                }
            }
        }
    }

    public rearrange(parent: RowContract, except: Blueprint = null): void
    {
        for (let i = parent.components.length; i > 0; i--)
        {
            if (parent.components[i - 1] == except)
            {
                continue;
            }

            if (this.shrink(parent.components[i - 1] as any))
            {
                break;
            }
        }
    }

    public smaller(component: HasWidth): boolean
    {
        return component.width > component.minWidth;
    }

    public larger(component: HasWidth): boolean
    {
        const parent = this.parent(component as any);

        return this.space(parent) > 0 || this.available(parent, component as any) > 0;
    }

    public shrink(component: HasWidth): boolean
    {
        if (component.width > component.minWidth)
        {
            component.width--;

            while (component.exceptWidth && component.exceptWidth.includes(component.width))
            {
                component.width--;
            }

            return true;
        }

        return false;
    }

    public enlarge(component: HasWidth): boolean
    {
        const parent = this.parent(component as any);

        if (this.space(parent) == 0 && this.available(parent) > 0)
        {
            this.rearrange(parent, component as any);
        }

        let space = this.space(parent);

        if (space > 0)
        {
            let width = component.width;

            space--;
            width++;

            while (component.exceptWidth && component.exceptWidth.includes(width))
            {
                space--;
                width++;
            }

            if (space >= 0)
            {
                component.width = width;

                return true;
            }
        }

        return false;
    }

    public newId(): string
    {
        return uuidv4().toString();
    }

    public offsetHeight(height: number): void
    {
        this.clipboard.height = height;
    }

    public getOffsetHeight(): number
    {
        return this.clipboard.height;
    }

    public offsetTop(element: any): void
    {
        let offset = element.$el.getBoundingClientRect().top;

        do
        {
            if (element.$options.name == this.designer(Components.FORM))
            {
                this.clipboard.top = element.$el.getBoundingClientRect().top;
                offset = offset - this.clipboard.top;
            }

            element = element.$parent;
        }
        while (element.$parent);

        this.clipboard.offset = offset;
    }

    public getOffsetTop(): number
    {
        return this.clipboard.offset;
    }

    public getFormTop(): number
    {
        return this.clipboard.top;
    }

    public setCurrentOffset(offset: number, top: number): [number, number]
    {
        return (this.clipboard.toolbox = [offset, top]);
    }

    public getCurrentOffset(): [number, number]
    {
        return this.clipboard.toolbox;
    }

    // --------------------------------------------------

    private globals: any = null;

    public customError(blueprint: CustomErrorBlueprint): boolean
    {
        if (instanceOfCustomErrorBlueprint(blueprint) && blueprint.customError == WhenChoice.When)
        {
            return this.executeExpression(blueprint.customErrorWhen);
        }

        return false;
    }

    public customErrorMessage(blueprint: CustomErrorBlueprint): string
    {
        if (instanceOfCustomErrorBlueprint(blueprint) && blueprint.customError == WhenChoice.When)
        {
            return this.executeExpression("`" + blueprint.customErrorMessage + "`") || '[[[Podano nieprawidłowe dane]]]';
        }

        return null;
    }

    private compileCode(expression: string, formBuilder: FormBuilder, formEntry: FormEntry): any
    {
        if (this.globals == null)
        {
            this.globals = Object.keys(globalThis).reduce((o, key) => ({ ...o, [key]: undefined }), {});
        }

        const variables = {
            globalThis: undefined,
            XMLHttpRequest: undefined,
            GeneratorFunction: undefined,
            AsyncFunction: undefined,
            Function: undefined,
            Object: undefined,
            console: undefined,
            eval: undefined,
            ...this.globals,
            DateTime: DateTime,
            Math: Math,
            Form: formBuilder,
            Entry: formEntry
        };

        // eslint-disable-next-line no-new-func
        const code = new Function(...Object.keys(variables), `return (${expression})`);

        return code(...Object.values(variables));
    }

    public checkExpression(expression: string): boolean
    {
        try
        {
            if (expression)
            {
                this.compileCode(expression, this.getProxy(), this.entry);
            }

            return true;
        }
        catch (ex) { /**/ }

        return false;
    }

    public executeExpression(expression: string): any
    {
        try
        {
            if (expression)
            {
                return this.compileCode(expression, this.getProxy(), this.entry);
            }
        }
        catch (ex) { /**/ }

        return null;
    }

    public visible(blueprint: Blueprint, ancestors: boolean = false): boolean
    {
        let result = true;

        if (blueprint && instanceOfVisibleBlueprint(blueprint))
        {
            if (this.designMode())
            {
                result = blueprint.visible == AlwaysChoice.Always;
            }
            else
            {
                switch (blueprint.visible)
                {
                    case AlwaysChoice.Always:
                        result = true;
                        break;
                    case NeverChoice.Never:
                        result = false;
                        break;
                    case InternallyChoice.Internally:
                        result = this.internalMode();
                        break;
                    case WhenChoice.When:
                        result = this.executeExpression(blueprint.visibleWhen);
                        result = result !== null ? result : true;
                        break;
                }
            }
        }

        if (ancestors == true)
        {
            do
            {
                blueprint = this.parent(blueprint);
                result = result && this.visible(blueprint);
            }
            while (blueprint != null);
        }

        return result;
    }

    public readonly(blueprint: Blueprint, ancestors: boolean = false): boolean
    {
        let result = false;

        if (blueprint && instanceOfReadonlyBlueprint(blueprint))
        {
            if (this.designMode())
            {
                result = blueprint.readonly != NeverChoice.Never;
            }
            else
            {
                switch (blueprint.readonly)
                {
                    case AlwaysChoice.Always:
                        result = true;
                        break;
                    case NeverChoice.Never:
                        result = false;
                        break;
                    case InternallyChoice.Internally:
                        result = this.internalMode();
                        break;
                    case WhenChoice.When:
                        result = this.executeExpression(blueprint.readonlyWhen);
                        result = result !== null ? result : true;
                        break;
                }
            }
        }

        if (ancestors == true)
        {
            do
            {
                blueprint = this.parent(blueprint);
                result = result || this.readonly(blueprint);
            }
            while (blueprint != null);
        }

        return result;
    }

    public required(blueprint: Blueprint): boolean
    {
        let result = false;

        if (instanceOfRequiredBlueprint(blueprint))
        {
            if (this.designMode())
            {
                result = blueprint.required != NeverChoice.Never;
            }
            else
            {
                switch (blueprint.required)
                {
                    case AlwaysChoice.Always:
                        result = true;
                        break;
                    case NeverChoice.Never:
                        result = false;
                        break;
                    case WhenChoice.When:
                        result = this.executeExpression(blueprint.requiredWhen);
                        result = result !== null ? result : true;
                        break;
                }
            }
        }

        return result;
    }

    // --------------------------------------------------

    public getClipboard(): Clipboard
    {
        return this.clipboard;
    }

    public selectForm(form: FormContract): void
    {
        this.clipboard.form = form;
        this.clipboard.page = null;
        this.clipboard.row = null;
        this.clipboard.placeholder = null;
        this.clipboard.component = null;
    }

    public isFormSelected(form: FormContract): boolean
    {
        return this.clipboard.form === form;
    }

    public selectPage(page: PageContract): void
    {
        this.clipboard.form = null;
        this.clipboard.page = page;
        this.clipboard.row = null;
        this.clipboard.placeholder = null;
        this.clipboard.component = null;
    }

    public isPageSelected(page: PageContract): boolean
    {
        return this.clipboard.page === page;
    }

    public selectRow(row: RowContract): RowContract
    {
        this.clipboard.form = null;
        this.clipboard.page = null;
        this.clipboard.row = row;
        this.clipboard.placeholder = null;
        this.clipboard.component = null;

        return row;
    }

    public isRowSelected(row: RowContract): boolean
    {
        return this.clipboard.row === row;
    }

    public selectPlaceholder(row: RowContract, component: Blueprint): void
    {
        if (this.available(row) > 0)
        {
            if (this.space(row) == 0)
            {
                this.rearrange(row);
            }

            this.selectRow(row);
            this.clipboard.placeholder = component;
        }
    }

    public isPlaceholderSelected(row: RowContract, component: Blueprint): boolean
    {
        return this.isRowSelected(row) && this.clipboard.placeholder == component;
    }

    public selectComponent(component: Blueprint): void
    {
        this.clipboard.form = null;
        this.clipboard.page = null;
        this.clipboard.row = null;
        this.clipboard.placeholder = null;
        this.clipboard.component = component;
    }

    public isComponentSelected(component: Blueprint): boolean
    {
        return this.clipboard.component === component;
    }

    public isSelected(blueprint: Blueprint): boolean
    {
        return this.isFormSelected(blueprint as any) || this.isPageSelected(blueprint as any) || this.isRowSelected(blueprint as any) || this.isComponentSelected(blueprint as any);
    }

    // --------------------------------------------------

    public copy(component: Blueprint): void
    {
        this.clipboard.copy = component;
        this.clipboard.cut = null;
    }

    public cut(component: Blueprint): void
    {
        this.clipboard.copy = null;
        this.clipboard.cut = component;
    }

    public paste(parent: AggregateBlueprint, before: Blueprint = null): void
    {
        if (this.clipboard.copy != null && this.canPaste(parent))
        {
            const names: Record<string, string[]> = {};
            const component = cloneDeepWith(this.clipboard.copy, (value, key, item) =>
            {
                if (key == 'id')
                    return this.newId();

                if (key == 'name')
                {
                    names[item.type] = names[item.type] || this.names(item.type);

                    const name = this.name(item.type, names[item.type]);

                    names[item.type].push(name);

                    return name;
                }
            });

            this.insertComponent(parent, component, before);
            this.selectComponent(component);
        }

        if (this.clipboard.cut != null && this.canPaste(parent))
        {
            this.removeComponent(this.clipboard.cut);
            this.insertComponent(parent, this.clipboard.cut, before);
            this.selectComponent(this.clipboard.cut);
        }

        if (this.space(parent) < 0)
        {
            this.autoarrange(parent);
        }

        this.clipboard.copy = null;
        this.clipboard.cut = null;
    }

    public isCut(component: Blueprint): boolean
    {
        return this.clipboard.cut == component;
    }

    public isCopy(component: Blueprint): boolean
    {
        return this.clipboard.copy == component;
    }

    public canPaste(parent: AggregateBlueprint): boolean
    {
        if (this.clipboard.copy != null && (this.clipboard.copy as HasWidth).minWidth <= this.available(parent) && this.parent(parent, this.clipboard.copy.type) === null)
        {
            return true;
        }

        if (this.clipboard.cut != null && this.available(parent, this.clipboard.cut) >= 0 && this.parent(parent, this.clipboard.cut.type) === null)
        {
            return true;
        }

        return false;
    }

    // --------------------------------------------------

    public static create(contract: FormContract = null, entry: FormEntry = null): FormBuilder
    {
        entry = entry || new FormEntry();

        const data = new FormEntry();
        const builder = new FormBuilder();
        const initBlueprint = (contract: Blueprint, parent: AggregateBlueprint = null): Blueprint =>
        {
            try
            {
                let blueprint = new types[builder.typeName(contract.type)](builder, parent) as Blueprint;

                blueprint = Object.assign(blueprint, contract);

                if (instanceOfEntryFactory(blueprint))
                {
                    data[blueprint.name] = blueprint.createEntry(blueprint.name in entry ? entry[blueprint.name] : null);
                }

                if (instanceOfAggregateBlueprint(blueprint))
                {
                    for (let i = 0; i < blueprint.components.length; i++)
                    {
                        blueprint.components[i] = initBlueprint(blueprint.components[i], blueprint);
                    }

                    blueprint.components = blueprint.components.filter(p => p !== null);
                }

                return blueprint;
            }
            catch (ex)
            {
                // eslint-disable-next-line no-console
                console.log(`Blueprint does not exist: ${contract.type}.`);
            }

            return null;
        };

        const form = (contract != null ? initBlueprint(contract) : null) as FormType;

        builder.init(form, data);

        return builder;
    }

    public addPage(parent: FormContract, before: PageContract = null): PageContract
    {
        const page = new PageType(this, parent);

        if (before != null)
            parent.components.splice(parent.components.indexOf(before), 0, page);
        else
            parent.components.push(page);

        return page;
    }

    public addRow(parent: AggregateBlueprint, before: RowContract = null): RowContract
    {
        const row = new RowType(this, parent);

        if (before != null)
            parent.components.splice(parent.components.indexOf(before), 0, this.selectRow(row));
        else
            parent.components.push(row);

        return row;
    }

    public addRowBefore(before: RowContract): void
    {
        this.addRow(this.parent(before), before);
    }

    public addRowAfter(after: RowContract): void
    {
        const next = this.next(after);

        if (next != null)
        {
            if ((next as any as RowContract).components.length > 0)
                this.addRow(this.parent(after), next as any as RowContract);
            else
                this.selectRow(next as any as RowContract);
        }
    }

    public insertComponent(parent: AggregateBlueprint, component: Blueprint, before: Blueprint = null): void
    {
        if (before != null)
            parent.components.splice(parent.components.indexOf(before), 0, component);
        else
            parent.components.push(component);
    }

    public removeComponent(component: Blueprint): void
    {
        const parent = this.parent(component);

        parent.components = parent.components.filter(p => p !== component);
    }

    public createComponent(parent: AggregateBlueprint, type: string): Blueprint
    {
        return new types[this.typeName(type)](this, parent);
    }

    public addComponent(parent: AggregateBlueprint, type: string, before: Blueprint = null): void
    {
        // utworzenie nowego obiektu danego typu po nazwie
        const component: any = this.createComponent(parent, type);

        this.insertComponent(parent, component, before);
        this.selectComponent(component);
    }

    public addBefore(component: Blueprint): void
    {
        const parent = this.parent(component);

        this.selectPlaceholder(parent as any as RowContract, component);
    }

    public addAfter(component: Blueprint): void
    {
        const parent = this.parent(component);
        const next = this.next(component);

        this.selectPlaceholder(parent as any as RowContract, next);
    }

    public moveUp(component: Blueprint): void
    {
        const parent = this.parent(component);
        const index = parent.components.indexOf(component);

        if (index > 0)
        {
            parent.components.splice(index, 1);
            parent.components.splice(index - 1, 0, component);
        }
    }

    public moveDown(component: Blueprint): void
    {
        const parent = this.parent(component);
        const index = parent.components.indexOf(component);
        const length = parent.components.length - (parent.type == Components.FORM ? 0 : 1);

        if (index + 1 < length)
        {
            parent.components.splice(index + 2, 0, component);
            parent.components.splice(index, 1);
        }
    }
}
