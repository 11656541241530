import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import dot from 'dot-object';
import cloneDeep from 'lodash/cloneDeep';
import core from '@/modules/core/common/store';
import saas from '@/modules/saas/common/store';
import renter from '@/modules/renter/common/store';

export default createStore({
    strict: false,
    modules: {
        ...core,
        ...saas,
        ...renter
    },
    plugins: [
        createPersistedState({
            reducer: (persistedState: any) =>
            {
                const stateFilter = {};
                const states = ['common.theme']; // states which we want to persist.

                states.forEach(path => dot.copy(path, path, persistedState, stateFilter));

                return cloneDeep(stateFilter);
            }
        })
    ]
});

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $store: any;
    }
}
