import properties from '@/modules/renter/properties/store';
import companies from '@/modules/renter/companies/store';
import tasks from '@/modules/renter/tasks/store';
import checklists from '@/modules/renter/checklists/store';
import reservations from '@/modules/renter/reservations/store';
import regions from '@/modules/renter/regions/store';
import cities from '@/modules/renter/cities/store';
import supplies from '@/modules/renter/supplies/store';
import dashboard from '@/modules/renter/dashboard/store';
import owners from '@/modules/renter/owners/store';
import notes from '@/modules/renter/notes/store';
import taskManagement from '@/modules/renter/tasks_management/store';
import stocktakes from '@/modules/renter/stocktakes/store';
import driversRoutes from '@/modules/renter/drivers_routes/store';
import calendar from '@/modules/renter/calendar/store';
import costReports from '@/modules/renter/cost_reports/store';
import salaries from '@/modules/renter/salaries/store';
import systemChat from '@/modules/renter/chat/store';
import costStatistics from '@/modules/renter/cost_statistics/store';
import districts from '@/modules/renter/districts/store';
import customProperties from '@/modules/renter/custom_properties/store';
import tasksTags from '@/modules/renter/tasksTags/store';
import warehouse from '@/modules/renter/warehouse/store';

export default {
    renter: {
        namespaced: true,
        modules: {
            properties: properties,
            companies: companies,
            tasks: tasks,
            checklists: checklists,
            reservations: reservations,
            supplies: supplies,
            regions: regions,
            cities: cities,
            dashboard: dashboard,
            owners: owners,
            notes: notes,
            stocktakes: stocktakes,
            taskManagement: taskManagement,
            driversRoutes: driversRoutes,
            calendar: calendar,
            costReports: costReports,
            salaries: salaries,
            chat: systemChat,
            costStatistics: costStatistics,
            districts: districts,
            customProperties: customProperties,
            tasksTags: tasksTags,
            warehouse: warehouse
        }
    }
};
