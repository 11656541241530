/* eslint-disable no-console */
import { defineAsyncComponent, Plugin } from 'vue';

const DynamicFormsPlugin: Plugin =
{
    install(app, options)
    {
        app.component('DesignPanel', defineAsyncComponent(() => import('@/components/forms/helpers/DesignPanel.vue')));
        app.component('DesignPlaceholder', defineAsyncComponent(() => import('@/components/forms/helpers/DesignPlaceholder.vue')));
        app.component('DesignRuller', defineAsyncComponent(() => import('@/components/forms/helpers/DesignRuller.vue')));
        app.component('DesignStatus', defineAsyncComponent(() => import('@/components/forms/helpers/DesignStatus.vue')));
        app.component('DesignToolbar', defineAsyncComponent(() => import('@/components/forms/helpers/DesignToolbar.vue')));

        app.component('FormComponent', defineAsyncComponent(() => import('@/components/forms/helpers/FormComponent.vue')));
        app.component('FormComponentWrapper', defineAsyncComponent(() => import('@/components/forms/helpers/FormComponentWrapper.vue')));
        app.component('FormErrorMessage', defineAsyncComponent(() => import('@/components/forms/helpers/FormErrorMessage.vue')));
        app.component('FormHelp', defineAsyncComponent(() => import('@/components/forms/helpers/FormHelp.vue')));

        app.component('FormToolbox', defineAsyncComponent(() => import('@/components/forms/helpers/DesignPanelTarget.vue')));

        // new vite specific API for webpacks's require.context
        const requireFormDesignerComponent = import.meta.glob('@/components/forms/presentation/*.vue', { eager: true });

        Object.values(requireFormDesignerComponent).forEach((component: any) =>
        {
            app.component(component.default.name, component.default);
        });
    }
};

export default DynamicFormsPlugin;
