<script lang="ts" src="../blueprints/Line.ts"></script>

<template>
    <form-component-wrapper class="line-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <hr>
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
hr {
    margin: 0 0 10px 0;
}
</style>
