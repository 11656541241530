<template>
    <div class="vdatetime-calendar">
        <div class="vdatetime-calendar__navigation">
            <div class="vdatetime-calendar__navigation--previous" @click="previousMonth">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.3 102.8">
                    <path fill="none" stroke="#444" stroke-width="14" stroke-miterlimit="10" d="M56.3 97.8L9.9 51.4 56.3 5" />
                </svg>
            </div>
            <div class="vdatetime-calendar__current--month">{{ monthName }} {{ newYear }}</div>
            <div class="vdatetime-calendar__navigation--next" @click="nextMonth">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.3 102.8">
                    <path fill="none" stroke="#444" stroke-width="14" stroke-miterlimit="10" d="M56.3 97.8L9.9 51.4 56.3 5" />
                </svg>
            </div>
        </div>
        <div class="vdatetime-calendar__month" v-if="isTasksOrReservationsDaysSet">
            <div class="vdatetime-calendar__month__weekday" v-for="weekday in weekdays" :key="weekday">{{ weekday }}</div>
            <div class="vdatetime-calendar__month__day" v-for="day in days" :key="day" @click="selectDay(day)" :class="{'vdatetime-calendar__month__day--selected': day.selected, 'vdatetime-calendar__month__day--disabled': day.disabled}">
                <span :class="{ 'is-reservation': isReservationOnThisDay(day.number), 'is-blocked': isBlockadeImposed(day.number), 'is-arrival-day': isFirstOrLastDayOfReservation(day.number, dayType.First), 'is-departure-day': isFirstOrLastDayOfReservation(day.number, dayType.Last), 'is-blockade-first-day': isFirstOrLastDayOfBlockade(day.number, dayType.First), 'is-blockade-last-day': isFirstOrLastDayOfBlockade(day.number, dayType.Last) }">
                    <span>{{ day.number }}</span>
                    <span v-if="isTaskOnThisDay(day.number)" class="task-dot"></span>
                </span>
            </div>
        </div>
        <div class="vdatetime-calendar__month" v-else>
            <div class="vdatetime-calendar__month__weekday" v-for="weekday in weekdays" :key="weekday">{{ weekday }}</div>
            <div class="vdatetime-calendar__month__day" v-for="day in days" :key="day" @click="selectDay(day)" :class="{'vdatetime-calendar__month__day--selected': day.selected, 'vdatetime-calendar__month__day--disabled': day.disabled}">
                <span><span>{{ day.number }}</span></span>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DateTime } from 'luxon';
import { monthDayIsDisabled, monthDays, months, weekdays } from './util';
import { StatusEnum } from '@/modules/renter/reservations/services/ReservationsService';
import { DayTypeEnum } from '@/modules/renter/tasks/services/TasksService';

export default {
    props: {
        year: {
            type: Number,
            required: true
        },
        month: {
            type: Number,
            required: true
        },
        day: {
            type: Number,
            default: null
        },
        disabled: {
            type: Array,
            default: () => []
        },
        minDate: {
            type: DateTime,
            default: null
        },
        maxDate: {
            type: DateTime,
            default: null
        },
        weekStart: {
            type: Number,
            default: 1
        },
        daysWithTask: {
            type: Array,
            default: () => []
        },
        daysWithReservations: {
            type: Array,
            default: () => []
        }
    },

    emits: ['change'],

    data()
    {
        return {
            newDate: DateTime.fromObject({ year: this.year, month: this.month, zone: 'UTC' }),
            weekdays: weekdays(this.weekStart),
            months: months()
        };
    },

    computed: {
        newYear()
        {
            return this.newDate.year;
        },
        newMonth()
        {
            const dataForEmit = {
                month: this.newDate.month,
                year: this.newDate.year
            };

            this.$events.$emit('onCalendarMonthChange', dataForEmit);

            return this.newDate.month;
        },
        monthName()
        {
            return this.months[this.newMonth - 1];
        },
        days()
        {
            return monthDays(this.newYear, this.newMonth, this.weekStart).map(day => ({
                number: day,
                selected: day && this.year === this.newYear && this.month === this.newMonth && this.day === day,
                disabled: !day || monthDayIsDisabled(this.minDate, this.maxDate, this.newYear, this.newMonth, day)
            }));
        },
        isTasksOrReservationsDaysSet()
        {
            return !!this.daysWithTask?.length || !!this.daysWithReservations?.length;
        },
        dayType()
        {
            return DayTypeEnum;
        }
    },

    methods: {
        selectDay(day)
        {
            if (day.disabled)
            {
                return;
            }

            this.$emit('change', this.newYear, this.newMonth, day.number);
        },
        previousMonth()
        {
            this.newDate = this.newDate.minus({ months: 1 });
        },
        nextMonth()
        {
            this.newDate = this.newDate.plus({ months: 1 });
        },
        isTaskOnThisDay(day)
        {
            return this.daysWithTask.map(el => el.dateUtc.day).includes(day);
        },
        isReservationOnThisDay(day)
        {
            let isIncludedInReservedDays = false;
            const reservationDays = this.getReservationDays();

            const currentDate = new Date(this.newDate.year, this.newDate.month - 1, day);

            reservationDays.forEach(({ arrivalDate, departureDate }) =>
            {
                if (currentDate >= arrivalDate && currentDate <= departureDate)
                {
                    isIncludedInReservedDays = true;
                }
            });

            return isIncludedInReservedDays;
        },
        isBlockadeImposed(day)
        {
            let isDayBlocked = false;
            const reservationDays = this.getReservationDays();

            const currentDate = new Date(this.newDate.year, this.newDate.month - 1, day);

            reservationDays.forEach(({ arrivalDate, departureDate, bookingType }) =>
            {
                if (currentDate >= arrivalDate && currentDate <= departureDate && bookingType === StatusEnum.UNAVAILABLE)
                {
                    isDayBlocked = true;
                }
            });

            return isDayBlocked;
        },
        isFirstOrLastDayOfReservation(day, type)
        {
            let isFirstOrLast = false;
            const reservationDays = this.getReservationDaysToCheckFirstAndLastDay(type);

            const currentDate = new Date(this.newDate.year, this.newDate.month - 1, day);

            reservationDays.forEach(({ date }) =>
            {
                if (currentDate.getTime() === date.getTime())
                {
                    isFirstOrLast = true;
                }
            });

            return isFirstOrLast;
        },
        isFirstOrLastDayOfBlockade(day, type)
        {
            let isFirstOrLast = false;
            const reservationDays = this.getReservationDaysToCheckFirstAndLastDay(type);

            const currentDate = new Date(this.newDate.year, this.newDate.month - 1, day);

            reservationDays.forEach(({ date, bookingType }) =>
            {
                if (currentDate.getTime() === date.getTime() && bookingType === StatusEnum.UNAVAILABLE)
                {
                    isFirstOrLast = true;
                }
            });

            return isFirstOrLast;
        },
        getReservationDays()
        {
            const reservationDays = this.daysWithReservations.map(reservation =>
            {
                const arrivalDate = new Date(reservation.arrivalDateUtc.year, reservation.arrivalDateUtc.month - 1, reservation.arrivalDateUtc.day);
                const departureDate = new Date(reservation.departureDateUtc.year, reservation.departureDateUtc.month - 1, reservation.departureDateUtc.day);

                return { arrivalDate, departureDate, bookingType: reservation.bookingType };
            });

            return reservationDays;
        },
        getReservationDaysToCheckFirstAndLastDay(type)
        {
            const reservationDays = this.daysWithReservations.map(({arrivalDateUtc, departureDateUtc, bookingType}) =>
            {
                const dateUtc = type === DayTypeEnum.First ? arrivalDateUtc : departureDateUtc;
                const date = new Date(dateUtc.year, dateUtc.month - 1, dateUtc.day);

                return { date, bookingType };
            });

            return reservationDays;
        }
    }
};
</script>

<style lang="scss">
.vdatetime-calendar__navigation,
.vdatetime-calendar__navigation * {
    box-sizing: border-box;
}

.vdatetime-calendar__navigation {
    position: relative;
    margin: 15px 0;
    padding: 0 30px;
    width: 100%;
}

.vdatetime-calendar__navigation--previous,
.vdatetime-calendar__navigation--next {
    position: absolute;
    top: 0;
    padding: 0 5px;
    width: 18px;
    cursor: pointer;

    & svg {
        width: 8px;
        height: 13px;

        & path {
            transition: stroke .3s;
        }
    }

    &:hover svg path {
        stroke: #888;
    }
}

.vdatetime-calendar__navigation--previous {
    left: 25px;
}

.vdatetime-calendar__navigation--next {
    right: 25px;
    transform: scaleX(-1);
}

.vdatetime-calendar__current--month {
    text-align: center;
    text-transform: capitalize;
}

.vdatetime-calendar__month {
    padding: 0 20px;
    transition: height .2s;
}

.vdatetime-calendar__month__weekday,
.vdatetime-calendar__month__day {
    display: inline-block;
    width: calc(100% / 7);
    line-height: 36px;
    text-align: center;
    font-size: 15px;
    font-weight: 300;
    cursor: pointer;

    & > span {
        display: block;
        width: 100%;
        position: relative;
        height: 0;
        padding: 0 0 100%;
        overflow: hidden;

        & > span {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 0;
            border-radius: 50%;
            transition: background-color .3s, color .3s;
        }
    }
}

.vdatetime-calendar__month__weekday {
    font-weight: bold;
}

.vdatetime-calendar__month__day:hover > span > span {
    background: #ddd;
}

.c-dark-theme .vdatetime-calendar__month__day:hover > span > span {
    background: #444757;
}

.vdatetime-calendar__month__day--selected {
    & > span > span,
    &:hover > span > span {
        color: #fff;
        background: #3f51b5;
    }
}

.vdatetime-calendar__month__day--disabled {
    opacity: 0.4;
    cursor: default;

    &:hover > span > span {
        color: inherit;
        background: transparent;
    }
}

.vdatetime-calendar__month__day span .task-dot {
    position: absolute;
    top: unset;
    left: 50%;
    bottom: 5%;
    transform: translateX(-50%);
    width: 4px;
    height: 4px;
    background: #3f51b5;
    border-radius: 50%;
    z-index: 9;
}

.vdatetime-calendar__month__day:hover > span > .task-dot,
.c-dark-theme .vdatetime-calendar__month__day:hover > span > .task-dot {
    background: #3f51b5;
}

.vdatetime-calendar__month__day .is-reservation {
    overflow: visible;
}

.is-reservation::after,
.is-reservation.is-departure-day::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: $reservedProperty;
    transform: skew(-20deg)
}
.is-reservation.is-arrival-day::after,
.is-reservation.is-departure-day::after,
.is-reservation.is-departure-day::before {
    width: 42%;
}

.is-reservation.is-arrival-day::after {
    right: 0;
}

.is-reservation.is-departure-day::before {
    left: 0;
}

.is-reservation.is-blocked::after,
.is-reservation.is-blocked::before,
.is-reservation.is-blocked.is-blockade-first-day::after,
.is-reservation.is-blocked.is-blockade-last-day::before {
    background: $blockedProperty;
}

.is-reservation.is-departure-day::before,
.is-reservation.is-arrival-day::after {
    background: $reservedProperty ;
}

.c-dark-theme .is-reservation::after,
.c-dark-theme .is-reservation::before,
.c-dark-theme .is-reservation.is-departure-day::before,
.c-dark-theme .is-reservation.is-arrival-day::after {
    background: #3b3d4b ;
}

.c-dark-theme .is-blocked::after,
.c-dark-theme .is-blocked::before,
.c-dark-theme .is-reservation.is-blocked.is-blockade-first-day::after,
.c-dark-theme .is-reservation.is-blocked.is-blockade-last-day::before {
    background: #6b6e7a;
}

.is-reservation span {
    z-index: 2;
}
</style>
