import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    VisibleBlueprint,
    ReadonlyBlueprint,
    RequiredBlueprint,
    CustomErrorBlueprint,
    HasLabel,
    HasPlaceholder,
    HasHelp,
    HasWidth,
    ChoiceOption,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice,
    AggregateBlueprint,
    EntryFactory,
    ValidationErrors,
    Validatable,
    ValidEntry
} from '../Types';
import min from 'lodash/min';

export enum ChoiceFieldTypes {
    Dropdown = 'Dropdown',
    Radio = 'Radio',
    Checkbox = 'Checkbox'
}

export class ChoiceEntry extends ValidEntry
{
    public type: string = Components.CHOICE;
    public values?: string[] = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.values = data.values;
        }
    }

    public get value(): string
    {
        return this.values && this.values.length > 0 ? this.values[0] : null;
    }

    public async collect(blueprint: ChoiceContract, form: FormBuilder, preprocess: (name: string, entry: any) => Promise<void>): Promise<any>
    {
        await preprocess(blueprint.name, this);

        return {
            type: this.type,
            values: this.values || [form.executeExpression(blueprint.defaultValue)].filter(p => !!p)
        };
    }

    public validate(blueprint: ChoiceContract, form: FormBuilder): boolean
    {
        this.errors = {};

        const values = this.values || [form.executeExpression(blueprint.defaultValue)].filter(p => !!p);

        if (!form.readonly(blueprint, true) && form.visible(blueprint, true))
        {
            if (form.required(blueprint) && (values == null || values.length == 0))
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${blueprint.label}]]]`];
            }
            else if (form.customError(blueprint))
            {
                this.errors.custom = [form.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }
}

export const instanceOfChoiceEntry = (object: any): object is ChoiceEntry =>
{
    return object && 'type' in object && object.type === Components.CHOICE;
};

export interface ChoiceContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasPlaceholder, HasHelp, HasWidth
{
    fieldType: ChoiceFieldTypes;
    options: ChoiceOption[];
    defaultValue: string;
    layout: number;
}

export class ChoiceType implements ChoiceContract, Validatable, EntryFactory<ChoiceEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public placeholder: string;
    public help: string;
    public width: number;
    public minWidth: number;
    public fieldType: ChoiceFieldTypes;
    public options: ChoiceOption[];
    public defaultValue: string;
    public layout: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.CHOICE;
        this.name = builder.name(Components.CHOICE);
        this.label = '[[[Wybór]]]';
        this.showLabel = true;
        this.placeholder = '';
        this.options = [
            { value: '1', text: 'Primus...', selected: false },
            { value: '2', text: 'Secundus...', selected: false },
            { value: '3', text: 'Tetrium...', selected: false }
        ];
        this.layout = 0;
        this.defaultValue = '';
        this.help = '';
        this.fieldType = ChoiceFieldTypes.Dropdown;
        this.width = min([3, builder.space(parent)]);
        this.minWidth = 1;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public createEntry(data: any): ChoiceEntry
    {
        return new ChoiceEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
