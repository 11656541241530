<template>
    <button ref="$el" class="dropdown-item" v-bind="{...$props, ...$attrs}">
        <slot name="default"></slot>
        <confirmation :message="message" :target="$refs.$el" :value="item" @confirm="onConfirm" v-if="loaded && message" />
    </button>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Inject, Prop } from '@/helpers/Decorators';

@Options({
    name: 'list-view-button',
    inheritAttrs: false
})
export default class ListViewButton extends Vue
{
    public loaded: boolean = false;

    @Inject('index')
    public index: number;

    @Inject('item')
    public item: any;

    @Prop()
    public message: string;

    @Prop()
    public confirm: any;

    public get id(): string
    {
        return `${this.$.uid}`;
    }

    public mounted(): void
    {
        this.loaded = true;
    }

    @Emit('confirm')
    public onConfirm(item: any): any
    {
        return item;
    }
}
</script>
