<template>
    <div class="d-flex justify-content-between align-items-center">
        <div class="breadcrumb-container">
            <router-link class="link" :to="{name: 'renter-dashboard'}" :aria-label="'dasbhoard'">
                <i class="fas fa-home"></i>
            </router-link>
            <div class="breadcrumb-wrapper">
                <router-link :to="url(item)" v-for="(item, index) in allCrumbs" :key="index" :aria-label="$t(item.name)">{{ $t(item.name) }}</router-link>
            </div>
            <router-link class="link active" v-if="lastCrumb" :to="url(lastCrumb)" :aria-label="$t(lastCrumb.name)">{{ $t(lastCrumb.name) }}</router-link>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Watch } from '@/helpers/Decorators';
import { RouteLocationNormalizedLoaded as Route } from 'vue-router';

interface CrumbDetails {
    name: string;
    visible: boolean;
    allowed: boolean;
    route?: string;
    url?: string;
    hidebreadcrumb?: boolean;
}

@Options({
    name: 'partials-navigation-breadcrumbs'
})
export default class Menu extends Vue
{
    public crumbs: CrumbDetails[] = [];
    public extraCrumb: string = '';

    public get allCrumbs(): CrumbDetails[]
    {
        if (this.extraCrumb)
        {
            return this.crumbs;
        }

        return this.crumbs.slice(0, -1);
    }

    public get lastCrumb(): CrumbDetails | null
    {
        if (this.extraCrumb)
        {
            return {
                name: this.extraCrumb,
                visible: true,
                allowed: true
            };
        }

        return this.crumbs[this.crumbs.length - 1] || null;
    }

    public url(node: any): void
    {
        return this.$sitemap.url(node, this.$route);
    }

    @Watch('$sitemap.extraCrumb')
    public handleExtraCrumbChange(value: string): void
    {
        this.extraCrumb = value;
    }

    @Watch('$route', { immediate: true })
    public async onRouteChanged(route: Route): Promise<void>
    {
        this.$sitemap.extraCrumb = '';
        this.crumbs = await this.$sitemap.crumbs(route);
    }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
    &-container {
        display: flex;
        align-items: center;
        max-width: calc(100vw - 30px);
        line-height: 1.4;

        >.link {
            white-space: nowrap;

            &:last-child {
                &:before {
                    display: inline-block;
                    color: $gray-600;
                    content: "/";
                    padding: 0 4px;
                    transform: translateY(-1px);
                }
            }
        }

        .link {
            color: var(--primary);

            &.active {
                color: $black;
            }
        }
    }

    &-wrapper {
        direction: rtl;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-grow: 1;
        max-width: max-content;

        a {
            direction: rtl;
            color: var(--primary);

            &:after {
                padding-left: 4px;
                padding-right: 4px;
                display: inline-block;
                color: $gray-600;
                content: "/";
                transform: translateY(-1px);
            }

            &:first-child, &:last-child {
                padding-left: 0;
            }
        }
    }
}
</style>
