export default {
  "pl-PL": {
    "Szkolenie rozpoczyna się": "",
    "Miasta": "",
    "Zacznij pisać aby wyszukać inspektora...": "",
    "Przełącznik": "",
    "Token": "",
    "Tekst": "",
    "Pole \"Drugie imię\" jest wymagane.": "",
    "Dodaj nieruchomość do trasy": "",
    "Dodaj kafelek": "",
    "Pokaż więcej": "",
    "NIE": "",
    "Avantio ID": "",
    "Nie udało się dodać nieruchomość do trasy": "",
    "Firma domyślna": "",
    "Data utworzenia aranżacji": "",
    "Ilość wyników do pobrania": "",
    "Usuń blok z sekcji": "",
    "Peticion disponibilidad": "",
    "Eksportuj do PDF": "",
    "Nie do odzyskania": "",
    "BIC/Swift": "",
    "Link został dodany.": "",
    "Ilość gości": "",
    "Nieruchomość została usunięta.": "",
    "Standard 1.2": "",
    "Niewidoczne": "",
    "Brak dostępnych tras": "",
    "Informacje Podstawowe": "",
    "Miejsce logowania": "",
    "Dodaj zastępstwo": "",
    "Liczba szkoleń": "",
    "Paczka została usunięta.": "",
    "Edycja witryny": "",
    "Potwierdzenie publikacji": "",
    "Informacje podstawowe": "",
    "Powiększ": "",
    "Litery": "",
    "Przypisana do (Imię i Nazwisko)": "",
    "Adres url do pliku wideo": "",
    "Akcje": "",
    "Klienci": "",
    "Proszę obciążyć gościa": "",
    "Status dokumentu": "",
    "Do akceptacji": "",
    "Liczba wyświetlanych najnowszych elementów": "",
    "Koordynator Główny": "",
    "Strona nadrzędna": "",
    "Godziny": "",
    "Miasto zostało dodane.": "",
    "Element opublikowany": "",
    "Nazwa witryny": "",
    "Osoba zastępowana": "",
    "Przesuń do góry": "",
    "Certyfikat został usunięty.": "",
    "Co godzinę": "",
    "Usuń z zespołu": "",
    "Wybierz autora": "",
    "Wydział zadania": "",
    "Nie udało się pobrać listy katalogów": "",
    "Ilość nocy": "",
    "Zaznacz wszystkie": "",
    "Rozliczenia pracowników": "",
    "Ścieżka szkoleniowa została dodana.": "",
    "Akceptuj": "",
    "Określa z której strony do ścieżki mają być dodawane kolejne pozycje.": "",
    "Klient został usunięty.": "",
    "Lekcja SCORM została dodana.": "",
    "Wyzeruj licznik": "",
    "Wybierz kierowce": "",
    "Zapomniałeś hasła?": "",
    "Dane dostępu": "",
    "Włącz monitorowanie aktywności": "",
    "Wstaw dwie kolumny 2/3 oraz 1/3": "",
    "Dodawanie checklisty": "",
    "Średnie wynagrodzenie pracownika": "",
    "Data wykonania do": "",
    "Nie znaleziono żadnych plików": "",
    "Przyjazd": "",
    "Nie udało się pobrać danych o rolach zalogowanego użytkownika": "",
    "Zacznij pisać numer aby wyszukać źródło rezerwacji...": "",
    "Konfiguracja bloku została zapisana.": "",
    "Zdarzenie": "",
    "Baza danych": "",
    "Wstaw szablon informacyjny ze zdjęciem": "",
    "Zacznij pisać aby wyszukać region...": "",
    "Niestandardowy adres strony": "",
    "Liczba wyświetlanych elementów": "",
    "Wyrażenie regularne": "",
    "Pilne": "",
    "Właściciele": "",
    "Przeciągnij i upuść aby posortować pliki": "",
    "Dział został zaktualizowany.": "",
    "Slajdy": "",
    "Edycja checklisty": "",
    "Gotowe": "",
    "Zacznij pisać numer aby wyszukać rezerwację...": "",
    "Ten użytkownik nie posiada jeszcze przypisanych dzielnic.": "",
    "Typ materiału": "",
    "Nazwa Firmy": "",
    "Adres e-mail potwierdzony": "",
    "Dodaj nieruchomość": "",
    "Dzielnica nieruchomości": "",
    "Urlop został zaktualizowany.": "",
    "Aranżacja utowrzona przez": "",
    "Wprowadzona wartość nie jest poprawna": "",
    "Opracowane": "",
    "Zastępstwo zostało dodane.": "",
    "Data nieobecności od": "",
    "Osoba zastępująca": "",
    "Niski": "",
    "Imię klienta": "",
    "Typ Firmy": "",
    "Liczba": "",
    "Program został zaktualizowany.": "",
    "Czas trwania blokady": "",
    "Dodaj obrazek": "",
    "Nie przypisano": "",
    "Nazwa szablonu": "",
    "Sprawdzić": "",
    "Przywróć użytkownika": "",
    "Pole wymagane": "",
    "Rezerwacje": "",
    "Region został dodany.": "",
    "Oznacz wszystkie jako przeczytane": "",
    "Usuń publikację": "",
    "Azure AD": "",
    "Ścieżka nawigacyjna": "",
    "Formularz został dodany.": "",
    "Pokaż szczegóły": "",
    "Strona została usunięta.": "",
    "Adres URL": "",
    "Możliwość modyfikacji tego działu jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "",
    "Wiadomość została ukryta.": "",
    "Edytuj temat": "",
    "Dodanie nieruchomości": "",
    "Publikuj od": "",
    "Brak zadań": "",
    "Co drugi miesiąc": "",
    "Wpis nieprzeczytany": "",
    "Opis źródła": "",
    "Tag został zaktualizowany.": "",
    "Edycja wynagrodzenia za posprzątane obiekty dla:": "",
    "Wtorek": "",
    "Właściciel aktywny": "",
    "Pokaż tytuł formularza": "",
    "Nie możesz edytować tego punktu. Został on już oznaczony jako odwiedzony": "",
    "Został przywrócony oryginalny harmonogram": "",
    "Słowa kluczowe": "",
    "Zastępuje osobę": "",
    "Wybierz użytkownika": "",
    "Na pewno usunąć wybrane elementy?": "",
    "${ex.data.errors.symbol[0]}": "",
    "Zdecydowanie tak": "",
    "Formularze": "",
    "Element został przywrócony.": "",
    "Dodano użytkownika do czatu": "",
    "W zespole od %0": "",
    "Stopka": "",
    "Co 3 godziny": "",
    "Dodanie kategorii": "",
    "Komunikat": "",
    "Wpis domyślny": "",
    "Dane wydziału zostały zaktualizowane.": "",
    "Dodaj stwierdzenie": "",
    "Przejdź do zadania z notatki": "",
    "Usuń odpowiedź": "",
    "Dodaj zestaw": "",
    "Typ zadania": "",
    "Folder został usunięty.": "",
    "Zastępstwo wygaśnie za minutę.": "",
    "Numer sesji": "",
    "Zespół został przypisany do szkolenia.": "",
    "reset": "",
    "Edycja regionu": "",
    "Pracownik niedostępny od": "",
    "Obciążenie Właściciela": "",
    "Przypisane miasta": "",
    "W organizacji nie ma jeszcze żadnych działów.": "",
    "Tylko priorytetowe": "",
    "Zobacz trasę": "",
    "Kategoria została usunięta.": "",
    "Wpis został zaktualizowany.": "",
    "Nazwa roli": "",
    "Stawka domyślna": "",
    "Więcej...": "",
    "Typ slajdu": "",
    "Dodaj paczkę SCORM": "",
    "Radio": "",
    "Brak przypisanych tagów.": "",
    "Ilość": "",
    "Checkbox": "",
    "Ten region nie posiada jeszcze przypisanych miast.": "",
    "Potwierdź publikację wersji": "",
    "Pralnia": "",
    "Nie udało się pobrać listy bloków": "",
    "Nazwa przycisku": "",
    "Zaczyna sie od": "",
    "Data modyfikacji": "",
    "Usuń wiersz": "",
    "Prawda-Fałsz": "",
    "Opis sekcji": "",
    "Domyślne ustawienie dla witryny": "",
    "Nie pokazuj krótkiego opisu strony w wynikach wyszukiwania (NoSnippet)": "",
    "Zawsze": "",
    "Imie i nazwisko": "",
    "Zadanie zostało ukończone.": "",
    "Utworzone": "",
    "Edycja tagu nieruchomości": "",
    "Pole może zawierać jedynie cyfry.": "",
    "Opiekun ścieżki": "",
    "Nazwa aranżacji": "",
    "Numer telefonu: ": "",
    "Wysłano kolejne powiadomienie": "",
    "Nie udało się pobrać listy stron": "",
    "Zmień hasło": "",
    "Modyfikacja": "",
    "Wysyłaj tym kanałem": "",
    "Region został zaktualizowany.": "",
    "Usuń filtr": "",
    "Data wylogowania": "",
    "Strona lekcji SCORM opublikowana": "",
    "Wykonaj": "",
    "Ścieżka": "",
    "Typ obiektu": "",
    "Dział aktywny": "",
    "Edytuj dział": "",
    "Bardzo niezadowolony": "",
    "Zacznij pisać aby wyszukać...": "",
    "Co 20 minut": "",
    "Dodane przeze mnie": "",
    "Zmień ustawienia": "",
    "Under request": "",
    "Treść została dodana.": "",
    "Certyfikat nie jest opublikowany": "",
    "Stawka mieszana została zaktualizowana.": "",
    "Nie udało się pobrać listy zastępstw": "",
    "Dodaj zdjęcia do wiadomości": "",
    "Wybrane pliki: {0}": "",
    "Przetestuj ustawienia poczty": "",
    "Strona %0": "",
    "Identyfikator zadania": "",
    "Książka": "",
    "Firma sprzątająca": "",
    "Komentarz": "",
    "Szablon": "",
    "Odznacz wszystkie": "",
    "Dodanie poradnika": "",
    "Potwierdzenie zmiany statusu": "",
    "Logo firmy": "",
    "zacznij pisać aby wyszukać slajder...": "",
    "Skopiuj szkolenie": "",
    "Osoba przypisana do zadania": "",
    "Wybranie strony jest wymagane": "",
    "Musisz wybrać obiekt na mapie": "",
    "Zobacz szczegóły sesji użytkownika": "",
    "Liczba slajdów widoczna dla użytkownika nie może być większa od liczby slajdów dodanych w panelu.": "",
    "Adres": "",
    "Zacznij pisać aby wyszukać kierowcę...": "",
    "Październik": "",
    "Wybierz artykuł": "",
    "Do sprawdzenia - przeniesione": "",
    "Lista regulaminów": "",
    "Kategorie szkoleń": "",
    "Dodaj slajd": "",
    "Uprawnienia": "",
    "Wprowadzona wartość jest nieprawidłowa": "",
    "Edycja lekcji": "",
    "Hasło musi zawierać co najmniej jeden znak specjalny np.: ~ @ # $ % ^ & *": "",
    "Inwentaryzacje": "",
    "Wysłane": "",
    "Dodaj do trasy": "",
    "Zresetuj hasło": "",
    "Od ilości posprzątanych obiektów": "",
    "Nowa data": "",
    "pl": "",
    "Tagi zadań": "",
    "Średnio": "",
    "Edytuj": "",
    "Edycja aranżacji": "",
    "Brak powiązań": "",
    "Zastępstwo": "",
    "Ponów zadanie": "",
    "Dodanie zespołu": "",
    "*Koordynator zespołu posiada dostęp do panelu koordynatora": "",
    "Dodaj źródło": "",
    "Moje trasy": "",
    "Rozliczone": "",
    "od": "",
    "Sesja nr %0": "",
    "Numer aktywnej strony": "",
    "pokaż więcej...": "",
    "Koszty do zadania": "",
    "Odpowiedzi": "",
    "Wysłano kolejne wezwanie do zapłaty": "",
    "Pokaż": "",
    "Czas na slajd": "",
    "Nie udało się pobrać listy serwerów baz danych": "",
    "Zdjęcie główne": "",
    "Historia logowania": "",
    "Nie mam zdania": "",
    "Płyta z muzyką": "",
    "Najnowsze": "",
    "Log z aktywności": "",
    "Lekcja SCORM ukryta": "",
    "Aby dodawać zespół do szkolenia musisz najpierw zapisać formularz.": "",
    "Pokaż przycisk ``Wyślij``": "",
    "Dane nieruchomości": "",
    "Dodanie certyfikatu": "",
    "Filtruj": "",
    "Strona opublikowana": "",
    "Konfiguruj stronę": "",
    "Nie wybrano strony": "",
    "Koordynator*": "",
    "Szef regionu": "",
    "Ilość kosztów": "",
    "Nazwa": "",
    "Ostatnia wiadomość - data": "",
    "Kwota zniszczeń": "",
    "Opis certyfikatu": "",
    "Adres domenowy": "",
    "Nazwa szkolenia": "",
    "Do ścieżki szkoleniowej nie przypisano jeszcze żadnych zespołów.": "",
    "5": "",
    "Zakończone": "",
    "Dodaj ścieżkę": "",
    "Dodaj lekcję Wideo": "",
    "Urlop": "",
    "Projekt": "",
    "Zmiana rozmiaru obrazu": "",
    "Wykonane": "",
    "Jeśli pole jest puste to wstawiana automatycznie.": "",
    "Dokument nie ma ustawionej daty publikacji.": "",
    "Regulaminy": "",
    "Nie pokazuj linku do archiwum strony (NoArchive)": "",
    "Dodanie zestawu regulaminów": "",
    "Ukryj materiał": "",
    "Pre-booking": "",
    "%0 znaków": "",
    "Statystyki pracownicze": "",
    "Zawiera": "",
    "Suma wynagrodzeń": "",
    "Do kogo chcesz napisać?": "",
    "Zmiana hasła": "",
    "Nie podano nazwy poprzedniej kategorii.": "",
    "Porównania": "",
    "Sekcje dla gości": "",
    "Usuń obrazek": "",
    "Rok": "",
    "Status formularza został zmieniony.": "",
    "Nie udało się pobrać listy powiadomień": "",
    "Wpis przeczytany": "",
    "Zasięg": "",
    "Wybierz punkt": "",
    "Nie udało się pobrać danych odnośnie rezerwacji i zadań": "",
    "Dodanie artykułu": "",
    "Wynagrodzenia Konserwatorzy": "",
    "Ostatnia strona": "",
    "Liczba dziesiętna": "",
    "Edycja stawki godzinowej dla:": "",
    "Imię lub nazwisko właściciela": "",
    "Zespół został dodany.": "",
    "Tak": "",
    "Zakończone przez": "",
    "Szablony": "",
    "Wybierz plik": "",
    "Wpisz nazwę dzielnicy...": "",
    "Dodaj lekcję": "",
    "URL": "",
    "Właściciel został zaktualizowany.": "",
    "Witryna internetowa": "",
    "Konserwacje": "",
    "Za przepracowane godziny": "",
    "Element został usunięty.": "",
    "Udało się zaktualizować zadania!": "",
    "Podstawowe operacje warunkowe": "",
    "Organizacja": "",
    "Dashboard": "",
    "Utworzył": "",
    "Nr telefonu": "",
    "TAK": "",
    "Wymagane jest wybranie sekcji": "",
    "Nie masz uprawnień do panelu administracyjnego.": "",
    "Dodaj formularz": "",
    "Wszystkie obiekty:": "",
    "FavIcona": "",
    "Żaden kanał komunikacji nie został włączony w ustawieniach powiadomień.": "",
    "Zacznij pisać aby wyszukać nieruchomość...": "",
    "Administracja - Kraje": "",
    "Skala 1-5": "",
    "Zastępstwo wygaśnie za 10 minut.": "",
    "Edycja Notatki": "",
    "Data zakońćzenia": "",
    "zacznij pisać aby wyszukać paczkę SCORM...": "",
    "Numer inwentaryzacji": "",
    "Zobacz zdjęcia": "",
    "Lekcja wideo została zaktualizowana.": "",
    "Lista certyfikatów": "",
    "Edycja trasy": "",
    "Nie zdefiniowano żadnych ról.": "",
    "Słowa oddzielone przecinkami.": "",
    "Wybierz sekcję": "",
    "Dodaj szkolenie": "",
    "To miasto jest już przypisane do użytkownika.": "",
    "Usuń zestaw": "",
    "Wynagrodzenie stałe miesięczne": "",
    "Dane wydziału zostały dodane.": "",
    "Tekst do uzupełnienia": "",
    "Zakończ trasę": "",
    "Otwórz w nowym oknie": "",
    "Tytuł kafelka": "",
    "Aplikacja nie ma uprawnień do wysyłania powiadomień PUSH": "",
    "Typ": "",
    "Kwiecień": "",
    "Archiwum": "",
    "Wstecz": "",
    "Porównanie": "",
    "kanał wymagany": "",
    "Pierwsza strona": "",
    "Lista punktów": "",
    "Rozmiar": "",
    "Kategoria slajdu": "",
    "Usuń spacje": "",
    "Nie udało się pobrać listy działów": "",
    "Nazwa kolumny z opisem": "",
    "Data zmiany statusu do": "",
    "Dodanie użytkownika": "",
    "To szkolenie znajduje się już w ścieżce szkoleń": "",
    "Nazwa lekcji": "",
    "Nazwa nieruchmości": "",
    "Usuń materiał z testu": "",
    "Liczba zgłoszonych problemów": "",
    "Nie udało się zapisać notatki": "",
    "Strona szkolenia ukryta": "",
    "Szczegóły zdarzenia": "",
    "Drugie imię": "",
    "Dwie kolumny": "",
    "Rezerwacja": "",
    "Zadanie zostało wyłączone": "",
    "Zacznij pisać aby wyszukać szablon checklisty...": "",
    "Wyczyść sesję": "",
    "Dodanie firmy": "",
    "Podstawowe operacje na datach": "",
    "Neutralne": "",
    "12 godzin": "",
    "Nazwa bazy danych": "",
    "Wytnij": "",
    "Ulica": "",
    "Element został zaktualizowany.": "",
    "(AirBnb) Wysłano żądanie do portalu": "",
    "Obraz został wycięty.": "",
    "Apple iPad": "",
    "Co pół roku": "",
    "Dodanie zadania": "",
    "3": "",
    "Wewnętrznie": "",
    "Data zmiany statusu od": "",
    "Dodaj zasób": "",
    "Źle": "",
    "Statystyki użytkowników": "",
    "Przypisane miasta do regionu:": "",
    "Nazwa domyślnej checklisty": "",
    "Witryna została dodana.": "",
    "Rejestr zmian": "",
    "Zobacz szczegóły zdarzenia": "",
    "Usuń tag": "",
    "Wprowadzona wartość jest za krótka": "",
    "Parametry, wymagane do wykonania zapytania:": "",
    "Publikacja została dodana.": "",
    "Pionowo": "",
    "Treść": "",
    "Dostępne wersje językowe.": "",
    "Wersja językowa": "",
    "Komunikat błędu": "",
    "Potwierdź wyczyszczenie logów": "",
    "Slajd został dodany.": "",
    "Laptop 15,6\"": "",
    "Brak tagów": "",
    "Klient aktywny": "",
    "Trzy kolumny": "",
    "Certyfikat jest opublikowany": "",
    "Wprowadzona wartość jest za wysoka": "",
    "Nazwa slajdu": "",
    "Dodanie szkolenia": "",
    "Włączenie protokołu SSL dla tej witryny": "",
    "Nazwa testu": "",
    "Dostęp do szkoleń:": "",
    "Wyjazd": "",
    "Pasek postępu": "",
    "Oraz nowe hasło": "",
    "Nie udało się pobrać listy filtrów": "",
    "Kwota wynagrodzenia PLN brutto": "",
    "Data opuszczenia zespołu": "",
    "Konfiguracja": "",
    "Przesyłanie zdjęcia nie powiodło się": "",
    "Nie wybrano żadnej kategorii.": "",
    "Kolejność punktów trasy została zaktualizowana": "",
    "Obrazek wyróżniający": "",
    "Kod Bing Webmaster": "",
    "Włączenie obsługi cacheu dla witryny": "",
    "Standard nieznany": "",
    "Właściciel został usunięty": "",
    "Nieważne": "",
    "Zakończone: ${$filters.datetime(route.dateCompletedUtc)}": "",
    "Nazwa katalogu lub pliku": "",
    "Email": "",
    "Logi aktywności zostały wyczyszczone": "",
    "Lista koordynatorów": "",
    "Edytuj lekcje wideo": "",
    "usuń notatkę": "",
    "Średnie wynagrodzenie koordynatora": "",
    "Miesiąc edycji wynagrodzenia": "",
    "Wybierz regulamin": "",
    "Czas realizacji": "",
    "Tło certyfikatu": "",
    "Dodaj element": "",
    "Wybrana dzielnica": "",
    "Edycja strony": "",
    "Wyświetl popup z kontynuacją lekcji": "",
    "Bardzo dobrze": "",
    "Adres 2": "",
    "Zablokowany": "",
    "Tło strona 2": "",
    "Podstawowe": "",
    "Typ strony": "",
    "Certyfikaty": "",
    " Elementy do uzupełnienia w tekście zamieszczamy pomiędzy zdefiniowanymi znacznikami %$dowolna_nazwa$%.": "",
    "Wiadomość": "",
    "Przycisk": "",
    "Checklisty": "",
    "Dodanie etykiety": "",
    "Stawka za apartamenty została zaktualizowana.": "",
    "Nie masz wymaganych uprawnień do wykonania wybranej operacji.": "",
    "Co 2 godziny": "",
    "Filtry map": "",
    "Brak strony nadrzędnej": "",
    "Zmień daty publikacji": "",
    "Notatka dla": "",
    "Zastępstwa": "",
    "ID": "",
    "przejdź do komentarzy": "",
    "Ustawienia ogólne": "",
    "Teraz": "",
    "Ilość znaków": "",
    "Suma Wynagrodzeń": "",
    "Powtórz hasło": "",
    "Legacy mode": "",
    "Materiał opisowy": "",
    "W przyszłości": "",
    "Dodaj pytanie": "",
    "Szablon graficzny": "",
    "Nazwa regionu": "",
    "Opis kategorii": "",
    "Dodaj inwentaryzacje": "",
    "Pytanie 3": "",
    "Ogólne informacje": "",
    "Brak stron": "",
    "Włączone (Follow)": "",
    "Dodanie ankiety": "",
    "Cofam": "",
    "Nazwa tagu": "",
    "co 30 dni": "",
    "Zasoby": "",
    "Hasło (Klucz ukryty)": "",
    "Witryna tylko dla zalogowanych użytkowników": "",
    "Treść certyfikatu": "",
    "Procent obciążeń": "",
    "Ilość wierszy": "",
    "Rola aktywna": "",
    "Notatka została zaktualizowana.": "",
    "Porównywana wersja": "",
    "Kod pocztowy": "",
    "Edycja regulaminu": "",
    "Artykuł": "",
    "Domyślna firma": "",
    "Przyrostek": "",
    "Publikuj do": "",
    "Strona główna": "",
    "Rozmiar zdjęcia został zmieniony.": "",
    "Pasek": "",
    "Wpisz nazwę miasta...": "",
    "To zadanie nie posiada jeszcze przypisanych użytkowników.": "",
    "Kolejka": "",
    "W każdy dzień tygodnia": "",
    "Usuń": "",
    "wybierz...": "",
    "Nieobecność": "",
    "Edycja testu": "",
    "Szablon nr 2": "",
    "Nowe hasło": "",
    "Nazwa trasy": "",
    "Moje konto": "",
    "Ładuję...": "",
    "Dodaj rolę": "",
    "Nazwa strony": "",
    "Wybór": "",
    "Dodanie ścieżki szkoleniowej": "",
    "Test ukryty": "",
    "Typ zestawu": "",
    "Sekcje i bloki": "",
    "Zdecydowanie się nie zgadzam": "",
    "Typ źródła": "",
    "Obiekty": "",
    "Srednie wynagrodzenie pracowników": "",
    "Katalog linków": "",
    "Nazwa użytkownika": "",
    "Logger": "",
    "Dostępne bloki": "",
    "Administracja - Stanowiska pracy": "",
    "Niedziela": "",
    "Wczutuję...": "",
    "Tag został dodany.": "",
    "Edycja certyfikatu": "",
    "Zastosuj": "",
    "Brak aktywnego filtru": "",
    "Sekcje dla zalogowanych": "",
    "Usuń kafelek": "",
    "Wielokrotny wybór": "",
    "Notatka utworzona z zadania": "",
    "Typ dodanego kosztu": "",
    "Nieaktywna": "",
    "Dodaj lekcję SCORM": "",
    "Metadane": "",
    "Przenieś wybrane": "",
    "Włączone": "",
    "Lista efektywnych uprawnień użytkownika wynikających z uprawnień indywidualnych oraz ról.": "",
    "Wyposażenie zostało zaktualizowane.": "",
    "Uwzględniaj Dzielnice": "",
    "Odpowiedź 3": "",
    "UWAGA! Nieruchomość jest już przypisana od innego właściciela": "",
    "Wznów zadanie": "",
    "Dostępne czaty:": "",
    "Anuluj edycję": "",
    "${PriorityName(PriorityEnum[this.task.priority])}": "",
    "Pokaż przycisk": "",
    "Zablokuj użytkownika": "",
    "Dane formularza są przechowywane w obiekcie Entry.": "",
    "Blokowanie użytkowników szkoleń": "",
    "Typ wynagrodzenia": "",
    "Pokaż numery stron": "",
    "Tekst zastępczy": "",
    "Data realizacji": "",
    "Do ścieżki szkoleniowej nie przypisano jeszcze żadnych użytkowników.": "",
    "Aby dodawać zespół do ścieżki szkoleniowej musisz najpierw zapisać formularz.": "",
    "Grupa": "",
    "Lista linków": "",
    "Nazwa pliku została zmieniona.": "",
    "Historia zmian": "",
    "Koszt został dodany.": "",
    "Hasło": "",
    "Automatyczny": "",
    "Nieruchomość jest już przypisana": "",
    "Nie możesz wpływać na kolejność punktów już oznaczonych jako odwiedzone": "",
    "Zastępstwo wygasło.": "",
    "Edycja firmy": "",
    "Elementy inwentaryzacji": "",
    "Nie możesz zapisać zmian, gdyż liczba elementów nie może być mniejsza niż ustawiona liczba wymaganych elementów w zakładce ustawienia testu": "",
    "Nie podano wymaganej ilości znaków: %0.": "",
    "Role": "",
    "Ogólne": "",
    "Nie udało się pobrać listy wydziałów": "",
    "Data utworzenia od": "",
    "Łączenie treści": "",
    "Wysłane wezwanie do zapłaty": "",
    "Wybór koloru jest wymagany": "",
    "Stwierdzenia": "",
    "Blok został usunięty.": "",
    "Nazwa Kategorii": "",
    "1 godzina": "",
    "Pole może zawierać jedynie litery i cyfry.": "",
    "Wszystkie próby": "",
    "Slajder został zaktualizowany.": "",
    "Owner Booking": "",
    "Pozostałe ustawienia": "",
    "3 godziny": "",
    "Widoczny obszar": "",
    "Zacznij pisać aby wyszukać dzielnicę...": "",
    "Dowolny": "",
    "Miejscowość": "",
    "Wybrane miasto": "",
    "zacznij pisać aby wyszukać autora...": "",
    "Aranżacja została zachowana.": "",
    "W zespole od": "",
    "Co rok": "",
    "Wybierz inspektora": "",
    "Nieaktywni": "",
    "Co 2 minuty": "",
    "Angielski": "",
    "Obsługa Klienta (BOK)": "",
    "Dodanie zastępstwa": "",
    "Lista paczek SCORM": "",
    "Imię i nazwisko właściciela": "",
    "Ten punkt został już odwiedzony": "",
    "Przesuń na dół": "",
    "Wybrane kategorie": "",
    "Zgłoszenie serwisowe": "",
    "Urlop został usunięty": "",
    "Wskazuje robotom wyszukiwarek czy mają podążać za linkami.": "",
    "Użytkownicy w tej roli": "",
    "Nie pokazuj strony jako źródła grafiki w wynikach wyszukiwania (NoImageIndex)": "",
    "Dodanie slajdera": "",
    "Zaakceptowane": "",
    "Nazwa taga": "",
    "wybierz szablon": "",
    "Wiadomość testowa została wysłana.": "",
    "Nazwa klienta": "",
    "linie": "",
    "Wynagrodzenia od ilości posprzątanych obiektów": "",
    "Kategorie linków": "",
    "Data dodania kosztu": "",
    "Link przycisku": "",
    "Do szkolenia nie przypisano jeszcze żadnych użytkowników.": "",
    "Edytuj dane wydziału": "",
    "Wartości": "",
    "Załączone zdjęcia": "",
    "Wybierz typ powiadomienia aby zobaczyć dostępne klucze.": "",
    "Osiągnąłeś limit zdjęć. Nie możesz dodać więcej zdjęć": "",
    "Dodanie klienta": "",
    "Nazwa kosztu": "",
    "Trasy archiwalne": "",
    "Adres strony": "",
    "Ścieżka szkoleniowa": "",
    "Wideo z biblioteki": "",
    "Ważność": "",
    "Wyświetl również zarchiwizowane formularze": "",
    "Migracje": "",
    "Koszty zadania zostały zaktualizowane.": "",
    "Data dodania inwentaryzacji": "",
    "Wielokrotnego wyboru": "",
    "Zacznij pisać aby wyszukać właściciela...": "",
    "Czaty": "",
    "Zadanie zostało wznowione.": "",
    "Wymagane jest ustawienie minimalnej długości hasła": "",
    "Tydzień": "",
    "Confirmed": "",
    "Miasto": "",
    "Zdecydowanie nieważne": "",
    "Nie wybrano żadnej strony.": "",
    "zacznij pisać aby wyszukać artykuł...": "",
    "Szkolenia": "",
    "Lista dostępnych zmiennych, które można wykorzystać we wszystkich polach tekstowych:": "",
    "Zgłoszenia w apartamencie": "",
    "W trakcie": "",
    "Przejdź do mapy": "",
    "Moje filtry": "",
    "Synchronizuj": "",
    "Bardzo źle": "",
    "Zadanie zostało usunięte": "",
    "Czy zablokować użytkownika i jego wiadomość?": "",
    "Dopasuj do obrazka": "",
    "Przenieś": "",
    "Rozpocznij zadanie": "",
    "Nie można usunąć kategorii do ktorej są przypisane elementy.": "",
    "Zmień status edytowanej wersji": "",
    "Zameldowanie": "",
    "Utwórz raport wyników": "",
    "Język": "",
    "Nazwa wydziału": "",
    "Dodanie witryny": "",
    "Ostatnia wiadomość - użytkownik": "",
    "Dodaj opis...": "",
    "Usuń kategorię": "",
    "Data wykonania": "",
    "Nadpisz kwotę dla": "",
    "Wszystkie": "",
    "Ładuje...": "",
    "Nazwa koordynatora": "",
    "Wybrane regulaminy: ": "",
    "Ikona": "",
    "Wpisz nazwę regionu...": "",
    "Nazwa pokoju": "",
    "Przywróć": "",
    "Anuluj": "",
    "Opublikowane na wybranej stronie": "",
    "Kontrolka wyboru (wybór wielokrotny)": "",
    "Szczegóły": "",
    "Edycja slajdera": "",
    "Powiadomienia": "",
    "Wstaw z prawej strony": "",
    "Strona ukryta": "",
    "Typ pola": "",
    "Stack Trace": "",
    "Filtr prywatny": "",
    "Podpis": "",
    "Wybrane elementy zostały przeniesione.": "",
    "Edytuj datę dostępu": "",
    "Informacja o ciasteczkach": "",
    "Repozytorium plików": "",
    "Niemiecki": "",
    "Administracja - Miasta": "",
    "Wyświetl materiały": "",
    "Not Available": "",
    "Dodaj kategorię": "",
    "Rola": "",
    "Nie możesz rozpocząć obsługi tego zadania": "",
    "Punkty": "",
    "Resetuj": "",
    "brak danych": "",
    "Dziś": "",
    "Id": "",
    "Zadowolony": "",
    "Lista rezerwacji": "",
    "Odpowiedź": "",
    "Kolejne pozycje z prawej strony": "",
    "Katalog został utworzony.": "",
    "edycja": "",
    "Statystyki regionalne - regiony": "",
    "Dodaj opłaconą kwotę": "",
    "Nazwa źródła danych": "",
    "Edytuj wpis": "",
    "Imie i nazwisko osoby, która dodawała koszty": "",
    "Telefon": "",
    "Marzec": "",
    "Serwer dostępny": "",
    "Miniaturki": "",
    "Dashboard szkolenia": "",
    "Stwórz zadanie z tej rezerwacji": "",
    "Przeniesienie wybranych elementów": "",
    "Zmiana motywu": "",
    "Imie i nazwisko: ": "",
    "Data zakończenia": "",
    "Edycja formularza": "",
    "Opublikuj materiał": "",
    "Klient został dodany.": "",
    "Szkolenie zostało usunięte.": "",
    "Wideo zewnętrzne (URL)": "",
    "Treści pytań są wymagane": "",
    "Brak odpowiedzi": "",
    "Obojętny": "",
    "W momencie tworzenia kosztu": "",
    "Polski": "",
    "Satysfakcja": "",
    "Godzina zameldowania": "",
    "Informacje dodatkowe": "",
    "Niezadowolony": "",
    "Włącz przynajmniej jedną wersję językową dla witryny aby rozpocząć zarządzanie stronami.": "",
    "Edycja slajdów": "",
    "Wynagrodzenie": "",
    "Liczba pracowników pracujących w więcej niż jednym regionie": "",
    "Opis witryny": "",
    "Witryna domyślna": "",
    "Potwierdź zapis do aktualnej wersji": "",
    "Zadanie zostało uruchomione.": "",
    "Właściciel został dodany.": "",
    "Rola została zaktualizowana.": "",
    "Zacznij pisać aby wyszukać osobę...": "",
    "Przejdź do Checklisty": "",
    "Nie udało się pobrać listy kolejek": "",
    "Nie udało się pobrać listy modułów": "",
    "Poprzednia strona": "",
    "Typ mime": "",
    "Szkolenie zostało zaktualizowane.": "",
    "Ten Miesiąc": "",
    "Ścieżka szkoleniowa została zaktualizowana.": "",
    "Przypisz do czatu": "",
    "Przepracowana liczba godzin w miesiącu": "",
    "Nazwa serwera": "",
    "Pole może zawierać jedynie litery.": "",
    "Data wykonania od": "",
    "Edycja dzielnicy": "",
    "Dodaj serwer": "",
    "Dodaj dni": "",
    "Zmień kolejność punktów": "",
    "Zacznij pisać aby wyszukać dzielnice...": "",
    "Edycja ustawień": "",
    "Opis zespołu": "",
    "Pełny ekran": "",
    "Nazwa paczki SCORM": "",
    "Domyślny kolor trasy (dotyczy kierowców)": "",
    "Dodanie serwera": "",
    "Wpis został usunięty.": "",
    "Wyczyść": "",
    "Pokaż listę dostępnych tras": "",
    "Nazwa działu": "",
    "Dodanie dzielnicy": "",
    "Trasa została zapisana.": "",
    "Zmień dane kosztu": "",
    "Etykieta": "",
    "Dni": "",
    "Numer zadania": "",
    "Dodanie aktualności": "",
    "Firma została dodana.": "",
    "Ustawienia witryny": "",
    "Ankieta": "",
    "-": "",
    "Dostęp do": "",
    "Mnożenie": "",
    "Statyczny": "",
    "Dark mode": "",
    "Edycja wynagrodzenia stałego dla:": "",
    "Użytkownik został dodany do zespołu.": "",
    "Notatki": "",
    "Wybierz...": "",
    "Odpowiedź 1": "",
    "Masz nową wiadomość!": "",
    "źródło zostało usunięte.": "",
    "Wyszukaj albo dodaj nowy tag": "",
    "Wpisz kwotę": "",
    "Pole \"Tytuł\" jest wymagane.": "",
    "Dostępne moduły": "",
    "Raporty": "",
    "Data rozpoczęcia": "",
    "Podano nieprawidłowe dane": "",
    "Wyświetlaj nazwę bloczka": "",
    "Zadanie do wykonania": "",
    "Nazwa certyfikatu": "",
    "Edycja użytkownika": "",
    "Aktywni": "",
    "Opłacono": "",
    "Data zakończenia blokady konta": "",
    "Status został zmieniony.": "",
    "Stawka PLN brutto za godzinę": "",
    "Zaawansowane": "",
    "Poziomo": "",
    "Ustawienia zaawansowane": "",
    "Obsługa Właściciela (OW)": "",
    "Typ rezerwacji": "",
    "Podanie nazwy jest wymagane.": "",
    "Ukraiński": "",
    "Czat": "",
    "Rozwiń/Zwiń": "",
    "Dodaj treść": "",
    "Ustawienia": "",
    "Akcja": "",
    "Czas wykonania": "",
    "zacznij pisać aby wyszukać zespół...": "",
    "Stwórz nowy czat": "",
    "Treść opublikowana": "",
    "Id rezerwacji:": "",
    "Kontrolki podstawowe": "",
    "Dodane Koszty": "",
    "Paczki SCORM": "",
    "Zamknięte przez": "",
    "Opcje": "",
    "Id sesji": "",
    "Co 15 minut": "",
    "Nie wybrano obrazu.": "",
    "Zacznij pisać aby wyszukać regionu...": "",
    "Osoba odpowiedzialna": "",
    "SUMA": "",
    "Przypisz obiekty": "",
    "Nazwa nieruchomości": "",
    "Włączone (Index)": "",
    "Typ nieobecności": "",
    "Usuń pytanie": "",
    "Usuń wybrane": "",
    "Kanały włączone w ustawieniach aplikacji": "",
    "Powiązane artykuły": "",
    "Zdecydowanie się zgadzam": "",
    "Data następnej inwentaryzacji": "",
    "Ustawienia podstawowe": "",
    "Powiadomienie dla użytkownika o nowych wiadomościach na chacie": "",
    "znaki": "",
    "Dzielenie": "",
    "Fiirma została zaktualizowana.": "",
    "Imię i Nazwisko": "",
    "Pracownicy z brakującymi ustawieniami wynagrodzenia": "",
    "Dodaj katalog": "",
    "Bazy danych": "",
    "Aby dodawać użytkowników do ścieżki szkoleniowej musisz najpierw zapisać formularz.": "",
    "Równość": "",
    "Wybierz tagi": "",
    "Dzielnica została zaktualizowana.": "",
    "Publikuj szkolenie": "",
    "Czas (minuty)": "",
    "Region został usunięty": "",
    "REGON firmy": "",
    "Wymagany": "",
    "Edycja etykiety": "",
    "Zapisuję...": "",
    "Wczytaj zapisany filtr": "",
    "Proszę obciążyć właściciela obiektu": "",
    "Konfiguracja domyślna": "",
    "Wyświetlam": "",
    "Edycja serwera": "",
    "Przypisane do:": "",
    "Opublikowane wersje językowe.": "",
    "Rejestracja konta": "",
    "Kategoria linku": "",
    "Brak typu zadania": "",
    "${DepartmentName(DepartmentEnum[this.task.department])}": "",
    "Facebook Admins": "",
    "Szkic": "",
    "Dodaj wpis": "",
    "Ustawienia powiadomień zostały zaktualizowane.": "",
    "Sesja użytkownika": "",
    "Rezerwacja właścicielska": "",
    "Zawartość": "",
    "Nazwa źródła": "",
    "Na wniosek": "",
    "Separator elementów w tytule": "",
    "Cron": "",
    "Listopad": "",
    "Zadania": "",
    "Nieruchomości robocze": "",
    "Dodaj Koszt do zadania": "",
    "co 3 miesiące": "",
    "Historia zadania": "",
    "Wybrany znak zastąpi znacznik separatora | wstawiony w tytule.": "",
    "Pole \"%0\" nie zawiera prawidłowego adresu email.": "",
    "Nie udało się pobrać listy typów modeli": "",
    "Bardzo zadowolony": "",
    "Źródło danych zostało zaktualizowane.": "",
    "Typ Nieruchomości": "",
    "Kobieta": "",
    "Edytowana wersja": "",
    "Użytkownicy": "",
    "Adres serwera": "",
    "Edytuj szablon": "",
    "wybierz długość": "",
    "Do kogo wysłać testowe powiadomienie": "",
    "Resetowanie podejść": "",
    "Administratorzy": "",
    "Wykonaj akcję": "",
    "Wartość musi być liczbą ujemną": "",
    "Materiały szkoleniowe": "",
    "Film": "",
    "Data zakończenia do": "",
    "Nazwa checklisty": "",
    "Zestaw niestandardowy": "",
    "Obrazek": "",
    "Konta prywatne": "",
    "Kategorie": "",
    "Serwer został dodany.": "",
    "Numer nieruchomości (StreetNumber)": "",
    "Test został dodany.": "",
    "Wybierz stronę": "",
    "Data wygaśnięcia": "",
    "Ustawienia strony": "",
    "Właściciel": "",
    "Zmodyfikował": "",
    "Oczekujące": "",
    "Sortowanie rosnąco": "",
    "Dodaj Zadanie": "",
    "Aby dodawać członków zespołu musisz najpierw zapisać formularz.": "",
    "Zmień nazwę katalogu": "",
    "Typ wynagrodzenia został usunięty": "",
    "Dział został dodany.": "",
    "Imie i nazwisko właściciela": "",
    "Nazwisko klienta": "",
    "Porównaj %0": "",
    "Typ słownika": "",
    "Firma": "",
    "Wrzesień": "",
    "Kategorie materiałów": "",
    "Kwota kosztów": "",
    "Mężczyzna": "",
    "Dodanie źródła danych": "",
    "Data dezaktywacji": "",
    "Facebook Application ID": "",
    "Lekcja została zaktualizowana.": "",
    "Usunięte zadania": "",
    "Procent": "",
    "Usuń plik z listy": "",
    "Do sprawdzenia": "",
    "Chat został zarchiwizowany.": "",
    "Tag nieruchomości": "",
    "Potwierdzenie usunięcia": "",
    "Połącz w pary": "",
    "Przepracowana ilość godzin": "",
    "Są to wszystkie obiekty, nawet te, które posiadają już właściciela.": "",
    "więcej...": "",
    "Biblioteka": "",
    "Dalej": "",
    "Dodaj temat/link": "",
    "Wybierz zaznaczone": "",
    "Wprowadzona wartość jest za długa": "",
    "Inwentaryzacja została dodana.": "",
    "Wybrane pliki": "",
    "Liczba szkoleń w ścieżce": "",
    "Dodaj odpowiedź": "",
    "UWAGA! Ta tabela pokazuje tylko przeterminowane zadania, filtr \"STATUS\" nie jest aktywny. Wróć do \"Wszystkie zadania\" aby mieć dostępne wszystkie filtry": "",
    "Dodanie szablonu": "",
    "Uzupełnij luki": "",
    "Nie udało się pobrać listy ról": "",
    "Wpisz komentarz...": "",
    "Aby dodawać strony należy najpierw stworzyć nowy katalog.": "",
    "Krótki opis": "",
    "Edycja paczek SCORM": "",
    "Nazwa przycisku ``Wstecz``": "",
    "Link do rezerwacji": "",
    "Ilość toalet": "",
    "Urlopy": "",
    "Typ konta": "",
    "Pliki do wysłania: %0": "",
    "Utwórz czat": "",
    "Sprawdzenie czy pusty": "",
    "Potwierdź akceptację": "",
    "Dodawanie": "",
    "Minimum": "",
    "Kosz": "",
    "Witryna aktywna": "",
    "Data przyjazdu": "",
    "Data akceptacji": "",
    "Wstaw z góry": "",
    "Uzupełnij brakujące pola.": "",
    "Ostatnio oglądane": "",
    "Raczej się nie zgadzam": "",
    "Nazwa zespołu": "",
    "Biuro": "",
    "Cena PLN": "",
    "Stawka wynagrodzenia została zaktualizowana.": "",
    "gotowe": "",
    "Kopiuj": "",
    "Wyświetl szczegóły": "",
    "Nazwa zadania": "",
    "Zakończ zadanie": "",
    "Wpisz tytuł...": "",
    "Włącz chat": "",
    "Sekcja": "",
    "Dodaj trasę": "",
    "ID lub typ zadania": "",
    "Dodaj nowy tag": "",
    "Załącz zdjęcia": "",
    "Regiony": "",
    "Numer nieruchomości Avantio": "",
    "Grudzień": "",
    "Dodanie nieruchomości roboczej": "",
    "Pliki prywatne": "",
    "Miesiąc": "",
    "obrót": "",
    "Weekend": "",
    "Sesja": "",
    "Godzina trasy": "",
    "Godzina wykonania": "",
    "Zacznij pisać aby wyszukać użytkownika...": "",
    "Wyloguj zalogowanego obecnie użytkownika": "",
    "Co 30 minut": "",
    "Etykiety": "",
    "Włączenie generowania pliku sitemap.xml dla całej witryny": "",
    "Dodaj zdjęcia": "",
    "Wynagrodzenie stałe brutto": "",
    "Dane": "",
    "Usunięto użytkownika z czatu": "",
    "Hasło do sieci Wi-Fi": "",
    "Resetuj zmiany": "",
    "nie wymuszaj zmiany hasła": "",
    "Źródło rezerwacji": "",
    "Zmieną %HEAD_TITLE% można wykorzystać w innych polach tekstowych.": "",
    "Ilość koordynatorów w regionie": "",
    "Zmień status": "",
    "IBAN": "",
    "NIP firmy": "",
    "Dokument został dodany.": "",
    "Lista powiadomień": "",
    "Wybierz": "",
    "Kopiuj filtr": "",
    "Zapisz filtry": "",
    "Wybranie katalogu jest wymagane": "",
    "Standard 2004": "",
    "Lista Tras:": "",
    "Dodaj certyfikat": "",
    "Strony": "",
    "Kategoria została dodana.": "",
    "Wybierz język": "",
    "Brak przypisanych użytkowników.": "",
    "Link do raportu": "",
    "Aktywny": "",
    "Dzielnica": "",
    "Kategoria jest wymagana": "",
    "Tak/Nie": "",
    "Sobota": "",
    "Pole \"Imię\" jest wymagane.": "",
    "Ważne": "",
    "Ten element nie zawiera menu kontekstowego.": "",
    "Niestandardowy błąd": "",
    "Czy na pewno chcesz ukryć wiadomość?": "",
    "Link do zewnętrznej strony": "",
    "Ta czynność została wykonana przez kogoś innego. Odśwież aplikację.": "",
    "Ilość łóżek jednoosobowe": "",
    "Usuń regulamin": "",
    "Usuń trasę": "",
    "Edycja miasta": "",
    "Zapisz wersję slajdu": "",
    "Notatka została dodana.": "",
    "Konfiguracja aranżacji jest niedostępna ponieważ nie podano definicji szablonu.": "",
    "Filtr globalny": "",
    "Filtry globalne": "",
    "Podgląd": "",
    "Nie udało się wysłać pliku": "",
    "Źródło": "",
    "Wysokość": "",
    "Dodaj tag nieruchomości": "",
    "Dodaj do regionu": "",
    "Multimedia": "",
    "Edycja działu": "",
    "Typ źródła danych": "",
    "Deadline - Data wykonania": "",
    "Przypisane tagi: ": "",
    "Miejsca po przecinku": "",
    "Wybrany materiał został już dodany do lekcji": "",
    "Koszt został zaktualizowany.": "",
    "Inspektor": "",
    "Edycja ankiety": "",
    "Pokaż tytuły stron": "",
    "Dodanie paczki SCORM": "",
    "Domyślna wartość": "",
    "Konfiguracja bloku": "",
    "Ranga": "",
    "Hasło zostało zmienione.": "",
    "Data dodania do": "",
    "Niżej": "",
    "Liczba całkowita": "",
    "Data rezerwacji od": "",
    "Użytkownik został przypisany do ścieżki szkoleniowej.": "",
    "Adres kanoniczny": "",
    "Serwer został usunięty.": "",
    "Zbliżająca się data zakończenia": "",
    "Usuń poprzednie wersje certyfikatu": "",
    "Symbol": "",
    "Konfiguracja sekcji jest niedostępna ponieważ nie podano definicji szablonu.": "",
    "Nowa kwota": "",
    "Formularz został zaktualizowany.": "",
    "Aktualność została dodana.": "",
    "Podążanie za linkami": "",
    "Zadania na wniosek działu": "",
    "Nie udało się pobrać listy serwerów plików": "",
    "NIP": "",
    "Nr rezerwacji": "",
    "Dane gościa": "",
    "Czy na pewno chcesz zkopiować szkolenie?": "",
    "Wiadomość została przywrócona.": "",
    "Testowy adres email": "",
    "Dodanie lekcji": "",
    "Dodaj wszystkie z kategorii": "",
    "Formularz zarchiwizowany": "",
    "Statystyki kosztów w obiektach": "",
    "Zadanie": "",
    "Punkt został oznaczony": "",
    "Kanały komunikacji": "",
    "Średnie wynagrodzenie pracowników": "",
    "Wartość musi być liczbą dodatnią": "",
    "Stwórz czat z rezerwacji": "",
    "Rezerwacje anulowane": "",
    "Litery i cyfry": "",
    "Trasa została rozpoczęta": "",
    "Dodanie slajdów": "",
    "Czas trwania:": "",
    "Edycja artykułu": "",
    "Zwiń": "",
    "Nie udało się pobrać listy typów materiałów": "",
    "Dodaj koszt": "",
    "Liczba dni do zakończenia szkolenia przy której zostanie wysłane powiadomienie": "",
    "Region": "",
    "Aktualności": "",
    "Pobrane z magazynu Renters": "",
    "Lista szkoleń": "",
    "Wynagrodzenia w regionie łącznie z wynagrodzeniami koordynatorów": "",
    "Lista zgłoszeń w tym apartamancie": "",
    "Wyrażone zgody": "",
    "Co tydzień": "",
    "Element grupujący": "",
    "Nie ma ustalonej roli dla tego wydziału": "",
    "Powiązane zadania": "",
    "Data od": "",
    "Wszystkie kategorie": "",
    "Pytanie": "",
    "Adres e-mail": "",
    "Pole zawiera": "",
    "Logo": "",
    "Aktualne hasło": "",
    "Kwota opłacona od": "",
    "Usuń materiał z lekcji": "",
    "Wklej komponent": "",
    "Pytanie 2": "",
    "Attempt of reservation by Credit Card": "",
    "Zastępstwo zostało usunięte.": "",
    "Usuń kurs z ścieżki szkoleń": "",
    "Klucze": "",
    "%$ – znacznik otwierający tag": "",
    "Nazwa kolumny z wartością": "",
    "Administracja": "",
    "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "",
    "Dzisiaj": "",
    "Host główny": "",
    "Brak właściciela": "",
    "Checklista": "",
    "Rola została dodana.": "",
    "Aktywność": "",
    "Nie wiem": "",
    "Źródło danych": "",
    "Data zalogowania": "",
    "Do szkolenia nie przypisano jeszcze żadnych zespołów.": "",
    "Ważne informacje": "",
    "Wszystkie konta": "",
    "Przeglądarka z której korzystasz nie wspiera powiadomień PUSH": "",
    "Wpisz treść wiadomości.": "",
    "Nie masz uprawnień do publikacji aranżacji.": "",
    "Firmy": "",
    "Wybrane elementy zostały usunięte.": "",
    "Wszyscy": "",
    "Podaj swój adres email": "",
    "Edycja zadania": "",
    "Ładuję zgłoszenia...": "",
    "Status zadania": "",
    "Exclusivity": "",
    "Laptop 17\"": "",
    "Powtórzone": "",
    "Szkolenie zostało skopiowane": "",
    "Podstawowe operacje tekstowe": "",
    "Uwzględnij wydziały podrzędne": "",
    "Zarządzaj": "",
    "Trasy dla Kierowców": "",
    "Nazwa:": "",
    "Moja trasa": "",
    "(nowe wiadomości)": "",
    "Wróć do strony głównej": "",
    "Prefix": "",
    "Pojedynczy artykuł (strona opisowa)": "",
    "Co 5 dni": "",
    "Zaloguj się": "",
    "Resetuj filtry": "",
    "Edytuj aranżację": "",
    "Nie wypełniono poprzedniego wiersza.": "",
    "Wysłane powiadomienie": "",
    "Wstaw kontrolkę": "",
    "Nieprawidłowy adres email": "",
    "Raporty z rozliczeń": "",
    "zacznij pisać aby wyszukać szkolenie...": "",
    "Dodaj Notatkę": "",
    "Dział": "",
    "Zadanie zostało rozpoczęte.": "",
    "Kwota kosztu do": "",
    "Styczeń": "",
    "Pytanie 1": "",
    "Dobrze": "",
    "Inwentaryzaje": "",
    "Konto aktywne": "",
    "Numer dowodu osobistego": "",
    "Dostęp niezdefiniowany": "",
    "Edycja źródła danych": "",
    "Reżyseria": "",
    "Zaakceptowane, zapisz": "",
    "Minimalna długość hasła": "",
    "Nie udało się pobrać listy szablonów": "",
    "Lista wyboru": "",
    "Co 2 tygodnie": "",
    "Przypisane dzielnice:": "",
    "Nazwa nadawcy": "",
    "co rok": "",
    "Lekcja wideo została dodana.": "",
    "Słownik systemowy": "",
    "zacznij pisać aby wyszukać użytkownika...": "",
    "Przypomnij": "",
    "Zespoły": "",
    "Wybrana ikona powinna być kwadratem": "",
    "Apple iPhone 5": "",
    "Nie udało się dodać użytkownika do czatu": "",
    "Dodaj slajder": "",
    "Testowe powiadomienie wysłane": "",
    "Opublikowana": "",
    "Domyślna checklista": "",
    "Co 3 miesiące": "",
    "Dane wydziałów": "",
    "Wyliczenie stawki godzinowej (statystyczna)": "",
    "Edytuj test": "",
    "Data utworzenia": "",
    "Szablon checklisty": "",
    "Dodanie Notatki": "",
    "Pseudonim": "",
    "Zacznij pisać aby wyszukać tagi...": "",
    "Zamknij": "",
    "Treść została zaktualizowana.": "",
    "Definicja szablonu": "",
    "Nieobecność do": "",
    "Chat": "",
    "Autor notatki": "",
    "Stan powiadomienia": "",
    "Materiał wideo": "",
    "Podtytuł": "",
    "Certyfikat przygotowany jest do formatu A4. Wyświetlany podgląd stanowi jedynie widok roboczy. Docelowy wygląd certyfikatu będzie powiązany z właściwymi danymi szkolenia oraz użytkownika.": "",
    "Wystąpił błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.": "",
    "Zatwierdź": "",
    "Ta dzielnica jest już przypisana do użytkownika.": "",
    "Typ szablonu": "",
    "Filtr o podanej nazwie już istnieje.": "",
    "Konta publiczne": "",
    "Dodaj nowy": "",
    "Resetowanie podejść użytkowników": "",
    "Wybrana data jest większa niż dozwolona %0.": "",
    "Data Zmiany": "",
    "Dodaj stronę": "",
    "Wybór języka": "",
    "Co minutę": "",
    "Data odrzucenia": "",
    "Zmień rozmiar": "",
    "Edycja roli": "",
    "Dane wydziału zostały usunięte.": "",
    "Domyślna": "",
    "Ilość punktów: ": "",
    "Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk \"Aktualizuj\". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.": "",
    "Kolejne pozycje z lewej strony": "",
    "Sierpień": "",
    "Nieruchomość została zaktualizowana.": "",
    "Przybornik": "",
    "Zrób zdjęcie": "",
    "Powiadomienia zostały skonfigurowane.": "",
    "Następna inwentaryzacja": "",
    "Kwota rezerwacji": "",
    "Wstaw trzy kolumny 1/3": "",
    "Wybrany region": "",
    "Status rozliczenia": "",
    "Utwórz katalog": "",
    "Adres email nadawcy": "",
    "Zwierzę": "",
    "Edycja zespołu": "",
    "Wyszukaj użytkownika": "",
    "Aktualności ze strony": "",
    "Zaopatrzenie zostało usunięte.": "",
    "Odśwież": "",
    "Zaokrąglenie": "",
    "Wybór kategorii": "",
    "Edycja wpisu": "",
    "Kwota średnia": "",
    "Zarządzaj zespołami": "",
    "Zacznij pisać aby wyszukać firmę...": "",
    "Synchronizacja rezerwacji": "",
    "Lista użytkowników": "",
    "Płeć": "",
    "Tryb monitorowania aktywności włączony.": "",
    "Nie wybrano paczki.": "",
    "Odpowiedź 4": "",
    "Pytania": "",
    "Zastępstwo wygaśnie za 5 minut.": "",
    "Pokaż nieaktywnych właścicieli": "",
    "Publikacja została zmieniona.": "",
    "Wybierz serwer": "",
    "Dane kierowcy: ": "",
    "Nazwa pliku": "",
    "Open Graph": "",
    "Aktualizuj": "",
    "${(ex as any).response.data.errors.message[0]}": "",
    "Dokument nie jest jeszcze nigdzie opublikowany.": "",
    "Planowanie inwentaryzacji": "",
    "Zapisz nową kolejność punktów": "",
    "wybierz katalog": "",
    "Id zdarzenia": "",
    "Checklista została usunięta": "",
    "Galeria zdjęć": "",
    "Kalendarz nieruchomości": "",
    "źródło danych zostało dodane.": "",
    "Nieobecny do": "",
    "To pole jest wymagane": "",
    "Logowanie zakończyło się niepowodzeniem.": "",
    "Przywrócić użytkownika i jego wiadomości?": "",
    "Aby dodawać użytkowników do szkolenia musisz najpierw zapisać formularz.": "",
    "Zaawansowane polecenia": "",
    "Serwery plików": "",
    "Statystyki wniosków": "",
    "Niewykonane zadania": "",
    "Kierowca Główny": "",
    "Default mode": "",
    "Szkolenie nie jest opublikowane": "",
    "Dostęp przyznany ręcznie": "",
    "Kwota opłacona": "",
    "Kwota łącznie": "",
    "Brak danych.": "",
    "Pracownik": "",
    "Wyślij link": "",
    "Zapauzuj zadanie": "",
    "Zestaw został dodany.": "",
    "Dowolna data": "",
    "Automatyczna akceptacja": "",
    "Filtr został usunięty": "",
    "Zarchiwizowany": "",
    "Stawka nieruchomości": "",
    "Zwrócony": "",
    "Edytowane mogą być tylko szablony włączonych i obsługiwanych kanałów.": "",
    "Czas pomiędzy": "",
    "Ustawienia globalne": "",
    "Dane właściciela": "",
    "Elementy": "",
    "Podstawowe operacje na liczbach": "",
    "Opublikuj tutaj": "",
    "Dodanie formularza": "",
    "Wybrana liczba jest większa niż dozwolona %0.": "",
    "Wystąpił błąd połączenia. Upewnij się że masz włączony internet i spróbuj ponownie.": "",
    "Dopuść brak odpowiedzi": "",
    "Usunięte": "",
    "Edycja nowej trasy": "",
    "Powiązany artykuł": "",
    "Uwzględnij dodatkowe nieruchomości": "",
    "Back Office": "",
    "Brak wersji": "",
    "Zacznij pisać aby wyszukać miasto...": "",
    "Dodaj firmę": "",
    "Archiwizuj": "",
    "Akceptacja": "",
    "Potwierdzenie usunięcia.": "",
    "Paczka SCORM została dodana.": "",
    "Dodane:": "",
    "Otwórz czat - link": "",
    "Kategoria materiału": "",
    "Kontrolki specjalizowane": "",
    "Trasa została usunięta": "",
    "Obciążenie Renters": "",
    "Eksportuj do Excel": "",
    "Domyślny czas rezerwacji": "",
    "Zespół został przypisany do ścieżki szkoleniowej.": "",
    "Pobierz raport": "",
    "Oczekuje na wysłanie": "",
    "Tytuł strony": "",
    "Profil został zaktualizowany.": "",
    "Edytuj zestaw": "",
    "Facturada": "",
    "Wybrane": "",
    "Liczba miast w regionie": "",
    "Powiadomienie o oczekującym szkoleniu": "",
    "Do poziomu": "",
    "Struktura serwisu": "",
    "Ten czat nie posiada przypisanych konkretnych użytkowników.": "",
    "Walidacja": "",
    "Co 5 minut": "",
    "ŚREDNIA": "",
    "Źródła danych": "",
    "Regulamin został zaktualizowany.": "",
    "Wybierz autora z listy": "",
    "Ten zespół nie posiada jeszcze żadnych członków.": "",
    "Rozliczenia": "",
    "Nigdy": "",
    "Edycja wielu elementów": "",
    "Data do": "",
    "Nie wszystkie wybrane elementy zostały przeniesione. Nie posiadasz wymaganych uprawnień lub, w przypadku przenoszenia katalogów, katalog docelowy jest podkatalogiem katalogu źródłowego. Katalogi i pliki których nie udało się przenieść pozostały nadal w zakładce Wybrane.": "",
    "ISBN": "",
    "Plik jest wysyłany": "",
    "Rola została usunięta.": "",
    "15 minut": "",
    "Zdecydowanie ważne": "",
    "Dodaj nową stronę lub wybierz z menu aby edytować.": "",
    "Dodatkowe wynagrodzenie - komentarz": "",
    "Maksimum": "",
    "Nazwa pola": "",
    "Usuń plik": "",
    "Kod HTML": "",
    "Upload": "",
    "Nazwa firmy": "",
    "Nazwa paczki": "",
    "Wstaw z dołu": "",
    "Wstaw przycisk": "",
    "Stała wartość": "",
    "Wynagrodzenia Pracowników": "",
    "Codziennie": "",
    "Dodaj klienta": "",
    "Adres URL profilu autora.": "",
    "Scenariusz": "",
    "Zapisz filtr": "",
    "Status dokumentu został zmieniony.": "",
    "Bez \"http(s)://\" i \"/\" na końcu.": "",
    "HR": "",
    "zacznij pisać aby wyszukać materiał...": "",
    "Otwórz stronę w nowym oknie": "",
    "Opisy": "",
    "Zakres czasu": "",
    "Liczba pracowników w regionie": "",
    "Koordynator": "",
    "Edytuj Notatkę": "",
    "Kwota za godzinę": "",
    "Błędnie określona liczba, sprawdź liczbę opublikowanych materiałów.": "",
    "Witryna została zaktualizowana.": "",
    "Przejdź do rezerwacji": "",
    "Nieruchomość została dodana.": "",
    "Dodanie strony": "",
    "Aktualność została zaktualizowana.": "",
    "Użytkownik": "",
    "Grafik pracowniczy": "",
    "Wynagrodzenia Koordynatorzy": "",
    "Przeglądnij logi": "",
    "MasterCode nieruchomości": "",
    "Wysłano powiadomienie.": "",
    "Top mieszkań generujacych najwięcej kosztów": "",
    "Ustawienia konta": "",
    "Tytuł sekcji": "",
    "Ten kanał ma możliwość wysyłania tekstu sformatowanego.": "",
    "Peticion informacion": "",
    "Nazwa użytkownika serwisowego": "",
    "Stawka za typ apartamentu": "",
    "Nazwa sekcji": "",
    "Dodaj użytkownika": "",
    "Inwentaryzacja została zaktualizowana.": "",
    "Odejmowanie": "",
    "Link do wybranej strony serwisu": "",
    "Odpowiedź 5": "",
    "Język domyślny": "",
    "Opis szkolenia": "",
    "Wymagaj znaków specjalnych w hasłach": "",
    "Miesiące": "",
    "Zespół został zaktualizowany.": "",
    "Zdecydowanie nie": "",
    "Nie udało się pobrać listy aranżacji": "",
    "Potwierdzenie przywrócenia": "",
    "Liczba dni od jakiej użytkownik otrzyma powiadomienie": "",
    "${StatusName(StatusEnum[this.form.bookingType])}": "",
    "Zapisz i wróć": "",
    "6 godzin": "",
    "Dodanie miasta": "",
    "Dodano zdjęć": "",
    "Typy materiałów": "",
    "Kategoria nadrzędna": "",
    "Co 12 godzin": "",
    "Dokument %0": "",
    "Podlega ocenie": "",
    "Publiczne": "",
    "Podziedziałek - Piątek": "",
    "Ustawienia slajdera": "",
    "Czy jesteś pewien? ": "",
    "Nie": "",
    "Przypisz do zadania": "",
    "Szkolenie zostało dodane.": "",
    "Ten region jest już przypisany do użytkownika.": "",
    "Dane zadania": "",
    "Pole \"Nazwisko\" jest wymagane.": "",
    "Wynagrodzenia HK": "",
    "Nazwisko właściciela": "",
    "Ilość wyników do odrzucenia": "",
    "wybierz": "",
    "Kalendarz pracowników": "",
    "Wróć do logowania": "",
    "Nieopublikowany": "",
    "Hasło użytkownika serwisowego": "",
    "Wyloguj": "",
    "Dodaj do zestawu": "",
    "Przypisane tagi:": "",
    "Brak elementów podsumowania": "",
    "Ten użytkownik już jest przypisany do czatu": "",
    "Przypisane do": "",
    "Wymuś zmianę hasła": "",
    "Opis": "",
    "Lista rozwijana": "",
    "Niepotwierdzeni": "",
    "Dostęp przyznany przez zespół": "",
    "Dodaj typ wynagrodzenia": "",
    "Przekroczono dozwoloną ilość znaków: %0.": "",
    "Baza danych tego klienta jest nieaktualna. Uruchom aktualizację za pomocą przycisku na dole formularza.": "",
    "Wybierz dział": "",
    "Dzielnice": "",
    "Pozostało %0 znaków.": "",
    "Zastępstwo zostało zaktualizowane.": "",
    "Zapauzowane": "",
    "Uwzględniaj tagi": "",
    "Nazwa zestawu": "",
    "Miasto zostało usunięte": "",
    "Treści odpowiedzi są wymagane": "",
    "Publikuj certyfikat": "",
    "Najniższy": "",
    "Aranżacje": "",
    "Dodaj chat": "",
    "Środa": "",
    "Tłumaczę...": "",
    "Długość": "",
    "Kanał jest domyślnie wymagany. Nie da się wyłączyć wysyłania powiadomienia tym kanałem. Możliwa jest tylko zmiana tytułu i treści komunikatu.": "",
    "Legenda": "",
    "Nadpisanie kwoty wynagrodzenia zostało dodane.": "",
    "Piątek": "",
    "Utworzone przez": "",
    "Stwierdzenie": "",
    "Kalendarz": "",
    "Informacje": "",
    "Wymiary": "",
    "Wideo": "",
    "Komentarz dotyczący naprawy problemu": "",
    "Ankiety": "",
    "Edycja klienta": "",
    "Adres email": "",
    "Na pewno przenieść wybrane elementy do bieżącego katalogu?": "",
    "Zadania dzisiaj": "",
    "Podaj identyfikatory użytkowników Facebooka, oddzielone przecinkami, aby mogli uzyskać dostęp do statystyk (Facebook Insights).": "",
    "Nadpisz kwotę": "",
    "Usuń slajd": "",
    "Data dodania od": "",
    "Numer mieszkania (Number)": "",
    "Ikona wyświetlana jest w postaci kwadratu.": "",
    "Przypisani użytkownicy:": "",
    "Anuluj i wyjdź z kreatora": "",
    "Wyszukuję...": "",
    "Członkowie zespołu": "",
    "Pokaż tytuł strony": "",
    "Dodaj premię": "",
    "Edytuj lekcje": "",
    "Nie udało się pobrać ról!": "",
    "Data wydania": "",
    "Opracowane, zapisz": "",
    "Dane osobowe": "",
    "Szablon został usunięty.": "",
    "Lekcja SCORM została zaktualizowana.": "",
    "W zespole od %0 do %1": "",
    "Notatki Koordynatorów": "",
    "Wyszukaj wszędzie": "",
    "Usunięcie wybranych elementów": "",
    "Kontrolka wyboru (pojedynczy wybór)": "",
    "Strona lekcji opublikowana": "",
    "Edytuj kategorie": "",
    "Uwzględniaj dzielnice": "",
    "Przywrócić wiadomość?": "",
    "Wyłączone (NoFollow)": "",
    "Dozwolone rozszerzenia": "",
    "Publikacja została usunięta.": "",
    "Checklista została dodana.": "",
    "Numer rezerwacji Avantio": "",
    "Administracja - Użytkownicy": "",
    "5 minut": "",
    "Nie zdefiniowano żadnych sekcji.": "",
    "Data zdarzenia": "",
    "Edytuj datę opuszczenia zespołu": "",
    "Szablon nr 1": "",
    "Dodaj dział": "",
    "(AirBnb) Wysłano żądanie do gościa": "",
    "ID obiektu": "",
    "Cancelled": "",
    "Witryny": "",
    "Nierozwiązane": "",
    "Przywróć oryginał": "",
    "Nazwa bloku": "",
    "Edycja ustawień wynagrodzenia dla:": "",
    "Wyposażenie": "",
    "Wyślij": "",
    "Trasa została zakończona": "",
    "odznacz wszystko": "",
    "Ustawienia domyślne": "",
    "Szef Regionu": "",
    "Nieobecność od": "",
    "Edycja szablonu": "",
    "Wyczyść aranżację": "",
    "10": "",
    "Wartość musi być liczbą całkowitą": "",
    "Witryna / Wersja językowa": "",
    "Witryna została usunięta.": "",
    "Link został usunięty.": "",
    "Dodaj nową witrynę aby rozpocząć zarządzanie stronami.": "",
    "Zaplanowane": "",
    "Dodawanie inwentaryzacji": "",
    "Rodzaj adresu kanonicznego": "",
    "Tylko do odczytu": "",
    "Tagi nieruchomości": "",
    "Dodaj witrynę": "",
    "Tytuł formularza jest wymagany": "",
    "Nieruchomości": "",
    "Czy zapisać trasę i wysłac ją do kierowcy?": "",
    "Dane podstawowe": "",
    "Edycja nieruchomości": "",
    "Brak kategorii nadrzędnej": "",
    "Ten Tydzień": "",
    "Monitoring": "",
    "Nazwa kategorii": "",
    "Zobacz przed opublikowaniem": "",
    "Statystyki regionalne - miasta": "",
    "Dodaj dane wydziału": "",
    "Konto pozostanie nieaktywne dopóki adres e-mail nie zostanie potwierdzony": "",
    "Pokaż rezerwacje właścicielskie": "",
    "Aktywne": "",
    "Liczba wyświetlanych slajdów": "",
    "Ustawienia wynagrodzenia zostały zaktualizowane.": "",
    "+": "",
    "Data modyfikacji do": "",
    "Aktywne kanały komunikacji": "",
    "słowa": "",
    "Powiadomienie o zbliżającym się zakończeniu szkolenia": "",
    "Wszystkie zadania": "",
    "Wróć na dół": "",
    "Link został zaktualizowany.": "",
    "Plik": "",
    "Walidacja certyfikatu SSL": "",
    "Stawki nieruchomości": "",
    "Nazwa linku": "",
    "Nieprzeczytane": "",
    "Jedna kolumna": "",
    "Porównaj z edytowaną wersją": "",
    "Wyznacz punkty": "",
    "Zmniejsz": "",
    "Język:": "",
    "Data publikacji": "",
    "Usługi": "",
    "Ilość pracowników": "",
    "Wybierz temat": "",
    "Typ nieruchomości": "",
    "Standard": "",
    "Odpowiedź 2": "",
    "Generuję plik...": "",
    "treść notatki": "",
    "do": "",
    "Program": "",
    "Zespół": "",
    "Dane rezerwacji": "",
    "Liczba pracowników zatrudnionych tylko w tym mieście": "",
    "Poprawna odpowiedź": "",
    "Nazwisko i Imię": "",
    "Brak tematu": "",
    "Serwer bazy danych": "",
    "Przeciągnij do innej sekcji": "",
    "Lista nieruchomości": "",
    "Prywatne": "",
    "Wymagane jest wybranie źródła danych": "",
    "Cyfry": "",
    "Brak kluczy": "",
    "Wprowadzona wartość jest za niska": "",
    "Potwierdzenie ukrycia": "",
    "Klient został zaktualizowany.": "",
    "Obciążenie Magazyn": "",
    "Certyfikat został dodany.": "",
    "Inwentaryzacja": "",
    "Usuń stronę": "",
    "Rozpoczęcie": "",
    "Jesteś pewien?": "",
    "Edytuj uprawnienia": "",
    "Polska": "",
    "Dodanie linku": "",
    "Aranżacja została opublikowana.": "",
    "Dodanie roli": "",
    "Nowy materiał": "",
    "Pytania i odpowiedzi": "",
    "Użytkownik został dodany.": "",
    "Tag": "",
    "Wynagrodzenia mieszane": "",
    "Stwórz czat z zadania": "",
    "Nieopłacone/dłużnik": "",
    "Właściwości": "",
    "Nazwa konta (Klucz dostępu)": "",
    "Kanały obsługiwane przez wybrany typ powiadmienia": "",
    "Coś poszło nie tak": "",
    "Wybierz blok": "",
    "Województwo": "",
    "Nie udało się pobrać listy kategorii": "",
    "Szkolenie": "",
    "Ok": "",
    "Poprzedni miesiąc": "",
    "Certyfikat został zaktualizowany.": "",
    "Skopiowano": "",
    "Zapytanie SQL": "",
    "Od poziomu": "",
    "Konto publiczne": "",
    "Nie udało się usunąć użytkownika z czatu": "",
    "Ma dzisiaj": "",
    "Domyślny": "",
    "Nie udało się pobrać powiadomień.": "",
    "Bloki": "",
    "Nazwa obiektu": "",
    "Tło przycisku": "",
    "Uwzględniaj Miasta": "",
    "Podsumowanie": "",
    "Uwaga! wraz z miastem": "",
    "Harmonogram": "",
    "Kwota pozostała": "",
    "Przenieś wyżej": "",
    "Zespół został usunięty.": "",
    "Potwierdzam, zapisz": "",
    "po %0 nieudanych próbach logowania": "",
    "Załączniki": "",
    "ID nazwa obiektu": "",
    "Aktywna nieruchomość": "",
    "Ilość osób": "",
    "usunięto dzielnice": "",
    "Czwartek": "",
    "Czy na pewno chcesz zablokować użytkownika?": "",
    "Zarządzanie witrynami": "",
    "Otwórz Ustawienia": "",
    "Slajder": "",
    "Dostęp od": "",
    "Zapauzowano:": "",
    "Ostatnia aktywność": "",
    "Filtry kalendarza": "",
    "Edycja": "",
    "Edytuj blok": "",
    "Wybierz plik lub katalog": "",
    "Data informująca o terminie wejścia w życie tego regulaminu.": "",
    "Inna": "",
    "Przyczyna": "",
    "Włącz social media": "",
    "Wczytuję...": "",
    "Status: ": "",
    "Wybierz certyfikat": "",
    "Wersje aranżacji": "",
    "Dodaj nowy katalog aby rozpocząć zarządzanie stronami.": "",
    "Tłumacz": "",
    "Data zakończenia/wykonania": "",
    "Strona testu opublikowana": "",
    "Edycja ścieżki szkoleniowej": "",
    "Ilość łóżek dwuosobowe": "",
    "Odrzucone zgody": "",
    "Odrzuć": "",
    "Dodaj zadanie": "",
    "Host dodatkowy": "",
    "Tryb monitorowania aktywności wyłączony.": "",
    "Tytuł": "",
    "Avantio ID nieruchomości": "",
    "Classic mode": "",
    "Checklista:": "",
    "Tagi": "",
    "Przypisani użytkownicy": "",
    "Strony opisowe": "",
    "Czy opublikowany": "",
    "Brak": "",
    "Wyszukaj": "",
    "$% - znacznik zamykający tag": "",
    "Komunikat mailowy": "",
    "Wersje dokumentu": "",
    "Premiera": "",
    "Kalendarz Pracowniczy": "",
    "Dostępne języki": "",
    "Miniaturka wideo": "",
    "Mieszane": "",
    "Zadanie zostało dodane.": "",
    "Ustawienia zostały zapisane.": "",
    "Dodane przez moje zespoły": "",
    "Opublikowany na stronach": "",
    "Formularz kontaktowy": "",
    "Symbol może składać się tylko z małych liter, cyfr oraz znaku - ": "",
    "Standardowa strona": "",
    "Kalendarz - Kategorie zasobów": "",
    "Tryb monitorowania aktywności": "",
    "Rodzaj odpowiedzi": "",
    "Rozpocznij synchronizację": "",
    "Zobacz sesję użytkownika": "",
    "Nie udało się zapisać szablonu": "",
    "...nie znalazłeś bloku?": "",
    "Małe znaki": "",
    "Nieobecny od": "",
    "Dołącz stronę do pliku sitemap.xml": "",
    "Zabierz dostęp": "",
    "Filtr został zapisany": "",
    "Upload plików": "",
    "Kwota opłacona do": "",
    "Przypisane:": "",
    "Moduły": "",
    "Wybrany język": "",
    "Szczegóły zadania": "",
    "Jednokrotnego wyboru": "",
    "Marketing": "",
    "Brakujące tytuły kafelków.": "",
    "Moje dokumenty": "",
    "To miasto znajduję się już w tym regionie.": "",
    "Czas": "",
    "Wyszukiwany ciąg znaków": "",
    "Treść notatki": "",
    "Nieruchomości (Data przyjazdu)": "",
    "Inwentaryzacja została usunięta.": "",
    "Brak nowych powiadomień.": "",
    "Wielkość kroku": "",
    "Administracja - Zespoły": "",
    "Back Office (BO)": "",
    "Edytuj stawkę za godzinę": "",
    "Edycja poradnika": "",
    "Brak modułu": "",
    "Nazwa sieci Wi-Fi": "",
    "Użytkownik został zaktualizowany.": "",
    "Renters": "",
    "W tym katalogu nie ma jeszcze żadnych stron.": "",
    "Dział:": "",
    "Brak działu nadrzędnego": "",
    "Narzędzia": "",
    "Użytkownik został zablokowany, a jego wiadomość ukryte.": "",
    "Zadanie na dziś": "",
    "Kod Google Webmaster": "",
    "Co 6 godzin": "",
    "Zobacz czaty": "",
    "Numer rezerwacji": "",
    "Link": "",
    "Wybierz paczkę SCORM": "",
    "Archiwalny": "",
    "Usunięcie": "",
    "Edytuj urlop": "",
    "Edytuj obraz": "",
    "Podano nieprawidłowe dane.": "",
    "Dodanie działu": "",
    "Bezpieczeństwo": "",
    "Usuń zadanie": "",
    "Wymagane": "",
    "Krótki tytuł": "",
    "Wyszukaj zespół": "",
    "Sprzątanie": "",
    "Pokaż zdjęcie w raporcie": "",
    "Kategoria": "",
    "Źródło URL": "",
    "Co 10 dni": "",
    "Zaopatrzenie zostało zaktualizowane.": "",
    "Element": "",
    "Edycja zestawu regulaminóe": "",
    "Udało się usunąć zadania!": "",
    "Liczba pracowników": "",
    "Token został usunięty.": "",
    "Region apartamentu": "",
    "Musisz najpierw zapisać zmienioną kolejność punktów zanim oznaczysz punkt": "",
    "Role i uprawnienia": "",
    "Opublikuj": "",
    "Nazwa dzielnicy": "",
    "Kontakty - Typ działania": "",
    "Kolor trasy": "",
    "Ukryj": "",
    "Wykonawca": "",
    "Wstęp": "",
    "Nikomu nie przydzielono uprawnień": "",
    "Zakończenie": "",
    "Przyznaj dostęp": "",
    "Dodanie regulaminu": "",
    "Zawierające jeden z wybranych tagów": "",
    "Suma wynagrodzeń koordynatorów": "",
    "Moduł": "",
    "Status edytowanej wersji dokumentu": "",
    "Historia nieruchomości": "",
    "Dodaj zaopatrzenie": "",
    "Lista urządzeń na których jesteś zalogowany": "",
    "Booking with conflict": "",
    "Wszystkie niezapisane zmiany zostaną utracone.": "",
    "Nie można dodać pliku(ów): \"%0\". Można dodawać tylko pliki typu: \"%1\".": "",
    "Dodaj test": "",
    "Dane do sieci": "",
    "Housekeeping (HK)": "",
    "Tylko serwer deweloperski. Nie używać w trybie produkcyjnym. ": "",
    "Zakończone: ${$filters.datetime(task.dateCompleted)}": "",
    "Zablokowano możliwość edycji": "",
    "Powiadomienie jest domyślnie wymagane. Nie da się wyłączyć wysyłania tego powiadomienia.": "",
    "Widoczność": "",
    "Opracowanie kosztu": "",
    "Nazwa przycisku ``Wyślij``": "",
    "Dokument": "",
    "Nazwa katalogu": "",
    "Wszystkie typy": "",
    "Edycja inwentaryzacji": "",
    "Dostępne obiekty:": "",
    "Publikacja aranżacji została wyłączona.": "",
    "Marka": "",
    "Indywidualne tokeny": "",
    "Data utworzenia zadania": "",
    "Slajdery": "",
    "Aktywna": "",
    "Kwota kosztu od": "",
    "Zacznij pisać aby wyszukać zaopatrzenie...": "",
    "Zajęte": "",
    "Wersje": "",
    "Częstotliwość odświeżania": "",
    "Nieudane": "",
    "Wyszukaj...": "",
    "Publikacja priorytetowa": "",
    "Statystyki wyniosków": "",
    "Lewy panel": "",
    "Sortowanie malejąco": "",
    "Zaopatrzenie": "",
    "Wyślij testowe powiadomienie": "",
    "Wybór listy stron": "",
    "Podana wartość jest nieprawidłowa.": "",
    "Wyszukaj nieruchomość...": "",
    "Odrzucone": "",
    "Pokaż cały błąd": "",
    "Warunkowo": "",
    "Typ powiadomienia": "",
    "Próg zaliczenia (%)": "",
    "Nie podano nazwy poprzedniego elementu.": "",
    "Data dodania": "",
    "Odepnij menu": "",
    "Edytuj stronę": "",
    "Zacznij pisać aby wyszukać tag...": "",
    "Informacje o czacie": "",
    "Dodaj zadanie: inspekcja": "",
    "Zgłoszone problemy": "",
    "Stawka za godzinę brutto": "",
    "Pokaż etykietę": "",
    "Slajd został usunięty.": "",
    "Liczba podejść": "",
    "Zaakceptowany": "",
    "Przeterminowane": "",
    "Wydział / Rola": "",
    "Autor": "",
    "Kwota pobrana": "",
    "Wstaw treść widoczną po rozwinięciu": "",
    "Tylko małe litery, bez znaków specjalnych, cyfr i spacji.": "",
    "Pobierz plik": "",
    "Konfiguracja harmonogramu": "",
    "Apple iPhone 6": "",
    "Zdjęcia": "",
    "Wybrano:": "",
    "ID zadania": "",
    "Wyrażenia warunkowe": "",
    "Wiadomości": "",
    "Typ obciążenia": "",
    "Wstaw stronę": "",
    "W polu możemy umieszczać jedynie cyfry": "",
    "Rozwiń": "",
    "Najwyższy": "",
    "Google Nexus 10": "",
    "Rozpoczęto synchronizację rezerwacji": "",
    "Nie możesz wznowić obsługi tego zadania, ponieważ wykonujesz teraz inne zadanie": "",
    "Ustawienia sekcji \"%0\"": "",
    "Docelowa strona": "",
    "Log błędu": "",
    "Brak nowych wiadomości.": "",
    "Zaakceptuj, wyślij": "",
    "Statystyki": "",
    "Odstęp": "",
    "Uwzględniaj zadania z miesiąca": "",
    "Pomoc": "",
    "Ścieżka szkoleniowa została usunięta.": "",
    "Podana nazwa jest zajęta.": "",
    "Maj": "",
    "Dodaj zespół": "",
    "Właściciel regionu": "",
    "Przypisane miasta:": "",
    "Wymagaj kolejności zaliczania elementów": "",
    "Godzina wymeldowania": "",
    "Dodaj szablon": "",
    "Tytuł formularza nie może być dłuższy niż %0 znaków": "",
    "Data aktualizacji": "",
    "Przeglądanie logów aktywności": "",
    "Tekst alternatywny": "",
    "Czy jesteś pewien, że chcesz przypisać dzielnicę do nowego miasta? Wszystkie nieruchomości i użytkownicy przypisani do tej dzielnicy przestaną być do niej przypisani.": "",
    "Jeszcze nie ma kategorii.": "",
    "Zdjęcie": "",
    "Opublikowane języki": "",
    "Oznacz widoczne jako przeczytane": "",
    "Temat": "",
    "Nie można usunąć swojego konta.": "",
    "Wstaw dwie równe kolumny": "",
    "Uczestnicy rozmowy:": "",
    "Powtóz hasło": "",
    "Obciążenie Gościa": "",
    "KRS": "",
    "Obok siebie": "",
    "Szerokość": "",
    "Imię i nazwisko pracownika": "",
    "Dodaj urlop": "",
    "Checklista została zaktualizowana.": "",
    "Priorytet": "",
    "Wyloguj się": "",
    "Ukryj czaty": "",
    "zgłoś problem w apartamencie": "",
    "30 minut": "",
    "Domyślny tytuł aktywnej strony": "",
    "Opis obrazka": "",
    "Dodaj temat": "",
    "Symbol klienta": "",
    "Terminy do słownika będzie można dodawać dopiero po zapisaniu źródła danych.": "",
    "Imię właściciela": "",
    "Miasto zostało zaktualizowane.": "",
    "Wybierz kategorię": "",
    "Użytkownik jest liderem zespołu": "",
    "Ustawienia zostały zaktualizowane.": "",
    "Brudne": "",
    "Pokaż tytuł sekcji": "",
    "Notatka do zadania": "",
    "Konfiguracja bloków jest niedostępna ponieważ nie podano definicji szablonu.": "",
    "Data użycia": "",
    "W zespole do": "",
    "Host": "",
    "Data": "",
    "Kraj": "",
    "Wstaw z lewej strony": "",
    "Imię i nazwisko": "",
    "Strona została dodana.": "",
    "Data rezerwacji do": "",
    "Jest to lista obiektów które nie mają jeszcze przypisanego właściciela.": "",
    "Usuń datę": "",
    "Kwota brakująca": "",
    "Wartość": "",
    "Liczba inspektorów": "",
    "Dostęp zabroniony": "",
    "Lista jest pusta": "",
    "Gość": "",
    "Serwer plików": "",
    "Dostęp": "",
    "Kategoria szkolenia": "",
    "24 godziny": "",
    "Usunął": "",
    "Program szkolenia": "",
    "Błąd 404. Nie znaleziono żądanego zasobu.": "",
    "Data uruchomienia": "",
    "Wybierz adres kanoniczny z listy lub podaj ręcznie.": "",
    "Slajd został zaktualizowany.": "",
    "Ilość sypialni": "",
    "Paczka SCORM została zaktualizowana.": "",
    "Przenieś niżej": "",
    "Wyłączone (NoIndex)": "",
    "L4": "",
    "Co miesiąc": "",
    "Data Wykonania": "",
    "Inspekcja": "",
    "Wybrano 1 plik": "",
    "Kroki": "",
    "Ukryj przycisk": "",
    "Tytuł witryny": "",
    "Nieaktywne": "",
    "Wyżej": "",
    "Administracja - Role": "",
    "Dokument został zaktualizowany.": "",
    "Powiązane": "",
    "Godzina:": "",
    "Wygeneruj pilik": "",
    "Urządzenie zostało usunięte.": "",
    "Zamknięte": "",
    "Poczta": "",
    "Status regulaminu został zmieniony.": "",
    "Ustaw w kolejności": "",
    "Uwzględniaj nieruchomości": "",
    "Edycja linku": "",
    "Administracja - Województwa": "",
    "Poprawne": "",
    "Uwzględniaj miasta": "",
    "Pytanie i odpowiedzi": "",
    "Prawy panel": "",
    "Średni": "",
    "${title}": "",
    "Linia": "",
    "Tryb wyświetlania": "",
    "Numer konta bankowego": "",
    "Ustaw szerokości proporcjonalnie": "",
    "Data usunięcia": "",
    "Czerwiec": "",
    "Numer telefonu": "",
    "Symbol strony": "",
    "Zapisz jako": "",
    "10 minut": "",
    "Koszty wg regionów": "",
    "Kwota": "",
    "Miasto apartamentu": "",
    "Slajder został usunięty.": "",
    "Widok tygodniowy": "",
    "Numer mieszkania": "",
    "Wersja nieopublikowana": "",
    "Średnie wynagrodzenie": "",
    "Edycja zastępstwa": "",
    "Data zakończenia od": "",
    "Status paczki został zmieniony.": "",
    "Nieprawidłowy adres URL": "",
    "Edytuj treść": "",
    "Potwierdź usunięcie": "",
    "Wartość domyślna": "",
    "Opis zadania": "",
    "Szkolenie kończy się": "",
    "Brak zadania": "",
    "Szablon został dodany.": "",
    "Potwierdzam": "",
    "Firma została usunięta.": "",
    "Luty": "",
    "Co 4 miesiące": "",
    "Wybierz koordynatora": "",
    "Koszt Renters": "",
    "Google Nexus 5": "",
    "Edytuj slajder": "",
    "Zacznik pisać Form lub Entry aby uruchomić podpowiadanie.": "",
    "Lista artykułów": "",
    "Sekcje": "",
    "Ścieżka aktywnej strony": "",
    "Nazwa ścieżki": "",
    "Zaopatrzenie zostało dodane.": "",
    "Dodanie właściciela": "",
    "Data logowania": "",
    "Nie masz uprawnień do modyfikacji aranżacji.": "",
    "Rozpocznij trasę": "",
    "Ten kanał nie ma możliwości wysyłania tekstu sformatowanego.": "",
    "Stwórz zadanie z notatki": "",
    "Zadanie zostało zapauzowane.": "",
    "Aktualne urządzenie": "",
    "Uwzględnij wydziały i statusy jednocześnie": "",
    "Dodaj nowy koszt": "",
    "Blokada konta": "",
    "Szkolenie jest puste": "",
    "Posprzątane": "",
    "Imię": "",
    "Pokaż zdjęcia": "",
    "Skrócony opis": "",
    "Ta nieruchomość nie posiada informacji na temat wyposażenia.": "",
    "Szczegóły zdarzenia nr %0": "",
    "Pokaż czaty": "",
    "Definicja formularza znajduje się w obiekcie Form.": "",
    "Strona logowania": "",
    "Slajder został dodany.": "",
    "Zdjęcie użytkownika": "",
    "Adres url": "",
    "Minuty": "",
    "Katalog": "",
    "co 6 miesięcy": "",
    "Harmonogram został zmieniony": "",
    "Komunikat walidacji": "",
    "Pokaż filtry": "",
    "Edytuj katalog": "",
    "Edycja kategorii": "",
    "Usuń element": "",
    "Imię gościa": "",
    "Zawiera sekcje": "",
    "Następna strona": "",
    "Przejdź do zadania": "",
    "Wynagrodzenie za sprzątanie": "",
    "Niestandardowy adres": "",
    "Wymagaj odpowiedzi, aby przejść dalej": "",
    "Role użytkownika": "",
    "zacznij pisać aby wyszukać regulamin...": "",
    "Zamiany zostały zapisane.": "",
    "Okładka": "",
    "Piosenka": "",
    "Cztery kolumny": "",
    "Odejmij kwotę": "",
    "Uwzględnij zadania z miesiąca:": "",
    "Zajawka": "",
    "Organizuj": "",
    "Błędne": "",
    "Adres firmy": "",
    "Paid": "",
    "Wyszukaj blok": "",
    "Zewnętrzny serwis": "",
    "Nazwa przycisku ``Dalej``": "",
    "zacznij pisać aby wyszukać certyfikat...": "",
    "Dzielnica została dodana.": "",
    "Dodanie regionu": "",
    "np. pl-PL": "",
    "Uruchom zadanie": "",
    "Miasta:": "",
    "Zapis został usunięty": "",
    "Nazwa ulicy (StreetName)": "",
    "Nawiguj do kolejnego punktu": "",
    "Publikacja zarchiwizowana": "",
    "Status": "",
    "Filtry": "",
    "Twoje hasło wygasło. Zmień je na nowe.": "",
    "Przypnij menu": "",
    "Edytuj tag": "",
    "zacznij pisać aby wyszukać kategorię...": "",
    "Lista zespołów": "",
    "Przesyłam...": "",
    "Pojedynczy wybór": "",
    "zaznacz wszystko": "",
    "Pliki publiczne": "",
    "Login": "",
    "Status notatki": "",
    "Zapisz trasę": "",
    "Zapisz": "",
    "Ustawienia chatów": "",
    "Top kosztów wg miast": "",
    "Adres IP": "",
    "Nazwisko": "",
    "Wybrane regulaminy:": "",
    "Nieaktywni właściciele": "",
    "Co 10 minut": "",
    "Email został wysłany.": "",
    "Użytkownik posiada pełne uprawnienia": "",
    "Czas uruchomienia": "",
    "Wydział": "",
    "Wymuszaj zaliczenie elementów ścieżki zgodnie z kolejnością szkoleń na liście": "",
    "Dodatkowe wynagrodzenie - kwota": "",
    "Powiadomienie dla administratora chatu o nowych wiadomościach": "",
    "Cancelled by owner": "",
    "Nie możesz modyfikować uprawnień": "",
    "Jutro": "",
    "Edycja szkolenia": "",
    "Inspekcje": "",
    "Zestaw został zaktualizowany.": "",
    "Symbol testu": "",
    "Dodaj etykietę": "",
    "Domyślna witryna": "",
    "Nazwa katalogu została zmieniona.": "",
    "Typ elementu": "",
    "Strona": "",
    "Notatka została usunięta.": "",
    "Dodaj": "",
    "Bank": "",
    "Utwórz nowy": "",
    "Wyświetlanie": "",
    "Aranżacja": "",
    "Zadania do wykonania": "",
    "Urlop został dodany.": "",
    "Nazwa przedmiotu": "",
    "Pokaż historię": "",
    "Google Nexus 7": "",
    "Status linku został zmieniony.": "",
    "Treść wiadomości": "",
    "Szablon został zaktualizowany.": "",
    "Wybierz pliki": "",
    "Komentarz Konserwatora": "",
    "Brak dodanych punktów trasy": "",
    "Dopasowanie": "",
    "Konserwacja": "",
    "Umożliwienie sprawdzania odpowiedzi": "",
    "Tytuł aktywnej witryny": "",
    "Dane Adresowe": "",
    "Harmonogram zadań": "",
    "Tryb konserwacji systemu": "",
    "Tag został usunięty.": "",
    "Przedrostek": "",
    "Edycja wynagrodzenia": "",
    "Kierowca": "",
    "Potwierdzenie archiwizacji": "",
    "Edycja wynagrodzenia mieszanego dla:": "",
    "Nazwa elementu": "",
    "Wróć do strony logowania": "",
    "Przenieś do sekcji": "",
    "Liczba nocy": "",
    "Liczba dni co ile wysyłane jest powiadomienie": "",
    "Użytkownik i jego wiadomości zostały przywrócone.": "",
    "Podgląd szablonu e-maila": "",
    "Nie podano nazwy tytułu.": "",
    "Plik wideo": "",
    "Data ostatniej zmiany statusu": "",
    "Edytuj zadanie": "",
    "Profil użytkownika": "",
    "Dodawanie trasy": "",
    "Błąd": "",
    "Profil koordynatora": "",
    "Data nieobecności do": "",
    "Potwierdź wyłączenie publikacji": "",
    "Nazwa slajdera": "",
    "Warunki logiczne": "",
    "Kwota za zadania": "",
    "Zmień nazwę pliku": "",
    "Kwota jednostkowa": "",
    "Zapamiętaj logowanie": "",
    "Nie udało się zmienić statusu zadania": "",
    "Wybrany szablon": "",
    "Data ostatniej inwentaryzacji": "",
    "Wyszukaj tag...": "",
    "Nieudane próby logowania": "",
    "i posiadających wymagane uprawnienia": "",
    "Zaloguj się na swoje konto": "",
    "ZMIANA": "",
    "Kolor tła": "",
    "Podaj adres url...": "",
    "Edycja nieruchomości roboczej": "",
    "Nie możesz rozpocząć obsługi tego zadania, ponieważ wykonujesz teraz inne zadanie": "",
    "Dodaj wiersz": "",
    "Kategoria materiałów": "",
    "Publikacja": "",
    "Nazwa apartamentu": "",
    "Wpisz nazwę trasy": "",
    "Strona dostępna dla wszystkich": "",
    "Rola przypisana do tego wydziału jest nieaktywna": "",
    "Zadanie zostało włączone": "",
    "Wpis został dodany.": "",
    "Szukana fraza": "",
    "Nazwa tytułu nie może być dłuższa niż 240 znaków ani krótsza niż 3 znaki.": "",
    "Designer": "",
    "Lipiec": "",
    "Rozwiązane": "",
    "Czyim kosztem jest dodany przedmiot?": "",
    "Progress Gotowości": "",
    "Udało się dodać nieruchomość do trasy": "",
    "Zobacz wszystkie": "",
    "Wynagrodzenia Kierowcy": "",
    "Stwórz czat": "",
    "Dodaj link": "",
    "Wysoki": "",
    "Data wyjazdu": "",
    "Wyświetlaj tylko treść": "",
    "E-learning": "",
    "Edycja właściciela": "",
    "Nowe połączenie": "",
    "Zostałeś zablokowany, dodanie wiadomości jest niemożliwe": "",
    "Typ serwera": "",
    "Zespół aktywny": "",
    "Kategoria została zaktualizowana.": "",
    "Numer domu": "",
    "Numer mieszkania (Door)": "",
    "Element został dodany.": "",
    "Pomieszczenie": "",
    "Zarządzanie zespołami": "",
    "Strona dostępna tylko dla zalogowanych użytkowników": "",
    "Dodanych plików %0 (limit wynosi %1).": "",
    "Test został zaktualizowany.": "",
    "Wymeldowanie": "",
    "Port": "",
    "Wynagrodzenie PLN brutto": "",
    "Właściwość ``%0`` aktywnego wpisu": "",
    "Strona została zaktualizowana.": "",
    "Poniedziałek": "",
    "Zapisz slajd": "",
    "Wybrana liczba jest mniejsza niż dozwolona %0.": "",
    "Wersja opublikowana": "",
    "Losowa kolejność elementów": "",
    "Id modelu: %0": "",
    "Twój token": "",
    "Zwierzątko": "",
    "Pozycja": "",
    "Maksymalny rozmiar pliku w MB": "",
    "Po zaznaczeniu tej opcji uprawnienia indywidualne i pochodzące z ról nie są brane pod uwagę.": "",
    "Opublikowany": "",
    "Nazwa aktywnej strony": "",
    "Uprawnienia użytkownika": "",
    "Serwer został zaktualizowany.": "",
    "Nie udało się pobrać listy kanałów": "",
    "do czasu ręcznego odblokowania": "",
    "Nie podano opisu.": "",
    "Artykuły": "",
    "Przeczytane": "",
    "Aby zobaczyć aktualny podgląd certyfikatu kliknij Zapisz.": "",
    "Wybierz pliki (do {0})": "",
    "Usuń powiązanie": "",
    "Posiadasz niezapisane materiały": "",
    "Zmień nazwę": "",
    "Dostępy": "",
    "Słownik": "",
    "Blokowanie użytkowników": "",
    "Logowanie": "",
    "Zakolejkowane": "",
    "Podaj dane autora": "",
    "Rozwiń do": "",
    "Wyszukiwarka miejsc": "",
    "Dostępny w sekcjach": "",
    "Ilość wyświetlanych aktualności": "",
    "Dostęp przyznany": "",
    "Data utworzenia do": "",
    "Dodawanie wyposażenia": "",
    "Maksymalna ilość plików": "",
    "Pole \"%0\" jest wymagane.": "",
    "Wystąpił problem z dostępem do kamery": "",
    "Monitor kolejek": "",
    "Dział nadrzędny": "",
    "Brak kategorii": "",
    "Automatyczne akceptacja wersji po osiągnięciu daty publikacji. Wybrana wersja musi mieć ustawiony status na \"Ukończony\"": "",
    "Nie znaleziono żadnych wyników.": "",
    "Formularz": "",
    "Brak wyników": "",
    "Zgoda": "",
    "E-mail": "",
    "Szkolenie jest opublikowane": "",
    "Wykonane zadania": "",
    "Usuń ze szkolenia": "",
    "Wynagrodzenia Pracownicy": "",
    "Firma własna": "",
    "Daty publikacji zostały zaktualizowane.": "",
    "Powrót": "",
    "Data rejestracji": "",
    "Wybierz strony": "",
    "Dodanie testu": "",
    "Potwierdzenie usunięcia. Usunięcie tematu spowoduje usunięcie przypisanych do niego lekcji": "",
    "Dodaj kategorie": "",
    "Suma wynagrodzeń pracowników": "",
    "Wstaw cztery kolumny 1/4": "",
    "Przetwarzane": "",
    "Zadanie zostało zaktualizowane.": "",
    "Moje zadania": "",
    "Wybierz paczkę": "",
    "Adres aktywnej strony": "",
    "Wskazuje robotom wyszukiwarek czy mają indeksować stronę.": "",
    "Lekcja ukryta": "",
    "Nazwa płyty": "",
    "Regulamin został dodany.": "",
    "Nieruchomość": "",
    "Układ": "",
    "Data modyfikacji od": "",
    "Stałe/miesięczne": "",
    "Bezpieczne połączenie SSL": "",
    "Zapisz wersję": "",
    "Data automatycznej akceptacji": "",
    "Regulamin wymagany": "",
    "Monitor aktywności": "",
    "Kontener plików": "",
    "Sekcje wspólne": "",
    "Sortuj po": "",
    "Wybrana data jest mniejsza niż dozwolona %0.": "",
    "Subskrypcja": "",
    "Dodaj Koszt": "",
    "Nazwa grupy": "",
    "Co drugi dzień": "",
    "Lista plików": "",
    "Wartość 0 oznacza brak limitu": "",
    "Edytuj lekcje SCORM": "",
    "Ilość zadań": "",
    "Ten miesiąc": "",
    "Dodanie": "",
    "Synchronizuj razem z anulowanymi": "",
    "Usuń katalog": "",
    "Notatka": "",
    "Uzupełnij dane dotyczące szkolenia": "",
    "Ilość dostępnych bloków": "",
    "Trasa została zaktualizowana.": "",
    "Stwórz zadanie": "",
    "Zacznij pisać aby wyszukać tag nieruchomości...": "",
    "Symbol strony szkolenia": "",
    "Laptop 15\"": "",
    "Nie udało się wczytać podglądu strony.": "",
    "Wynagrodzenie za przepracowane godziny": "",
    "Dodaj do zespołu": "",
    "Łączny czas wykonywania zadań": "",
    "Przypisane regiony:": "",
    "Data utworzenia wersji": "",
    "Zadanie zostało ukończone przez kogoś z Twojego zespołu.": "",
    "Edycja aktualności": "",
    "Raczej się zgadzam": "",
    "Symbol lekcji": "",
    "Użytkownik został usunięty.": "",
    "Plik został wysłany": "",
    "Publikuj na stronie": "",
    "Szczegóły rezerwacji": "",
    "Dopasuj do kategorii": "",
    "Pole \"%0\" nie zawiera prawidłowego adresu URL.": "",
    "Lekcja może zawierać tylko materiały opisowe lub materiały video": "",
    "Filtry systemowe": "",
    "Lista ścieżek szkoleniowych": "",
    "Słownik dowolny": "",
    "Szablon nr 3": "",
    "Data wystąpienia": "",
    "Hasło zostało zmienione. Zaloguj się ponownie": "",
    "Indeksowanie strony": "",
    "Blok aktywny": "",
    "REGON": "",
    "Ustawienia Domyślne": "",
    "Link do strony": "",
    "Wzorzec": "",
    "Kreator trasy: ": "",
    "Wstaw dwie kolumny 1/3 oraz 2/3": "",
    "Użytkownik został przypisany do szkolenia.": "",
    "Ten użytkownik nie posiada jeszcze przypisanych miast.": "",
    "Szukaj...": "",
    "Nie udało się usunąć szablonu": "",
    "Nazwa chatu": "",
    "Data i czas": "",
    "Nazwa autora": "",
    "Serial telewizyjny": "",
    "Dodanie wpisu": "",
    "Batch": "",
    "Zapisz zmiany": "",
    "Ta nieruchomość już posiada takie wyposażenie.": "",
    "Ustawienia dostępności": "",
    "Nie podano odpowiedzi dla ostatniego elementu.": "",
    "Wersja utowrzona przez": "",
    "Mapa": "",
    "Magazyn": "",
    "Lekcja została dodana.": "",
    "Włącz system powiadomień": "",
    "Kategoria wyszukiwanego matariału": "",
    "Szukaj": ""
  },
  "en-US": {
    "Szkolenie rozpoczyna się": "Training begins",
    "Miasta": "Cities",
    "Zacznij pisać aby wyszukać inspektora...": "Start typing to search for an inspector...",
    "Przełącznik": "Switcher",
    "Token": "Token",
    "Tekst": "Text",
    "Pole \"Drugie imię\" jest wymagane.": "''Middle name'' is required.",
    "Dodaj nieruchomość do trasy": "",
    "Dodaj kafelek": "Add tile",
    "Pokaż więcej": "Show more",
    "NIE": "NO",
    "Avantio ID": "Avantio ID",
    "Nie udało się dodać nieruchomość do trasy": "",
    "Firma domyślna": "Default company",
    "Data utworzenia aranżacji": "Arrangement creation date",
    "Ilość wyników do pobrania": "Number of results to download",
    "Usuń blok z sekcji": "Remove a block from a section",
    "Peticion disponibilidad": "Peticion disponibilidad",
    "Eksportuj do PDF": "Export to PDF",
    "Nie do odzyskania": "Unrecoverable",
    "BIC/Swift": "BIC/Swift",
    "Link został dodany.": "Link has been added.",
    "Ilość gości": "Number of guests",
    "Nieruchomość została usunięta.": "The property has been removed.",
    "Standard 1.2": "Standard 1.2",
    "Niewidoczne": "Not visible",
    "Brak dostępnych tras": "No routes available",
    "Informacje Podstawowe": "Basic information",
    "Miejsce logowania": "Sign in place",
    "Dodaj zastępstwo": "Add a substitution",
    "Liczba szkoleń": "Number of trainings",
    "Paczka została usunięta.": "The package has been removed.",
    "Edycja witryny": "Edit site",
    "Potwierdzenie publikacji": "Publication confirmation",
    "Informacje podstawowe": "Basic information",
    "Powiększ": "Zoom in",
    "Litery": "Letters",
    "Przypisana do (Imię i Nazwisko)": "Assigned to (Name and Surname)",
    "Adres url do pliku wideo": "Url to video file",
    "Akcje": "Actions",
    "Klienci": "Clients",
    "Proszę obciążyć gościa": "Please charge the guest",
    "Do akceptacji": "To be accepted",
    "Liczba wyświetlanych najnowszych elementów": "Number of recent elements displayed",
    "Koordynator Główny": "",
    "Strona nadrzędna": "Parent page",
    "Godziny": "Hours",
    "Miasto zostało dodane.": "The city has been added.",
    "Element opublikowany": "Published element",
    "Nazwa witryny": "Site name",
    "Osoba zastępowana": "Person being substituted",
    "Przesuń do góry": "Move up",
    "Certyfikat został usunięty.": "The certificate has been removed.",
    "Co godzinę": "Every hour",
    "Usuń z zespołu": "Remove from team",
    "Wybierz autora": "Choose an author",
    "Wydział zadania": "",
    "Ilość nocy": "Number of nights",
    "Zaznacz wszystkie": "Select all",
    "Rozliczenia pracowników": "Empoyee settlements",
    "Ścieżka szkoleniowa została dodana.": "The learning path has been added.",
    "Akceptuj": "Accept",
    "Określa z której strony do ścieżki mają być dodawane kolejne pozycje.": "Specifies where you want to add additional items to the path.",
    "Klient został usunięty.": "The client has been deleted.",
    "Lekcja SCORM została dodana.": "The SCORM lesson has been added.",
    "Wyzeruj licznik": "Reset counter",
    "Wybierz kierowce": "Choose a driver",
    "Zapomniałeś hasła?": "Forgot your password?",
    "Dane dostępu": "",
    "Włącz monitorowanie aktywności": "Turn on activity monitoring",
    "Wstaw dwie kolumny 2/3 oraz 1/3": "Insert two columns 2/3 and 1/3",
    "Dodawanie checklisty": "Add a checklist",
    "Średnie wynagrodzenie pracownika": "The average salary of an employee",
    "Data wykonania do": "Execution date to",
    "Nie znaleziono żadnych plików": "No files found",
    "Przyjazd": "Arrival",
    "Nie udało się pobrać danych o rolach zalogowanego użytkownika": "",
    "Zacznij pisać numer aby wyszukać źródło rezerwacji...": "",
    "Konfiguracja bloku została zapisana.": "The block configuration has been saved.",
    "Zdarzenie": "Event",
    "Baza danych": "Database",
    "Wstaw szablon informacyjny ze zdjęciem": "Insert information template with photo",
    "Zacznij pisać aby wyszukać region...": "Start typing to search for a region...",
    "Niestandardowy adres strony": "Non-standard page address",
    "Liczba wyświetlanych elementów": "Number of elements displayed",
    "Wyrażenie regularne": "Regular expression",
    "Pilne": "Important",
    "Właściciele": "Owners",
    "Przeciągnij i upuść aby posortować pliki": "Drag and drop to sort files",
    "Dział został zaktualizowany.": "The department has been updated.",
    "Slajdy": "Slides",
    "Edycja checklisty": "Edit checklist",
    "Gotowe": "Ready",
    "Zacznij pisać numer aby wyszukać rezerwację...": "",
    "Ten użytkownik nie posiada jeszcze przypisanych dzielnic.": "",
    "Typ materiału": "Material type",
    "Nazwa Firmy": "Company name",
    "Adres e-mail potwierdzony": "The email address has been confirmed",
    "Dodaj nieruchomość": "Add property",
    "Dzielnica nieruchomości": "",
    "Urlop został zaktualizowany.": "Absence has been updated.",
    "Aranżacja utowrzona przez": "Arranged by",
    "Wprowadzona wartość nie jest poprawna": "The value you entered is not valid",
    "Opracowane": "Edited",
    "Zastępstwo zostało dodane.": "The substitution has been added.",
    "Data nieobecności od": "Date of absence from",
    "Osoba zastępująca": "Substitute",
    "Niski": "Low",
    "Imię klienta": "Customer name",
    "Typ Firmy": "Company Type",
    "Liczba": "Number",
    "Program został zaktualizowany.": "The program has been updated.",
    "Czas trwania blokady": "Account blocking time",
    "Dodaj obrazek": "Add image",
    "Nie przypisano": "Not assigned",
    "Nazwa szablonu": "Template name",
    "Sprawdzić": "Check",
    "Przywróć użytkownika": "Restore user",
    "Pole wymagane": "Field required",
    "Rezerwacje": "Reservations",
    "Region został dodany.": "The region has been added.",
    "Oznacz wszystkie jako przeczytane": "",
    "Usuń publikację": "Delete a publication",
    "Azure AD": "Azure AD",
    "Ścieżka nawigacyjna": "Navigation path",
    "Formularz został dodany.": "The form has been added.",
    "Pokaż szczegóły": "Show details",
    "Strona została usunięta.": "The page has been deleted.",
    "Adres URL": "URL address",
    "Możliwość modyfikacji tego działu jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "The possibility of modifying this department is limited. Inactive fields are synchronized with the external database %0.",
    "Wiadomość została ukryta.": "The message has been hidden.",
    "Edytuj temat": "Edit topic",
    "Dodanie nieruchomości": "Add a property",
    "Publikuj od": "Publish from",
    "Brak zadań": "No task",
    "Co drugi miesiąc": "Every other month",
    "Wpis nieprzeczytany": "Unread post",
    "Opis źródła": "Source description",
    "Tag został zaktualizowany.": "Tag has been updated.",
    "Edycja wynagrodzenia za posprzątane obiekty dla:": "Edit salary for cleaned properties for:",
    "Wtorek": "Tuesday",
    "Właściciel aktywny": "Active owner",
    "Pokaż tytuł formularza": "Show form title",
    "Nie możesz edytować tego punktu. Został on już oznaczony jako odwiedzony": "You cannot edit this point. It has already been marked as visited",
    "Został przywrócony oryginalny harmonogram": "The original schedule has been restored",
    "Słowa kluczowe": "Keywords",
    "Zastępuje osobę": "Substituting",
    "Wybierz użytkownika": "Select a user",
    "Na pewno usunąć wybrane elementy?": "Are you sure to delete the selected elements?",
    "${ex.data.errors.symbol[0]}": "${ex.data.errors.symbol[0]}",
    "Zdecydowanie tak": "Definitely yes",
    "Formularze": "Forms",
    "Element został przywrócony.": "The element has been restored.",
    "Dodano użytkownika do czatu": "",
    "W zespole od %0": "In the team from %0",
    "Stopka": "Footer",
    "Co 3 godziny": "Every 3 hours",
    "Dodanie kategorii": "Add category",
    "Komunikat": "Message",
    "Wpis domyślny": "Default entry",
    "Dane wydziału zostały zaktualizowane.": "The department's data has been updated.",
    "Dodaj stwierdzenie": "Add statement",
    "Przejdź do zadania z notatki": "Go to a task from a note",
    "Usuń odpowiedź": "Delete answer",
    "Dodaj zestaw": "Add set",
    "Typ zadania": "Task type",
    "Folder został usunięty.": "The folder has been deleted.",
    "Zastępstwo wygaśnie za minutę.": "The substitution will expire in a minute.",
    "Numer sesji": "Session number",
    "Zespół został przypisany do szkolenia.": "The team was assigned to the training.",
    "reset": "",
    "Edycja regionu": "Edit a region",
    "Pracownik niedostępny od": "Employee absent from",
    "Obciążenie Właściciela": "Charge Homeowner",
    "Przypisane miasta": "Assigned cities",
    "W organizacji nie ma jeszcze żadnych działów.": "There are no sections in the organization yet.",
    "Tylko priorytetowe": "Priority only",
    "Zobacz trasę": "See the route",
    "Kategoria została usunięta.": "The category has been removed.",
    "Wpis został zaktualizowany.": "The post has been updated.",
    "Nazwa roli": "Role name",
    "Stawka domyślna": "Default rate",
    "Więcej...": "More…",
    "Typ slajdu": "Slide type",
    "Dodaj paczkę SCORM": "Add SCORM package",
    "Radio": "Radio",
    "Brak przypisanych tagów.": "",
    "Ilość": "Quantity",
    "Checkbox": "Checkbox",
    "Ten region nie posiada jeszcze przypisanych miast.": "This region does not yet have assigned cities.",
    "Potwierdź publikację wersji": "Confirm version publication",
    "Pralnia": "Laundry",
    "Nie udało się pobrać listy bloków": "Failed to download block list",
    "Nazwa przycisku": "Button name",
    "Zaczyna sie od": "It starts with",
    "Data modyfikacji": "Modification date",
    "Usuń wiersz": "Delete row",
    "Prawda-Fałsz": "True-False",
    "Opis sekcji": "Section description",
    "Domyślne ustawienie dla witryny": "Default site setting",
    "Nie pokazuj krótkiego opisu strony w wynikach wyszukiwania (NoSnippet)": "Do not show a short description of the page in search results (NoSnippet)",
    "Zawsze": "Always",
    "Imie i nazwisko": "Name and surname",
    "Zadanie zostało ukończone.": "The task is complete.",
    "Utworzone": "Created",
    "Edycja tagu nieruchomości": "Edit property tag",
    "Pole może zawierać jedynie cyfry.": "Field can only contain digits.",
    "Opiekun ścieżki": "Path supervisor",
    "Nazwa aranżacji": "Arrangement name",
    "Numer telefonu: ": "Phone number: ",
    "Wysłano kolejne powiadomienie": "Another notification has been sent",
    "Nie udało się pobrać listy stron": "Failed to download the pages list ",
    "Zmień hasło": "Change password",
    "Modyfikacja": "Modification",
    "Wysyłaj tym kanałem": "Send through this channel",
    "Region został zaktualizowany.": "The region has been updated.",
    "Usuń filtr": "Remove filter",
    "Data wylogowania": "Logout date",
    "Strona lekcji SCORM opublikowana": "SCORM lesson page published",
    "Wykonaj": "Execute",
    "Ścieżka": "Path",
    "Typ obiektu": "Object type",
    "Dział aktywny": "Active department",
    "Edytuj dział": "Edit department",
    "Bardzo niezadowolony": "Very unsatisfied",
    "Zacznij pisać aby wyszukać...": "",
    "Co 20 minut": "Every 20 minutes",
    "Dodane przeze mnie": "Added by me",
    "Zmień ustawienia": "Change settings",
    "Under request": "Under request",
    "Certyfikat nie jest opublikowany": "Certificate is not published",
    "Stawka mieszana została zaktualizowana.": "Mixed rate has been updated.",
    "Nie udało się pobrać listy zastępstw": "Failed to download the substitution list",
    "Dodaj zdjęcia do wiadomości": "",
    "Wybrane pliki: {0}": "Selected files: {0}",
    "Przetestuj ustawienia poczty": "Test your mail settings",
    "Strona %0": "Page %0",
    "Identyfikator zadania": "Task ID",
    "Książka": "Book",
    "Firma sprzątająca": "Cleaning company",
    "Komentarz": "Comments",
    "Odznacz wszystkie": "Unselect all",
    "Dodanie poradnika": "Add guide",
    "Potwierdzenie zmiany statusu": "Confirmation of status change",
    "Logo firmy": "Company logo",
    "zacznij pisać aby wyszukać slajder...": "start typing to search for a slider...",
    "Skopiuj szkolenie": "Copy training",
    "Osoba przypisana do zadania": "",
    "Wybranie strony jest wymagane": "Selecting a page is required",
    "Musisz wybrać obiekt na mapie": "",
    "Zobacz szczegóły sesji użytkownika": "View user session details",
    "Liczba slajdów widoczna dla użytkownika nie może być większa od liczby slajdów dodanych w panelu.": "The number of slides visible to the user cannot be greater than the number of slides added in the panel.",
    "Adres": "Address",
    "Zacznij pisać aby wyszukać kierowcę...": "Start typing to search for a driver...",
    "Październik": "October",
    "Wybierz artykuł": "Select an article",
    "Do sprawdzenia - przeniesione": "To be checked - moved",
    "Lista regulaminów": "Regulation list",
    "Kategorie szkoleń": "Training categories",
    "Dodaj slajd": "Add slide",
    "Uprawnienia": "Permissions",
    "Wprowadzona wartość jest nieprawidłowa": "The value you entered is invalid",
    "Edycja lekcji": "Editing lesson",
    "Hasło musi zawierać co najmniej jeden znak specjalny np.: ~ @ # $ % ^ & *": "The password must contain at least one special character, e.g.: ~ @ $ % ^ & *",
    "Inwentaryzacje": "Inventories",
    "Wysłane": "Sent",
    "Dodaj do trasy": "Add to route",
    "Zresetuj hasło": "Reset password",
    "Od ilości posprzątanych obiektów": "By the number of cleaned properties",
    "Nowa data": "New date",
    "pl": "pl",
    "Tagi zadań": "",
    "Średnio": "Moderately",
    "Edytuj": "Edit",
    "Edycja aranżacji": "Edit arrangement",
    "Brak powiązań": "No connection",
    "Zastępstwo": "Substitution",
    "Ponów zadanie": "Retry task",
    "Dodanie zespołu": "Add team",
    "*Koordynator zespołu posiada dostęp do panelu koordynatora": "*The team coordinator has access to the coordinator panel",
    "Dodaj źródło": "Add source",
    "Moje trasy": "My routes",
    "Rozliczone": "Accounted",
    "od": "from",
    "Sesja nr %0": "Session No . %0",
    "Numer aktywnej strony": "Active page number",
    "pokaż więcej...": "show more...",
    "Koszty do zadania": "Costs in the task",
    "Odpowiedzi": "Responses",
    "Wysłano kolejne wezwanie do zapłaty": "Another payment request sent",
    "Pokaż": "",
    "Czas na slajd": "Time per slide",
    "Nie udało się pobrać listy serwerów baz danych": "Failed to retrieve the database server list",
    "Zdjęcie główne": "Main photo",
    "Historia logowania": "Login history",
    "Nie mam zdania": "I have no opinion",
    "Płyta z muzyką": "Music CD",
    "Najnowsze": "Recent",
    "Log z aktywności": "Activity log",
    "Lekcja SCORM ukryta": "SCORM Lesson Hidden",
    "Aby dodawać zespół do szkolenia musisz najpierw zapisać formularz.": "To add a team to the training, you have to save the form.",
    "Pokaż przycisk ``Wyślij``": "Show ''Send'' button",
    "Dane nieruchomości": "Property data",
    "Dodanie certyfikatu": "Add certificate",
    "Filtruj": "Filter",
    "Strona opublikowana": "Published page",
    "Konfiguruj stronę": "Configure page",
    "Nie wybrano strony": "No page has been selected",
    "Koordynator*": "Coordinator*",
    "Szef regionu": "Head of the Region",
    "Ilość kosztów": "Costs amount",
    "Nazwa": "Name",
    "Ostatnia wiadomość - data": "",
    "Kwota zniszczeń": "Amount of damage",
    "Opis certyfikatu": "Certificate description",
    "Adres domenowy": "Domain address",
    "Nazwa szkolenia": "Training name",
    "Do ścieżki szkoleniowej nie przypisano jeszcze żadnych zespołów.": "No assigned teams to the learning path.",
    "5": "5",
    "Zakończone": "Completed",
    "Dodaj ścieżkę": "Add path",
    "Dodaj lekcję Wideo": "Add Video lesson",
    "Urlop": "Paid leave",
    "Projekt": "Project",
    "Zmiana rozmiaru obrazu": "Resize an image",
    "Wykonane": "Executed",
    "Jeśli pole jest puste to wstawiana automatycznie.": "If the field is empty, the value is inserted automatically.",
    "Dokument nie ma ustawionej daty publikacji.": "The document does not have a publication date set.",
    "Regulaminy": "Regulations",
    "Nie pokazuj linku do archiwum strony (NoArchive)": "Do not show a link to the archive of the page (NoArchive)",
    "Dodanie zestawu regulaminów": "Add set of regulations",
    "Ukryj materiał": "Hide material",
    "Pre-booking": "Pre-booking",
    "%0 znaków": "%0 characters",
    "Statystyki pracownicze": "Employee statistics",
    "Zawiera": "Contains",
    "Suma wynagrodzeń": "Sum of salaries",
    "Do kogo chcesz napisać?": "Who do you want to write to?",
    "Zmiana hasła": "Password change",
    "Nie podano nazwy poprzedniej kategorii.": "No name is given for the previous category.",
    "Porównania": "Comparison",
    "Sekcje dla gości": "Guest sections",
    "Usuń obrazek": "Delete image",
    "Rok": "Year",
    "Status formularza został zmieniony.": "The status of the form has been changed.",
    "Nie udało się pobrać listy powiadomień": "Failed to download notification list",
    "Wpis przeczytany": "Read post",
    "Zasięg": "Range",
    "Wybierz punkt": "",
    "Nie udało się pobrać danych odnośnie rezerwacji i zadań": "",
    "Dodanie artykułu": "Add article",
    "Wynagrodzenia Konserwatorzy": "Maintenance salary",
    "Ostatnia strona": "Last page",
    "Liczba dziesiętna": "Decimal",
    "Edycja stawki godzinowej dla:": "Edit the hourly rate for:",
    "Imię lub nazwisko właściciela": "",
    "Zespół został dodany.": "The team has been added.",
    "Tak": "Yes",
    "Zakończone przez": "Completed by",
    "Szablony": "Templates",
    "Wybierz plik": "Select a file",
    "Wpisz nazwę dzielnicy...": "",
    "Dodaj lekcję": "Add lesson",
    "URL": "URL",
    "Właściciel został zaktualizowany.": "The owner has been updated.",
    "Witryna internetowa": "Website",
    "Konserwacje": "Maintenance",
    "Za przepracowane godziny": "For hours worked",
    "Element został usunięty.": "The element has been deleted.",
    "Udało się zaktualizować zadania!": "",
    "Podstawowe operacje warunkowe": "Basic conditional operations",
    "Organizacja": "Organization",
    "Dashboard": "Dashboard",
    "Utworzył": "Created",
    "Nr telefonu": "Phone number",
    "TAK": "YES",
    "Wymagane jest wybranie sekcji": "A section must be selected",
    "Nie masz uprawnień do panelu administracyjnego.": "You do not have permission to access the administration panel.",
    "Dodaj formularz": "Add form",
    "Wszystkie obiekty:": "All objects:",
    "FavIcona": "FavIcona",
    "Żaden kanał komunikacji nie został włączony w ustawieniach powiadomień.": "No communication channel has been enabled in the notification settings.",
    "Zacznij pisać aby wyszukać nieruchomość...": "Start typing to search for a property...",
    "Administracja - Kraje": "Administration - Countries",
    "Skala 1-5": "Scale 1-5",
    "Zastępstwo wygaśnie za 10 minut.": "The substitution will expire in 10 minutes.",
    "Edycja Notatki": "Edit a note",
    "Data zakońćzenia": "End date",
    "zacznij pisać aby wyszukać paczkę SCORM...": "start typing to search for a SCORM package...",
    "Numer inwentaryzacji": "Inventory number",
    "Zobacz zdjęcia": "See photos",
    "Lekcja wideo została zaktualizowana.": "The video lesson has been updated.",
    "Lista certyfikatów": "List of certificates",
    "Edycja trasy": "Edit a route",
    "Nie zdefiniowano żadnych ról.": "No roles are defined.",
    "Słowa oddzielone przecinkami.": "Words separated by commas.",
    "Wybierz sekcję": "Select a section",
    "Dodaj szkolenie": "Add training",
    "To miasto jest już przypisane do użytkownika.": "This city is already assigned to the user.",
    "Usuń zestaw": "Delete set",
    "Wynagrodzenie stałe miesięczne": "Fixed monthly salary",
    "Dane wydziału zostały dodane.": "The department's data has been added.",
    "Tekst do uzupełnienia": "Text to be completed",
    "Zakończ trasę": "End the route",
    "Otwórz w nowym oknie": "Open in a new window",
    "Tytuł kafelka": "Tile title",
    "Aplikacja nie ma uprawnień do wysyłania powiadomień PUSH": "App has no permission to send PUSH notification",
    "Typ": "Type",
    "Kwiecień": "April",
    "Archiwum": "Archive",
    "Wstecz": "Back",
    "Porównanie": "Comparison",
    "kanał wymagany": "required channel",
    "Pierwsza strona": "First page",
    "Lista punktów": "List of points",
    "Rozmiar": "Size",
    "Kategoria slajdu": "Slide category",
    "Usuń spacje": "Remove spaces",
    "Nie udało się pobrać listy działów": "Failed to download the departments list",
    "Nazwa kolumny z opisem": "Name of column with description",
    "Data zmiany statusu do": "Date of status change to",
    "Dodanie użytkownika": "Add user",
    "To szkolenie znajduje się już w ścieżce szkoleń": "This training is already in the training path",
    "Nazwa lekcji": "Lesson name",
    "Nazwa nieruchmości": "Property name",
    "Usuń materiał z testu": "Remove material from the test",
    "Liczba zgłoszonych problemów": "Number of issues reported",
    "Nie udało się zapisać notatki": "",
    "Strona szkolenia ukryta": "Training page hidden",
    "Szczegóły zdarzenia": "Event details",
    "Drugie imię": "Middle name",
    "Dwie kolumny": "Two columns",
    "Rezerwacja": "Reservation",
    "Zadanie zostało wyłączone": "The task has been disabled",
    "Zacznij pisać aby wyszukać szablon checklisty...": "Start typing to search for a checklist template...",
    "Wyczyść sesję": "Clear session",
    "Dodanie firmy": "Add a company",
    "Podstawowe operacje na datach": "Basic date operations",
    "Neutralne": "Neutral",
    "12 godzin": "12 hours",
    "Nazwa bazy danych": "Database name",
    "Wytnij": "Cut",
    "Ulica": "Street",
    "Element został zaktualizowany.": "The element has been updated.",
    "(AirBnb) Wysłano żądanie do portalu": "(AirBnb) A request has been sent to the portal",
    "Obraz został wycięty.": "The image has been cut out.",
    "Apple iPad": "Apple iPad",
    "Co pół roku": "Semi-annually",
    "Dodanie zadania": "Add a task",
    "3": "3",
    "Wewnętrznie": "Internally",
    "Data zmiany statusu od": "Date of status change from",
    "Dodaj zasób": "Add resource",
    "Źle": "Bad",
    "Statystyki użytkowników": "User statistics",
    "Przypisane miasta do regionu:": "Cities assigned to the region:",
    "Nazwa domyślnej checklisty": "Name of the default checklist",
    "Witryna została dodana.": "The site has been added.",
    "Rejestr zmian": "Changelog",
    "Zobacz szczegóły zdarzenia": "View event details",
    "Usuń tag": "Remove tag",
    "Wprowadzona wartość jest za krótka": "The value you entered is too short",
    "Parametry, wymagane do wykonania zapytania:": "Parameters required to execute the request:",
    "Publikacja została dodana.": "The publication has been added.",
    "Pionowo": "Vertically",
    "Treść": "Content",
    "Dostępne wersje językowe.": "Available language versions.",
    "Wersja językowa": "Language version",
    "Komunikat błędu": "Error message",
    "Potwierdź wyczyszczenie logów": "Confirm clearing logs",
    "Slajd został dodany.": "The slide has been added.",
    "Laptop 15,6\"": "Laptop 15.6\"",
    "Brak tagów": "",
    "Klient aktywny": "Active client",
    "Trzy kolumny": "Three columns",
    "Certyfikat jest opublikowany": "The certificate is published",
    "Wprowadzona wartość jest za wysoka": "The value you entered is too high",
    "Nazwa slajdu": "Slide name",
    "Dodanie szkolenia": "Add training",
    "Włączenie protokołu SSL dla tej witryny": "Enable SSL for this site",
    "Nazwa testu": "Test name",
    "Dostęp do szkoleń:": "Access to training:",
    "Wyjazd": "Check-out",
    "Pasek postępu": "Progress bar",
    "Oraz nowe hasło": "And a new password",
    "Nie udało się pobrać listy filtrów": "Failed to download filter list",
    "Kwota wynagrodzenia PLN brutto": "Gross salary amount in PLN",
    "Data opuszczenia zespołu": "Date of leaving the team",
    "Konfiguracja": "Settings",
    "Przesyłanie zdjęcia nie powiodło się": "",
    "Nie wybrano żadnej kategorii.": "No category has been selected.",
    "Kolejność punktów trasy została zaktualizowana": "",
    "Obrazek wyróżniający": "Featured image",
    "Kod Bing Webmaster": "Bing Webmaster Code",
    "Włączenie obsługi cacheu dla witryny": "Enable cache support for the website",
    "Standard nieznany": "Standard unknown",
    "Właściciel został usunięty": "The owner has been removed",
    "Nieważne": "Not important",
    "Zakończone: ${$filters.datetime(route.dateCompletedUtc)}": "Completed: ${$filters.datetime(route.dateCompletedUtc)}",
    "Nazwa katalogu lub pliku": "Directory or file name",
    "Email": "E-mail",
    "Logi aktywności zostały wyczyszczone": "Activity logs cleared",
    "Lista koordynatorów": "Coordinators list",
    "Edytuj lekcje wideo": "Edit video lesson",
    "usuń notatkę": "delete note",
    "Średnie wynagrodzenie koordynatora": "Average coordinator salary",
    "Miesiąc edycji wynagrodzenia": "Month of salary edition",
    "Wybierz regulamin": "Select regulations",
    "Czas realizacji": "Delivery time",
    "Tło certyfikatu": "Certificate background",
    "Dodaj element": "Add element",
    "Wybrana dzielnica": "",
    "Edycja strony": "Edit page",
    "Wyświetl popup z kontynuacją lekcji": "View the popup with the continuation of the lesson",
    "Bardzo dobrze": "Very good",
    "Adres 2": "Address 2",
    "Zablokowany": "Blocked",
    "Tło strona 2": "Background page 2",
    "Podstawowe": "Basic",
    "Typ strony": "Page type",
    "Certyfikaty": "Certificates",
    " Elementy do uzupełnienia w tekście zamieszczamy pomiędzy zdefiniowanymi znacznikami %$dowolna_nazwa$%.": "Elements to be completed are placed between the defined tags %$dowolna_name$%.",
    "Wiadomość": "Message",
    "Przycisk": "Button",
    "Checklisty": "Checklists",
    "Dodanie etykiety": "Add taf",
    "Stawka za apartamenty została zaktualizowana.": "The property pay rate has been updated.",
    "Nie masz wymaganych uprawnień do wykonania wybranej operacji.": "You do not have the required permissions to perform the selected operation.",
    "Co 2 godziny": "Every 2 hours",
    "Filtry map": "",
    "Brak strony nadrzędnej": "No parent page",
    "Zmień daty publikacji": "Change publication dates",
    "Notatka dla": "",
    "Zastępstwa": "Substitutions",
    "ID": "ID",
    "przejdź do komentarzy": "go to comments",
    "Ustawienia ogólne": "General settings",
    "Teraz": "Now",
    "Ilość znaków": "Number of characters",
    "Suma Wynagrodzeń": "Sum of salaries",
    "Powtórz hasło": "Repeat password",
    "Materiał opisowy": "Descriptive material",
    "W przyszłości": "In the future",
    "Dodaj pytanie": "Add a question",
    "Szablon graficzny": "Graphic template",
    "Nazwa regionu": "Region name",
    "Opis kategorii": "Category description",
    "Dodaj inwentaryzacje": "Add inventories",
    "Pytanie 3": "Question 3",
    "Ogólne informacje": "General information",
    "Brak stron": "No pages",
    "Włączone (Follow)": "Enabled (Follow)",
    "Dodanie ankiety": "Add surveys",
    "Cofam": "",
    "Nazwa tagu": "Tag name",
    "co 30 dni": "every 30 days",
    "Zasoby": "Resources",
    "Hasło (Klucz ukryty)": "Password (Hidden Key)",
    "Witryna tylko dla zalogowanych użytkowników": "Site for logged-in users only",
    "Treść certyfikatu": "Certificate content",
    "Procent obciążeń": "Percentage of loads",
    "Ilość wierszy": "Number of rows",
    "Rola aktywna": "Active role",
    "Notatka została zaktualizowana.": "The note has been updated.",
    "Porównywana wersja": "Compared version",
    "Kod pocztowy": "Postal code",
    "Edycja regulaminu": "Edit regulations",
    "Artykuł": "Article",
    "Domyślna firma": "Default company",
    "Przyrostek": "Suffix",
    "Publikuj do": "Publish to",
    "Strona główna": "Homepage",
    "Rozmiar zdjęcia został zmieniony.": "The image has been resized.",
    "Pasek": "Bar",
    "Wpisz nazwę miasta...": "Enter the name of the city...",
    "To zadanie nie posiada jeszcze przypisanych użytkowników.": "This task does not yet have users assigned.",
    "Kolejka": "Queue",
    "W każdy dzień tygodnia": "Every day of the week",
    "Usuń": "Delete",
    "wybierz...": "select...",
    "Nieobecność": "Absence",
    "Edycja testu": "Edit test",
    "Nowe hasło": "New password",
    "Nazwa trasy": "Route name",
    "Moje konto": "My account",
    "Ładuję...": "Loading...",
    "Dodaj rolę": "Add a role",
    "Nazwa strony": "Page name",
    "Wybór": "Choice",
    "Dodanie ścieżki szkoleniowej": "Add learning path",
    "Test ukryty": "Hidden test",
    "Typ zestawu": "Set type",
    "Sekcje i bloki": "Sections and blocks",
    "Zdecydowanie się nie zgadzam": "I strongly disagree",
    "Typ źródła": "Source type",
    "Obiekty": "Apartments",
    "Srednie wynagrodzenie pracowników": "Average salary of employees",
    "Katalog linków": "Links directory",
    "Nazwa użytkownika": "User name",
    "Logger": "Logger",
    "Dostępne bloki": "Available blocks",
    "Administracja - Stanowiska pracy": "Administration - Positions",
    "Niedziela": "Sunday",
    "Wczutuję...": "",
    "Tag został dodany.": "Tag has been added.",
    "Edycja certyfikatu": "Edit certificate",
    "Zastosuj": "Apply",
    "Brak aktywnego filtru": "No active filter",
    "Sekcje dla zalogowanych": "Sections for logged in",
    "Usuń kafelek": "Remove tile",
    "Wielokrotny wybór": "Multiple choice",
    "Notatka utworzona z zadania": "Note created from task",
    "Typ dodanego kosztu": "The type of added cost",
    "Nieaktywna": "Inactive",
    "Dodaj lekcję SCORM": "Add SCORM lesson",
    "Metadane": "Metadata",
    "Przenieś wybrane": "Move selected",
    "Włączone": "Enabled",
    "Lista efektywnych uprawnień użytkownika wynikających z uprawnień indywidualnych oraz ról.": "List of effective user permissions resulting from individual permissions and roles.",
    "Wyposażenie zostało zaktualizowane.": "Amenities has been updated.",
    "Uwzględniaj Dzielnice": "",
    "Odpowiedź 3": "Answer 3",
    "UWAGA! Nieruchomość jest już przypisana od innego właściciela": "ATTENTION! The property is already assigned to another owner",
    "Wznów zadanie": "",
    "Dostępne czaty:": "Available chats:",
    "Anuluj edycję": "Undo",
    "${PriorityName(PriorityEnum[this.task.priority])}": "${PriorityName(PriorityEnum[this.task.priority])}",
    "Pokaż przycisk": "",
    "Zablokuj użytkownika": "Block user",
    "Dane formularza są przechowywane w obiekcie Entry.": "The form data is stored in the Entry object.",
    "Blokowanie użytkowników szkoleń": "Block training users",
    "Typ wynagrodzenia": "Salary type",
    "Pokaż numery stron": "Show page numbers",
    "Tekst zastępczy": "Substitute text",
    "Data realizacji": "Execution date",
    "Do ścieżki szkoleniowej nie przypisano jeszcze żadnych użytkowników.": "No assigned users to the learning path.",
    "Aby dodawać zespół do ścieżki szkoleniowej musisz najpierw zapisać formularz.": "To add a team to the learning path, you have to save the form.",
    "Grupa": "Group",
    "Lista linków": "Links list",
    "Nazwa pliku została zmieniona.": "The file has been renamed.",
    "Historia zmian": "History of changes",
    "Koszt został dodany.": "The cost has been added.",
    "Hasło": "Password",
    "Automatyczny": "Automatic",
    "Nieruchomość jest już przypisana": "Property is already assigned",
    "Nie możesz wpływać na kolejność punktów już oznaczonych jako odwiedzone": "",
    "Zastępstwo wygasło.": "The substitution has expired.",
    "Edycja firmy": "Edit company",
    "Elementy inwentaryzacji": "Inventory elements",
    "Nie możesz zapisać zmian, gdyż liczba elementów nie może być mniejsza niż ustawiona liczba wymaganych elementów w zakładce ustawienia testu": "You cannot save changes because the number of elements cannot indicate less than the set number of required elements in the test settings tab",
    "Nie podano wymaganej ilości znaków: %0.": "The required number of characters was not met: %0.",
    "Ogólne": "General",
    "Nie udało się pobrać listy wydziałów": "Failed to download the list of departments",
    "Data utworzenia od": "Creation date from",
    "Łączenie treści": "Combining content",
    "Wysłane wezwanie do zapłaty": "Payment request sent",
    "Wybór koloru jest wymagany": "Color selection is required",
    "Stwierdzenia": "Statement",
    "Blok został usunięty.": "The block has been removed.",
    "Nazwa Kategorii": "Category Name",
    "1 godzina": "1 hour",
    "Pole może zawierać jedynie litery i cyfry.": "This field can contain only letters and digits.",
    "Wszystkie próby": "All attempts",
    "Slajder został zaktualizowany.": "The slider has been updated.",
    "Owner Booking": "Owner Booking",
    "Pozostałe ustawienia": "Other settings",
    "3 godziny": "3 hours",
    "Widoczny obszar": "Visible area",
    "Zacznij pisać aby wyszukać dzielnicę...": "",
    "Dowolny": "Any",
    "Miejscowość": "Town",
    "Wybrane miasto": "Selected city",
    "zacznij pisać aby wyszukać autora...": "start typing to search for an author...",
    "Aranżacja została zachowana.": "The arrangement has been saved.",
    "W zespole od": "In the team from",
    "Co rok": "Every year",
    "Wybierz inspektora": "Select an inspector",
    "Nieaktywni": "Deactivated",
    "Co 2 minuty": "Every 2 minutes",
    "Angielski": "English",
    "Obsługa Klienta (BOK)": "Customer Service (BOK)",
    "Dodanie zastępstwa": "Add replacement",
    "Lista paczek SCORM": "SCORM Packages List",
    "Imię i nazwisko właściciela": "Owner's name and surname",
    "Ten punkt został już odwiedzony": "This place has already been visited",
    "Przesuń na dół": "Move down",
    "Wybrane kategorie": "Selected categories",
    "Zgłoszenie serwisowe": "Support Request",
    "Urlop został usunięty": "Absence has been removed",
    "Wskazuje robotom wyszukiwarek czy mają podążać za linkami.": "It indicates to search engine robots whether to follow the links.",
    "Użytkownicy w tej roli": "Users in this role",
    "Nie pokazuj strony jako źródła grafiki w wynikach wyszukiwania (NoImageIndex)": "Do not show the page as an image source in search results (NoImageIndex)",
    "Dodanie slajdera": "Add slider",
    "Zaakceptowane": "Accepted",
    "Nazwa taga": "Tag name",
    "wybierz szablon": "select a template",
    "Wiadomość testowa została wysłana.": "A test message has been sent.",
    "Nazwa klienta": "Client name",
    "linie": "lines",
    "Wynagrodzenia od ilości posprzątanych obiektów": "Salary based on the number of cleaned properties",
    "Kategorie linków": "Link categories",
    "Data dodania kosztu": "Date the cost was added",
    "Link przycisku": "Button link",
    "Do szkolenia nie przypisano jeszcze żadnych użytkowników.": "No assigned users to the training.",
    "Edytuj dane wydziału": "Edit department data",
    "Wartości": "Values",
    "Załączone zdjęcia": "Attached photos",
    "Wybierz typ powiadomienia aby zobaczyć dostępne klucze.": "Select the notification type to see the available keys.",
    "Osiągnąłeś limit zdjęć. Nie możesz dodać więcej zdjęć": "",
    "Dodanie klienta": "Add client",
    "Nazwa kosztu": "Name of cost",
    "Trasy archiwalne": "Archival routes",
    "Adres strony": "Website address",
    "Ścieżka szkoleniowa": "Learning path",
    "Wideo z biblioteki": "Video from library",
    "Ważność": "Validity",
    "Wyświetl również zarchiwizowane formularze": "Display archived forms too",
    "Migracje": "Migrations",
    "Koszty zadania zostały zaktualizowane.": "The cost of the task has been updated.",
    "Data dodania inwentaryzacji": "Date the inventory was added",
    "Wielokrotnego wyboru": "Multiple choice",
    "Zacznij pisać aby wyszukać właściciela...": "Start typing to search for an owner...",
    "Czaty": "Chats",
    "Zadanie zostało wznowione.": "",
    "Wymagane jest ustawienie minimalnej długości hasła": "Minimum password length is required",
    "Tydzień": "Week",
    "Confirmed": "Confirmed",
    "Miasto": "City",
    "Zdecydowanie nieważne": "Definitely not important",
    "Nie wybrano żadnej strony.": "No page has been selected.",
    "zacznij pisać aby wyszukać artykuł...": "start typing to search for an article...",
    "Szkolenia": "Trainings",
    "Lista dostępnych zmiennych, które można wykorzystać we wszystkich polach tekstowych:": "List of available variables that can be used in all text fields:",
    "Zgłoszenia w apartamencie": "",
    "W trakcie": "In progress",
    "Przejdź do mapy": "Go to map",
    "Moje filtry": "My filters",
    "Synchronizuj": "Sync",
    "Bardzo źle": "Very bad",
    "Zadanie zostało usunięte": "The task has been deleted",
    "Czy zablokować użytkownika i jego wiadomość?": "Do you want to block the user and messages?",
    "Dopasuj do obrazka": "Match image",
    "Przenieś": "Move",
    "Rozpocznij zadanie": "Start a task",
    "Nie można usunąć kategorii do ktorej są przypisane elementy.": "You cannot delete a category to which the elements are assigned.",
    "Zmień status edytowanej wersji": "Change the status of the edited version",
    "Zameldowanie": "",
    "Utwórz raport wyników": "Create a results report",
    "Język": "Language",
    "Nazwa wydziału": "Department name",
    "Dodanie witryny": "Add website",
    "Ostatnia wiadomość - użytkownik": "",
    "Dodaj opis...": "Add a description...",
    "Usuń kategorię": "Delete category",
    "Data wykonania": "Execution date",
    "Nadpisz kwotę dla": "Overwrite the amount for",
    "Wszystkie": "All",
    "Ładuje...": "Loading...",
    "Nazwa koordynatora": "Coordinator name",
    "Wybrane regulaminy: ": "Selected regulations: ",
    "Ikona": "Icon",
    "Wpisz nazwę regionu...": "Enter the name of the region...",
    "Nazwa pokoju": "Room name",
    "Przywróć": "Restore",
    "Anuluj": "Cancel",
    "Opublikowane na wybranej stronie": "Published on the selected page",
    "Kontrolka wyboru (wybór wielokrotny)": "Choice control (multiple choice)",
    "Szczegóły": "Details",
    "Edycja slajdera": "Edit slider",
    "Powiadomienia": "Notifications",
    "Wstaw z prawej strony": "Insert right",
    "Strona ukryta": "Hidden page",
    "Typ pola": "Field type",
    "Stack Trace": "Stack Trace",
    "Filtr prywatny": "Private filter",
    "Podpis": "Signature",
    "Wybrane elementy zostały przeniesione.": "The selected items have been moved.",
    "Edytuj datę dostępu": "Edit access date",
    "Informacja o ciasteczkach": "Information about cookies",
    "Repozytorium plików": "File repository",
    "Niemiecki": "German",
    "Administracja - Miasta": "Administration - Cities",
    "Wyświetl materiały": "View materials",
    "Not Available": "Not Available",
    "Dodaj kategorię": "Add category",
    "Rola": "Role",
    "Nie możesz rozpocząć obsługi tego zadania": "You can't start this task",
    "Punkty": "Points",
    "Resetuj": "Reset",
    "brak danych": "no data",
    "Dziś": "Today",
    "Id": "Id",
    "Zadowolony": "Satisfied",
    "Lista rezerwacji": "Booking list",
    "Odpowiedź": "Answer",
    "Kolejne pozycje z prawej strony": "Next items on the right",
    "Katalog został utworzony.": "The directory has been created.",
    "edycja": "edit",
    "Statystyki regionalne - regiony": "Regional statistics - regions",
    "Dodaj opłaconą kwotę": "Add a paid amount",
    "Nazwa źródła danych": "Data source name",
    "Edytuj wpis": "Edit post",
    "Imie i nazwisko osoby, która dodawała koszty": "Name of the person who added costs",
    "Telefon": "Phone",
    "Marzec": "March",
    "Serwer dostępny": "Server available",
    "Miniaturki": "Thumbnails",
    "Dashboard szkolenia": "Training dashboard",
    "Stwórz zadanie z tej rezerwacji": "",
    "Przeniesienie wybranych elementów": "Transfer selected items",
    "Zmiana motywu": "Theme change",
    "Imie i nazwisko: ": "First and last name: ",
    "Data zakończenia": "End date",
    "Edycja formularza": "Edit form",
    "Opublikuj materiał": "Publish material",
    "Klient został dodany.": "The client has been added.",
    "Szkolenie zostało usunięte.": "The training has been removed.",
    "Wideo zewnętrzne (URL)": "External video (URL)",
    "Treści pytań są wymagane": "Questions are required",
    "Brak odpowiedzi": "No response",
    "Obojętny": "Indifferent",
    "W momencie tworzenia kosztu": "At cost creation time",
    "Polski": "Polish",
    "Satysfakcja": "Satisfaction",
    "Godzina zameldowania": "Check-in time",
    "Informacje dodatkowe": "Additional information",
    "Niezadowolony": "Unsatisfied",
    "Włącz przynajmniej jedną wersję językową dla witryny aby rozpocząć zarządzanie stronami.": "Enable at least one language version for your site to start managing your pages.",
    "Edycja slajdów": "Edit slides",
    "Wynagrodzenie": "Salary",
    "Liczba pracowników pracujących w więcej niż jednym regionie": "Number of employees working in more then one region",
    "Opis witryny": "Site description",
    "Witryna domyślna": "Default site",
    "Potwierdź zapis do aktualnej wersji": "Confirm saving to the current version",
    "Zadanie zostało uruchomione.": "The task has started.",
    "Właściciel został dodany.": "The owner has been added.",
    "Rola została zaktualizowana.": "The role has been updated.",
    "Zacznij pisać aby wyszukać osobę...": "Start typing to search for a person...",
    "Przejdź do Checklisty": "Go to Checklist",
    "Nie udało się pobrać listy kolejek": "Failed to retrieve queue list",
    "Nie udało się pobrać listy modułów": "Failed to download module list",
    "Poprzednia strona": "Previous page",
    "Typ mime": "Mime type",
    "Szkolenie zostało zaktualizowane.": "The training has been updated.",
    "Ten Miesiąc": "This month",
    "Ścieżka szkoleniowa została zaktualizowana.": "The learning path has been updated.",
    "Przypisz do czatu": "Assign to chat",
    "Przepracowana liczba godzin w miesiącu": "Number of hours worked per month",
    "Nazwa serwera": "Server name",
    "Pole może zawierać jedynie litery.": "This field can only contain letters.",
    "Data wykonania od": "Exectuion date from",
    "Edycja dzielnicy": "",
    "Dodaj serwer": "Add server",
    "Dodaj dni": "Add days",
    "Zmień kolejność punktów": "",
    "Zacznij pisać aby wyszukać dzielnice...": "",
    "Edycja ustawień": "Edit settings",
    "Opis zespołu": "Team description",
    "Pełny ekran": "Full screen",
    "Nazwa paczki SCORM": "SCORM package name",
    "Domyślny kolor trasy (dotyczy kierowców)": "",
    "Dodanie serwera": "Add server",
    "Wpis został usunięty.": "The post has been deleted.",
    "Wyczyść": "Clear",
    "Pokaż listę dostępnych tras": "Show list of available routes",
    "Nazwa działu": "Department name",
    "Dodanie dzielnicy": "",
    "Trasa została zapisana.": "The route has been saved.",
    "Zmień dane kosztu": "Change the cost data",
    "Etykieta": "Tag",
    "Dni": "Days",
    "Numer zadania": "Task number",
    "Dodanie aktualności": "Add news",
    "Firma została dodana.": "The company has been added.",
    "Ustawienia witryny": "Site settings",
    "Ankieta": "Survey",
    "-": "-",
    "Dostęp do": "Access to",
    "Mnożenie": "Multiplication",
    "Statyczny": "Static",
    "Dark mode": "Dark mode",
    "Edycja wynagrodzenia stałego dla:": "Edit fixed salary for:",
    "Użytkownik został dodany do zespołu.": "The user has been added to the team.",
    "Notatki": "Notes",
    "Wybierz...": "Select...",
    "Odpowiedź 1": "Answer 1",
    "Masz nową wiadomość!": "You have a new message!",
    "źródło zostało usunięte.": "the source has been removed.",
    "Wyszukaj albo dodaj nowy tag": "Search for or add a new tag",
    "Wpisz kwotę": "Enter the amount",
    "Pole \"Tytuł\" jest wymagane.": "''Title'' is required.",
    "Dostępne moduły": "Available modules",
    "Raporty": "Reports",
    "Data rozpoczęcia": "Start date",
    "Podano nieprawidłowe dane": "Invalid data provided",
    "Wyświetlaj nazwę bloczka": "Display block name",
    "Zadanie do wykonania": "Task to be done",
    "Nazwa certyfikatu": "Certificate name",
    "Edycja użytkownika": "Edit user",
    "Aktywni": "Active",
    "Opłacono": "Paid",
    "Data zakończenia blokady konta": "Account block end date",
    "Stawka PLN brutto za godzinę": "PLN gross rate per hour",
    "Zaawansowane": "Advanced",
    "Poziomo": "Horizontally",
    "Ustawienia zaawansowane": "Advanced settings",
    "Obsługa Właściciela (OW)": "Owner Service (OW)",
    "Typ rezerwacji": "Booking type",
    "Podanie nazwy jest wymagane.": "Name is required.",
    "Ukraiński": "Ukrainian",
    "Czat": "Chat",
    "Rozwiń/Zwiń": "Expand/Collapse",
    "Ustawienia": "Settings",
    "Akcja": "Action",
    "Czas wykonania": "Completion time",
    "zacznij pisać aby wyszukać zespół...": "start typing to search for a team...",
    "Stwórz nowy czat": "Create new chat",
    "Id rezerwacji:": "Booking ID:",
    "Kontrolki podstawowe": "Basic controls",
    "Dodane Koszty": "Added costs",
    "Paczki SCORM": "SCORM packages",
    "Zamknięte przez": "",
    "Opcje": "Options",
    "Id sesji": "Session Id",
    "Co 15 minut": "Every 15 minutes",
    "Nie wybrano obrazu.": "No image has been selected.",
    "Zacznij pisać aby wyszukać regionu...": "Start typing to search for a region...",
    "Osoba odpowiedzialna": "Responsible person",
    "SUMA": "SUM",
    "Przypisz obiekty": "Assign objects",
    "Nazwa nieruchomości": "Property name",
    "Włączone (Index)": "Enabled (Index)",
    "Typ nieobecności": "Absence type",
    "Usuń pytanie": "Delete question",
    "Usuń wybrane": "Delete selected",
    "Kanały włączone w ustawieniach aplikacji": "Channels enabled in app settings",
    "Powiązane artykuły": "Related articles",
    "Zdecydowanie się zgadzam": "I strongly agree",
    "Data następnej inwentaryzacji": "Date of next inventory",
    "Ustawienia podstawowe": "Basic settings",
    "Powiadomienie dla użytkownika o nowych wiadomościach na chacie": "Notification to the user about new messages in the chat",
    "znaki": "characters",
    "Dzielenie": "Division",
    "Fiirma została zaktualizowana.": "Company has been updated.",
    "Imię i Nazwisko": "First name and last name",
    "Pracownicy z brakującymi ustawieniami wynagrodzenia": "Employees with missing salary settings",
    "Dodaj katalog": "Add directory",
    "Bazy danych": "Database",
    "Aby dodawać użytkowników do ścieżki szkoleniowej musisz najpierw zapisać formularz.": "To add users to the learning path, you have to save the form.",
    "Równość": "Equality",
    "Wybierz tagi": "",
    "Dzielnica została zaktualizowana.": "",
    "Publikuj szkolenie": "Publish training",
    "Czas (minuty)": "Time (minutes)",
    "Region został usunięty": "The region has been deleted",
    "REGON firmy": "Company REGON",
    "Wymagany": "Required",
    "Edycja etykiety": "Edit label",
    "Zapisuję...": "",
    "Wczytaj zapisany filtr": "Load saved filter",
    "Proszę obciążyć właściciela obiektu": "Please charge the property owner",
    "Konfiguracja domyślna": "Default configuration",
    "Wyświetlam": "Displaying",
    "Edycja serwera": "Edit server",
    "Przypisane do:": "Assigned to:",
    "Opublikowane wersje językowe.": "Published language versions.",
    "Rejestracja konta": "Sign-up",
    "Kategoria linku": "Link category",
    "Brak typu zadania": "No task type",
    "${DepartmentName(DepartmentEnum[this.task.department])}": "${DepartmentName(DepartmentEnum[this.task.department])}",
    "Facebook Admins": "Facebook Admins",
    "Szkic": "Draft",
    "Dodaj wpis": "Add entry",
    "Ustawienia powiadomień zostały zaktualizowane.": "Your notification settings have been updated.",
    "Sesja użytkownika": "User's session",
    "Rezerwacja właścicielska": "Owner's reservation",
    "Zawartość": "Content",
    "Nazwa źródła": "Source name",
    "Na wniosek": "On request",
    "Separator elementów w tytule": "Element separator in title",
    "Cron": "Cron",
    "Listopad": "November",
    "Zadania": "Tasks",
    "Nieruchomości robocze": "",
    "Dodaj Koszt do zadania": "Add Cost to Task",
    "co 3 miesiące": "every 3 months",
    "Historia zadania": "Task history",
    "Wybrany znak zastąpi znacznik separatora | wstawiony w tytule.": "The selected character will replace the separator marker | inserted in the title.",
    "Pole \"%0\" nie zawiera prawidłowego adresu email.": "Field ''%0'' does not contain a valid email address.",
    "Nie udało się pobrać listy typów modeli": "Failed to download the list of model types",
    "Bardzo zadowolony": "Very satisfied",
    "Źródło danych zostało zaktualizowane.": "The data source has been updated.",
    "Typ Nieruchomości": "",
    "Kobieta": "Woman",
    "Edytowana wersja": "Edited version",
    "Użytkownicy": "Users",
    "Adres serwera": "Server address",
    "Edytuj szablon": "Edit template",
    "wybierz długość": "choose length",
    "Do kogo wysłać testowe powiadomienie": "To whom to send the test notification",
    "Resetowanie podejść": "Reset approaches",
    "Administratorzy": "Administrators",
    "Wykonaj akcję": "",
    "Wartość musi być liczbą ujemną": "The value must be a negative number",
    "Materiały szkoleniowe": "Training materials",
    "Film": "Film",
    "Data zakończenia do": "End date to",
    "Nazwa checklisty": "Checklist name",
    "Zestaw niestandardowy": "Custom set",
    "Obrazek": "Image",
    "Konta prywatne": "Private accounts",
    "Kategorie": "Categories",
    "Serwer został dodany.": "Server has been added.",
    "Numer nieruchomości (StreetNumber)": "Property number (StreetNumber)",
    "Test został dodany.": "The test has been added.",
    "Wybierz stronę": "Select a page",
    "Data wygaśnięcia": "Expiration date",
    "Ustawienia strony": "Page settings",
    "Właściciel": "Owner",
    "Zmodyfikował": "Modified by",
    "Oczekujące": "Waiting",
    "Sortowanie rosnąco": "Sort ascending",
    "Dodaj Zadanie": "Add a task",
    "Aby dodawać członków zespołu musisz najpierw zapisać formularz.": "To add team members, you have to save the form first.",
    "Zmień nazwę katalogu": "Rename a directory",
    "Typ wynagrodzenia został usunięty": "Salary type has been deleted",
    "Dział został dodany.": "The department has been added.",
    "Imie i nazwisko właściciela": "Owner's name and surname",
    "Nazwisko klienta": "Customer name",
    "Porównaj %0": "Compare %0",
    "Typ słownika": "Dictionary type",
    "Firma": "Company",
    "Wrzesień": "September",
    "Kategorie materiałów": "Material categories",
    "Kwota kosztów": "Amount of costs",
    "Mężczyzna": "Man",
    "Dodanie źródła danych": "Add data source",
    "Data dezaktywacji": "Deactivation date",
    "Facebook Application ID": "Facebook Application ID",
    "Lekcja została zaktualizowana.": "The lesson has been updated.",
    "Usunięte zadania": "Deleted tasks",
    "Procent": "Percentage",
    "Usuń plik z listy": "Remove the file from the list",
    "Do sprawdzenia": "To be checked",
    "Chat został zarchiwizowany.": "The chat has been archived.",
    "Tag nieruchomości": "Property tag",
    "Potwierdzenie usunięcia": "Deletion confirmation",
    "Połącz w pary": "Pair up",
    "Przepracowana ilość godzin": "Number of hours worked",
    "Są to wszystkie obiekty, nawet te, które posiadają już właściciela.": "These are all properties, even those that already have an owner.",
    "więcej...": "more...",
    "Biblioteka": "Library",
    "Dalej": "Next",
    "Dodaj temat/link": "Add topic/link",
    "Wybierz zaznaczone": "Choose selected",
    "Wprowadzona wartość jest za długa": "The value you entered is too long",
    "Inwentaryzacja została dodana.": "The inventory has been added.",
    "Wybrane pliki": "Selected files",
    "Liczba szkoleń w ścieżce": "Number of trainings in the path",
    "Dodaj odpowiedź": "Add an answer",
    "UWAGA! Ta tabela pokazuje tylko przeterminowane zadania, filtr \"STATUS\" nie jest aktywny. Wróć do \"Wszystkie zadania\" aby mieć dostępne wszystkie filtry": "ATTENTION! This table shows only overdue tasks, the \"STATUS\" filter is not active. Go back to \"All Tasks\" to have all filters available",
    "Dodanie szablonu": "Add template",
    "Uzupełnij luki": "Fill in the gaps",
    "Nie udało się pobrać listy ról": "Failed to download role list",
    "Wpisz komentarz...": "",
    "Krótki opis": "Short description",
    "Edycja paczek SCORM": "Edit SCORM packages",
    "Nazwa przycisku ``Wstecz``": "Button name ''Back''",
    "Link do rezerwacji": "Link to booking",
    "Ilość toalet": "Number of toilets",
    "Urlopy": "Absences",
    "Typ konta": "Account type",
    "Pliki do wysłania: %0": "Files to send: %0",
    "Utwórz czat": "Create chat",
    "Sprawdzenie czy pusty": "Check if empty",
    "Potwierdź akceptację": "Confirm acceptance",
    "Dodawanie": "Adding",
    "Minimum": "Minimum",
    "Kosz": "Recycle bin",
    "Witryna aktywna": "Active site",
    "Data przyjazdu": "Arrival date",
    "Data akceptacji": "Acceptance date",
    "Wstaw z góry": "Insert from above",
    "Uzupełnij brakujące pola.": "Fill in the missing fields.",
    "Ostatnio oglądane": "Recently viewed",
    "Raczej się nie zgadzam": "I rather disagree",
    "Nazwa zespołu": "Team name",
    "Biuro": "",
    "Cena PLN": "Price PLN",
    "Stawka wynagrodzenia została zaktualizowana.": "The pay rate has been updated.",
    "gotowe": "ready",
    "Kopiuj": "Copy",
    "Wyświetl szczegóły": "Show details",
    "Nazwa zadania": "Task name",
    "Zakończ zadanie": "End task",
    "Wpisz tytuł...": "Enter a title...",
    "Włącz chat": "Enable chat",
    "Sekcja": "Section",
    "Dodaj trasę": "Add a route",
    "ID lub typ zadania": "Task ID or type",
    "Dodaj nowy tag": "Add a new tag",
    "Załącz zdjęcia": "Attach photos",
    "Regiony": "Regions",
    "Numer nieruchomości Avantio": "Avantio Property ID",
    "Grudzień": "December",
    "Dodanie nieruchomości roboczej": "",
    "Pliki prywatne": "Private files",
    "Miesiąc": "Month",
    "obrót": "",
    "Weekend": "Weekend",
    "Sesja": "Session",
    "Godzina trasy": "",
    "Godzina wykonania": "Time of execution",
    "Zacznij pisać aby wyszukać użytkownika...": "Start typing to search for a user...",
    "Wyloguj zalogowanego obecnie użytkownika": "Log off the currently logged-in user",
    "Co 30 minut": "Every 30 minutes",
    "Etykiety": "Tags",
    "Włączenie generowania pliku sitemap.xml dla całej witryny": "Enable sitemap.xml file generation for the website",
    "Dodaj zdjęcia": "",
    "Wynagrodzenie stałe brutto": "Gross fixed salary",
    "Dane": "Data",
    "Usunięto użytkownika z czatu": "",
    "Hasło do sieci Wi-Fi": "Wi-Fi network password",
    "Resetuj zmiany": "Reset changes",
    "nie wymuszaj zmiany hasła": "do not force password change",
    "Źródło rezerwacji": "Reservation source",
    "Zmieną %HEAD_TITLE% można wykorzystać w innych polach tekstowych.": "The variable %HEAD_TITLE% can be used in other text boxes.",
    "Ilość koordynatorów w regionie": "Number of coordinatiors in the region",
    "Zmień status": "Change status",
    "IBAN": "IBAN / Acc number",
    "NIP firmy": "Company VAT number",
    "Dokument został dodany.": "Document has been added.",
    "Lista powiadomień": "Notification list",
    "Wybierz": "Select",
    "Kopiuj filtr": "Copy filter",
    "Zapisz filtry": "Save filters",
    "Wybranie katalogu jest wymagane": "Selecting a directory is required",
    "Standard 2004": "Standard 2004",
    "Lista Tras:": "List of routes:",
    "Dodaj certyfikat": "Add certificate",
    "Strony": "Page",
    "Kategoria została dodana.": "The category has been added.",
    "Brak przypisanych użytkowników.": "",
    "Link do raportu": "Link to the report",
    "Aktywny": "Active",
    "Dzielnica": "",
    "Kategoria jest wymagana": "Category is required",
    "Tak/Nie": "Yes/No",
    "Sobota": "Saturday",
    "Pole \"Imię\" jest wymagane.": "''Name'' is required.",
    "Ważne": "Important",
    "Ten element nie zawiera menu kontekstowego.": "This item does not contain a context menu.",
    "Niestandardowy błąd": "Non-standard error",
    "Czy na pewno chcesz ukryć wiadomość?": "Are you sure you want to hide the message?",
    "Link do zewnętrznej strony": "Link to external site",
    "Ta czynność została wykonana przez kogoś innego. Odśwież aplikację.": "",
    "Ilość łóżek jednoosobowe": "Number of single beds",
    "Usuń regulamin": "Delete regulations",
    "Usuń trasę": "Delete route",
    "Edycja miasta": "Edit a city",
    "Zapisz wersję slajdu": "Save slide version",
    "Notatka została dodana.": "The note has been added.",
    "Konfiguracja aranżacji jest niedostępna ponieważ nie podano definicji szablonu.": "The arrangement configuration is not available because no template definition is provided.",
    "Filtr globalny": "Global filter",
    "Filtry globalne": "Global filters",
    "Podgląd": "Preview",
    "Nie udało się wysłać pliku": "Failed to send file",
    "Źródło": "Source",
    "Wysokość": "Height",
    "Dodaj tag nieruchomości": "Add a property tag",
    "Dodaj do regionu": "Add to region",
    "Multimedia": "Multimedia",
    "Edycja działu": "Edit department",
    "Typ źródła danych": "Data source type",
    "Deadline - Data wykonania": "Deadline - Date of execution",
    "Przypisane tagi: ": "",
    "Miejsca po przecinku": "Decimal points",
    "Wybrany materiał został już dodany do lekcji": "The selected material has already been added to the lesson",
    "Koszt został zaktualizowany.": "The cost has been updated.",
    "Inspektor": "Inspector",
    "Edycja ankiety": "Edit survey",
    "Pokaż tytuły stron": "Show page titles",
    "Dodanie paczki SCORM": "Add SCORM package",
    "Domyślna wartość": "Default value",
    "Konfiguracja bloku": "Block configuration",
    "Ranga": "Rank",
    "Data dodania do": "Adding date to",
    "Niżej": "Below",
    "Liczba całkowita": "Integer",
    "Data rezerwacji od": "",
    "Użytkownik został przypisany do ścieżki szkoleniowej.": "The user has been assigned to a learning path.",
    "Adres kanoniczny": "Canonical address",
    "Serwer został usunięty.": "Server has been removed.",
    "Zbliżająca się data zakończenia": "Upcoming end date",
    "Usuń poprzednie wersje certyfikatu": "Delete previous versions of the certificate",
    "Symbol": "Symbol",
    "Konfiguracja sekcji jest niedostępna ponieważ nie podano definicji szablonu.": "Section configuration is not available because no template definition is provided.",
    "Nowa kwota": "New amount",
    "Formularz został zaktualizowany.": "The form has been updated.",
    "Podążanie za linkami": "Links following",
    "Zadania na wniosek działu": "Tasks at the request of the department",
    "Nie udało się pobrać listy serwerów plików": "Failed to download the file server list",
    "NIP": "NIP",
    "Nr rezerwacji": "Reservation No",
    "Dane gościa": "Guest details",
    "Czy na pewno chcesz zkopiować szkolenie?": "Are you sure you want to copy the training?",
    "Wiadomość została przywrócona.": "The message has been restored.",
    "Testowy adres email": "Tested email address",
    "Dodanie lekcji": "Add lesson",
    "Dodaj wszystkie z kategorii": "Add all categories",
    "Formularz zarchiwizowany": "Archived form",
    "Statystyki kosztów w obiektach": "Properties cost statistics",
    "Zadanie": "Task",
    "Punkt został oznaczony": "The point has been marked",
    "Kanały komunikacji": "Communication channels",
    "Średnie wynagrodzenie pracowników": "Average salary of employees",
    "Wartość musi być liczbą dodatnią": "The value must be a positive number",
    "Stwórz czat z rezerwacji": "Create chat from reservation",
    "Rezerwacje anulowane": "",
    "Litery i cyfry": "Letters and digits",
    "Trasa została rozpoczęta": "The route has started",
    "Dodanie slajdów": "Add slides",
    "Czas trwania:": "Duration:",
    "Edycja artykułu": "Edit article",
    "Zwiń": "",
    "Nie udało się pobrać listy typów materiałów": "Failed to download material type list",
    "Dodaj koszt": "Add cost",
    "Liczba dni do zakończenia szkolenia przy której zostanie wysłane powiadomienie": "Number of days until the end of the training at which the notification will be sent",
    "Region": "Region",
    "Aktualności": "News",
    "Pobrane z magazynu Renters": "Retrieved from Renters storage",
    "Lista szkoleń": "Training list",
    "Wynagrodzenia w regionie łącznie z wynagrodzeniami koordynatorów": "Salaries in the region including salaries of coordinators",
    "Lista zgłoszeń w tym apartamancie": "",
    "Wyrażone zgody": "Consents given",
    "Co tydzień": "Every week",
    "Element grupujący": "Grouping element",
    "Nie ma ustalonej roli dla tego wydziału": "There is no fixed role for this department",
    "Powiązane zadania": "",
    "Data od": "Date from",
    "Wszystkie kategorie": "All categories",
    "Pytanie": "Question",
    "Adres e-mail": "E-mail address",
    "Pole zawiera": "Field contains",
    "Logo": "Logo",
    "Aktualne hasło": "Current password",
    "Kwota opłacona od": "Amount paid from",
    "Usuń materiał z lekcji": "Remove material from the lesson",
    "Wklej komponent": "Paste component",
    "Pytanie 2": "Question 2",
    "Attempt of reservation by Credit Card": "Attempt of reservation by Credit Card",
    "Zastępstwo zostało usunięte.": "The substitution has been removed.",
    "Usuń kurs z ścieżki szkoleń": "Remove the course from the learning path",
    "Klucze": "Keys",
    "%$ – znacznik otwierający tag": "%$ – tag to start a tag",
    "Nazwa kolumny z wartością": "Name of column with value",
    "Administracja": "Administration",
    "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "The possibility to modify this account is limited. Inactive fields are synchronized with the external database %0.",
    "Dzisiaj": "Today",
    "Host główny": "Main host",
    "Brak właściciela": "No owner",
    "Checklista": "Checklist",
    "Rola została dodana.": "The role has been added.",
    "Aktywność": "Activity",
    "Nie wiem": "I don't know",
    "Źródło danych": "Data source",
    "Data zalogowania": "Login date",
    "Do szkolenia nie przypisano jeszcze żadnych zespołów.": "No assigned teams to the training.",
    "Ważne informacje": "Important information",
    "Wszystkie konta": "All accounts",
    "Przeglądarka z której korzystasz nie wspiera powiadomień PUSH": "The browser you are using does not support PUSH notifications",
    "Wpisz treść wiadomości.": "Enter the text of the message.",
    "Nie masz uprawnień do publikacji aranżacji.": "You don't have permission to publish the arrangement.",
    "Firmy": "Companies",
    "Wybrane elementy zostały usunięte.": "The selected items have been removed.",
    "Wszyscy": "Everyone",
    "Podaj swój adres email": "Enter your email address",
    "Edycja zadania": "Edit a task",
    "Ładuję zgłoszenia...": "",
    "Status zadania": "",
    "Exclusivity": "Exclusivity",
    "Laptop 17\"": "17\" laptop",
    "Powtórzone": "Repeated",
    "Szkolenie zostało skopiowane": "Training has been copied",
    "Podstawowe operacje tekstowe": "Basic text operations",
    "Uwzględnij wydziały podrzędne": "Include sub-departments",
    "Zarządzaj": "",
    "Trasy dla Kierowców": "Routes for Drivers",
    "Nazwa:": "Name:",
    "Moja trasa": "My route",
    "(nowe wiadomości)": "(new messages)",
    "Wróć do strony głównej": "Back to Home Page",
    "Prefix": "Prefix",
    "Pojedynczy artykuł (strona opisowa)": "Single article (descriptive page)",
    "Co 5 dni": "Every 5 days",
    "Zaloguj się": "Log in",
    "Resetuj filtry": "",
    "Edytuj aranżację": "Edit arrangement",
    "Nie wypełniono poprzedniego wiersza.": "The previous line was not filled in.",
    "Wysłane powiadomienie": "Notification sent",
    "Wstaw kontrolkę": "Insert control",
    "Nieprawidłowy adres email": "Invalid email address",
    "Raporty z rozliczeń": "Billing reports",
    "zacznij pisać aby wyszukać szkolenie...": "start typing to search for training...",
    "Dodaj Notatkę": "Add a note",
    "Dział": "Department",
    "Zadanie zostało rozpoczęte.": "The task has started.",
    "Kwota kosztu do": "Cost amount up to",
    "Styczeń": "January",
    "Pytanie 1": "Question 1",
    "Dobrze": "Good",
    "Inwentaryzaje": "Inventory",
    "Konto aktywne": "Active account",
    "Numer dowodu osobistego": "ID card number",
    "Dostęp niezdefiniowany": "Undefined access",
    "Edycja źródła danych": "Edit data source",
    "Reżyseria": "Direction",
    "Zaakceptowane, zapisz": "Accepted, save",
    "Minimalna długość hasła": "Minimum password length",
    "Nie udało się pobrać listy szablonów": "Failed to download template list",
    "Lista wyboru": "Selection list",
    "Co 2 tygodnie": "Every 2 weeks",
    "Przypisane dzielnice:": "",
    "Nazwa nadawcy": "Sender name",
    "co rok": "every year",
    "Lekcja wideo została dodana.": "The video lesson has been added.",
    "Słownik systemowy": "System dictionary",
    "zacznij pisać aby wyszukać użytkownika...": "start typing to search for a user...",
    "Przypomnij": "",
    "Zespoły": "Teams",
    "Wybrana ikona powinna być kwadratem": "The selected icon should be a square",
    "Apple iPhone 5": "Apple iPhone 5",
    "Nie udało się dodać użytkownika do czatu": "",
    "Dodaj slajder": "Add slider",
    "Testowe powiadomienie wysłane": "Test notification sent",
    "Opublikowana": "Published",
    "Domyślna checklista": "Default checklist",
    "Co 3 miesiące": "Every 3 months",
    "Dane wydziałów": "Departments data",
    "Wyliczenie stawki godzinowej (statystyczna)": "Calculation of the hourly rate (statistical)",
    "Edytuj test": "Edit test",
    "Data utworzenia": "Creation date",
    "Szablon checklisty": "Checklist template",
    "Dodanie Notatki": "Add a note",
    "Pseudonim": "Pseudonym",
    "Zacznij pisać aby wyszukać tagi...": "",
    "Zamknij": "Close",
    "Definicja szablonu": "Template definition",
    "Nieobecność do": "Absence until",
    "Chat": "Chat",
    "Autor notatki": "Author of the note",
    "Stan powiadomienia": "Notification status",
    "Materiał wideo": "Video",
    "Podtytuł": "Subtitle",
    "Certyfikat przygotowany jest do formatu A4. Wyświetlany podgląd stanowi jedynie widok roboczy. Docelowy wygląd certyfikatu będzie powiązany z właściwymi danymi szkolenia oraz użytkownika.": "The certificate is prepared in A4 format. The preview displayed is only a draft. The desired appearance of the certificate will be associated with the relevant training and user data.",
    "Wystąpił błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.": "A server error occurred while handling the selected operation. Please try again.",
    "Zatwierdź": "",
    "Ta dzielnica jest już przypisana do użytkownika.": "",
    "Typ szablonu": "Template type",
    "Filtr o podanej nazwie już istnieje.": "The filter with a given name already exists.",
    "Konta publiczne": "Public accounts",
    "Dodaj nowy": "Add new",
    "Resetowanie podejść użytkowników": "Reset user approaches",
    "Wybrana data jest większa niż dozwolona %0.": "Selected date is above the permitted one %0.",
    "Data Zmiany": "Date of change",
    "Dodaj stronę": "Add page",
    "Wybór języka": "Language selection",
    "Co minutę": "Every minute",
    "Data odrzucenia": "Rejection date",
    "Zmień rozmiar": "Resize",
    "Edycja roli": "Edit role",
    "Dane wydziału zostały usunięte.": "The department's data has been deleted.",
    "Domyślna": "Default",
    "Ilość punktów: ": "Number of points: ",
    "Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk \"Aktualizuj\". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.": "A new update is available. Please save the work progress and click the \"Update\" button. You can also accept the update by refreshing the browser. No update may result in errors.",
    "Kolejne pozycje z lewej strony": "Next items on the left",
    "Sierpień": "August",
    "Nieruchomość została zaktualizowana.": "The property has been updated.",
    "Przybornik": "Kit",
    "Zrób zdjęcie": "",
    "Powiadomienia zostały skonfigurowane.": "Notifications have been configured.",
    "Następna inwentaryzacja": "Next inventory",
    "Kwota rezerwacji": "",
    "Wstaw trzy kolumny 1/3": "Insert three columns 1/3",
    "Wybrany region": "Selected region",
    "Status rozliczenia": "Settlement status",
    "Utwórz katalog": "Create a directory",
    "Adres email nadawcy": "Sender's email address",
    "Zwierzę": "Pet",
    "Edycja zespołu": "Edit team",
    "Wyszukaj użytkownika": "Search users",
    "Aktualności ze strony": "Website news",
    "Zaopatrzenie zostało usunięte.": "The supply has been removed.",
    "Odśwież": "Refresh",
    "Zaokrąglenie": "Rounding",
    "Wybór kategorii": "Category selection",
    "Edycja wpisu": "Edit post",
    "Kwota średnia": "Average amount",
    "Zarządzaj zespołami": "Manage teams",
    "Zacznij pisać aby wyszukać firmę...": "Start typing to search for a company...",
    "Synchronizacja rezerwacji": "",
    "Lista użytkowników": "List of users",
    "Płeć": "Gender",
    "Tryb monitorowania aktywności włączony.": "Activity monitoring mode enabled.",
    "Nie wybrano paczki.": "No package has been selected.",
    "Odpowiedź 4": "Answer 4",
    "Pytania": "Questions",
    "Zastępstwo wygaśnie za 5 minut.": "The substitution will expire in 5 minutes.",
    "Pokaż nieaktywnych właścicieli": "Show inactive owners",
    "Wybierz serwer": "Select server",
    "Dane kierowcy: ": "Driver data: ",
    "Nazwa pliku": "File name",
    "Open Graph": "Open Graph",
    "Aktualizuj": "Update",
    "${(ex as any).response.data.errors.message[0]}": "${(ex as any).response.data.errors.message[0]}",
    "Dokument nie jest jeszcze nigdzie opublikowany.": "The document has yet not been published anywhere.",
    "Planowanie inwentaryzacji": "Inventory planning",
    "Zapisz nową kolejność punktów": "",
    "wybierz katalog": "select directory",
    "Id zdarzenia": "Event Id",
    "Checklista została usunięta": "Checklist has been removed",
    "Galeria zdjęć": "Photo gallery",
    "Kalendarz nieruchomości": "",
    "źródło danych zostało dodane.": "the data source has been added.",
    "Nieobecny do": "Absent until",
    "To pole jest wymagane": "The field is required",
    "Logowanie zakończyło się niepowodzeniem.": "Login failed.",
    "Przywrócić użytkownika i jego wiadomości?": "Restore the user and their messages?",
    "Aby dodawać użytkowników do szkolenia musisz najpierw zapisać formularz.": "To add users to the training, you have to save the form.",
    "Zaawansowane polecenia": "Advanced commands",
    "Serwery plików": "File servers",
    "Statystyki wniosków": "Request statistics",
    "Niewykonane zadania": "Unfinished tasks",
    "Kierowca Główny": "",
    "Default mode": "Default mode",
    "Szkolenie nie jest opublikowane": "Training is not published",
    "Dostęp przyznany ręcznie": "Manually-granted access",
    "Kwota opłacona": "Amount paid",
    "Kwota łącznie": "Total amount",
    "Pracownik": "Employee",
    "Wyślij link": "Send link",
    "Zapauzuj zadanie": "",
    "Zestaw został dodany.": "The set has been added.",
    "Dowolna data": "Any date",
    "Automatyczna akceptacja": "Auto acceptance",
    "Filtr został usunięty": "Filter has been removed",
    "Zarchiwizowany": "Archived",
    "Stawka nieruchomości": "Property rate",
    "Zwrócony": "Returned",
    "Edytowane mogą być tylko szablony włączonych i obsługiwanych kanałów.": "Only templates for enabled and supported channels can be edited.",
    "Czas pomiędzy": "Time between",
    "Ustawienia globalne": "Global Settings",
    "Dane właściciela": "Owner details",
    "Elementy": "Elements",
    "Podstawowe operacje na liczbach": "Basic digits operations",
    "Dodanie formularza": "Add form",
    "Wybrana liczba jest większa niż dozwolona %0.": "Selected number is greater then allowed %0.",
    "Wystąpił błąd połączenia. Upewnij się że masz włączony internet i spróbuj ponownie.": "A connection error has occurred. Make sure you are online and try again.",
    "Dopuść brak odpowiedzi": "Allow lack of response",
    "Usunięte": "Deleted",
    "Edycja nowej trasy": "Edit a new route",
    "Powiązany artykuł": "Related article",
    "Uwzględnij dodatkowe nieruchomości": "",
    "Back Office": "Back Office",
    "Brak wersji": "No version",
    "Zacznij pisać aby wyszukać miasto...": "Start typing to search for a city...",
    "Dodaj firmę": "Add company",
    "Archiwizuj": "Archive",
    "Akceptacja": "Acceptance",
    "Potwierdzenie usunięcia.": "Deletion confirmation",
    "Paczka SCORM została dodana.": "The SCORM package has been added.",
    "Dodane:": "",
    "Otwórz czat - link": "Open chat - link",
    "Kategoria materiału": "Material category",
    "Kontrolki specjalizowane": "Specialized controls",
    "Trasa została usunięta": "The route has been removed",
    "Obciążenie Renters": "Charge Renters",
    "Eksportuj do Excel": "Export to Excel",
    "Domyślny czas rezerwacji": "Default booking time",
    "Zespół został przypisany do ścieżki szkoleniowej.": "The team has been assigned to a learning path.",
    "Pobierz raport": "",
    "Oczekuje na wysłanie": "To be send",
    "Tytuł strony": "Page title",
    "Profil został zaktualizowany.": "The profile has been updated.",
    "Edytuj zestaw": "Edit set",
    "Facturada": "Facturada",
    "Wybrane": "Selected",
    "Liczba miast w regionie": "Number of cities in the region",
    "Powiadomienie o oczekującym szkoleniu": "Pending training notification",
    "Do poziomu": "To level",
    "Struktura serwisu": "Site structure",
    "Ten czat nie posiada przypisanych konkretnych użytkowników.": "This chat does not have specific users assigned to it.",
    "Walidacja": "Validation",
    "Co 5 minut": "Every 5 minutes",
    "ŚREDNIA": "AVERAGE",
    "Źródła danych": "Data Sources",
    "Regulamin został zaktualizowany.": "Ragulations have been updated.",
    "Wybierz autora z listy": "Select an author from the list",
    "Ten zespół nie posiada jeszcze żadnych członków.": "This team does not have any members yet.",
    "Rozliczenia": "Settlements",
    "Nigdy": "Never",
    "Edycja wielu elementów": "",
    "Data do": "Date to",
    "Nie wszystkie wybrane elementy zostały przeniesione. Nie posiadasz wymaganych uprawnień lub, w przypadku przenoszenia katalogów, katalog docelowy jest podkatalogiem katalogu źródłowego. Katalogi i pliki których nie udało się przenieść pozostały nadal w zakładce Wybrane.": "Not all selected items have been transfered. You do not have the required permissions or, if you are moving directories, the destination directory is a subdirectory of the source directory. Directories and files that could not have been transfered remain in the Selected tab.",
    "ISBN": "ISBN",
    "Plik jest wysyłany": "The file is being sent",
    "Rola została usunięta.": "The role has been removed.",
    "15 minut": "15 minutes",
    "Zdecydowanie ważne": "Definitely important",
    "Dodatkowe wynagrodzenie - komentarz": "",
    "Maksimum": "Maximum",
    "Nazwa pola": "Field name",
    "Usuń plik": "Delete file",
    "Kod HTML": "HTML code",
    "Upload": "Upload",
    "Nazwa firmy": "Company name",
    "Nazwa paczki": "Package name",
    "Wstaw z dołu": "Insert from below",
    "Wstaw przycisk": "Insert button",
    "Stała wartość": "Constant value",
    "Wynagrodzenia Pracowników": "Employees salary",
    "Codziennie": "Every day",
    "Dodaj klienta": "Add client",
    "Adres URL profilu autora.": "URL of the author profile.",
    "Scenariusz": "Scenario",
    "Zapisz filtr": "Save filter",
    "Status dokumentu został zmieniony.": "The status of the document has been changed.",
    "Bez \"http(s)://\" i \"/\" na końcu.": "No \"http(s)://\" and \"/\" at the end.",
    "HR": "HR",
    "zacznij pisać aby wyszukać materiał...": "start typing to search for material...",
    "Otwórz stronę w nowym oknie": "Open the page in a new window",
    "Opisy": "Descriptions",
    "Zakres czasu": "Time range",
    "Liczba pracowników w regionie": "Number of employees in the region",
    "Koordynator": "Coordinator",
    "Edytuj Notatkę": "Edit Note",
    "Kwota za godzinę": "Pay per hour",
    "Błędnie określona liczba, sprawdź liczbę opublikowanych materiałów.": "Incorrect number, check the number of published materials.",
    "Witryna została zaktualizowana.": "The site has been updated.",
    "Przejdź do rezerwacji": "",
    "Nieruchomość została dodana.": "The property has been added.",
    "Dodanie strony": "Add page",
    "Użytkownik": "User",
    "Grafik pracowniczy": "Work schedule",
    "Wynagrodzenia Koordynatorzy": "Coordinator salary",
    "Przeglądnij logi": "View logs",
    "MasterCode nieruchomości": "MasterCode Real Estate",
    "Wysłano powiadomienie.": "",
    "Top mieszkań generujacych najwięcej kosztów": "Top costs by apartments",
    "Ustawienia konta": "Account settings",
    "Tytuł sekcji": "Section title",
    "Ten kanał ma możliwość wysyłania tekstu sformatowanego.": "This channel provides a possibility to send formatted text.",
    "Peticion informacion": "Peticion informacion",
    "Nazwa użytkownika serwisowego": "Maintenance user name",
    "Stawka za typ apartamentu": "Rate per apartment type",
    "Nazwa sekcji": "Section name",
    "Dodaj użytkownika": "Add user",
    "Inwentaryzacja została zaktualizowana.": "The inventory has been updated.",
    "Odejmowanie": "Subtraction",
    "Link do wybranej strony serwisu": "Link to the selected page of the website",
    "Odpowiedź 5": "Answer 5",
    "Język domyślny": "Default language",
    "Opis szkolenia": "Training description",
    "Wymagaj znaków specjalnych w hasłach": "Require special characters in passwords",
    "Miesiące": "Months",
    "Zespół został zaktualizowany.": "The team has been updated.",
    "Zdecydowanie nie": "Definitely no",
    "Nie udało się pobrać listy aranżacji": "Failed to download the arrangement list",
    "Potwierdzenie przywrócenia": "Confirmation of restoration",
    "Liczba dni od jakiej użytkownik otrzyma powiadomienie": "Number of days from which the user will receive a notification",
    "${StatusName(StatusEnum[this.form.bookingType])}": "${StatusName(StatusEnum[this.form.bookingType])}",
    "Zapisz i wróć": "Save and return",
    "6 godzin": "6 hours",
    "Dodanie miasta": "Add a city",
    "Dodano zdjęć": "",
    "Typy materiałów": "Material types",
    "Kategoria nadrzędna": "Parent category",
    "Co 12 godzin": "Every 12 hours",
    "Dokument %0": "Document %0",
    "Podlega ocenie": "To be graded",
    "Publiczne": "Public",
    "Podziedziałek - Piątek": "Monday - Friday",
    "Ustawienia slajdera": "Slider settings",
    "Czy jesteś pewien? ": "",
    "Nie": "No",
    "Przypisz do zadania": "Assign to task",
    "Szkolenie zostało dodane.": "Training has been added.",
    "Ten region jest już przypisany do użytkownika.": "This region is already assigned to the user.",
    "Dane zadania": "Task data",
    "Pole \"Nazwisko\" jest wymagane.": "''Surname'' is required.",
    "Wynagrodzenia HK": "HK salary",
    "Nazwisko właściciela": "Owner's name",
    "Ilość wyników do odrzucenia": "Number of results to be rejected",
    "wybierz": "select",
    "Kalendarz pracowników": "",
    "Wróć do logowania": "Back to login",
    "Nieopublikowany": "Unpublished",
    "Hasło użytkownika serwisowego": "Maintenace user password",
    "Wyloguj": "Logout",
    "Dodaj do zestawu": "Add to set",
    "Przypisane tagi:": "",
    "Brak elementów podsumowania": "No summary items",
    "Ten użytkownik już jest przypisany do czatu": "",
    "Przypisane do": "Assigned to",
    "Wymuś zmianę hasła": "Force password change",
    "Opis": "Description",
    "Lista rozwijana": "Drop-down list",
    "Niepotwierdzeni": "Unconfirmed",
    "Dostęp przyznany przez zespół": "Team-granted access",
    "Dodaj typ wynagrodzenia": "Add salary type",
    "Przekroczono dozwoloną ilość znaków: %0.": "Allowed number of characters exceeded: %0.",
    "Baza danych tego klienta jest nieaktualna. Uruchom aktualizację za pomocą przycisku na dole formularza.": "This customer's database is out of date. Install an update with the button at the bottom of the form.",
    "Wybierz dział": "Select a department",
    "Dzielnice": "",
    "Pozostało %0 znaków.": "There are %0 characters left.",
    "Zastępstwo zostało zaktualizowane.": "The substitution has been updated.",
    "Zapauzowane": "",
    "Uwzględniaj tagi": "",
    "Nazwa zestawu": "Set name",
    "Miasto zostało usunięte": "The city has been removed",
    "Treści odpowiedzi są wymagane": "Answers are required",
    "Publikuj certyfikat": "Publish certificate",
    "Najniższy": "Lowest",
    "Aranżacje": "Arrangements",
    "Dodaj chat": "Add chat",
    "Środa": "Wednesday",
    "Tłumaczę...": "Translating...",
    "Długość": "Length",
    "Kanał jest domyślnie wymagany. Nie da się wyłączyć wysyłania powiadomienia tym kanałem. Możliwa jest tylko zmiana tytułu i treści komunikatu.": "The channel is required by default. It is not possible to stop sending a notification through this channel. It is only possible to change the title and content of the message.",
    "Legenda": "Legend",
    "Nadpisanie kwoty wynagrodzenia zostało dodane.": "Overwriting the salary amount has been added.",
    "Piątek": "Friday",
    "Utworzone przez": "Created by",
    "Stwierdzenie": "Statement",
    "Kalendarz": "Calendar",
    "Informacje": "Information",
    "Wymiary": "Dimensions",
    "Wideo": "Video",
    "Komentarz dotyczący naprawy problemu": "Comment on fixing the problem",
    "Ankiety": "Surveys",
    "Edycja klienta": "Edit client",
    "Adres email": "Email address",
    "Na pewno przenieść wybrane elementy do bieżącego katalogu?": "Are you sure you want to move the selected elements to the current directory?",
    "Zadania dzisiaj": "Tasks for today",
    "Podaj identyfikatory użytkowników Facebooka, oddzielone przecinkami, aby mogli uzyskać dostęp do statystyk (Facebook Insights).": "Provide Facebook user IDs, separated by commas, so they can access to their statistics (Facebook Insights).",
    "Nadpisz kwotę": "Overwrite the amount",
    "Usuń slajd": "Delete slide",
    "Data dodania od": "Adding date from",
    "Numer mieszkania (Number)": "Apartment number",
    "Ikona wyświetlana jest w postaci kwadratu.": "The icon is displayed in the form of a square.",
    "Przypisani użytkownicy:": "Assigned users:",
    "Anuluj i wyjdź z kreatora": "Cancel and exit the creator",
    "Wyszukuję...": "",
    "Członkowie zespołu": "Team members",
    "Pokaż tytuł strony": "Show page title",
    "Dodaj premię": "Add bonus",
    "Edytuj lekcje": "Edit lessons",
    "Nie udało się pobrać ról!": "",
    "Data wydania": "Release date",
    "Opracowane, zapisz": "Edited, save",
    "Dane osobowe": "Personal data",
    "Szablon został usunięty.": "The template has been removed.",
    "Lekcja SCORM została zaktualizowana.": "The SCORM lesson has been updated.",
    "W zespole od %0 do %1": "In Team from %0 to %1",
    "Notatki Koordynatorów": "Coordinators' notes",
    "Wyszukaj wszędzie": "Search everywhere",
    "Usunięcie wybranych elementów": "Delete selected items",
    "Kontrolka wyboru (pojedynczy wybór)": "Choice control (single choice)",
    "Strona lekcji opublikowana": "Lesson page published",
    "Edytuj kategorie": "Edit category",
    "Uwzględniaj dzielnice": "",
    "Przywrócić wiadomość?": "Restore a message?",
    "Wyłączone (NoFollow)": "Off (NoFollow)",
    "Dozwolone rozszerzenia": "Allowed extensions",
    "Publikacja została usunięta.": "The publication has been deleted.",
    "Checklista została dodana.": "Checklist has been added.",
    "Numer rezerwacji Avantio": "Avantio Reservation number",
    "Administracja - Użytkownicy": "Administration - Users",
    "5 minut": "5 minutes",
    "Nie zdefiniowano żadnych sekcji.": "No sections are defined.",
    "Data zdarzenia": "Event date",
    "Edytuj datę opuszczenia zespołu": "Edit team leave date",
    "Dodaj dział": "Add section",
    "(AirBnb) Wysłano żądanie do gościa": "(AirBnb) A request has been sent to the guest",
    "ID obiektu": "Object ID",
    "Cancelled": "Cancelled",
    "Witryny": "Sites",
    "Nierozwiązane": "Unresolved",
    "Przywróć oryginał": "Restore original",
    "Nazwa bloku": "Block name",
    "Edycja ustawień wynagrodzenia dla:": "Edit salary settings for:",
    "Wyposażenie": "Amenities",
    "Wyślij": "Send",
    "Trasa została zakończona": "The route has been completed",
    "odznacz wszystko": "unselect all",
    "Ustawienia domyślne": "Default settings",
    "Szef Regionu": "Head of the Region",
    "Nieobecność od": "Absence from",
    "Edycja szablonu": "Edit template",
    "Wyczyść aranżację": "Clear arrangement",
    "10": "10",
    "Wartość musi być liczbą całkowitą": "The value must be an integer",
    "Witryna / Wersja językowa": "Website / Language version",
    "Witryna została usunięta.": "The site has been removed.",
    "Link został usunięty.": "The link has been removed.",
    "Dodaj nową witrynę aby rozpocząć zarządzanie stronami.": "Add a new website to start managing your pages.",
    "Zaplanowane": "Scheduled",
    "Dodawanie inwentaryzacji": "Inventory adding",
    "Rodzaj adresu kanonicznego": "Type of canonical address",
    "Tylko do odczytu": "Read-only",
    "Tagi nieruchomości": "Property tags",
    "Dodaj witrynę": "Add website",
    "Tytuł formularza jest wymagany": "Form title is required",
    "Nieruchomości": "Properties",
    "Czy zapisać trasę i wysłac ją do kierowcy?": "Save the route and send it to the driver?",
    "Dane podstawowe": "Basic data",
    "Edycja nieruchomości": "Edit a property",
    "Brak kategorii nadrzędnej": "No parent category",
    "Ten Tydzień": "This week",
    "Monitoring": "Monitoring",
    "Nazwa kategorii": "Category name",
    "Zobacz przed opublikowaniem": "View before publishing",
    "Statystyki regionalne - miasta": "Regional statistics - cities",
    "Dodaj dane wydziału": "Add departmental data",
    "Pokaż rezerwacje właścicielskie": "Show owner reservations",
    "Aktywne": "Active",
    "Liczba wyświetlanych slajdów": "Number of slides displayed",
    "Ustawienia wynagrodzenia zostały zaktualizowane.": "Salary settings have been updated.",
    "+": "+",
    "Data modyfikacji do": "",
    "Aktywne kanały komunikacji": "Active communication channels",
    "słowa": "words",
    "Powiadomienie o zbliżającym się zakończeniu szkolenia": "Notification of the upcoming end of the training",
    "Wszystkie zadania": "All tasks",
    "Wróć na dół": "Back to bottom",
    "Link został zaktualizowany.": "The link has been updated.",
    "Plik": "File",
    "Walidacja certyfikatu SSL": "SSL certificate validation",
    "Stawki nieruchomości": "",
    "Nazwa linku": "Link name",
    "Nieprzeczytane": "Unread",
    "Jedna kolumna": "One column",
    "Porównaj z edytowaną wersją": "Compare with edited version",
    "Wyznacz punkty": "Set points",
    "Zmniejsz": "Reduce",
    "Język:": "Language:",
    "Data publikacji": "Publication date",
    "Usługi": "Services",
    "Ilość pracowników": "Number of employees",
    "Wybierz temat": "Select a topic",
    "Typ nieruchomości": "",
    "Standard": "Standard",
    "Odpowiedź 2": "Answer 2",
    "Generuję plik...": "Generating a file...",
    "treść notatki": "contents of the note",
    "do": "to",
    "Program": "Program",
    "Zespół": "Team",
    "Dane rezerwacji": "Booking details",
    "Liczba pracowników zatrudnionych tylko w tym mieście": "The number of employees employed only in this city",
    "Poprawna odpowiedź": "Correct answer",
    "Nazwisko i Imię": "",
    "Brak tematu": "No topic",
    "Serwer bazy danych": "Database server",
    "Przeciągnij do innej sekcji": "Drag to another section",
    "Lista nieruchomości": "List of properties",
    "Prywatne": "Private",
    "Wymagane jest wybranie źródła danych": "Choosing data source is required",
    "Cyfry": "Digits",
    "Brak kluczy": "No keys",
    "Wprowadzona wartość jest za niska": "The value you entered is too low",
    "Potwierdzenie ukrycia": "Hiding confirmation",
    "Klient został zaktualizowany.": "The client has been updated.",
    "Obciążenie Magazyn": "Charge Storage",
    "Certyfikat został dodany.": "The certificate has been added.",
    "Inwentaryzacja": "Inventory",
    "Usuń stronę": "Delete page",
    "Rozpoczęcie": "Start ",
    "Jesteś pewien?": "",
    "Edytuj uprawnienia": "Edit permissions",
    "Polska": "Poland",
    "Dodanie linku": "Add link",
    "Aranżacja została opublikowana.": "The arrangement has been published.",
    "Dodanie roli": "Add role",
    "Nowy materiał": "New material",
    "Pytania i odpowiedzi": "Questions & Answers",
    "Użytkownik został dodany.": "The user has been added.",
    "Tag": "Tag",
    "Wynagrodzenia mieszane": "Mixed salary",
    "Stwórz czat z zadania": "Create chat from task",
    "Nieopłacone/dłużnik": "Unpaid/debtor",
    "Właściwości": "Characteristics",
    "Nazwa konta (Klucz dostępu)": "Account Name (Access Key)",
    "Kanały obsługiwane przez wybrany typ powiadmienia": "Channels supported by the selected notification type",
    "Coś poszło nie tak": "Something went wrong",
    "Wybierz blok": "Select a block",
    "Województwo": "Province",
    "Nie udało się pobrać listy kategorii": "Failed to download category list",
    "Szkolenie": "Training",
    "Ok": "Ok",
    "Poprzedni miesiąc": "Previous month",
    "Certyfikat został zaktualizowany.": "The certificate has been updated.",
    "Skopiowano": "Copied",
    "Zapytanie SQL": "SQL Query",
    "Od poziomu": "From level",
    "Konto publiczne": "Public account",
    "Nie udało się usunąć użytkownika z czatu": "",
    "Ma dzisiaj": "",
    "Domyślny": "Default",
    "Nie udało się pobrać powiadomień.": "Failed to download notifications.",
    "Bloki": "Blocks",
    "Nazwa obiektu": "Object name",
    "Tło przycisku": "Button background",
    "Uwzględniaj Miasta": "Include cities",
    "Podsumowanie": "Summary",
    "Uwaga! wraz z miastem": "",
    "Harmonogram": "Schedule",
    "Kwota pozostała": "Remaining amount",
    "Przenieś wyżej": "Move higher",
    "Zespół został usunięty.": "The team has been removed.",
    "Potwierdzam, zapisz": "",
    "po %0 nieudanych próbach logowania": "after %0 unsuccessful login attempts",
    "Załączniki": "Attachments",
    "ID nazwa obiektu": "ID property name",
    "Aktywna nieruchomość": "Active property",
    "Ilość osób": "Number of people",
    "usunięto dzielnice": "",
    "Czwartek": "Thursday",
    "Czy na pewno chcesz zablokować użytkownika?": "Are you sure you want to block a user?",
    "Zarządzanie witrynami": "Manage sites",
    "Otwórz Ustawienia": "Open settings",
    "Slajder": "Slider",
    "Dostęp od": "Access from",
    "Zapauzowano:": "",
    "Ostatnia aktywność": "Recent activity",
    "Filtry kalendarza": "Calendar filters",
    "Edycja": "Edit",
    "Edytuj blok": "Edit block",
    "Wybierz plik lub katalog": "Select a file or directory",
    "Data informująca o terminie wejścia w życie tego regulaminu.": "The date informing about date of entry into force of these regulations.",
    "Inna": "",
    "Przyczyna": "Reason",
    "Włącz social media": "Enable social media",
    "Wczytuję...": "Loading...",
    "Status: ": "Status: ",
    "Wybierz certyfikat": "Select a certificate",
    "Wersje aranżacji": "Arrangement versions",
    "Dodaj nowy katalog aby rozpocząć zarządzanie stronami.": "Add a new directory to start managing your pages.",
    "Tłumacz": "Translate",
    "Data zakończenia/wykonania": "End/Execution Date",
    "Strona testu opublikowana": "Test page published",
    "Edycja ścieżki szkoleniowej": "Edit learning path",
    "Ilość łóżek dwuosobowe": "Number of double beds",
    "Odrzucone zgody": "Rejected consents",
    "Odrzuć": "Reject",
    "Dodaj zadanie": "Add a task",
    "Host dodatkowy": "Secondary host",
    "Tryb monitorowania aktywności wyłączony.": "Activity monitoring mode disabled.",
    "Tytuł": "Title",
    "Avantio ID nieruchomości": "Avantio Property ID",
    "Classic mode": "Classic mode",
    "Checklista:": "Checklist:",
    "Tagi": "Tags",
    "Przypisani użytkownicy": "Assigned users",
    "Czy opublikowany": "Whether published",
    "Brak": "None",
    "Wyszukaj": "Search",
    "$% - znacznik zamykający tag": "$% - tag to close a tag",
    "Komunikat mailowy": "E-mail message",
    "Wersje dokumentu": "Document versions",
    "Premiera": "Premiere",
    "Kalendarz Pracowniczy": "",
    "Dostępne języki": "Available languages",
    "Miniaturka wideo": "Video thumbnail",
    "Mieszane": "Mixed",
    "Zadanie zostało dodane.": "The task has been added.",
    "Ustawienia zostały zapisane.": "Settings have been saved.",
    "Dodane przez moje zespoły": "Added by my teams",
    "Opublikowany na stronach": "Published on pages",
    "Formularz kontaktowy": "Contact form",
    "Symbol może składać się tylko z małych liter, cyfr oraz znaku - ": "A symbol can only consist of lowercase letters, numbers, and a character - ",
    "Standardowa strona": "Standard page",
    "Kalendarz - Kategorie zasobów": "Calendar - Resource Categories",
    "Tryb monitorowania aktywności": "Activity monitoring mode",
    "Rodzaj odpowiedzi": "Type of response",
    "Rozpocznij synchronizację": "",
    "Zobacz sesję użytkownika": "View user session",
    "Nie udało się zapisać szablonu": "Failed to save template",
    "...nie znalazłeś bloku?": "... have you not found the block?",
    "Małe znaki": "Small characters",
    "Nieobecny od": "Absent from",
    "Dołącz stronę do pliku sitemap.xml": "Add the page to the sitemap file.xml",
    "Zabierz dostęp": "Take away access",
    "Filtr został zapisany": "Filter has been saved",
    "Upload plików": "Upload files",
    "Kwota opłacona do": "Amount paid up to",
    "Przypisane:": "Assigned:",
    "Moduły": "Modules",
    "Wybrany język": "Selected language",
    "Szczegóły zadania": "Task details",
    "Jednokrotnego wyboru": "Single choice",
    "Marketing": "Marketing",
    "Brakujące tytuły kafelków.": "Missing tile titles.",
    "To miasto znajduję się już w tym regionie.": "This city is already in this region.",
    "Czas": "Time",
    "Wyszukiwany ciąg znaków": "Search set of characters",
    "Treść notatki": "Contents of the note",
    "Nieruchomości (Data przyjazdu)": "Properties (Arrival Date)",
    "Inwentaryzacja została usunięta.": "Inventory has been removed.",
    "Brak nowych powiadomień.": "No new notifications.",
    "Wielkość kroku": "Step size",
    "Administracja - Zespoły": "Administration - Teams",
    "Back Office (BO)": "Back Office (BO)",
    "Edytuj stawkę za godzinę": "Edit hourly rate",
    "Edycja poradnika": "Edit guide",
    "Brak modułu": "No module",
    "Nazwa sieci Wi-Fi": "Wi-Fi network name",
    "Użytkownik został zaktualizowany.": "The user has been updated.",
    "Renters": "Renters",
    "W tym katalogu nie ma jeszcze żadnych stron.": "There are no pages in this directory yet.",
    "Dział:": "Department:",
    "Brak działu nadrzędnego": "No parent department",
    "Narzędzia": "Tools",
    "Użytkownik został zablokowany, a jego wiadomość ukryte.": "The user has been blocked and their message has been hidden.",
    "Zadanie na dziś": "",
    "Kod Google Webmaster": "Google Webmaster Code",
    "Co 6 godzin": "Every 6 hours",
    "Zobacz czaty": "See chat",
    "Numer rezerwacji": "Reservation number",
    "Link": "Link",
    "Wybierz paczkę SCORM": "Select the SCORM package.",
    "Archiwalny": "Archival",
    "Usunięcie": "Deletion",
    "Edytuj urlop": "Edit absence",
    "Edytuj obraz": "Edit image",
    "Podano nieprawidłowe dane.": "Invalid data provided.",
    "Dodanie działu": "Add department",
    "Bezpieczeństwo": "Safety",
    "Usuń zadanie": "",
    "Wymagane": "Required",
    "Krótki tytuł": "Short title",
    "Wyszukaj zespół": "Search teams",
    "Sprzątanie": "Cleaning",
    "Pokaż zdjęcie w raporcie": "Show photo in report",
    "Kategoria": "Category",
    "Źródło URL": "URL source",
    "Co 10 dni": "Every 10 days",
    "Zaopatrzenie zostało zaktualizowane.": "The supply has been updated.",
    "Element": "Element",
    "Edycja zestawu regulaminóe": "Edit set of regulations",
    "Udało się usunąć zadania!": "",
    "Liczba pracowników": "Number of employees",
    "Token został usunięty.": "The token has been removed.",
    "Region apartamentu": "Region of the apartment",
    "Musisz najpierw zapisać zmienioną kolejność punktów zanim oznaczysz punkt": "",
    "Role i uprawnienia": "Roles and permissions",
    "Opublikuj": "Publish",
    "Nazwa dzielnicy": "",
    "Kontakty - Typ działania": "Contacts - Activity Type",
    "Kolor trasy": "Route color",
    "Ukryj": "Hide",
    "Wykonawca": "Artist ",
    "Wstęp": "Introduction",
    "Nikomu nie przydzielono uprawnień": "No one has been granted permissions",
    "Zakończenie": "End",
    "Przyznaj dostęp": "Grant access",
    "Dodanie regulaminu": "Add regulations",
    "Zawierające jeden z wybranych tagów": "Containing one of the selected tags",
    "Suma wynagrodzeń koordynatorów": "Sum of coordinators' salaries",
    "Moduł": "Module",
    "Status edytowanej wersji dokumentu": "Status of the edited version of the document",
    "Historia nieruchomości": "History of the property",
    "Dodaj zaopatrzenie": "Add Supplies",
    "Lista urządzeń na których jesteś zalogowany": "List of devices on which you are logged in",
    "Booking with conflict": "Booking with conflict",
    "Wszystkie niezapisane zmiany zostaną utracone.": "Any unsaved changes will be lost.",
    "Nie można dodać pliku(ów): \"%0\". Można dodawać tylko pliki typu: \"%1\".": "Unable to add file(s): \"%0\". You can only add files of type: \"%1\".",
    "Dodaj test": "Add test",
    "Dane do sieci": "Data to the network",
    "Housekeeping (HK)": "Housekeeping (HK)",
    "Tylko serwer deweloperski. Nie używać w trybie produkcyjnym. ": "Development server only. Do not use in production mode. ",
    "Zakończone: ${$filters.datetime(task.dateCompleted)}": "Completed: ${$filters.datetime(task.dateCompleted)}",
    "Zablokowano możliwość edycji": "",
    "Powiadomienie jest domyślnie wymagane. Nie da się wyłączyć wysyłania tego powiadomienia.": "Notification is required by default. You cannot turn off this notification.",
    "Widoczność": "Visibility",
    "Opracowanie kosztu": "Cost edit",
    "Nazwa przycisku ``Wyślij``": "Button name ''Send''",
    "Dokument": "Document",
    "Nazwa katalogu": "Directory name",
    "Wszystkie typy": "All types",
    "Edycja inwentaryzacji": "Edit inventory",
    "Dostępne obiekty:": "Available objects:",
    "Publikacja aranżacji została wyłączona.": "The publication of the arrangement has been disabled.",
    "Marka": "Brand",
    "Indywidualne tokeny": "Individual tokens",
    "Data utworzenia zadania": "Date the task was created",
    "Slajdery": "Sliders",
    "Aktywna": "Active",
    "Kwota kosztu od": "Cost amount from",
    "Zacznij pisać aby wyszukać zaopatrzenie...": "Start typing to search for supplies...",
    "Zajęte": "Occupied",
    "Wersje": "Versions",
    "Częstotliwość odświeżania": "Refresh rate",
    "Nieudane": "Failed",
    "Wyszukaj...": "Search...",
    "Publikacja priorytetowa": "Priority publication",
    "Statystyki wyniosków": "Request statistics",
    "Lewy panel": "Left panel",
    "Sortowanie malejąco": "Sort descending",
    "Zaopatrzenie": "Supply",
    "Wyślij testowe powiadomienie": "Send test notification",
    "Wybór listy stron": "Selection of the list of pages",
    "Podana wartość jest nieprawidłowa.": "The provided value is not valid.",
    "Wyszukaj nieruchomość...": "Search for a property...",
    "Odrzucone": "Rejected",
    "Pokaż cały błąd": "Show whole error",
    "Warunkowo": "Conditionally",
    "Typ powiadomienia": "Notification type",
    "Próg zaliczenia (%)": "Pass marker (%)",
    "Nie podano nazwy poprzedniego elementu.": "No name is given for the previous element.",
    "Data dodania": "Adding date",
    "Edytuj stronę": "Edit page",
    "Zacznij pisać aby wyszukać tag...": "",
    "Informacje o czacie": "Chat info",
    "Dodaj zadanie: inspekcja": "Add task: Inspection",
    "Zgłoszone problemy": "Reported issues",
    "Stawka za godzinę brutto": "Gross hourly rate",
    "Pokaż etykietę": "Show tag",
    "Slajd został usunięty.": "The slide has been deleted.",
    "Liczba podejść": "Number of approaches",
    "Zaakceptowany": "Accepted",
    "Przeterminowane": "Overdue",
    "Wydział / Rola": "Department / Role",
    "Autor": "Author",
    "Kwota pobrana": "Amount collected",
    "Wstaw treść widoczną po rozwinięciu": "Insert content visible when expanded",
    "Tylko małe litery, bez znaków specjalnych, cyfr i spacji.": "Lowercase letters only, no special characters, numbers, and spaces.",
    "Pobierz plik": "Download file",
    "Konfiguracja harmonogramu": "Schedule configuration",
    "Apple iPhone 6": "Apple iPhone 6",
    "Zdjęcia": "Photos",
    "Wybrano:": "Selected:",
    "ID zadania": "Task ID",
    "Wyrażenia warunkowe": "Conditional expressions",
    "Wiadomości": "Messages",
    "Typ obciążenia": "Cost type",
    "Wstaw stronę": "Insert page",
    "W polu możemy umieszczać jedynie cyfry": "",
    "Rozwiń": "Expand",
    "Najwyższy": "Highest",
    "Google Nexus 10": "Google Nexus 10",
    "Rozpoczęto synchronizację rezerwacji": "",
    "Nie możesz wznowić obsługi tego zadania, ponieważ wykonujesz teraz inne zadanie": "",
    "Ustawienia sekcji \"%0\"": "Settings of the \"%0\" section",
    "Docelowa strona": "Target page",
    "Log błędu": "Error log",
    "Brak nowych wiadomości.": "No new messages.",
    "Zaakceptuj, wyślij": "Accept, send",
    "Statystyki": "Statistics",
    "Odstęp": "Space",
    "Uwzględniaj zadania z miesiąca": "Include the month's tasks",
    "Pomoc": "Help",
    "Ścieżka szkoleniowa została usunięta.": "The learning path has been removed.",
    "Podana nazwa jest zajęta.": "The name you entered is already taken.",
    "Maj": "May",
    "Dodaj zespół": "Add team",
    "Właściciel regionu": "Region owner",
    "Przypisane miasta:": "Assigned cities:",
    "Wymagaj kolejności zaliczania elementów": "Require element passing order",
    "Godzina wymeldowania": "Check-out time",
    "Dodaj szablon": "Add template",
    "Tytuł formularza nie może być dłuższy niż %0 znaków": "The title of the form cannot be longer than %0 characters",
    "Data aktualizacji": "Update date",
    "Przeglądanie logów aktywności": "View activity logs",
    "Tekst alternatywny": "Alternative text",
    "Czy jesteś pewien, że chcesz przypisać dzielnicę do nowego miasta? Wszystkie nieruchomości i użytkownicy przypisani do tej dzielnicy przestaną być do niej przypisani.": "",
    "Jeszcze nie ma kategorii.": "There are no categories yet.",
    "Zdjęcie": "Photo",
    "Opublikowane języki": "Published languages",
    "Oznacz widoczne jako przeczytane": "Mark visible as read",
    "Temat": "Subject",
    "Nie można usunąć swojego konta.": "You can't delete your account.",
    "Wstaw dwie równe kolumny": "Insert two equal columns",
    "Uczestnicy rozmowy:": "Chat participants:",
    "Obciążenie Gościa": "Charge Guest",
    "KRS": "KRS",
    "Obok siebie": "Next to each other",
    "Szerokość": "Width",
    "Imię i nazwisko pracownika": "Name and surname of employee",
    "Dodaj urlop": "Add absence",
    "Checklista została zaktualizowana.": "The checklist has been updated.",
    "Priorytet": "Priority",
    "Wyloguj się": "Log out",
    "Ukryj czaty": "Hide chats",
    "zgłoś problem w apartamencie": "report a problem in the apartment",
    "30 minut": "30 minutes",
    "Domyślny tytuł aktywnej strony": "Default title of the active page",
    "Opis obrazka": "Image description",
    "Dodaj temat": "Add topic",
    "Symbol klienta": "Customer symbol",
    "Terminy do słownika będzie można dodawać dopiero po zapisaniu źródła danych.": "You won't be able to add terms to the dictionary until you save the data source.",
    "Imię właściciela": "Owner's name",
    "Miasto zostało zaktualizowane.": "The city has been updated.",
    "Wybierz kategorię": "Select a category",
    "Użytkownik jest liderem zespołu": "The user is a team leader",
    "Ustawienia zostały zaktualizowane.": "The settings have been updated.",
    "Brudne": "Dirty",
    "Pokaż tytuł sekcji": "Show section title",
    "Notatka do zadania": "Task note",
    "Konfiguracja bloków jest niedostępna ponieważ nie podano definicji szablonu.": "Block configuration is not available because no template definition is provided.",
    "Data użycia": "Date of use",
    "W zespole do": "In the team to",
    "Host": "Host",
    "Data": "Date",
    "Kraj": "Country",
    "Wstaw z lewej strony": "Insert left",
    "Imię i nazwisko": "Name and Surname",
    "Strona została dodana.": "The page has been added.",
    "Data rezerwacji do": "",
    "Jest to lista obiektów które nie mają jeszcze przypisanego właściciela.": "This is a list of apartments that have not yet been assigned an owner.",
    "Usuń datę": "Delete date",
    "Kwota brakująca": "Amount missing",
    "Wartość": "Value",
    "Liczba inspektorów": "Number of inspectors",
    "Dostęp zabroniony": "Access denied",
    "Lista jest pusta": "The list is empty",
    "Gość": "Guest",
    "Serwer plików": "File server",
    "Dostęp": "Access",
    "Kategoria szkolenia": "Training category",
    "24 godziny": "24 hours",
    "Usunął": "Deleted",
    "Program szkolenia": "Training program",
    "Błąd 404. Nie znaleziono żądanego zasobu.": "Error 404. The requested resource was not found.",
    "Data uruchomienia": "Launch date",
    "Wybierz adres kanoniczny z listy lub podaj ręcznie.": "Select a canonical address from the list or enter it manually.",
    "Slajd został zaktualizowany.": "The slide has been updated.",
    "Ilość sypialni": "Number of bedrooms",
    "Paczka SCORM została zaktualizowana.": "The SCORM package has been updated.",
    "Przenieś niżej": "Move lower",
    "Wyłączone (NoIndex)": "Disabled (NoIndex)",
    "L4": "L4",
    "Co miesiąc": "Every month",
    "Data Wykonania": "Date of execution",
    "Inspekcja": "Inspection",
    "Kroki": "Steps",
    "Ukryj przycisk": "",
    "Tytuł witryny": "Site title",
    "Nieaktywne": "Inactive",
    "Wyżej": "Above",
    "Administracja - Role": "Administration - Roles",
    "Dokument został zaktualizowany.": "The document has been updated.",
    "Powiązane": "Related",
    "Godzina:": "",
    "Wygeneruj pilik": "Generate file",
    "Urządzenie zostało usunięte.": "The device has been removed.",
    "Zamknięte": "",
    "Poczta": "Mail",
    "Status regulaminu został zmieniony.": "The status of the regulations has been changed.",
    "Ustaw w kolejności": "Set in order",
    "Uwzględniaj nieruchomości": "",
    "Edycja linku": "Edit link",
    "Administracja - Województwa": "Administration - Voivodships",
    "Poprawne": "Correct",
    "Uwzględniaj miasta": "Include cities",
    "Pytanie i odpowiedzi": "Question and answers",
    "Prawy panel": "Right panel",
    "Średni": "Medium",
    "${title}": "${title}",
    "Linia": "Line",
    "Tryb wyświetlania": "Display mode",
    "Numer konta bankowego": "Bank account number (IBAN)",
    "Ustaw szerokości proporcjonalnie": "Set widths proportionally",
    "Data usunięcia": "Deletion date",
    "Czerwiec": "June",
    "Numer telefonu": "Phone number",
    "Symbol strony": "Page symbol",
    "Zapisz jako": "Save As",
    "10 minut": "10 minutes",
    "Koszty wg regionów": "Costs by region",
    "Kwota": "Amount",
    "Miasto apartamentu": "City of the apartment",
    "Slajder został usunięty.": "The slider has been removed.",
    "Widok tygodniowy": "Week view",
    "Numer mieszkania": "Apartment number",
    "Wersja nieopublikowana": "Unpublished version",
    "Średnie wynagrodzenie": "Average salary",
    "Edycja zastępstwa": "Edit substitution",
    "Data zakończenia od": "End date from",
    "Status paczki został zmieniony.": "The status of the package has been changed.",
    "Nieprawidłowy adres URL": "Invalid URL address",
    "Potwierdź usunięcie": "Confirm deletion",
    "Wartość domyślna": "Default value",
    "Opis zadania": "Task description",
    "Szkolenie kończy się": "Training is ending up",
    "Brak zadania": "No task",
    "Szablon został dodany.": "The template has been added.",
    "Potwierdzam": "Confirm",
    "Firma została usunięta.": "The company has been removed.",
    "Luty": "February",
    "Co 4 miesiące": "Every 4 months",
    "Wybierz koordynatora": "Choose a coordinator",
    "Koszt Renters": "Renters cost",
    "Google Nexus 5": "Google Nexus 5",
    "Edytuj slajder": "Edit slider",
    "Zacznik pisać Form lub Entry aby uruchomić podpowiadanie.": "Start typing Form or Entry to show the hints.",
    "Lista artykułów": "List of articles",
    "Sekcje": "Sections",
    "Ścieżka aktywnej strony": "Path of the active page",
    "Nazwa ścieżki": "Path name",
    "Zaopatrzenie zostało dodane.": "Supplies have been added.",
    "Dodanie właściciela": "Add an owner",
    "Data logowania": "Login date",
    "Nie masz uprawnień do modyfikacji aranżacji.": "You do not have permission to modify the arrangement.",
    "Rozpocznij trasę": "Start your route",
    "Ten kanał nie ma możliwości wysyłania tekstu sformatowanego.": "This channel does not provide a possibility to send formatted text.",
    "Stwórz zadanie z notatki": "Create a task from a note",
    "Zadanie zostało zapauzowane.": "",
    "Aktualne urządzenie": "Current device",
    "Uwzględnij wydziały i statusy jednocześnie": "",
    "Dodaj nowy koszt": "Add new cost",
    "Blokada konta": "Account blocked",
    "Szkolenie jest puste": "Training is empty",
    "Posprzątane": "Cleaned up",
    "Imię": "First name",
    "Pokaż zdjęcia": "Show photos",
    "Skrócony opis": "Short description",
    "Ta nieruchomość nie posiada informacji na temat wyposażenia.": "This property has no information on amenity.",
    "Szczegóły zdarzenia nr %0": "Details of event number %0",
    "Pokaż czaty": "Show chats",
    "Definicja formularza znajduje się w obiekcie Form.": "The form definition is in the Form object.",
    "Strona logowania": "Login page",
    "Slajder został dodany.": "The slider has been added.",
    "Zdjęcie użytkownika": "User's photo",
    "Adres url": "Url",
    "Minuty": "Minutes",
    "Katalog": "Directory",
    "co 6 miesięcy": "every 6 months",
    "Harmonogram został zmieniony": "The schedule has been changed",
    "Komunikat walidacji": "Validation message",
    "Pokaż filtry": "Show filters",
    "Edytuj katalog": "Edit directory",
    "Edycja kategorii": "Edit category",
    "Usuń element": "Delete item",
    "Imię gościa": "Guest name",
    "Zawiera sekcje": "Contains sections",
    "Następna strona": "Next page",
    "Przejdź do zadania": "Go to task",
    "Wynagrodzenie za sprzątanie": "",
    "Niestandardowy adres": "Non-standard address",
    "Wymagaj odpowiedzi, aby przejść dalej": "Require a response to move on",
    "Role użytkownika": "User roles",
    "zacznij pisać aby wyszukać regulamin...": "start typing to search for the rules ...",
    "Zamiany zostały zapisane.": "The changes have been saved.",
    "Okładka": "Cover",
    "Piosenka": "Song",
    "Cztery kolumny": "Four columns",
    "Odejmij kwotę": "Subtract amount",
    "Uwzględnij zadania z miesiąca:": "Inclued tasks from month:",
    "Zajawka": "Sneak peek",
    "Organizuj": "Organize",
    "Błędne": "Incorrect",
    "Adres firmy": "Company address",
    "Paid": "Paid",
    "Wyszukaj blok": "Search blocks",
    "Zewnętrzny serwis": "External service",
    "Nazwa przycisku ``Dalej``": "Button name ''Next''",
    "zacznij pisać aby wyszukać certyfikat...": "start typing to search for a certificate...",
    "Dzielnica została dodana.": "",
    "Dodanie regionu": "Add a region",
    "np. pl-PL": "i.e., pl-PL",
    "Uruchom zadanie": "Run the task",
    "Miasta:": "Cities:",
    "Zapis został usunięty": "The record has been deleted",
    "Nazwa ulicy (StreetName)": "Street Name",
    "Nawiguj do kolejnego punktu": "Navigate to the next point",
    "Publikacja zarchiwizowana": "Archived publication",
    "Status": "Status",
    "Filtry": "Filters",
    "Twoje hasło wygasło. Zmień je na nowe.": "Your password has expired. Change it to new one.",
    "Edytuj tag": "Edit tag",
    "zacznij pisać aby wyszukać kategorię...": "start typing to search for a category...",
    "Lista zespołów": "Teams list",
    "Przesyłam...": "",
    "Pojedynczy wybór": "Single choice",
    "zaznacz wszystko": "select all",
    "Pliki publiczne": "Public files",
    "Login": "Login",
    "Status notatki": "Note status",
    "Zapisz trasę": "Save route",
    "Zapisz": "Save",
    "Ustawienia chatów": "Chat settings",
    "Top kosztów wg miast": "Top costs by city",
    "Adres IP": "IP address",
    "Nazwisko": "Last name",
    "Wybrane regulaminy:": "Selected regulations:",
    "Nieaktywni właściciele": "",
    "Co 10 minut": "Every 10 minutes",
    "Email został wysłany.": "Email has been sent.",
    "Użytkownik posiada pełne uprawnienia": "The user has full permissions",
    "Czas uruchomienia": "Start-up time",
    "Wydział": "Department",
    "Wymuszaj zaliczenie elementów ścieżki zgodnie z kolejnością szkoleń na liście": "Force path elements pass according to the order of training on the list",
    "Dodatkowe wynagrodzenie - kwota": "",
    "Powiadomienie dla administratora chatu o nowych wiadomościach": "Notification to the chat administrator about new messages",
    "Cancelled by owner": "Cancelled by owner",
    "Nie możesz modyfikować uprawnień": "You cannot modify permissions",
    "Jutro": "Tomorrow",
    "Edycja szkolenia": "Edit training",
    "Inspekcje": "Inspections",
    "Zestaw został zaktualizowany.": "The set has been updated.",
    "Symbol testu": "Test symbol",
    "Dodaj etykietę": "Add tag",
    "Domyślna witryna": "Default site",
    "Nazwa katalogu została zmieniona.": "The directory has been renamed.",
    "Typ elementu": "Element type",
    "Strona": "Page",
    "Notatka została usunięta.": "The note has been deleted.",
    "Dodaj": "Add",
    "Bank": "Bank",
    "Utwórz nowy": "Create new",
    "Wyświetlanie": "Displaying",
    "Aranżacja": "Arrangement",
    "Zadania do wykonania": "To-do tasks",
    "Urlop został dodany.": "Absence has been added.",
    "Nazwa przedmiotu": "Item Name",
    "Pokaż historię": "Show history",
    "Google Nexus 7": "Google Nexus 7",
    "Status linku został zmieniony.": "The status of the link has been changed.",
    "Treść wiadomości": "Message content",
    "Szablon został zaktualizowany.": "The template has been updated.",
    "Wybierz pliki": "Select files",
    "Komentarz Konserwatora": "Handyman comment",
    "Brak dodanych punktów trasy": "",
    "Dopasowanie": "Match",
    "Konserwacja": "Maintenance",
    "Umożliwienie sprawdzania odpowiedzi": "Enable checking of responses",
    "Tytuł aktywnej witryny": "Title of the active site",
    "Dane Adresowe": "Address data",
    "Harmonogram zadań": "Task schedule",
    "Tryb konserwacji systemu": "System maintenance Mode",
    "Tag został usunięty.": "",
    "Przedrostek": "Prefix",
    "Edycja wynagrodzenia": "Edit salary",
    "Kierowca": "Driver",
    "Potwierdzenie archiwizacji": "Archiving confirmation",
    "Edycja wynagrodzenia mieszanego dla:": "Edit mixed salary for:",
    "Nazwa elementu": "Item name",
    "Przenieś do sekcji": "Move to section",
    "Liczba nocy": "Number of nights",
    "Liczba dni co ile wysyłane jest powiadomienie": "Number of days of how often a notification is sent",
    "Użytkownik i jego wiadomości zostały przywrócone.": "The user and their messages have been restored.",
    "Podgląd szablonu e-maila": "Email template preview",
    "Nie podano nazwy tytułu.": "No title name is given.",
    "Plik wideo": "Video file",
    "Data ostatniej zmiany statusu": "Date of last status change",
    "Edytuj zadanie": "",
    "Profil użytkownika": "User's profile",
    "Dodawanie trasy": "Add a route",
    "Błąd": "Error",
    "Profil koordynatora": "Coordinator profile",
    "Data nieobecności do": "Date of absence to",
    "Potwierdź wyłączenie publikacji": "Confirm that you want to disable publication",
    "Nazwa slajdera": "Slider name",
    "Warunki logiczne": "Logical conditions",
    "Kwota za zadania": "Pay per task",
    "Zmień nazwę pliku": "Rename a file",
    "Kwota jednostkowa": "Pay per unit",
    "Zapamiętaj logowanie": "Remember login",
    "Nie udało się zmienić statusu zadania": "",
    "Wybrany szablon": "Selected template",
    "Data ostatniej inwentaryzacji": "Date of the last inventory",
    "Wyszukaj tag...": "Search for a tag...",
    "Nieudane próby logowania": "Failed login attempts",
    "i posiadających wymagane uprawnienia": "and having the required permissions",
    "Zaloguj się na swoje konto": "Log in to your account",
    "ZMIANA": "CHANGE",
    "Kolor tła": "Background color",
    "Podaj adres url...": "Enter the url...",
    "Edycja nieruchomości roboczej": "",
    "Nie możesz rozpocząć obsługi tego zadania, ponieważ wykonujesz teraz inne zadanie": "",
    "Dodaj wiersz": "Add row",
    "Kategoria materiałów": "Material category",
    "Publikacja": "Publication",
    "Nazwa apartamentu": "Name of the apartment",
    "Wpisz nazwę trasy": "Enter a name for the route",
    "Strona dostępna dla wszystkich": "Website available to everyone",
    "Rola przypisana do tego wydziału jest nieaktywna": "The role assigned to this department is inactive",
    "Zadanie zostało włączone": "The task has been enabled",
    "Wpis został dodany.": "The post has been added.",
    "Szukana fraza": "Search term",
    "Nazwa tytułu nie może być dłuższa niż 240 znaków ani krótsza niż 3 znaki.": "The title cannot contain neither more than 240 characters nor less than 3 characters.",
    "Designer": "Designer",
    "Lipiec": "July",
    "Rozwiązane": "Resolved",
    "Czyim kosztem jest dodany przedmiot?": "At whose expense is the item added?",
    "Progress Gotowości": "Readiness progress",
    "Udało się dodać nieruchomość do trasy": "",
    "Zobacz wszystkie": "See all",
    "Wynagrodzenia Kierowcy": "Drivers salary",
    "Stwórz czat": "Create chat",
    "Dodaj link": "Add link",
    "Wysoki": "High",
    "Data wyjazdu": "Date of departure",
    "Wyświetlaj tylko treść": "Display only content ",
    "E-learning": "E-Learning",
    "Edycja właściciela": "Edit owner",
    "Nowe połączenie": "New connection",
    "Zostałeś zablokowany, dodanie wiadomości jest niemożliwe": "You have been blocked, adding a message is not possible",
    "Typ serwera": "Server type",
    "Zespół aktywny": "Active team",
    "Kategoria została zaktualizowana.": "The category has been updated.",
    "Numer domu": "House no",
    "Numer mieszkania (Door)": "",
    "Element został dodany.": "The element has been added.",
    "Pomieszczenie": "Room",
    "Zarządzanie zespołami": "Team management",
    "Strona dostępna tylko dla zalogowanych użytkowników": "Page available only to logged in users",
    "Dodanych plików %0 (limit wynosi %1).": "Added files %0 (limit is %1).",
    "Test został zaktualizowany.": "The test has been updated.",
    "Wymeldowanie": "",
    "Port": "Port",
    "Wynagrodzenie PLN brutto": "Gross salary amount in PLN",
    "Właściwość ``%0`` aktywnego wpisu": "The ''%0'' feature of the active entry",
    "Strona została zaktualizowana.": "The page has been updated.",
    "Poniedziałek": "Monday",
    "Zapisz slajd": "Save slide",
    "Wybrana liczba jest mniejsza niż dozwolona %0.": "Selected number is lesser than allowed %0.",
    "Wersja opublikowana": "Published version",
    "Losowa kolejność elementów": "Random order of elements",
    "Id modelu: %0": "Model Id: %0",
    "Twój token": "Your token",
    "Zwierzątko": "Pet",
    "Pozycja": "Position",
    "Maksymalny rozmiar pliku w MB": "Maximum file size in MB",
    "Po zaznaczeniu tej opcji uprawnienia indywidualne i pochodzące z ról nie są brane pod uwagę.": "When you select this option, individual and role permissions will not be considered.",
    "Opublikowany": "Published",
    "Nazwa aktywnej strony": "Active page name",
    "Uprawnienia użytkownika": "User permissions",
    "Serwer został zaktualizowany.": "Server has been updated.",
    "Nie udało się pobrać listy kanałów": "Failed to download channel list",
    "do czasu ręcznego odblokowania": "until manually unlocked",
    "Nie podano opisu.": "No description is given.",
    "Artykuły": "Articles",
    "Przeczytane": "Read",
    "Aby zobaczyć aktualny podgląd certyfikatu kliknij Zapisz.": "To preview the certificate, click Save.",
    "Wybierz pliki (do {0})": "Select files (to {0})",
    "Usuń powiązanie": "Unlink",
    "Posiadasz niezapisane materiały": "You have unsaved materials",
    "Zmień nazwę": "Rename",
    "Dostępy": "Available",
    "Słownik": "Dictionary",
    "Blokowanie użytkowników": "Block users",
    "Logowanie": "Log in",
    "Zakolejkowane": "Pending",
    "Podaj dane autora": "Enter author details",
    "Rozwiń do": "Expand to",
    "Wyszukiwarka miejsc": "",
    "Dostępny w sekcjach": "Available in sections",
    "Ilość wyświetlanych aktualności": "Number of news updates displayed",
    "Dostęp przyznany": "Access granted",
    "Data utworzenia do": "Creation date to",
    "Dodawanie wyposażenia": "Adding amenities",
    "Maksymalna ilość plików": "Maximum number of files",
    "Pole \"%0\" jest wymagane.": "Field ''%0'' is required.",
    "Wystąpił problem z dostępem do kamery": "",
    "Monitor kolejek": "Queue monitor",
    "Dział nadrzędny": "Parent department",
    "Brak kategorii": "No categories",
    "Automatyczne akceptacja wersji po osiągnięciu daty publikacji. Wybrana wersja musi mieć ustawiony status na \"Ukończony\"": "Automatic version acceptance on the publication date. The selected version must be given status \"Completed\"",
    "Nr rezerwacji:": "Booking no:",
    "Nie znaleziono żadnych wyników.": "No results found.",
    "Formularz": "Form",
    "Brak wyników": "No results",
    "Zgoda": "Consent",
    "E-mail": "E-mail",
    "Szkolenie jest opublikowane": "The training is published",
    "Wykonane zadania": "Completed tasks",
    "Usuń ze szkolenia": "Remove from training",
    "Wynagrodzenia Pracownicy": "Employees salary",
    "Firma własna": "Own company",
    "Daty publikacji zostały zaktualizowane.": "The publication dates have been updated.",
    "Powrót": "Back",
    "Data rejestracji": "Registration date",
    "Wybierz strony": "Select pages",
    "Dodanie testu": "Add test",
    "Potwierdzenie usunięcia. Usunięcie tematu spowoduje usunięcie przypisanych do niego lekcji": "Confirmation of deletion. Deleting a topic will delete the lessons assigned to it",
    "Dodaj kategorie": "Add category",
    "Suma wynagrodzeń pracowników": "Sum of employees' salaries",
    "Wstaw cztery kolumny 1/4": "Insert four columns 1/4",
    "Przetwarzane": "Processing",
    "Zadanie zostało zaktualizowane.": "The task has been updated.",
    "Moje zadania": "My tasks",
    "Wybierz paczkę": "Select a package",
    "Adres aktywnej strony": "Address of the active page",
    "Wskazuje robotom wyszukiwarek czy mają indeksować stronę.": "It indicates to search engine robots whether to index the page.",
    "Lekcja ukryta": "Hidden lesson",
    "Nazwa płyty": "Disc name",
    "Regulamin został dodany.": "Regulations have been added.",
    "Nieruchomość": "Property",
    "Układ": "Layout",
    "Data modyfikacji od": "",
    "Stałe/miesięczne": "Fixed/monthly",
    "Bezpieczne połączenie SSL": "Secure SSL connection",
    "Zapisz wersję": "Save version",
    "Data automatycznej akceptacji": "Auto acceptance date",
    "Regulamin wymagany": "Regulations required",
    "Monitor aktywności": "Activity monitor",
    "Kontener plików": "File container",
    "Sekcje wspólne": "Common sections",
    "Sortuj po": "Sort by",
    "Wybrana data jest mniejsza niż dozwolona %0.": "Selected date is before the permitted one %0.",
    "Subskrypcja": "Subscription",
    "Dodaj Koszt": "Add cost",
    "Nazwa grupy": "Group name",
    "Co drugi dzień": "Every other day",
    "Lista plików": "List of files",
    "Wartość 0 oznacza brak limitu": "A value of 0 indicates no limit",
    "Edytuj lekcje SCORM": "Edit SCORM lessons",
    "Ilość zadań": "Number of tasks",
    "Ten miesiąc": "This month",
    "Dodanie": "Adding",
    "Synchronizuj razem z anulowanymi": "",
    "Usuń katalog": "Delete directory",
    "Notatka": "",
    "Uzupełnij dane dotyczące szkolenia": "Fill in the training data",
    "Ilość dostępnych bloków": "Number of available blocks",
    "Trasa została zaktualizowana.": "The route has been updated.",
    "Stwórz zadanie": "",
    "Zacznij pisać aby wyszukać tag nieruchomości...": "Start typing to search property tag...",
    "Symbol strony szkolenia": "Training page symbol",
    "Laptop 15\"": "15\" laptop",
    "Nie udało się wczytać podglądu strony.": "Failed to load the website preview.",
    "Wynagrodzenie za przepracowane godziny": "Salary for hours worked",
    "Dodaj do zespołu": "Add to team",
    "Łączny czas wykonywania zadań": "Total task completion time",
    "Przypisane regiony:": "Assigned regions:",
    "Data utworzenia wersji": "Version creation date",
    "Zadanie zostało ukończone przez kogoś z Twojego zespołu.": "The task was completed by someone from your team.",
    "Edycja aktualności": "Edit news",
    "Raczej się zgadzam": "I rather agree",
    "Symbol lekcji": "Lesson symbol",
    "Użytkownik został usunięty.": "User has been deleted.",
    "Plik został wysłany": "The file has been sent",
    "Publikuj na stronie": "Publish to page",
    "Szczegóły rezerwacji": "",
    "Dopasuj do kategorii": "Match to category",
    "Pole \"%0\" nie zawiera prawidłowego adresu URL.": "Field ''%0'' does not contain a valid URL address.",
    "Lekcja może zawierać tylko materiały opisowe lub materiały video": "The lesson can contain only descriptive or video materials",
    "Filtry systemowe": "System filters",
    "Lista ścieżek szkoleniowych": "Learning path list",
    "Słownik dowolny": "Dictionary allowed",
    "Data wystąpienia": "Occurrence date",
    "Hasło zostało zmienione. Zaloguj się ponownie": "The password has been changed. Log in again",
    "Indeksowanie strony": "Page indexing",
    "Blok aktywny": "Active block",
    "REGON": "REGON",
    "Ustawienia Domyślne": "Default settings",
    "Link do strony": "Website link",
    "Wzorzec": "Pattern",
    "Kreator trasy: ": "Route creator: ",
    "Wstaw dwie kolumny 1/3 oraz 2/3": "Insert two columns 1/3 and 2/3",
    "Użytkownik został przypisany do szkolenia.": "The user has been assigned to the training.",
    "Ten użytkownik nie posiada jeszcze przypisanych miast.": "This user does not have assigned cities yet.",
    "Nie udało się usunąć szablonu": "Failed to delete template",
    "Nazwa chatu": "Chat name",
    "Data i czas": "Date and time",
    "Nazwa autora": "Author name",
    "Serial telewizyjny": "Television series",
    "Dodanie wpisu": "Add entry",
    "Batch": "Batch",
    "Zapisz zmiany": "Save changes",
    "Ta nieruchomość już posiada takie wyposażenie.": "This property already has such amenity.",
    "Ustawienia dostępności": "Accessibility settings",
    "Nie podano odpowiedzi dla ostatniego elementu.": "No response was given for the last element.",
    "Wersja utowrzona przez": "Version created by",
    "Mapa": "Map",
    "Magazyn": "Storage",
    "Lekcja została dodana.": "The lesson has been added.",
    "Włącz system powiadomień": "Enable the notification system",
    "Kategoria wyszukiwanego matariału": "Category of the searched material",
    "Szukaj": "Search"
  },
  "uk-UA": {
    "Miasta": "Міста",
    "Zacznij pisać aby wyszukać inspektora...": "Почніть вводити текст для пошуку інспектора...",
    "Przełącznik": "Перемикач",
    "Token": "Toкен",
    "Tekst": "Текст",
    "Pole \"Drugie imię\" jest wymagane.": "Поле «Друге ім'я» обов'язкове для заповнення.",
    "Dodaj nieruchomość do trasy": "Додайте нерухомість до маршруту",
    "Pokaż więcej": "Показати більше",
    "NIE": "НІ",
    "Avantio ID": "Авантіо ID",
    "Nie udało się dodać nieruchomość do trasy": "Не вдалося додати нерухомість до маршруту",
    "Firma domyślna": "Компанія за замовчуванням",
    "Ilość wyników do pobrania": "Кількість результатів для завантаження",
    "Peticion disponibilidad": "Peticion disponibilidad",
    "Eksportuj do PDF": "Експортуйте до PDF",
    "Nie do odzyskania": "Не підлягає відновленню",
    "BIC/Swift": "BIC/Swift",
    "Ilość gości": "Кількість гостей",
    "Nieruchomość została usunięta.": "Нерухомість видалена.",
    "Niewidoczne": "Невидимий",
    "Brak dostępnych tras": "Бракує доступних маршрутів",
    "Informacje Podstawowe": "Основна Інформація",
    "Miejsce logowania": "Місцезнаходження для входу",
    "Dodaj zastępstwo": "Додати заміну",
    "Informacje podstawowe": "Основна інформація",
    "Powiększ": "Збільшити",
    "Litery": "Літери",
    "Przypisana do (Imię i Nazwisko)": "Призначено (ім'я та прізвище)",
    "Akcje": "Дії",
    "Klienci": "Клієнти",
    "Proszę obciążyć gościa": "Будь ласка, стягніть плату з гостя",
    "Do akceptacji": "На ухвалення",
    "Koordynator Główny": "Головний координатор",
    "Godziny": "Години",
    "Miasto zostało dodane.": "Місто додалося.",
    "Osoba zastępowana": "Особа, яку замінюють",
    "Przesuń do góry": "Проведіть вгору",
    "Co godzinę": "Щогодини",
    "Usuń z zespołu": "Видалити з команди",
    "Wydział zadania": "Відділ завдань",
    "Ilość nocy": "Кількість ночей",
    "Zaznacz wszystkie": "Виділити всі",
    "Rozliczenia pracowników": "Розрахування працівників",
    "Akceptuj": "Прийми",
    "Klient został usunięty.": "Клієнт був видалений.",
    "Wyzeruj licznik": "Скинути лічильник",
    "Wybierz kierowce": "Виберіть водія",
    "Zapomniałeś hasła?": "Забули пароль?",
    "Dane dostępu": "Дані доступу",
    "Wstaw dwie kolumny 2/3 oraz 1/3": "Вставте два стовпця 2/3 і 1/3",
    "Dodawanie checklisty": "Додавання чек-листа",
    "Średnie wynagrodzenie pracownika": "Середня зарплата працівника",
    "Data wykonania do": "Дата виконання до",
    "Nie znaleziono żadnych plików": "Файлів не знайдено",
    "Przyjazd": "Прибуття",
    "Nie udało się pobrać danych o rolach zalogowanego użytkownika": "Не вдалося отримати дані про роль користувача, який увійшов до системи",
    "Zacznij pisać numer aby wyszukać źródło rezerwacji...": "Почніть писати номер для пошуку джерела бронювання....",
    "Zdarzenie": "Подія",
    "Baza danych": "База даних",
    "Wstaw szablon informacyjny ze zdjęciem": "Вставити інформаційний шаблон з фотографією",
    "Zacznij pisać aby wyszukać region...": "Почніть вводити текст для пошуку регіону...",
    "Wyrażenie regularne": "Регулярний вираз",
    "Pilne": "Термінове",
    "Właściciele": "Власники",
    "Przeciągnij i upuść aby posortować pliki": "Перетягніть, щоб сортувати файли",
    "Dział został zaktualizowany.": "Розділ оновлено.",
    "Edycja checklisty": "Редагування чек-листа",
    "Gotowe": "Готовий",
    "Zacznij pisać numer aby wyszukać rezerwację...": "Почніть писати номер для пошуку бронювання....",
    "Ten użytkownik nie posiada jeszcze przypisanych dzielnic.": "Цей користувач ще не має призначених районів.",
    "Nazwa Firmy": "Назва Компанії",
    "Adres e-mail potwierdzony": "Підтверджена адреса електронної пошти",
    "Dodaj nieruchomość": "Додати нерухомість",
    "Dzielnica nieruchomości": "Район нерухомості",
    "Urlop został zaktualizowany.": "Відпустка актуалізована.",
    "Wprowadzona wartość nie jest poprawna": "Введене значення невірне",
    "Opracowane": "Обреблені",
    "Zastępstwo zostało dodane.": "Заміну додано.",
    "Data nieobecności od": "Дата відсутності від",
    "Osoba zastępująca": "Особа на заміну",
    "Niski": "Низький",
    "Imię klienta": "Ім'я клієнта",
    "Typ Firmy": "Тип Компанії",
    "Liczba": "Підрахунок",
    "Czas trwania blokady": "Тривалість блокади",
    "Nie przypisano": "Не призначено",
    "Nazwa szablonu": "Назва шаблону",
    "Sprawdzić": "Перевіряти",
    "Przywróć użytkownika": "Поверни користувача",
    "Pole wymagane": "Обов'язкове поле",
    "Rezerwacje": "Бронювання",
    "Region został dodany.": "Регіон додано.",
    "Oznacz wszystkie jako przeczytane": "Позначити все як прочитане",
    "Azure AD": "Azure AD",
    "Formularz został dodany.": "Форму додано.",
    "Pokaż szczegóły": "Показати подробиці",
    "Strona została usunięta.": "Сторінку видалено.",
    "Adres URL": "URL-адреса Adres",
    "Możliwość modyfikacji tego działu jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "Можливість модифікації цього розділу обмежена. Неактивні поля синхронізуються із зовнішньою базою даних %0.",
    "Wiadomość została ukryta.": "Повідомлення було приховано.",
    "Dodanie nieruchomości": "Додавання нерухомості",
    "Brak zadań": "Немає завдань",
    "Co drugi miesiąc": "Кожні два місяці",
    "Opis źródła": "Опис джерела",
    "Tag został zaktualizowany.": "Тег актуалізований.",
    "Edycja wynagrodzenia za posprzątane obiekty dla:": "Редагування заробітної плати за прибраний апартамент для:",
    "Wtorek": "Вівторок",
    "Właściciel aktywny": "Активний власник",
    "Pokaż tytuł formularza": "Показати заголовок форми",
    "Nie możesz edytować tego punktu. Został on już oznaczony jako odwiedzony": "Ви не можете редагувати цей пункт. Його вже позначено як відвіданий",
    "Został przywrócony oryginalny harmonogram": "Початковий графік був відновлений",
    "Zastępuje osobę": "Замінює людину",
    "Wybierz użytkownika": "Вибір користувача",
    "Na pewno usunąć wybrane elementy?": "Дійсно видалити вибрані елементи?",
    "Zdecydowanie tak": "Безумовно так",
    "Formularze": "Форми",
    "Element został przywrócony.": "Елемент відновлено.",
    "Dodano użytkownika do czatu": "Додано користувача до чату",
    "W zespole od %0": "У команді від %0",
    "Stopka": "Вихідні дані",
    "Co 3 godziny": "Кожні 3 години",
    "Komunikat": "Комунікат",
    "Wpis domyślny": "Запис за замовчуванням",
    "Dane wydziału zostały zaktualizowane.": "Дані відділу були оновлені.",
    "Przejdź do zadania z notatki": "Перейти до завдання із замітки",
    "Typ zadania": "Тип завдання",
    "Zastępstwo wygaśnie za minutę.": "Термін дії заміни закінчиться через хвилину.",
    "Numer sesji": "Номер сесії",
    "reset": "перезавантаження",
    "Edycja regionu": "Редагування області",
    "Pracownik niedostępny od": "Працівник недоступний від",
    "Obciążenie Właściciela": "Плата стягується з Власника",
    "Przypisane miasta": "Призначені міста",
    "W organizacji nie ma jeszcze żadnych działów.": "В організації поки немає відділів.",
    "Zobacz trasę": "Подивитися маршрут",
    "Kategoria została usunięta.": "Категорію видалено.",
    "Wpis został zaktualizowany.": "Запис оновлено.",
    "Nazwa roli": "Назва ролі",
    "Stawka domyślna": "Ставка за замовчуванням",
    "Więcej...": "Більше...",
    "Radio": "Радіо",
    "Brak przypisanych tagów.": "Ніяких тегів не призначено.",
    "Ilość": "Кількість",
    "Checkbox": "Чек-бокс",
    "Ten region nie posiada jeszcze przypisanych miast.": "Цей регіон ще не має закріплених за собою міст.",
    "Pralnia": "Пральня",
    "Zaczyna sie od": "Починається з",
    "Data modyfikacji": "Дата зміни",
    "Zawsze": "Завжди",
    "Imie i nazwisko": "Ім'я і прізвище",
    "Zadanie zostało ukończone.": "Завдання виконано.",
    "Utworzone": "Створене",
    "Edycja tagu nieruchomości": "Редагування тегу нерухомості",
    "Pole może zawierać jedynie cyfry.": "Поле може містити тільки числа.",
    "Numer telefonu: ": "Номер телефону: ",
    "Wysłano kolejne powiadomienie": "Відправлено чергове повідомлення",
    "Zmień hasło": "Змінити пароль",
    "Modyfikacja": "Зміненення",
    "Wysyłaj tym kanałem": "Надіслати через цей канал",
    "Region został zaktualizowany.": "Регіон оновлено.",
    "Usuń filtr": "Видалити фільтр",
    "Data wylogowania": "Дата виходу з системи",
    "Wykonaj": "Виконати",
    "Typ obiektu": "Тип об'єкта",
    "Dział aktywny": "Активний відділ",
    "Edytuj dział": "Редагувати розділ",
    "Bardzo niezadowolony": "Дуже незадоволений",
    "Zacznij pisać aby wyszukać...": "Почніть писати щоб шукати...",
    "Co 20 minut": "Кожні 20 хвилин",
    "Dodane przeze mnie": "Додано мною",
    "Zmień ustawienia": "Зміна параметрів",
    "Under request": "Under request",
    "Stawka mieszana została zaktualizowana.": "Змішана ставка актуалізована.",
    "Nie udało się pobrać listy zastępstw": "Не вдалося завантажити список замін",
    "Dodaj zdjęcia do wiadomości": "Додайте фотографії до повідомлення",
    "Wybrane pliki: {0}": "Вибрані файли: {0}",
    "Przetestuj ustawienia poczty": "Перевірте налаштування пошти",
    "Strona %0": "Сторінка %0",
    "Identyfikator zadania": "Ідентифікатор завдання",
    "Firma sprzątająca": "Клінінгова компанія",
    "Komentarz": "Коментар",
    "Odznacz wszystkie": "Зняти прапорець для всіх",
    "Potwierdzenie zmiany statusu": "Підтвердження зміну статусу",
    "Logo firmy": "Логотип компанії",
    "Osoba przypisana do zadania": "Особа, призначена для виконання завдання",
    "Musisz wybrać obiekt na mapie": "Необхідно вибрати об'єкт на карті",
    "Zobacz szczegóły sesji użytkownika": "Переглянути деталі сеансу користувача",
    "Adres": "Адреса",
    "Zacznij pisać aby wyszukać kierowcę...": "Почніть вводити текст для пошуку водія...",
    "Październik": "Жовтень",
    "Do sprawdzenia - przeniesione": "Підлягає перевірці - переміщенню",
    "Uprawnienia": "Дозвіл",
    "Wprowadzona wartość jest nieprawidłowa": "Введене значення невірно",
    "Hasło musi zawierać co najmniej jeden znak specjalny np.: ~ @ # $ % ^ & *": "Пароль повинен містити принаймні один спеціальний символ, наприклад: ~ @ # $ % ^ & *",
    "Inwentaryzacje": "Інвентаризації",
    "Wysłane": "Відправлене",
    "Dodaj do trasy": "Додати до маршруту",
    "Zresetuj hasło": "Скинути пароль",
    "Od ilości posprzątanych obiektów": "Від кількості прибраних об'єктів",
    "Nowa data": "Нова дата",
    "pl": "..pl",
    "Tagi zadań": "Теги завдань",
    "Średnio": "В середньому",
    "Edytuj": "Редагувати",
    "Zastępstwo": "Заміна",
    "Dodanie zespołu": "Додавання команди",
    "Dodaj źródło": "Додати джерело",
    "Moje trasy": "Мої маршрути",
    "Rozliczone": "Розрахунковий",
    "od": "від",
    "Sesja nr %0": "Сесія nr %0",
    "pokaż więcej...": "показати більше...",
    "Koszty do zadania": "Витрати, які потрібно запросити",
    "Odpowiedzi": "Відповіді",
    "Wysłano kolejne wezwanie do zapłaty": "Був відправлений ще один платіжний виклик",
    "Pokaż": "Показати",
    "Nie udało się pobrać listy serwerów baz danych": "Не вдалося отримати список серверів баз даних",
    "Historia logowania": "Історія входу",
    "Nie mam zdania": "У мене немає думки",
    "Pokaż przycisk ``Wyślij``": "Показати кнопку \"Відправити\"",
    "Dane nieruchomości": "Дані про нерухомість",
    "Filtruj": "Фільтрувати",
    "Szef regionu": "Очільник області",
    "Ilość kosztów": "Кількість витрат",
    "Nazwa": "Назва",
    "Ostatnia wiadomość - data": "Останнє повідомлення - дата",
    "Kwota zniszczeń": "Розмір збитку",
    "Zakończone": "Завершено",
    "Urlop": "Відпустка",
    "Projekt": "Проект",
    "Zmiana rozmiaru obrazu": "Зміна розміру зображення",
    "Wykonane": "Виконане",
    "Pre-booking": "Pre-booking",
    "%0 znaków": "%0 знаків",
    "Statystyki pracownicze": "Статистика співробітників",
    "Zawiera": "Містить",
    "Suma wynagrodzeń": "Сума заробітної плати",
    "Do kogo chcesz napisać?": "До кого хочеш написати?",
    "Zmiana hasła": "Зміна пароля",
    "Porównania": "Порівняння",
    "Rok": "Рік",
    "Status formularza został zmieniony.": "Статус форми був змінений.",
    "Nie udało się pobrać listy powiadomień": "Не вдалося отримати список сповіщень",
    "Zasięg": "Діапазон",
    "Wybierz punkt": "Виберіть пункт",
    "Nie udało się pobrać danych odnośnie rezerwacji i zadań": "Не вдалося отримати дані про бронювання та завдання",
    "Wynagrodzenia Konserwatorzy": "Заробітна плата техніки",
    "Ostatnia strona": "Остання сторінка",
    "Liczba dziesiętna": "Десятковий",
    "Edycja stawki godzinowej dla:": "Редагування ставки за годину для:",
    "Imię lub nazwisko właściciela": "Ім'я або прізвище власника",
    "Zespół został dodany.": "Команда була додана.",
    "Tak": "Так",
    "Zakończone przez": "Завершене",
    "Wybierz plik": "Виберіть файл",
    "Wpisz nazwę dzielnicy...": "Введіть назву району...",
    "Właściciel został zaktualizowany.": "Власник оновлений.",
    "Konserwacje": "Обслуговування",
    "Za przepracowane godziny": "За відпрацьовані години",
    "Element został usunięty.": "Елемент видалено.",
    "Udało się zaktualizować zadania!": "Вдалося оновити завдання!",
    "Podstawowe operacje warunkowe": "Основні умовні операції",
    "Organizacja": "Організація",
    "Utworzył": "Створити",
    "Nr telefonu": "Контактний телефон",
    "TAK": "ТАК",
    "Nie masz uprawnień do panelu administracyjnego.": "У вас немає дозволів на панель адміністрування.",
    "Dodaj formularz": "Додати форму",
    "Wszystkie obiekty:": "Всі об'єкти:",
    "Żaden kanał komunikacji nie został włączony w ustawieniach powiadomień.": "Жоден канал зв'язку не був увімкнений в налаштуваннях сповіщень.",
    "Zacznij pisać aby wyszukać nieruchomość...": "Почніть вводити текст, щоб знайти нерухомість...",
    "Administracja - Kraje": "Адміністрування - Країни",
    "Skala 1-5": "Масштаб 1-5",
    "Zastępstwo wygaśnie za 10 minut.": "Термін дії заміни закінчується через 10 хвилин.",
    "Edycja Notatki": "Редагування нотатки",
    "Numer inwentaryzacji": "Номер інвентаризації",
    "Zobacz zdjęcia": "Дивитись фото",
    "Edycja trasy": "Редагування маршруту",
    "Nie zdefiniowano żadnych ról.": "Ролі не визначені.",
    "To miasto jest już przypisane do użytkownika.": "Це місто вже закріплений за користувачем.",
    "Wynagrodzenie stałe miesięczne": "Фіксована місячна заробітна плата",
    "Dane wydziału zostały dodane.": "Дані відділу були додані.",
    "Zakończ trasę": "Закінчити маршрут",
    "Otwórz w nowym oknie": "Відкрити в новому вікні",
    "Aplikacja nie ma uprawnień do wysyłania powiadomień PUSH": "Додаток не має дозволу на відправку PUSH-повідомлень",
    "Typ": "Тип",
    "Kwiecień": "Квітень",
    "Wstecz": "Назад",
    "Porównanie": "Порівняння",
    "kanał wymagany": "необхідний канал",
    "Pierwsza strona": "Перша сторінка",
    "Lista punktów": "Список пунктів",
    "Rozmiar": "Розмір",
    "Usuń spacje": "Видалити пробіл",
    "Nie udało się pobrać listy działów": "Не вдалося завантажити список відділів",
    "Nazwa kolumny z opisem": "Назва стовпця з описом",
    "Data zmiany statusu do": "Дата зміни стану до",
    "Dodanie użytkownika": "Додавання користувача",
    "Nazwa nieruchmości": "Назва об'єкта нерухомості",
    "Liczba zgłoszonych problemów": "Кількість повідомлених проблем",
    "Nie udało się zapisać notatki": "Не вдалося зберегти нотатку",
    "Szczegóły zdarzenia": "Детальніше про подію",
    "Drugie imię": "Друге ім'я",
    "Dwie kolumny": "Два стовпці",
    "Rezerwacja": "Бронювання",
    "Zadanie zostało wyłączone": "Завдання вимкнуто",
    "Zacznij pisać aby wyszukać szablon checklisty...": "Почніть вводити текст для пошуку шаблону чек-листа...",
    "Wyczyść sesję": "Очистити сесію",
    "Dodanie firmy": "Додавання компанії",
    "Podstawowe operacje na datach": "Основні операції з датами",
    "Neutralne": "Нейтральні",
    "12 godzin": "12 годин",
    "Nazwa bazy danych": "Ім'я бази даних",
    "Wytnij": "Вирізати",
    "Ulica": "Вулиця",
    "(AirBnb) Wysłano żądanie do portalu": "(AirBnb) Запит висланий до портала",
    "Obraz został wycięty.": "Зображення вирізано.",
    "Co pół roku": "Що півроку",
    "Dodanie zadania": "Додавання завдання",
    "Wewnętrznie": "Внутрішньо",
    "Data zmiany statusu od": "Дата зміни статусу від",
    "Dodaj zasób": "Додати ресурс",
    "Źle": "Погано",
    "Statystyki użytkowników": "Статистика користувачів",
    "Przypisane miasta do regionu:": "Міста, закріплені за регіоном:",
    "Nazwa domyślnej checklisty": "Ім'я чек-листа за замовчуванням",
    "Rejestr zmian": "Реєстр змін",
    "Zobacz szczegóły zdarzenia": "Детальніше про подію",
    "Wprowadzona wartość jest za krótka": "Введене значення закоротке",
    "Parametry, wymagane do wykonania zapytania:": "Параметри, необхідні для виконання запиту:",
    "Treść": "Зміст",
    "Komunikat błędu": "Повідомлення про помилку",
    "Brak tagów": "Немає тегів",
    "Klient aktywny": "Активний клієнт",
    "Trzy kolumny": "Три стовпці",
    "Wprowadzona wartość jest za wysoka": "Введене значення занадто велике",
    "Wyjazd": "Від'їзд",
    "Pasek postępu": "Індикатор виконання",
    "Oraz nowe hasło": "І новий пароль",
    "Nie udało się pobrać listy filtrów": "Не вдалося завантажити список фільтрів",
    "Kwota wynagrodzenia PLN brutto": "Сумма заробітної плати PLN брутто",
    "Data opuszczenia zespołu": "Дата відходу з колективу",
    "Przesyłanie zdjęcia nie powiodło się": "Не вдалося завантажити фотографію",
    "Kolejność punktów trasy została zaktualizowana": "Оновлено порядок розташування маршрутних точок",
    "Właściciel został usunięty": "Власника прибрано",
    "Nieważne": "Не має значення",
    "Zakończone: ${$filters.datetime(route.dateCompletedUtc)}": "Виконано: ${$filters.datetime(route.dateCompletedUtc)}",
    "Nazwa katalogu lub pliku": "Назва каталог або файлу",
    "Email": "Електронна пошта",
    "usuń notatkę": "видалити примітку",
    "Średnie wynagrodzenie koordynatora": "Середня зарплата координатора",
    "Miesiąc edycji wynagrodzenia": "Місяць зміни заробітної плати",
    "Czas realizacji": "Терміни реалізації",
    "Dodaj element": "Додати елемент",
    "Wybrana dzielnica": "Вибраний район",
    "Bardzo dobrze": "Дуже добре",
    "Adres 2": "Адреса 2",
    "Zablokowany": "Заблокований",
    "Wiadomość": "Відомість",
    "Checklisty": "Чек-листи",
    "Stawka za apartamenty została zaktualizowana.": "Ставка за апартамент актуалізована.",
    "Nie masz wymaganych uprawnień do wykonania wybranej operacji.": "У вас немає необхідних дозволів на виконання вибраної операції.",
    "Co 2 godziny": "Кожні 2 години",
    "Filtry map": "Фільтри мапи",
    "Notatka dla": "Примітка до",
    "Zastępstwa": "Заміни",
    "ID": "ID",
    "przejdź do komentarzy": "перейти до коментарів",
    "Ustawienia ogólne": "Загальне налаштування",
    "Teraz": "Прямо зараз",
    "Ilość znaków": "Кількість символів",
    "Suma Wynagrodzeń": "Сума Заробітної Плати",
    "Powtórz hasło": "Повторіть пароль",
    "W przyszłości": "Попереду",
    "Nazwa regionu": "Назва регіону",
    "Dodaj inwentaryzacje": "Додати інвентаризацію",
    "Pytanie 3": "Питання 3",
    "Cofam": "Вертаюсь",
    "Nazwa tagu": "Назва тегу",
    "co 30 dni": "кожні 30 днів",
    "Zasoby": "Ресурси",
    "Hasło (Klucz ukryty)": "Пароль (прихований ключ)",
    "Procent obciążeń": "Відсоток навантажень",
    "Ilość wierszy": "Кількість рядків",
    "Rola aktywna": "Активна роль",
    "Notatka została zaktualizowana.": "Примітка була оновлена.",
    "Porównywana wersja": "Порівняна версія",
    "Kod pocztowy": "Поштовий індекс",
    "Domyślna firma": "Компанія за замовчуванням",
    "Przyrostek": "Суфікс",
    "Strona główna": "Головна сторінка",
    "Rozmiar zdjęcia został zmieniony.": "Розмір фото змінено.",
    "Pasek": "Ремінець",
    "Wpisz nazwę miasta...": "Введіть назву міста...",
    "To zadanie nie posiada jeszcze przypisanych użytkowników.": "Це завдання ще не має призначених користувачів.",
    "Kolejka": "Черга",
    "W każdy dzień tygodnia": "Кожен день тижня",
    "Usuń": "Видалити",
    "wybierz...": "виберіть...",
    "Nieobecność": "Відсутність",
    "Nowe hasło": "Новий пароль",
    "Nazwa trasy": "Назва маршруту",
    "Moje konto": "Мій аккаунт",
    "Ładuję...": "Завантаження...",
    "Dodaj rolę": "Додати роль",
    "Wybór": "Вибір",
    "Zdecydowanie się nie zgadzam": "Я категорично не згоден",
    "Typ źródła": "Тип джерела",
    "Obiekty": "Об'єкти",
    "Srednie wynagrodzenie pracowników": "Середня заробітна плата працівників",
    "Nazwa użytkownika": "Ім'я користувача",
    "Administracja - Stanowiska pracy": "Адміністрування - Робочі місця",
    "Niedziela": "Неділя",
    "Wczutuję...": "",
    "Tag został dodany.": "Тег було додано.",
    "Zastosuj": "Застосовувати",
    "Brak aktywnego filtru": "Бракує активного фільтру",
    "Wielokrotny wybór": "Широкий вибір",
    "Notatka utworzona z zadania": "Примітка, створена із завдання",
    "Typ dodanego kosztu": "Тип доданої вартості",
    "Nieaktywna": "Неактивна",
    "Przenieś wybrane": "Перемістити вибране",
    "Włączone": "Включений",
    "Wyposażenie zostało zaktualizowane.": "Обладнання оновлено.",
    "Uwzględniaj Dzielnice": "Візьміть до уваги райони",
    "Odpowiedź 3": "Відповідь 3",
    "UWAGA! Nieruchomość jest już przypisana od innego właściciela": "Увага! Нерухомість вже призначена від іншого власника",
    "Wznów zadanie": "Поновити завдання",
    "Dostępne czaty:": "Доступні чати:",
    "${PriorityName(PriorityEnum[this.task.priority])}": "${PriorityName(PriorityEnum[this.task.priority])}",
    "Pokaż przycisk": "Показати кнопку",
    "Zablokuj użytkownika": "Заблокувати користувача",
    "Dane formularza są przechowywane w obiekcie Entry.": "Дані форми зберігаються в об'єкті Entry.",
    "Typ wynagrodzenia": "Тип заробітної плати",
    "Pokaż numery stron": "Показати номери сторінок",
    "Tekst zastępczy": "Замісний текст",
    "Data realizacji": "Дата реалізації",
    "Grupa": "Група",
    "Nazwa pliku została zmieniona.": "Файл перейменовано.",
    "Historia zmian": "Історія змін",
    "Koszt został dodany.": "Вартість додана.",
    "Hasło": "Пароль",
    "Nieruchomość jest już przypisana": "Нерухомість вже присвоєна",
    "Nie możesz wpływać na kolejność punktów już oznaczonych jako odwiedzone": "Ви не можете вплинути на порядок пунктів, які вже позначені як відвідані",
    "Zastępstwo wygasło.": "Заміна закінчилася.",
    "Edycja firmy": "Редагування компанії",
    "Elementy inwentaryzacji": "Елементи інвентаризації",
    "Nie podano wymaganej ilości znaków: %0.": "Кількість символів не вказано: %0.",
    "Nie udało się pobrać listy wydziałów": "Не вдалося завантажити список відділів",
    "Data utworzenia od": "Дата створення від",
    "Łączenie treści": "Об'єднання контенту",
    "Wysłane wezwanie do zapłaty": "Надісланий запит на оплату",
    "1 godzina": "1 година",
    "Pole może zawierać jedynie litery i cyfry.": "Поле може містити тільки букви і цифри.",
    "Wszystkie próby": "Всі спроби",
    "Owner Booking": "Owner Booking",
    "3 godziny": "3 години",
    "Zacznij pisać aby wyszukać dzielnicę...": "Почніть писати для пошуку районів....",
    "Dowolny": "Будь-який",
    "Miejscowość": "Місцевість",
    "Wybrane miasto": "Вибране місто",
    "Wybierz inspektora": "Виберіть інспектора",
    "Nieaktywni": "Неактивні",
    "Co 2 minuty": "Кожні 2 хвилини",
    "Angielski": "Англійська",
    "Obsługa Klienta (BOK)": "Служба підтримки клієнта (BOK)",
    "Dodanie zastępstwa": "Додавання заміни",
    "Imię i nazwisko właściciela": "Ім'я і прізвище власника",
    "Ten punkt został już odwiedzony": "Цей пункт вже відвідано",
    "Przesuń na dół": "Проведіть вниз",
    "Zgłoszenie serwisowe": "Запит на обслуговування",
    "Urlop został usunięty": "Відпустку було видалено",
    "Użytkownicy w tej roli": "Користувачі в цій ролі",
    "Zaakceptowane": "Прийнято",
    "Nazwa taga": "Назва тегу",
    "Wiadomość testowa została wysłana.": "Тестове повідомлення відправлено.",
    "Nazwa klienta": "Ім'я клієнта",
    "linie": "лінії",
    "Wynagrodzenia od ilości posprzątanych obiektów": "Зарплата від кількості прибраних об'єктів",
    "Data dodania kosztu": "Дата додавання вартості",
    "Edytuj dane wydziału": "Редагувати дані відділу",
    "Wartości": "Цінності",
    "Załączone zdjęcia": "Прикріплені фотографії",
    "Wybierz typ powiadomienia aby zobaczyć dostępne klucze.": "Виберіть тип сповіщення щоб побачити доступні ключі.",
    "Osiągnąłeś limit zdjęć. Nie możesz dodać więcej zdjęć": "Ви вичерпали свій ліміт фотографій. Ви не можете додати більше фотографій",
    "Dodanie klienta": "Додавання клієнта",
    "Nazwa kosztu": "Назва вартості",
    "Trasy archiwalne": "Архівні маршрути",
    "Ważność": "Важливість",
    "Wyświetl również zarchiwizowane formularze": "Перегляд також заархівованих форм",
    "Migracje": "Міграція",
    "Koszty zadania zostały zaktualizowane.": "Вартість завдання оновлено.",
    "Data dodania inwentaryzacji": "Дата додавання інвентаризації",
    "Zacznij pisać aby wyszukać właściciela...": "Почніть вводити для пошуку власника...",
    "Czaty": "Чати",
    "Zadanie zostało wznowione.": "Завдання було відновлено.",
    "Wymagane jest ustawienie minimalnej długości hasła": "Необхідно встановити мінімальну довжину пароля",
    "Tydzień": "Тиждень",
    "Confirmed": "Підтверджений",
    "Miasto": "Місто",
    "Zdecydowanie nieważne": "Однозначно не важливо",
    "Zgłoszenia w apartamencie": "Зголошення в апартменті",
    "W trakcie": "В процесі",
    "Przejdź do mapy": "Перейти до карти",
    "Moje filtry": "Мої фільтри",
    "Bardzo źle": "Дуже погано",
    "Zadanie zostało usunięte": "Завдання видалено",
    "Czy zablokować użytkownika i jego wiadomość?": "Чи заблокувати користувача і його повідомлення?",
    "Przenieś": "Перемістити",
    "Rozpocznij zadanie": "Почати завдання",
    "Zmień status edytowanej wersji": "Змінити статус відредагованої версії",
    "Zameldowanie": "Заселення",
    "Język": "Мова",
    "Nazwa wydziału": "Назва відділу",
    "Ostatnia wiadomość - użytkownik": "Останнє повідомлення - користувач",
    "Dodaj opis...": "Додайте опис...",
    "Data wykonania": "Дата виконання",
    "Nadpisz kwotę dla": "Перепишіть квоту для",
    "Wszystkie": "Всі",
    "Ładuje...": "Завантажує...",
    "Wpisz nazwę regionu...": "Введіть назву регіону...",
    "Nazwa pokoju": "Назва кімнати",
    "Przywróć": "Відновити",
    "Anuluj": "Скасувати",
    "Kontrolka wyboru (wybór wielokrotny)": "Елемент керування виділенням (кілька виділень)",
    "Szczegóły": "Деталі",
    "Powiadomienia": "Сповіщення",
    "Wstaw z prawej strony": "Вставити справа",
    "Typ pola": "Тип поля",
    "Filtr prywatny": "Приватний фільтр",
    "Podpis": "Підпис",
    "Wybrane elementy zostały przeniesione.": "Вибрані елементи переміщено.",
    "Repozytorium plików": "Сховище файлів",
    "Niemiecki": "Німецькі",
    "Administracja - Miasta": "Адміністрування - Міста",
    "Not Available": "Not Available",
    "Dodaj kategorię": "Додати категорію",
    "Rola": "Роль",
    "Nie możesz rozpocząć obsługi tego zadania": "Не можна починати це завдання",
    "Resetuj": "Скинути",
    "brak danych": "бракує даних",
    "Dziś": "Сьогодні",
    "Id": "Id",
    "Zadowolony": "Задоволений",
    "Lista rezerwacji": "Перелік бронювань",
    "Katalog został utworzony.": "Створено каталог.",
    "edycja": "редагування",
    "Statystyki regionalne - regiony": "Регіональна статистика - регіони",
    "Dodaj opłaconą kwotę": "Додати сплачену суму",
    "Nazwa źródła danych": "Назва джерела даних",
    "Edytuj wpis": "Редагувати запис",
    "Imie i nazwisko osoby, która dodawała koszty": "Ім'я і прізвище особи, яка додала витрати",
    "Telefon": "Телефон",
    "Marzec": "Березень",
    "Serwer dostępny": "Доступний сервер",
    "Miniaturki": "Ескізи",
    "Stwórz zadanie z tej rezerwacji": "Створіть завдання з цього бронювання",
    "Przeniesienie wybranych elementów": "Перемістити вибрані елементи",
    "Zmiana motywu": "Зміна теми",
    "Imie i nazwisko: ": "Ім'я та прізвище: ",
    "Data zakończenia": "Дата закінчення",
    "Edycja formularza": "Редагування форми",
    "Klient został dodany.": "Клієнта додано.",
    "Treści pytań są wymagane": "Потрібен зміст питань",
    "Brak odpowiedzi": "Немає відповіді",
    "Obojętny": "Байдужий",
    "W momencie tworzenia kosztu": "На момент створення вартості",
    "Polski": "Польська",
    "Satysfakcja": "Задоволення",
    "Godzina zameldowania": "Час заїзду",
    "Informacje dodatkowe": "Додаткова інформація",
    "Niezadowolony": "Незадоволений",
    "Wynagrodzenie": "Заробітна плата",
    "Liczba pracowników pracujących w więcej niż jednym regionie": "Кількість працівників, які працюють у більш ніж одному регіоні",
    "Zadanie zostało uruchomione.": "Завдання розпочато.",
    "Właściciel został dodany.": "Власника додано.",
    "Rola została zaktualizowana.": "Роль оновлена.",
    "Zacznij pisać aby wyszukać osobę...": "Почніть вводити текст, щоб шукати людину...",
    "Przejdź do Checklisty": "Перейти до чек листа",
    "Nie udało się pobrać listy kolejek": "Не вдалося отримати список черги",
    "Poprzednia strona": "Попередня сторінка",
    "Typ mime": "Тип MIME",
    "Ten Miesiąc": "Цей Місяць",
    "Przypisz do czatu": "Припис до чату",
    "Przepracowana liczba godzin w miesiącu": "Відпрацьована кількість годин на місяць",
    "Nazwa serwera": "Назва сервера",
    "Pole może zawierać jedynie litery.": "Поле може містити тільки букви.",
    "Data wykonania od": "Дата виконання від",
    "Edycja dzielnicy": "Редагування району",
    "Dodaj serwer": "Додати сервер",
    "Dodaj dni": "Додати дні",
    "Zmień kolejność punktów": "Змінити порядок пунктів",
    "Zacznij pisać aby wyszukać dzielnice...": "Почніть писати для пошуку районів....",
    "Opis zespołu": "Опис команди",
    "Domyślny kolor trasy (dotyczy kierowców)": "Колір маршруту за замовчуванням (для водіїв)",
    "Dodanie serwera": "Додавання сервера",
    "Wpis został usunięty.": "Запис видалено.",
    "Wyczyść": "Очистити",
    "Pokaż listę dostępnych tras": "Показати список доступних маршрутів",
    "Nazwa działu": "Назва відділу",
    "Dodanie dzielnicy": "Додавання району",
    "Trasa została zapisana.": "Маршрут було записано.",
    "Zmień dane kosztu": "Змінити дані сумми",
    "Etykieta": "Мітка",
    "Dni": "Днів",
    "Firma została dodana.": "Компанія була додана.",
    "Ankieta": "Анкета",
    "-": "-",
    "Mnożenie": "Множення",
    "Dark mode": "Темний режим",
    "Edycja wynagrodzenia stałego dla:": "Редагування постійної заробітної плати для:",
    "Użytkownik został dodany do zespołu.": "Користувач був доданий в команду.",
    "Notatki": "Нотатки",
    "Odpowiedź 1": "Відповідь 1",
    "Masz nową wiadomość!": "Маєш нове повідомлення!",
    "źródło zostało usunięte.": "джерело було видалено.",
    "Wpisz kwotę": "Введіть суму",
    "Pole \"Tytuł\" jest wymagane.": "Поле «Назва» є обов’язковим для заповнення.",
    "Raporty": "Звіти",
    "Data rozpoczęcia": "Дата початку",
    "Podano nieprawidłowe dane": "Надані невірні дані",
    "Zadanie do wykonania": "Завдання для виконання",
    "Edycja użytkownika": "Редагування користувача",
    "Aktywni": "Активні",
    "Opłacono": "Сплачено",
    "Data zakończenia blokady konta": "Дата завершення блокування облікового запису",
    "Stawka PLN brutto za godzinę": "Сумма PLN брутто за годину",
    "Ustawienia zaawansowane": "Додаткові налаштування",
    "Obsługa Właściciela (OW)": "Служба підтримки власників (OW)",
    "Typ rezerwacji": "Тип бронювання",
    "Podanie nazwy jest wymagane.": "Необхідно вказати ім'я.",
    "Ukraiński": "Українська",
    "Czat": "Чат",
    "Ustawienia": "Налаштування",
    "Akcja": "Дія",
    "Czas wykonania": "Час виконання",
    "Stwórz nowy czat": "Створи новий чат",
    "Id rezerwacji:": "Id бронювання:",
    "Kontrolki podstawowe": "Основні елементи керування",
    "Dodane Koszty": "Додай кошти",
    "Zamknięte przez": "Закрито",
    "Opcje": "Параметри",
    "Id sesji": "Id сеансу",
    "Co 15 minut": "Кожні 15 хвилин",
    "Zacznij pisać aby wyszukać regionu...": "Почніть вводити текст для пошуку регіону...",
    "Osoba odpowiedzialna": "Особа відповідальна",
    "SUMA": "СУМА",
    "Przypisz obiekty": "Призначити об'єкти",
    "Nazwa nieruchomości": "Назва об'єкта нерухомості",
    "Typ nieobecności": "Тип відсутності",
    "Usuń wybrane": "Видалити виділене",
    "Kanały włączone w ustawieniach aplikacji": "Канали, увімкнені в налаштуваннях програми",
    "Zdecydowanie się zgadzam": "Я повністю згоден",
    "Data następnej inwentaryzacji": "Дата наступної інвентаризації",
    "znaki": "символи",
    "Dzielenie": "Поділ",
    "Fiirma została zaktualizowana.": "Фіірма була оновлена.",
    "Imię i Nazwisko": "Ім'я та прізвище",
    "Pracownicy z brakującymi ustawieniami wynagrodzenia": "Працівники з відсутніми налаштуваннями заробітної плати",
    "Bazy danych": "База даних",
    "Równość": "Рівності",
    "Wybierz tagi": "Виберіть теги",
    "Dzielnica została zaktualizowana.": "Район було оновлено.",
    "Region został usunięty": "Регіон видалено",
    "REGON firmy": "REGON фірми",
    "Zapisuję...": "Записую...",
    "Wczytaj zapisany filtr": "Завантажити збережений фільтр",
    "Proszę obciążyć właściciela obiektu": "Будь ласка, стягніть плату з власника нерухомості",
    "Konfiguracja domyślna": "Конфігурація за замовчуванням",
    "Wyświetlam": "Відображення",
    "Edycja serwera": "Редагування сервера",
    "Przypisane do:": "Призначається:",
    "${DepartmentName(DepartmentEnum[this.task.department])}": "${DepartmentName(DepartmentEnum[this.task.department])}",
    "Szkic": "Ескіз",
    "Dodaj wpis": "Додати запис",
    "Ustawienia powiadomień zostały zaktualizowane.": "Налаштування сповіщень оновлено.",
    "Sesja użytkownika": "Сесія  користувача",
    "Rezerwacja właścicielska": "Бронювання власника",
    "Nazwa źródła": "Назва джерела",
    "Na wniosek": "За запитом",
    "Cron": "Крон",
    "Listopad": "Листопад",
    "Zadania": "Завдання",
    "Nieruchomości robocze": "Робочі нерухомості",
    "Dodaj Koszt do zadania": "Додати вартість до завдання",
    "co 3 miesiące": "кожні 3 місяці",
    "Historia zadania": "Історія завдань",
    "Pole \"%0\" nie zawiera prawidłowego adresu email.": "Поле «%0» не містить дійсну адресу електронної пошти.",
    "Nie udało się pobrać listy typów modeli": "Не вдалося отримати список типів моделей",
    "Bardzo zadowolony": "Дуже задоволений",
    "Źródło danych zostało zaktualizowane.": "Джерело даних оновлено.",
    "Typ Nieruchomości": "Тип нерухомості",
    "Edytowana wersja": "Відредагована версія",
    "Użytkownicy": "Користувачі",
    "Adres serwera": "Адреса сервера",
    "Edytuj szablon": "Редагувати шаблон",
    "wybierz długość": "виберіть довжину",
    "Do kogo wysłać testowe powiadomienie": "Кому надіслати тестове повідомлення",
    "Administratorzy": "Адміністратори",
    "Wykonaj akcję": "Виконати дію",
    "Wartość musi być liczbą ujemną": "Вартість має бути від'ємним числом",
    "Data zakończenia do": "Дата закінчення до",
    "Nazwa checklisty": "Назва чек-листа",
    "Kategorie": "Катигорії",
    "Serwer został dodany.": "Сервер додано.",
    "Numer nieruchomości (StreetNumber)": "Номер об'єкта нерухомості (StreetNumber)",
    "Data wygaśnięcia": "Термін закінчення терміну дії",
    "Właściciel": "Власник",
    "Zmodyfikował": "Змінено",
    "Oczekujące": "Очікуванні",
    "Sortowanie rosnąco": "Сортування за зростанням",
    "Dodaj Zadanie": "Додати Завдання",
    "Aby dodawać członków zespołu musisz najpierw zapisać formularz.": "Щоб додати учасників команди, спочатку потрібно зберегти форму.",
    "Zmień nazwę katalogu": "Перейменувати каталог",
    "Typ wynagrodzenia został usunięty": "Тип заробітної плати був усунутий",
    "Dział został dodany.": "Розділ додано.",
    "Imie i nazwisko właściciela": "Ім'я власника",
    "Nazwisko klienta": "Прізвище клієнта",
    "Porównaj %0": "Порівняйте %0",
    "Typ słownika": "Тип словника",
    "Firma": "Копанія",
    "Wrzesień": "Вересень",
    "Kwota kosztów": "Сума витрат",
    "Dodanie źródła danych": "Додавання джерела даних",
    "Usunięte zadania": "Видалене завдання",
    "Procent": "Відсоток",
    "Usuń plik z listy": "Видалити файл зі списку",
    "Do sprawdzenia": "На перевірку",
    "Chat został zarchiwizowany.": "Чат був заархівований.",
    "Tag nieruchomości": "Тег нерухомості",
    "Potwierdzenie usunięcia": "Підтвердження видалення",
    "Przepracowana ilość godzin": "Відпрацьована кількість годин",
    "Są to wszystkie obiekty, nawet te, które posiadają już właściciela.": "Це всі об'єкти, навіть ті, які вже мають власника.",
    "więcej...": "більше...",
    "Dalej": "Далі",
    "Wybierz zaznaczone": "Виберіть позначене",
    "Wprowadzona wartość jest za długa": "Введене значення задовге",
    "Inwentaryzacja została dodana.": "Інвентаризація додана.",
    "Wybrane pliki": "Вибрані файли",
    "UWAGA! Ta tabela pokazuje tylko przeterminowane zadania, filtr \"STATUS\" nie jest aktywny. Wróć do \"Wszystkie zadania\" aby mieć dostępne wszystkie filtry": "Увага! У цій таблиці показані лише прострочені завдання, фільтр \"СТАТУС\" не активний. Поверніться до \"УСІ ЗАВДАННЯ\" щоб мати всі фільтри",
    "Nie udało się pobrać listy ról": "Не вдалося завантажити список ролей",
    "Wpisz komentarz...": "Введіть коментар...",
    "Nazwa przycisku ``Wstecz``": "Назва кнопки ''Назад''",
    "Link do rezerwacji": "Посилання на бронювання",
    "Ilość toalet": "Кількість туалетів",
    "Urlopy": "Відпустки",
    "Typ konta": "Тип рахунку",
    "Pliki do wysłania: %0": "Файли для висилання: %0",
    "Utwórz czat": "Створити чат",
    "Sprawdzenie czy pusty": "Перевірка якщо порожній",
    "Potwierdź akceptację": "Підтвердження прийняття",
    "Dodawanie": "Додавання",
    "Minimum": "Мінімум",
    "Kosz": "Кошик",
    "Data przyjazdu": "Дата прибуття",
    "Wstaw z góry": "Вставити зверху",
    "Raczej się nie zgadzam": "Я радше не згоден",
    "Nazwa zespołu": "Назва команди",
    "Biuro": "Офіс",
    "Cena PLN": "Ціна PLN",
    "Stawka wynagrodzenia została zaktualizowana.": "Ставка заробітної плати актуалізована.",
    "gotowe": "готово",
    "Kopiuj": "Копіювати",
    "Wyświetl szczegóły": "Покажи деталі",
    "Nazwa zadania": "Назва завдання",
    "Zakończ zadanie": "Закінчити завдання",
    "Wpisz tytuł...": "Введіть заголовок...",
    "Sekcja": "Розділ",
    "Dodaj trasę": "Додати маршрут",
    "ID lub typ zadania": "ID або тип завдання",
    "Dodaj nowy tag": "Додай новий тег",
    "Załącz zdjęcia": "Прикріпити фотографії",
    "Regiony": "Регіони",
    "Numer nieruchomości Avantio": "Номер нерухомості Avantio",
    "Grudzień": "Грудень",
    "Dodanie nieruchomości roboczej": "Додавання робочої нерухомості",
    "Pliki prywatne": "Особисті файли",
    "Miesiąc": "Місяць",
    "obrót": "обіг",
    "Weekend": "Вихідні",
    "Sesja": "Сесія",
    "Godzina trasy": "Час проходження маршруту",
    "Godzina wykonania": "Час виконання",
    "Zacznij pisać aby wyszukać użytkownika...": "Почніть вводити текст для пошуку користувача...",
    "Wyloguj zalogowanego obecnie użytkownika": "Вийти з поточного користувача",
    "Co 30 minut": "Кожні 30 хвилин",
    "Etykiety": "Мітки",
    "Dodaj zdjęcia": "Додайте фотографії",
    "Wynagrodzenie stałe brutto": "Фіксована заробітна плата брутто",
    "Dane": "Дані",
    "Usunięto użytkownika z czatu": "Користувача видалено з чату",
    "Hasło do sieci Wi-Fi": "Пароль від мережі Wi-Fi",
    "nie wymuszaj zmiany hasła": "не примушуйте до зміни пароля",
    "Źródło rezerwacji": "Джерело бронювання",
    "Ilość koordynatorów w regionie": "Кількість координаторів у регіоні",
    "Zmień status": "Змінити статус",
    "IBAN": "IBAN",
    "NIP firmy": "NIP firmy (Номер платника ПДВ компанії)",
    "Lista powiadomień": "Список повідомлень",
    "Wybierz": "Вибрати",
    "Kopiuj filtr": "Фільтр копіювання",
    "Zapisz filtry": "Зберегти фільтри",
    "Lista Tras:": "Перелік маршрутів:",
    "Kategoria została dodana.": "Категорія додана.",
    "Brak przypisanych użytkowników.": "Немає призначених користувачів.",
    "Link do raportu": "Посилання на звіт",
    "Aktywny": "Активний",
    "Dzielnica": "Район",
    "Kategoria jest wymagana": "Категорія обов'язкова",
    "Tak/Nie": "Так/Ні",
    "Sobota": "Субота",
    "Pole \"Imię\" jest wymagane.": "Поле «Ім'я» обов'язкове для заповнення.",
    "Ważne": "Важливо",
    "Ten element nie zawiera menu kontekstowego.": "Цей пункт не містить контекстного меню.",
    "Niestandardowy błąd": "Нестандартна помилка",
    "Czy na pewno chcesz ukryć wiadomość?": "Чи справді хочете сховати повідомлення?",
    "Ta czynność została wykonana przez kogoś innego. Odśwież aplikację.": "Ця дія була виконана іншою особою. Оновіть програму.",
    "Ilość łóżek jednoosobowe": "Кількість односпальних ліжок",
    "Usuń trasę": "Видалити маршрут",
    "Edycja miasta": "Редагування міста",
    "Notatka została dodana.": "Примітка додана.",
    "Filtr globalny": "Глобальний фільтр",
    "Filtry globalne": "Глобальні фільтри",
    "Podgląd": "Попередній перегляд",
    "Nie udało się wysłać pliku": "Не вдалося надіслати файл",
    "Źródło": "Джерело",
    "Wysokość": "Висота",
    "Dodaj tag nieruchomości": "Додай тег нерухомості",
    "Dodaj do regionu": "Додати до регіону",
    "Edycja działu": "Редагування розділу",
    "Typ źródła danych": "Тип джерела даних",
    "Deadline - Data wykonania": "Дедлайн - Дата виконання",
    "Przypisane tagi: ": "Приписані теги: ",
    "Miejsca po przecinku": "Десятковий знак",
    "Koszt został zaktualizowany.": "Вартість оновлено.",
    "Inspektor": "Інспектор",
    "Pokaż tytuły stron": "Показати заголовки сторінок",
    "Domyślna wartość": "Вартість за замовчуванням",
    "Ranga": "Ранг",
    "Liczba całkowita": "Ціле число",
    "Data rezerwacji od": "Дата бронювання від",
    "Serwer został usunięty.": "Сервер видалено.",
    "Nowa kwota": "Нова сумма",
    "Formularz został zaktualizowany.": "Форму оновлено.",
    "Zadania na wniosek działu": "Завдання за запитом відділу",
    "Nie udało się pobrać listy serwerów plików": "Не вдалося отримати список файлових серверів",
    "NIP": "Номер платника ПДВ",
    "Nr rezerwacji": "Номер бронювання",
    "Dane gościa": "Дані про гостя",
    "Wiadomość została przywrócona.": "Повідомлення було відновлено.",
    "Testowy adres email": "Тестова адреса електронної пошти",
    "Formularz zarchiwizowany": "Архівна форма",
    "Statystyki kosztów w obiektach": "Статистика витрат на об'єктах",
    "Zadanie": "Завдання",
    "Punkt został oznaczony": "Точку позначено",
    "Kanały komunikacji": "Канали зв'язку",
    "Średnie wynagrodzenie pracowników": "Середня заробітна плата працівників",
    "Wartość musi być liczbą dodatnią": "Вартість має бути додатним числом",
    "Stwórz czat z rezerwacji": "Створіть чат резервації",
    "Rezerwacje anulowane": "Бронювання скасовано",
    "Litery i cyfry": "Букви і цифри",
    "Trasa została rozpoczęta": "Маршрут було розпочато",
    "Czas trwania:": "Тривалість:",
    "Zwiń": "Згорнути",
    "Dodaj koszt": "Додати вартість",
    "Region": "Область",
    "Pobrane z magazynu Renters": "Отримано зі сховища Renters",
    "Wynagrodzenia w regionie łącznie z wynagrodzeniami koordynatorów": "Зарплати в регіоні, включаючи зарплати координаторів",
    "Lista zgłoszeń w tym apartamancie": "Список зголошень у цій квартирі",
    "Co tydzień": "Щотиждень",
    "Nie ma ustalonej roli dla tego wydziału": "Для цього відділу немає фіксованої ролі",
    "Powiązane zadania": "Пов'язані завдання",
    "Data od": "Дата від",
    "Adres e-mail": "Адреса e-mail",
    "Pole zawiera": "Поле містить",
    "Logo": "Логотип",
    "Aktualne hasło": "Поточний пароль",
    "Kwota opłacona od": "Сума, сплачена від",
    "Wklej komponent": "Вставте компонент",
    "Pytanie 2": "Питання 2",
    "Attempt of reservation by Credit Card": "Спроба резервації за допомогою кредитної картки",
    "Zastępstwo zostało usunięte.": "Заміну видалено.",
    "Klucze": "Ключі",
    "Nazwa kolumny z wartością": "Назва стовпця зі значенням",
    "Administracja": "Адміністрування",
    "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "Можливість змінювати цей обліковий запис обмежена. Неактивні поля синхронізуються із зовнішньою базою даних %0.",
    "Dzisiaj": "Сьогодні",
    "Host główny": "Головний хост",
    "Brak właściciela": "Немає власника",
    "Checklista": "Чек-лист",
    "Rola została dodana.": "Роль додана.",
    "Aktywność": "Активність",
    "Nie wiem": "Я не знаю",
    "Źródło danych": "Джерело даних",
    "Data zalogowania": "Дата входу",
    "Ważne informacje": "Важлива інформація",
    "Przeglądarka z której korzystasz nie wspiera powiadomień PUSH": "Браузер, який ви використовуєте, не підтримує PUSH-повідомлення",
    "Wpisz treść wiadomości.": "Введіть текст повідомлення.",
    "Firmy": "Компанії",
    "Wybrane elementy zostały usunięte.": "Вибрані елементи видалено.",
    "Wszyscy": "Всі",
    "Podaj swój adres email": "Введіть свою адресу електронної пошти",
    "Edycja zadania": "Редагування завдання",
    "Ładuję zgłoszenia...": "Завантажую заявки...",
    "Status zadania": "Статус завдання",
    "Exclusivity": "Exclusivity",
    "Powtórzone": "Повторюється",
    "Podstawowe operacje tekstowe": "Основні текстові операції",
    "Uwzględnij wydziały podrzędne": "Включити підпорядковані відділи",
    "Zarządzaj": "Керуй",
    "Trasy dla Kierowców": "Маршрути для водіїв",
    "Nazwa:": "Назва:",
    "Moja trasa": "Мій маршрут",
    "(nowe wiadomości)": "(нові повідомлення)",
    "Wróć do strony głównej": "Повернутися на головну сторінку",
    "Prefix": "Префікс",
    "Co 5 dni": "Кожні 5 днів",
    "Zaloguj się": "Ввійти",
    "Resetuj filtry": "Скинути фільтри",
    "Wysłane powiadomienie": "Відправлене повідомлення",
    "Wstaw kontrolkę": "Вставити елемент управління",
    "Nieprawidłowy adres email": "Недійсна адреса електронної пошти",
    "Raporty z rozliczeń": "Платіжні звіти",
    "Dodaj Notatkę": "Додати Примітку",
    "Dział": "Відділ",
    "Zadanie zostało rozpoczęte.": "Завдання розпочато.",
    "Kwota kosztu do": "Сума витрат до",
    "Styczeń": "Січень",
    "Pytanie 1": "Питання 1",
    "Dobrze": "Добре",
    "Inwentaryzaje": "Інвентаризація",
    "Konto aktywne": "Активний рахунок",
    "Numer dowodu osobistego": "Номер посвідчення особи",
    "Dostęp niezdefiniowany": "Невизначений доступ",
    "Edycja źródła danych": "Редагування джерела даних",
    "Zaakceptowane, zapisz": "Прийнято, зберегти",
    "Minimalna długość hasła": "Мінімальна довжина пароля",
    "Co 2 tygodnie": "Кожні 2 тижні",
    "Przypisane dzielnice:": "Закріплені райони:",
    "Nazwa nadawcy": "Ім'я відправника",
    "co rok": "щороку",
    "Słownik systemowy": "Системний словник",
    "zacznij pisać aby wyszukać użytkownika...": "почніть вводити текст для пошуку користувача...",
    "Przypomnij": "Нагадай",
    "Zespoły": "Команди",
    "Nie udało się dodać użytkownika do czatu": "Не вдалося додати користувача до чату",
    "Testowe powiadomienie wysłane": "Тестове повідомлення надіслано",
    "Domyślna checklista": "Чек-лист за замовчуванням",
    "Co 3 miesiące": "Кожні 3 місяці",
    "Dane wydziałów": "Дані відділів",
    "Wyliczenie stawki godzinowej (statystyczna)": "Розрахунок погодинної ставки (статистичний)",
    "Data utworzenia": "Дата створення",
    "Szablon checklisty": "Шаблон чек-листа",
    "Dodanie Notatki": "Додавання Примітки",
    "Zacznij pisać aby wyszukać tagi...": "Почніть писати для пошуку тегу...",
    "Zamknij": "Закрити",
    "Nieobecność do": "Відсутність до",
    "Chat": "Чат",
    "Autor notatki": "Автор замітки",
    "Stan powiadomienia": "Стан сповіщення",
    "Wystąpił błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.": "Сталася помилка сервера при обробці обраної операції. Спробуйте ще раз.",
    "Zatwierdź": "Затвердити",
    "Ta dzielnica jest już przypisana do użytkownika.": "Цей район вже закріплена за користувачем.",
    "Typ szablonu": "Тип шаблону",
    "Wybrana data jest większa niż dozwolona %0.": "Вибрана дата більша за дозволену %0.",
    "Data Zmiany": "Дата зміни",
    "Dodaj stronę": "Додати сторінку",
    "Wybór języka": "Вибір мови",
    "Co minutę": "Щохвилини",
    "Zmień rozmiar": "Змінити розмір",
    "Edycja roli": "Редагування ролі",
    "Dane wydziału zostały usunięte.": "Дані відділу були видалені.",
    "Ilość punktów: ": "Кількість балів: ",
    "Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk \"Aktualizuj\". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.": "Доступне нове оновлення. Будь ласка, збережіть хід роботи і натисніть кнопку \"Оновити\". Ви також можете прийняти оновлення, оновивши вікно браузера. Відмова від оновлення може призвести до помилок.",
    "Sierpień": "Серпень",
    "Nieruchomość została zaktualizowana.": "Нерухомість оновлено.",
    "Przybornik": "Ящик інструментів",
    "Zrób zdjęcie": "Зробіть фото",
    "Powiadomienia zostały skonfigurowane.": "Сповіщення налаштовано.",
    "Następna inwentaryzacja": "Наступна інвентаризація",
    "Kwota rezerwacji": "Сума бронювання",
    "Wstaw trzy kolumny 1/3": "Вставте три стовпці 1/3",
    "Wybrany region": "Обраний регіон",
    "Status rozliczenia": "Статус розрахунку",
    "Utwórz katalog": "Створити каталог",
    "Adres email nadawcy": "Адреса електронної пошти відправника",
    "Zwierzę": "Тварина",
    "Edycja zespołu": "Редагування команди",
    "Zaopatrzenie zostało usunięte.": "Постачання були усунуті.",
    "Odśwież": "Оновити",
    "Zaokrąglenie": "Округлення",
    "Kwota średnia": "Середня сума",
    "Zacznij pisać aby wyszukać firmę...": "Почніть вводити текст для пошуку компанії...",
    "Synchronizacja rezerwacji": "Синхронізація бронювання",
    "Odpowiedź 4": "Відповідь 4",
    "Pytania": "Питання",
    "Zastępstwo wygaśnie za 5 minut.": "Термін дії заміни закінчується через 5 хвилин.",
    "Pokaż nieaktywnych właścicieli": "Показати неактивних власників",
    "Wybierz serwer": "Виберіть сервер",
    "Dane kierowcy: ": "Дані водія: ",
    "Nazwa pliku": "Назва файлу",
    "Aktualizuj": "Оновити",
    "${(ex as any).response.data.errors.message[0]}": "${(ex as any).response.data.errors.message[0]}",
    "Planowanie inwentaryzacji": "Планування інвентаризації",
    "Zapisz nową kolejność punktów": "Збережіть новий порядок точок",
    "Id zdarzenia": "Id події",
    "Checklista została usunięta": "Видалено чек-лист",
    "Kalendarz nieruchomości": "Календар нерухомості",
    "źródło danych zostało dodane.": "додано джерело даних.",
    "Nieobecny do": "Відсутній до",
    "To pole jest wymagane": "Це поле є обов'язковим для заповнення",
    "Logowanie zakończyło się niepowodzeniem.": "Не вдалося ввійти.",
    "Przywrócić użytkownika i jego wiadomości?": "Повернути користувача і його повідомлення?",
    "Serwery plików": "Файлові сервери",
    "Statystyki wniosków": "Статистика заявок",
    "Niewykonane zadania": "Невиконані завдання",
    "Kierowca Główny": "Головний водій",
    "Default mode": "Режим за замовчуванням",
    "Kwota opłacona": "Сума сплачена",
    "Kwota łącznie": "Сума всього",
    "Pracownik": "Працівник",
    "Wyślij link": "Надіслати посилання",
    "Zapauzuj zadanie": "Призупинити завдання",
    "Dowolna data": "Будь-яка дата",
    "Filtr został usunięty": "Фільтр видалено",
    "Zarchiwizowany": "Архівовані",
    "Stawka nieruchomości": "Сумма нерухомості",
    "Zwrócony": "Повернений",
    "Edytowane mogą być tylko szablony włączonych i obsługiwanych kanałów.": "Редагувати можна лише шаблони для ввімкнених і підтримуваних каналів.",
    "Czas pomiędzy": "Час між",
    "Ustawienia globalne": "Глобальні налаштування",
    "Dane właściciela": "Дані власника",
    "Podstawowe operacje na liczbach": "Основні операції з числами",
    "Dodanie formularza": "Додавання форми",
    "Wybrana liczba jest większa niż dozwolona %0.": "Вибране число більше дозволеного %0.",
    "Wystąpił błąd połączenia. Upewnij się że masz włączony internet i spróbuj ponownie.": "Сталася помилка підключення. Переконайтеся, що у вас увімкнено інтернет і спробуйте ще раз.",
    "Dopuść brak odpowiedzi": "Дозволити відсутність відповіді",
    "Usunięte": "Видалено",
    "Edycja nowej trasy": "Редагування нового маршруту",
    "Uwzględnij dodatkowe nieruchomości": "Включити додаткові нерухомості",
    "Back Office": "Бек-офіс",
    "Brak wersji": "Немає версії",
    "Zacznij pisać aby wyszukać miasto...": "Почніть вводити текст для пошуку міста...",
    "Dodaj firmę": "Додати компанію",
    "Archiwizuj": "Архівуй",
    "Akceptacja": "Прийняття",
    "Dodane:": "Додано:",
    "Otwórz czat - link": "Відкрити чат - посилання",
    "Kontrolki specjalizowane": "Спеціалізовані елементи управління",
    "Trasa została usunięta": "Маршрут прибрали",
    "Obciążenie Renters": "Плата на рахунок Рентерс",
    "Eksportuj do Excel": "Експортуйте до Excel",
    "Domyślny czas rezerwacji": "Час бронювання за замовчуванням",
    "Pobierz raport": "Завантажити звіт",
    "Oczekuje na wysłanie": "В очікуванні відправки",
    "Profil został zaktualizowany.": "Ваш профіль оновлено.",
    "Facturada": "Exclusivity",
    "Wybrane": "Вибране",
    "Liczba miast w regionie": "Кількість міст в регіоні",
    "Ten czat nie posiada przypisanych konkretnych użytkowników.": "Цей чат не має приписаних конкретних користувачів.",
    "Walidacja": "Перевірка",
    "Co 5 minut": "Кожні 5 хвилин",
    "ŚREDNIA": "СЕРЕДНЄ",
    "Źródła danych": "Джерела даних",
    "Ten zespół nie posiada jeszcze żadnych członków.": "У цій команді ще немає членів.",
    "Rozliczenia": "Розрахування",
    "Nigdy": "Ніколи",
    "Edycja wielu elementów": "Редагування декількох елементів",
    "Data do": "Дата до",
    "Nie wszystkie wybrane elementy zostały przeniesione. Nie posiadasz wymaganych uprawnień lub, w przypadku przenoszenia katalogów, katalog docelowy jest podkatalogiem katalogu źródłowego. Katalogi i pliki których nie udało się przenieść pozostały nadal w zakładce Wybrane.": "Не всі вибрані елементи переміщено. У вас немає необхідних дозволів або, якщо ви переміщуєте каталоги, каталог призначення є підкаталогом вихідного каталогу. Каталоги та файли, які не вдалося перемістити, залишаються у вкладці Вибране.",
    "Plik jest wysyłany": "Файл відправлено",
    "Rola została usunięta.": "Роль видалена.",
    "15 minut": "15 хвилин",
    "Zdecydowanie ważne": "Безумовно важливо",
    "Dodatkowe wynagrodzenie - komentarz": "Додаткова винагорода - коментар",
    "Maksimum": "Максимум",
    "Nazwa pola": "Назва поля",
    "Usuń plik": "Видалити файл",
    "Upload": "Завантажити",
    "Nazwa firmy": "Назва компанії",
    "Wstaw z dołu": "Вставити знизу",
    "Stała wartość": "Стала вартість",
    "Wynagrodzenia Pracowników": "Заробітна плата Працівників",
    "Codziennie": "Щодня",
    "Dodaj klienta": "Додати клієнта",
    "Zapisz filtr": "Зберегти фільтр",
    "Status dokumentu został zmieniony.": "Статус документа змінено.",
    "HR": "Управління персоналом",
    "Opisy": "Описи",
    "Zakres czasu": "Часовий діапазон",
    "Liczba pracowników w regionie": "Кількість працівників у регіоні",
    "Koordynator": "Координатор",
    "Edytuj Notatkę": "Редагувати примітку",
    "Kwota za godzinę": "Сумма за годину",
    "Przejdź do rezerwacji": "Перейти до бронювання",
    "Nieruchomość została dodana.": "Нерухомість додана.",
    "Użytkownik": "Користувач",
    "Grafik pracowniczy": "Робочий графік",
    "Wynagrodzenia Koordynatorzy": "Заробітна плата координатори",
    "MasterCode nieruchomości": "Мастеркод нерухомості",
    "Wysłano powiadomienie.": "Повідомлення надіслано.",
    "Top mieszkań generujacych najwięcej kosztów": "Кращі квартири, що генерують найбільшу вартість",
    "Tytuł sekcji": "Назва розділу",
    "Ten kanał ma możliwość wysyłania tekstu sformatowanego.": "Цей канал має можливість відправляти форматований текст.",
    "Peticion informacion": "Peticion informacion",
    "Nazwa użytkownika serwisowego": "Ім'я користувача служби",
    "Stawka za typ apartamentu": "Ставка за тип апартаменту",
    "Dodaj użytkownika": "Додати користувача",
    "Inwentaryzacja została zaktualizowana.": "Інвентаризація оновлена.",
    "Odejmowanie": "Віднімання",
    "Odpowiedź 5": "Відповідь 5",
    "Wymagaj znaków specjalnych w hasłach": "Вимагати спеціальні символи в паролях",
    "Miesiące": "Місяці",
    "Zespół został zaktualizowany.": "Команда оновилася.",
    "Zdecydowanie nie": "Однозначно ні",
    "Potwierdzenie przywrócenia": "Підтвердження відновлення",
    "${StatusName(StatusEnum[this.form.bookingType])}": "${StatusName(StatusEnum[this.form.bookingType])}",
    "Zapisz i wróć": "Зберегти і повернутися назад",
    "6 godzin": "6 годин",
    "Dodanie miasta": "Додавання міста",
    "Dodano zdjęć": "Додано фотографії",
    "Co 12 godzin": "Кожні 12 годин",
    "Dokument %0": "Документ %0",
    "Publiczne": "Громадський",
    "Podziedziałek - Piątek": "Понеділок - П'ятниця",
    "Czy jesteś pewien? ": "Ви впевнені? ",
    "Nie": "Ні",
    "Przypisz do zadania": "Призначити завдання",
    "Ten region jest już przypisany do użytkownika.": "Цей регіон вже закріплений за користувачем.",
    "Dane zadania": "Дані завдання",
    "Pole \"Nazwisko\" jest wymagane.": "Поле «Прізвище» обов'язкове для заповнення.",
    "Wynagrodzenia HK": "Заробітна плата НК",
    "Nazwisko właściciela": "Прізвище власника",
    "Ilość wyników do odrzucenia": "Кількість результатів для відхилення",
    "wybierz": "виберіть",
    "Kalendarz pracowników": "Календар працівників",
    "Wróć do logowania": "Повернутися до входу",
    "Hasło użytkownika serwisowego": "Пароль користувача служби",
    "Wyloguj": "Вийди",
    "Przypisane tagi:": "Приписані теги:",
    "Brak elementów podsumowania": "Бракує підсумкових елементів",
    "Ten użytkownik już jest przypisany do czatu": "Цей користувач вже призначений до чату",
    "Przypisane do": "Призначено до",
    "Wymuś zmianę hasła": "Примусово змінити пароль",
    "Opis": "Опис",
    "Lista rozwijana": "Випадаючий список",
    "Niepotwierdzeni": "Непідтверджені",
    "Dodaj typ wynagrodzenia": "Додай тип винагороди",
    "Przekroczono dozwoloną ilość znaków: %0.": "Ви перевищили дозволену кількість символів: %0.",
    "Baza danych tego klienta jest nieaktualna. Uruchom aktualizację za pomocą przycisku na dole formularza.": "База даних цього клієнта застаріла. Запустіть оновлення за допомогою кнопки внизу форми.",
    "Wybierz dział": "Виберіть відділ",
    "Dzielnice": "Райони",
    "Pozostało %0 znaków.": "Залишилося %0 знаків.",
    "Zastępstwo zostało zaktualizowane.": "Заміна оновлена.",
    "Zapauzowane": "Призупинено",
    "Uwzględniaj tagi": "Включити теги",
    "Miasto zostało usunięte": "Місто видалено",
    "Treści odpowiedzi są wymagane": "Потрібен зміст відповідей",
    "Dodaj chat": "Додай чат",
    "Środa": "Середа",
    "Tłumaczę...": "Перекладаю...",
    "Kanał jest domyślnie wymagany. Nie da się wyłączyć wysyłania powiadomienia tym kanałem. Możliwa jest tylko zmiana tytułu i treści komunikatu.": "За замовчуванням потрібен канал. Відключити відправку повідомлень через цей канал не представляється можливим. Можливо лише змінити заголовок і зміст повідомлення.",
    "Nadpisanie kwoty wynagrodzenia zostało dodane.": "Перезапис суми зарплати був доданий.",
    "Piątek": "П'ятниця",
    "Utworzone przez": "Створене з",
    "Stwierdzenie": "Констатація",
    "Kalendarz": "Календар",
    "Informacje": "Інформація",
    "Wymiary": "Розміри",
    "Komentarz dotyczący naprawy problemu": "Коментар щодо усунення проблеми",
    "Edycja klienta": "Редагування клієнта",
    "Adres email": "Електронна пошта",
    "Na pewno przenieść wybrane elementy do bieżącego katalogu?": "Дійсно перемістити обрані позиції в поточний каталог?",
    "Zadania dzisiaj": "Сьогоднішні завдання",
    "Nadpisz kwotę": "Перепишіть сумму",
    "Numer mieszkania (Number)": "Номер квартири (Number)",
    "Przypisani użytkownicy:": "Призначені користувачі:",
    "Anuluj i wyjdź z kreatora": "Скасування та вихід із майстра",
    "Wyszukuję...": "Пошук...",
    "Członkowie zespołu": "Учасники команди",
    "Pokaż tytuł strony": "Показати заголовок сторінки",
    "Dodaj premię": "Додай премію",
    "Nie udało się pobrać ról!": "Не вдалося завантажити ролі!",
    "Opracowane, zapisz": "Розроблено, збережено",
    "Dane osobowe": "Особисті дані",
    "Szablon został usunięty.": "Шаблон видалено.",
    "W zespole od %0 do %1": "В команді від %0 до %1",
    "Notatki Koordynatorów": "Нотатки координаторів",
    "Wyszukaj wszędzie": "Пошук усюди",
    "Usunięcie wybranych elementów": "Видалення виділених елементів",
    "Kontrolka wyboru (pojedynczy wybór)": "Елемент керування виділенням (одинарне виділення)",
    "Uwzględniaj dzielnice": "Візьміть до уваги райони",
    "Przywrócić wiadomość?": "Повернути повідомлення?",
    "Dozwolone rozszerzenia": "Дозволені розширення",
    "Checklista została dodana.": "Додано чек-лист.",
    "Numer rezerwacji Avantio": "Номер бронювання Авантіо",
    "Administracja - Użytkownicy": "Адміністрування - Користувачі",
    "5 minut": "5 хвилин",
    "Data zdarzenia": "Дата події",
    "Dodaj dział": "Додати відділ",
    "(AirBnb) Wysłano żądanie do gościa": "(AirBnb) Запит висланий до гостя",
    "ID obiektu": "ID об'єкту",
    "Cancelled": "Cancelled",
    "Nierozwiązane": "Невирішені",
    "Przywróć oryginał": "Відновити оригінал",
    "Edycja ustawień wynagrodzenia dla:": "Зміна налаштування заробітної плати для:",
    "Wyposażenie": "Обладнання",
    "Wyślij": "Надіслати",
    "Trasa została zakończona": "Маршрут було завершено",
    "odznacz wszystko": "відзнач все",
    "Ustawienia domyślne": "Налаштування за замовчуванням",
    "Szef Regionu": "Очільник області",
    "Nieobecność od": "Відсутність від",
    "Wartość musi być liczbą całkowitą": "Вартість має бути цілим числом",
    "Zaplanowane": "Заплановано",
    "Dodawanie inwentaryzacji": "Додавання інвентаризації",
    "Tylko do odczytu": "Лише для читання",
    "Tagi nieruchomości": "Теги нерухомості",
    "Tytuł formularza jest wymagany": "Назва форми є обов'язковою",
    "Nieruchomości": "Нерухомості",
    "Czy zapisać trasę i wysłac ją do kierowcy?": "Зберегти маршрут і відправити його водієві?",
    "Dane podstawowe": "Основні дані",
    "Edycja nieruchomości": "Редагування нерухомості",
    "Ten Tydzień": "Цей Тиждень",
    "Monitoring": "Контроль",
    "Nazwa kategorii": "Назва категорії",
    "Statystyki regionalne - miasta": "Регіональна статистика - міста",
    "Dodaj dane wydziału": "Додати дані відділу",
    "Pokaż rezerwacje właścicielskie": "Показати бронювання власників",
    "Aktywne": "Активні",
    "Ustawienia wynagrodzenia zostały zaktualizowane.": "Налаштування заробітної плати були оновлені.",
    "+": "+",
    "Data modyfikacji do": "Дата зміни до",
    "Aktywne kanały komunikacji": "Активні канали зв'язку",
    "słowa": "слова",
    "Wszystkie zadania": "Всі завдання",
    "Wróć na dół": "Повернутися вниз",
    "Walidacja certyfikatu SSL": "Перевірка SSL сертифіката",
    "Stawki nieruchomości": "Ставка нерухомості",
    "Jedna kolumna": "Один стовпець",
    "Porównaj z edytowaną wersją": "Порівняння з відредагованою версією",
    "Wyznacz punkty": "Позначте точки",
    "Zmniejsz": "Зменшити",
    "Język:": "Мова:",
    "Usługi": "Послуги",
    "Ilość pracowników": "Кількість співробітників",
    "Typ nieruchomości": "Тип нерухомості",
    "Odpowiedź 2": "Відповідь 2",
    "Generuję plik...": "Генерую файл...",
    "treść notatki": "зміст примітки",
    "do": "до",
    "Zespół": "Команда",
    "Dane rezerwacji": "Дані бронювання",
    "Liczba pracowników zatrudnionych tylko w tym mieście": "Кількість співробітників, які працюють тільки в цьому місті",
    "Nazwisko i Imię": "Прізвище та ім'я",
    "Serwer bazy danych": "Сервер баз даних",
    "Lista nieruchomości": "Список нерухомості",
    "Prywatne": "Приватний",
    "Wymagane jest wybranie źródła danych": "Необхідно вибрати джерело даних",
    "Cyfry": "Цифри",
    "Brak kluczy": "Бракує ключів",
    "Wprowadzona wartość jest za niska": "Введене значення занадто низьке",
    "Klient został zaktualizowany.": "Клієнт оновився.",
    "Obciążenie Magazyn": "Плата на рахунок Складу",
    "Inwentaryzacja": "Інвентаризація",
    "Jesteś pewien?": "Ви впевнені?",
    "Edytuj uprawnienia": "Редагувати дозвіл",
    "Polska": "Польща",
    "Dodanie roli": "Додавання ролі",
    "Użytkownik został dodany.": "Користувача додано.",
    "Tag": "Тег",
    "Wynagrodzenia mieszane": "Змішана заробітна плата",
    "Stwórz czat z zadania": "Творіть чат завдання",
    "Nieopłacone/dłużnik": "Неоплачений/боржник",
    "Nazwa konta (Klucz dostępu)": "Назва облікового запису (ключ доступу)",
    "Kanały obsługiwane przez wybrany typ powiadmienia": "Канали, підтримувані обраним типом повідомлень",
    "Coś poszło nie tak": "Щось пішло не так",
    "Województwo": "Воєводство",
    "Ok": "Гаразд",
    "Poprzedni miesiąc": "Попередній місяць",
    "Zapytanie SQL": "SQL запит",
    "Nie udało się usunąć użytkownika z czatu": "Не вдалося видалити користувача з чату",
    "Ma dzisiaj": "На сьогоднішній день",
    "Nie udało się pobrać powiadomień.": "Не вдалося завантажити сповіщення.",
    "Nazwa obiektu": "Назва об'єкта",
    "Uwzględniaj Miasta": "Включити Міста",
    "Podsumowanie": "Зведення",
    "Uwaga! wraz z miastem": "Увага! разом з містом",
    "Harmonogram": "Розклад",
    "Kwota pozostała": "Залишкова кількість",
    "Zespół został usunięty.": "Команду прибрали.",
    "Potwierdzam, zapisz": "Підтверджую, зберігти",
    "po %0 nieudanych próbach logowania": "після %0 невдалих спроб входу",
    "Załączniki": "Вкладення",
    "ID nazwa obiektu": "ID назва об'єкту",
    "Aktywna nieruchomość": "Активна нерухомість",
    "Ilość osób": "Кількість осіб",
    "usunięto dzielnice": "видалено район",
    "Czwartek": "Четвер",
    "Czy na pewno chcesz zablokować użytkownika?": "Чи насправді хочете заблокувати користувача?",
    "Otwórz Ustawienia": "Відкрийте налаштування",
    "Zapauzowano:": "Призупинено:",
    "Ostatnia aktywność": "Остання активність",
    "Filtry kalendarza": "Фільтри календаря",
    "Edycja": "Редагування",
    "Wybierz plik lub katalog": "Виберіть файл або каталог",
    "Inna": "Інше",
    "Przyczyna": "Причина",
    "Wczytuję...": "Завантажувати...",
    "Status: ": "Статус: ",
    "Tłumacz": "Перекладач",
    "Data zakończenia/wykonania": "Дата закінчення/виконання",
    "Ilość łóżek dwuosobowe": "Кількість двоспальних ліжок",
    "Odrzuć": "Відкидати",
    "Dodaj zadanie": "Додати завдання",
    "Host dodatkowy": "Додатковий хост",
    "Tytuł": "Назва",
    "Avantio ID nieruchomości": "Авантіо ID нерухомості",
    "Classic mode": "Класичний режим",
    "Checklista:": "Чек-лист:",
    "Przypisani użytkownicy": "Приписані користувачі",
    "Brak": "Відсутність",
    "Wyszukaj": "Пошук",
    "Wersje dokumentu": "Версії документів",
    "Kalendarz Pracowniczy": "Календар для співробітників",
    "Mieszane": "Змішане",
    "Zadanie zostało dodane.": "Завдання додано.",
    "Ustawienia zostały zapisane.": "Налаштування збережено.",
    "Kalendarz - Kategorie zasobów": "Календар – категорії засобів",
    "Rodzaj odpowiedzi": "Тип відповіді",
    "Rozpocznij synchronizację": "Почніть синхронізацію",
    "Zobacz sesję użytkownika": "Переглянути сеанс користувача",
    "Nie udało się zapisać szablonu": "Не вдалося зберегти шаблон",
    "Małe znaki": "Символи з малої літери",
    "Nieobecny od": "Відсутній від",
    "Zabierz dostęp": "Заберіть доступ",
    "Filtr został zapisany": "Фільтр збережено",
    "Upload plików": "Завантаження файлів",
    "Kwota opłacona do": "Сума виплачена до",
    "Przypisane:": "Призначені:",
    "Wybrany język": "Вибрана мова",
    "Szczegóły zadania": "Деталі про завдання",
    "To miasto znajduję się już w tym regionie.": "Це місто вже є в цьому регіоні.",
    "Czas": "Час",
    "Wyszukiwany ciąg znaków": "Рядок пошуку",
    "Treść notatki": "Зміст примітки",
    "Nieruchomości (Data przyjazdu)": "Нерухомості (дата прибуття)",
    "Inwentaryzacja została usunięta.": "Інвентаризація видалена.",
    "Brak nowych powiadomień.": "Немає нових повідомлень.",
    "Wielkość kroku": "Розмір кроку",
    "Administracja - Zespoły": "Адміністрування - Команди",
    "Back Office (BO)": "Бек-офіс (BO)",
    "Edytuj stawkę za godzinę": "Редагуй ставку за годину",
    "Nazwa sieci Wi-Fi": "Назва мережі Wi-Fi",
    "Użytkownik został zaktualizowany.": "Користувач оновлений.",
    "Renters": "Renters",
    "Dział:": "Відділ:",
    "Brak działu nadrzędnego": "Бракує головного відділу",
    "Narzędzia": "Інструменти",
    "Użytkownik został zablokowany, a jego wiadomość ukryte.": "Користувач був заблокований, і його повідомлення приховано.",
    "Zadanie na dziś": "Завдання на сьогодні",
    "Co 6 godzin": "Кожні 6 годин",
    "Zobacz czaty": "Переглянути чати",
    "Numer rezerwacji": "Номер резервації",
    "Archiwalny": "Архівний",
    "Usunięcie": "Видалення",
    "Edytuj urlop": "Редагуй відпустку",
    "Edytuj obraz": "Редагувати зображення",
    "Podano nieprawidłowe dane.": "Надані невірні дані.",
    "Dodanie działu": "Додавання відділу",
    "Bezpieczeństwo": "Безпека",
    "Usuń zadanie": "Видалити завдання",
    "Wymagane": "Необхідне",
    "Sprzątanie": "Прибирання",
    "Pokaż zdjęcie w raporcie": "Показати фото у звіті",
    "Kategoria": "Категорія",
    "Co 10 dni": "Кожні 10 днів",
    "Zaopatrzenie zostało zaktualizowane.": "Постачання було оновлено.",
    "Udało się usunąć zadania!": "Вдалося видалити завдання!",
    "Liczba pracowników": "Кількість співробітників",
    "Token został usunięty.": "Токен видалено.",
    "Region apartamentu": "Регіон квартири",
    "Musisz najpierw zapisać zmienioną kolejność punktów zanim oznaczysz punkt": "Перед тим, як позначити точку, ви повинні спочатку зберегти змінений порядок точок",
    "Role i uprawnienia": "Ролі та дозволи",
    "Nazwa dzielnicy": "Назва району",
    "Kontakty - Typ działania": "Контакти - Вид дії",
    "Kolor trasy": "Колір маршруту",
    "Ukryj": "Сховай",
    "Nikomu nie przydzielono uprawnień": "Нікому не призначено дозволів",
    "Przyznaj dostęp": "Надати доступ",
    "Suma wynagrodzeń koordynatorów": "Сума заробітної плати координаторів",
    "Status edytowanej wersji dokumentu": "Статус відредагованої версії документа",
    "Historia nieruchomości": "Історія об'єкта нерухомості",
    "Dodaj zaopatrzenie": "Додати постачання",
    "Lista urządzeń na których jesteś zalogowany": "Список пристроїв, на яких ви увійшли в систему",
    "Booking with conflict": "Резервація з конфліктом",
    "Wszystkie niezapisane zmiany zostaną utracone.": "Усі незбережені зміни будуть втрачені.",
    "Nie można dodać pliku(ów): \"%0\". Można dodawać tylko pliki typu: \"%1\".": "Не вдається додати файл(и): \"%0\". Можна додавати тільки файли типу: \"%1\".",
    "Dane do sieci": "Дані для мережі",
    "Housekeeping (HK)": "Хаус-кіпінг (HK)",
    "Tylko serwer deweloperski. Nie używać w trybie produkcyjnym. ": "Тільки сервер розробки. Не використовувати в режимі виробництва. ",
    "Zakończone: ${$filters.datetime(task.dateCompleted)}": "Виконано: ${$filters.datetime(task.dateCompleted)}",
    "Zablokowano możliwość edycji": "Можливість редагування заблокована",
    "Powiadomienie jest domyślnie wymagane. Nie da się wyłączyć wysyłania tego powiadomienia.": "За замовчуванням потрібне сповіщення. Ви не можете вимкнути надсилання цього повідомлення.",
    "Widoczność": "Видимість",
    "Opracowanie kosztu": "Оброблення витрат",
    "Nazwa przycisku ``Wyślij``": "Назва кнопки ''Надіслати''",
    "Nazwa katalogu": "Назва каталогу",
    "Edycja inwentaryzacji": "Редагування інвентаризації",
    "Dostępne obiekty:": "Доступні об'єкти:",
    "Marka": "Бренд",
    "Indywidualne tokeny": "Індивідуальні жетони",
    "Data utworzenia zadania": "Дата створення завдання",
    "Aktywna": "Активна",
    "Kwota kosztu od": "Сума витрат від",
    "Zacznij pisać aby wyszukać zaopatrzenie...": "Почніть вводити текст для пошуку припасів...",
    "Zajęte": "Зайнятий",
    "Wersje": "Версії",
    "Nieudane": "Не вдалося",
    "Wyszukaj...": "Пошук...",
    "Statystyki wyniosków": "Статистика висновків",
    "Lewy panel": "Ліва панель",
    "Sortowanie malejąco": "Сортування за спаданням",
    "Zaopatrzenie": "Поставка",
    "Wyślij testowe powiadomienie": "Надіслати тестове повідомлення",
    "Podana wartość jest nieprawidłowa.": "Дане значення недійсне.",
    "Wyszukaj nieruchomość...": "Пошук нерухомості...",
    "Odrzucone": "Відхилено",
    "Warunkowo": "Умовно",
    "Typ powiadomienia": "Тип повідомлення",
    "Data dodania": "Дата додавання",
    "Zacznij pisać aby wyszukać tag...": "Почніть писати для пошуку тегу...",
    "Informacje o czacie": "Інформація про чат",
    "Dodaj zadanie: inspekcja": "Додати завдання: аудит",
    "Zgłoszone problemy": "Повідомлені проблеми",
    "Stawka za godzinę brutto": "Ставка за годину брутто",
    "Pokaż etykietę": "Показати мітку",
    "Zaakceptowany": "Прийнятий",
    "Przeterminowane": "Прострочення",
    "Wydział / Rola": "Відділ / Роль",
    "Autor": "Автор",
    "Kwota pobrana": "Зібрана сума",
    "Pobierz plik": "Завантажити файл",
    "Konfiguracja harmonogramu": "Конфігурація розкладу",
    "Zdjęcia": "Фотографії",
    "Wybrano:": "Вибрано:",
    "ID zadania": "ID завдання",
    "Wyrażenia warunkowe": "Умовні вирази",
    "Wiadomości": "Відомості",
    "Typ obciążenia": "Тип навантаження",
    "Wstaw stronę": "Вставити сторінку",
    "W polu możemy umieszczać jedynie cyfry": "Ми можемо вводити тільки цифри в полі",
    "Rozwiń": "Розширити",
    "Rozpoczęto synchronizację rezerwacji": "Розпочато синхронізацію бронювання",
    "Nie możesz wznowić obsługi tego zadania, ponieważ wykonujesz teraz inne zadanie": "Ви не можете повернутися до цього завдання, оскільки зараз виконуєте інше завдання",
    "Brak nowych wiadomości.": "Немає нових повідомлень.",
    "Zaakceptuj, wyślij": "Прийняти, відправити",
    "Statystyki": "Статистика",
    "Odstęp": "Відступ",
    "Uwzględniaj zadania z miesiąca": "Включити завдання за місяць",
    "Pomoc": "Допомога",
    "Podana nazwa jest zajęta.": "Введене вами ім'я зайнято.",
    "Maj": "Травень",
    "Dodaj zespół": "Додати команду",
    "Właściciel regionu": "Власник регіону",
    "Przypisane miasta:": "Призначені міста:",
    "Godzina wymeldowania": "Час виїзду",
    "Dodaj szablon": "Додати шаблон",
    "Tytuł formularza nie może być dłuższy niż %0 znaków": "Назва форми не може бути довшою за % 0 символів",
    "Data aktualizacji": "Дата поновлення",
    "Tekst alternatywny": "Альтернативний текст",
    "Czy jesteś pewien, że chcesz przypisać dzielnicę do nowego miasta? Wszystkie nieruchomości i użytkownicy przypisani do tej dzielnicy przestaną być do niej przypisani.": "Ви впевнені, що хочете приєднати район до нового міста? Всі нерухомості та користувачі, призначені до цього району, більше не будуть до нього приписані.",
    "Oznacz widoczne jako przeczytane": "Відзнач видиме як прочитане",
    "Temat": "Тема",
    "Nie można usunąć swojego konta.": "Ви не можете видалити свій обліковий запис.",
    "Wstaw dwie równe kolumny": "Вставте два рівних стовпця",
    "Uczestnicy rozmowy:": "Учасники розмови:",
    "Obciążenie Gościa": "Плата стягується з Гостя",
    "KRS": "KRS",
    "Obok siebie": "Біля себе",
    "Szerokość": "Ширина",
    "Imię i nazwisko pracownika": "Ім'я і прізвище працівника",
    "Dodaj urlop": "Додай відпустку",
    "Checklista została zaktualizowana.": "Оновлено чек-лист.",
    "Priorytet": "Пріоритет",
    "Ukryj czaty": "Сховай чати",
    "zgłoś problem w apartamencie": "повідомити про проблему в апартаменті",
    "30 minut": "30 хвилин",
    "Symbol klienta": "Символ клієнта",
    "Terminy do słownika będzie można dodawać dopiero po zapisaniu źródła danych.": "Ви зможете додавати терміни до словника лише після збереження джерела даних.",
    "Imię właściciela": "Ім'я власника",
    "Miasto zostało zaktualizowane.": "Місто оновилося.",
    "Użytkownik jest liderem zespołu": "Користувач є лідером команди",
    "Ustawienia zostały zaktualizowane.": "Налаштування були оновлені.",
    "Brudne": "Брудний",
    "Pokaż tytuł sekcji": "Показати заголовок розділу",
    "Notatka do zadania": "Примітка завдання",
    "Data użycia": "Дата використання",
    "Host": "Хост",
    "Data": "Дата",
    "Kraj": "Країна",
    "Wstaw z lewej strony": "Вставити зліва",
    "Imię i nazwisko": "Ім'я і прізвище",
    "Data rezerwacji do": "Дата бронювання до",
    "Jest to lista obiektów które nie mają jeszcze przypisanego właściciela.": "Це список об'єктів, які ще не мають призначеного власника.",
    "Usuń datę": "Видалити дату",
    "Kwota brakująca": "Сума котрої бракує",
    "Wartość": "Вартість",
    "Liczba inspektorów": "Кількість інспекторів",
    "Dostęp zabroniony": "Доступ заборонений",
    "Lista jest pusta": "Список пустий",
    "Gość": "Гість",
    "Serwer plików": "Файловий сервер",
    "Dostęp": "Доступ",
    "24 godziny": "24 години",
    "Usunął": "Видалений",
    "Błąd 404. Nie znaleziono żądanego zasobu.": "Помилка 404. Запитуваний ресурс не був знайдений.",
    "Data uruchomienia": "Дата запуску",
    "Ilość sypialni": "Кількість спалень",
    "L4": "Л4",
    "Co miesiąc": "Щомісяця",
    "Data Wykonania": "Дата Виконання",
    "Inspekcja": "Інспекція",
    "Kroki": "Кроки",
    "Ukryj przycisk": "Приховати кнопку",
    "Nieaktywne": "Неактивні",
    "Administracja - Role": "Адміністрування - Ролі",
    "Powiązane": "Пов'язане",
    "Godzina:": "Година:",
    "Wygeneruj pilik": "Створити файл",
    "Urządzenie zostało usunięte.": "Пристрій було видалено.",
    "Zamknięte": "Закрито",
    "Poczta": "Пошта",
    "Uwzględniaj nieruchomości": "Включити нерухомості",
    "Administracja - Województwa": "Адміністрування - Воєводства",
    "Poprawne": "Правильно",
    "Uwzględniaj miasta": "Включити міста",
    "Prawy panel": "Права панель",
    "Średni": "Середній",
    "${title}": "${title}",
    "Linia": "Лінія",
    "Numer konta bankowego": "Номер банківського рахунку",
    "Ustaw szerokości proporcjonalnie": "Встановіть ширину пропорційно",
    "Data usunięcia": "Дата видалення",
    "Czerwiec": "Червень",
    "Numer telefonu": "Номер телефону",
    "Zapisz jako": "Зберегти як",
    "10 minut": "10 хвилин",
    "Koszty wg regionów": "Витрати по регіонах",
    "Kwota": "Сумма",
    "Miasto apartamentu": "Місто квартири",
    "Widok tygodniowy": "Тижневий перегляд",
    "Numer mieszkania": "Номер квартири",
    "Średnie wynagrodzenie": "Середня зарплата",
    "Edycja zastępstwa": "Редагування заміни",
    "Data zakończenia od": "Дата закінчення від",
    "Nieprawidłowy adres URL": "Недійсна URL-адреса",
    "Wartość domyślna": "Вартість за замовчуванням",
    "Opis zadania": "Опис завдання",
    "Brak zadania": "Бракує завдання",
    "Szablon został dodany.": "Шаблон додано.",
    "Potwierdzam": "Підтверджую",
    "Firma została usunięta.": "Компанія була видалена.",
    "Luty": "Лютий",
    "Co 4 miesiące": "Кожні 4 місяці",
    "Wybierz koordynatora": "Виберіть координатора",
    "Koszt Renters": "На рахунок Рентерс",
    "Zacznik pisać Form lub Entry aby uruchomić podpowiadanie.": "Почніть вводити Form або Entry, щоб викликати підказку.",
    "Zaopatrzenie zostało dodane.": "Були додані поставки.",
    "Dodanie właściciela": "Додавання власника",
    "Rozpocznij trasę": "Почніть маршрут",
    "Ten kanał nie ma możliwości wysyłania tekstu sformatowanego.": "Цей канал не має можливості відправляти форматований текст.",
    "Stwórz zadanie z notatki": "Створіть завдання з нотатки",
    "Zadanie zostało zapauzowane.": "Завдання призупинено.",
    "Aktualne urządzenie": "Поточний пристрій",
    "Uwzględnij wydziały i statusy jednocześnie": "Включайте відділи та статуси одночасно",
    "Dodaj nowy koszt": "Додати нову вартість",
    "Blokada konta": "Блокада облікового запису",
    "Posprzątane": "Прибрано",
    "Imię": "Ім\"я",
    "Pokaż zdjęcia": "Показати фото",
    "Ta nieruchomość nie posiada informacji na temat wyposażenia.": "Ця нерухомість не має інформації про обладнання.",
    "Szczegóły zdarzenia nr %0": "Деталі події %0",
    "Pokaż czaty": "Покажи чати",
    "Definicja formularza znajduje się w obiekcie Form.": "Визначення форми знаходиться в об'єкті Form.",
    "Zdjęcie użytkownika": "Фото користувача",
    "Minuty": "Хвилини",
    "Katalog": "Каталог",
    "co 6 miesięcy": "кожні 6 місяців",
    "Harmonogram został zmieniony": "Розклад змінено",
    "Komunikat walidacji": "Повідомлення про перевірку",
    "Pokaż filtry": "Показати фільтри",
    "Usuń element": "Видалити елемент",
    "Imię gościa": "Ім'я гостя",
    "Następna strona": "Наступна сторінка",
    "Przejdź do zadania": "Перейти до завдання",
    "Wynagrodzenie za sprzątanie": "Винагорода за прибирання",
    "Role użytkownika": "Ролі користувачів",
    "Zamiany zostały zapisane.": "Заміни були збережені.",
    "Cztery kolumny": "Чотири стовпці",
    "Odejmij kwotę": "Відніми сумму",
    "Uwzględnij zadania z miesiąca:": "Включити завдання за місяць:",
    "Organizuj": "Організувати",
    "Błędne": "Неправильне",
    "Adres firmy": "Адреса компанії",
    "Paid": "Paid",
    "Nazwa przycisku ``Dalej``": "Назва кнопки ''Далі''",
    "Dzielnica została dodana.": "Район додано.",
    "Dodanie regionu": "Додавання регіону",
    "Uruchom zadanie": "Запустити задачу",
    "Miasta:": "Міста:",
    "Nazwa ulicy (StreetName)": "Назва вулиці",
    "Nawiguj do kolejnego punktu": "Перехід до наступного пункту",
    "Status": "Статус",
    "Filtry": "Фільтри",
    "Twoje hasło wygasło. Zmień je na nowe.": "Термін дії вашого пароля минув. Змініть його на новий.",
    "Przesyłam...": "Відправляю...",
    "Pojedynczy wybór": "Єдиний вибір",
    "zaznacz wszystko": "вибрати все",
    "Pliki publiczne": "Загальнодоступні файли",
    "Login": "Логін",
    "Status notatki": "Статус примітки",
    "Zapisz trasę": "Зберегти маршрут",
    "Zapisz": "Зберегти",
    "Ustawienia chatów": "Налаштування чатів",
    "Top kosztów wg miast": "Кращі ціни по містах",
    "Adres IP": "IP-адреса",
    "Nazwisko": "Прізвище",
    "Nieaktywni właściciele": "Неактивні власники",
    "Co 10 minut": "Кожні 10 хвилин",
    "Email został wysłany.": "Імейл висланий.",
    "Użytkownik posiada pełne uprawnienia": "Користувач має повні права",
    "Czas uruchomienia": "Час запуску",
    "Wydział": "Відділ",
    "Dodatkowe wynagrodzenie - kwota": "Додаткова винагорода - сума",
    "Cancelled by owner": "Cancelled by owner",
    "Nie możesz modyfikować uprawnień": "Не можна змінити дозволи",
    "Jutro": "Завтра",
    "Inspekcje": "Інспекції",
    "Nazwa katalogu została zmieniona.": "Каталог перейменовано.",
    "Strona": "Сторінка",
    "Notatka została usunięta.": "Примітку видалено.",
    "Dodaj": "Додай",
    "Bank": "Банк",
    "Zadania do wykonania": "Завдання для виконання",
    "Urlop został dodany.": "Відпустка була додана.",
    "Nazwa przedmiotu": "Назва елемента",
    "Pokaż historię": "Показати історію",
    "Treść wiadomości": "Зміст повідомлення",
    "Szablon został zaktualizowany.": "Шаблон оновлено.",
    "Wybierz pliki": "Виберіть файли",
    "Komentarz Konserwatora": "Коментар Техніка",
    "Brak dodanych punktów trasy": "Не додано жодної маршрутної точки",
    "Konserwacja": "Обслуговування",
    "Dane Adresowe": "Адресні дані",
    "Harmonogram zadań": "Розклад завдань",
    "Tryb konserwacji systemu": "Режим обслуговування системи",
    "Tag został usunięty.": "Тег було видалено.",
    "Przedrostek": "Префікс",
    "Edycja wynagrodzenia": "Зміна заробітної плати",
    "Kierowca": "Водій",
    "Potwierdzenie archiwizacji": "Підтвердження архівації",
    "Edycja wynagrodzenia mieszanego dla:": "Редагування змішаної заробітної плати для:",
    "Liczba nocy": "Кількість ночей",
    "Użytkownik i jego wiadomości zostały przywrócone.": "Користувач і його повідомлення були відновлені.",
    "Podgląd szablonu e-maila": "Попередній перегляд шаблону Електронної Пошти",
    "Data ostatniej zmiany statusu": "Дата останньої зміни статусу",
    "Edytuj zadanie": "Редагування завдання",
    "Profil użytkownika": "Профіль користувача",
    "Dodawanie trasy": "Додавання маршруту",
    "Data nieobecności do": "Дата відсутності до",
    "Warunki logiczne": "Логічні умови",
    "Kwota za zadania": "Сумма за завдання",
    "Zmień nazwę pliku": "Перейменувати файл",
    "Kwota jednostkowa": "Одинична сумма",
    "Zapamiętaj logowanie": "Запам'ятати логін",
    "Nie udało się zmienić statusu zadania": "Не вдалося змінити статус завдання",
    "Data ostatniej inwentaryzacji": "Дата останньої інвентаризації",
    "Wyszukaj tag...": "Пошук тегу...",
    "Nieudane próby logowania": "Невдалі спроби входу",
    "ZMIANA": "ЗМІНА",
    "Edycja nieruchomości roboczej": "Редагування робочої нерухомості",
    "Nie możesz rozpocząć obsługi tego zadania, ponieważ wykonujesz teraz inne zadanie": "Ви не можете розпочати виконання цього завдання, оскільки зараз виконуєте інше завдання",
    "Nazwa apartamentu": "Назва апартаменту",
    "Wpisz nazwę trasy": "Введіть назву маршруту",
    "Rola przypisana do tego wydziału jest nieaktywna": "Роль, відведена цьому відділу, неактивна",
    "Zadanie zostało włączone": "Завдання ввімкнуто",
    "Wpis został dodany.": "Запис додано.",
    "Szukana fraza": "Пошукова фраза",
    "Lipiec": "Липень",
    "Rozwiązane": "Вирішені",
    "Czyim kosztem jest dodany przedmiot?": "За чий рахунок додається предмет?",
    "Progress Gotowości": "Готовий процес",
    "Udało się dodać nieruchomość do trasy": "Успішно додано нерухомість до маршруту",
    "Zobacz wszystkie": "Дивитись все",
    "Wynagrodzenia Kierowcy": "Заробітна плата водія",
    "Stwórz czat": "Створіть чат",
    "Wysoki": "Високий",
    "Data wyjazdu": "Дата виїзду",
    "Edycja właściciela": "Редагування власника",
    "Zostałeś zablokowany, dodanie wiadomości jest niemożliwe": "Ви заблоковані, додавання повідомлення неможливо",
    "Typ serwera": "Тип сервера",
    "Zespół aktywny": "Активна команда",
    "Kategoria została zaktualizowana.": "Категорія була оновлена.",
    "Numer domu": "Номер будинку",
    "Numer mieszkania (Door)": "Номер квартири (Двері)",
    "Pomieszczenie": "Кімната",
    "Dodanych plików %0 (limit wynosi %1).": "Доданих файлів %0 (ліміт складає %1).",
    "Wymeldowanie": "Виселення",
    "Port": "Порт",
    "Wynagrodzenie PLN brutto": "Зарплата брутто",
    "Poniedziałek": "Понеділок",
    "Wybrana liczba jest mniejsza niż dozwolona %0.": "Вибране число менше дозволеного %0.",
    "Id modelu: %0": "Id моделі: %0",
    "Twój token": "Ваш токен",
    "Zwierzątko": "Тваринка",
    "Pozycja": "Позиція",
    "Maksymalny rozmiar pliku w MB": "Максимальний розмір файлу в Мб",
    "Po zaznaczeniu tej opcji uprawnienia indywidualne i pochodzące z ról nie są brane pod uwagę.": "Якщо вибрано цей параметр, індивідуальні та рольові дозволи не враховуються.",
    "Serwer został zaktualizowany.": "Сервер оновлено.",
    "Nie udało się pobrać listy kanałów": "Не вдалося завантажити список каналів",
    "do czasu ręcznego odblokowania": "до розблокування вручну",
    "Wybierz pliki (do {0})": "Виберіть файли (до {0})",
    "Zmień nazwę": "Перейменувати",
    "Słownik": "Словник",
    "Zakolejkowane": "У черзі",
    "Wyszukiwarka miejsc": "Пошук місць",
    "Dostęp przyznany": "Доступ надано",
    "Data utworzenia do": "Дата створення до",
    "Dodawanie wyposażenia": "Додавання обладнання",
    "Maksymalna ilość plików": "Максимальна кількість файлів",
    "Pole \"%0\" jest wymagane.": "Поле «%0» обов'язкове для заповнення.",
    "Wystąpił problem z dostępem do kamery": "Виникла проблема з доступом до камери",
    "Monitor kolejek": "Монітор черги",
    "Dział nadrzędny": "Головний відділ",
    "Nr rezerwacji:": "Номер резервації:",
    "Nie znaleziono żadnych wyników.": "Результатів не виявлено.",
    "Formularz": "Форма",
    "Brak wyników": "Немає результатів",
    "Zgoda": "Згоден",
    "E-mail": "електронна почта",
    "Wykonane zadania": "Виконати завдання",
    "Wynagrodzenia Pracownicy": "Заробітна плата Працівників",
    "Firma własna": "Власна компанія",
    "Powrót": "Повернення",
    "Data rejestracji": "Дата реєстрації",
    "Suma wynagrodzeń pracowników": "Сума заробітної плати працівників",
    "Wstaw cztery kolumny 1/4": "Вставте чотири стовпці 1/4",
    "Przetwarzane": "Оброблені",
    "Zadanie zostało zaktualizowane.": "Завдання оновлено.",
    "Moje zadania": "Мої завдання",
    "Nieruchomość": "Нерухомість",
    "Układ": "Система",
    "Data modyfikacji od": "Дата зміни від",
    "Stałe/miesięczne": "Постійне/місячне",
    "Bezpieczne połączenie SSL": "Безпечне з'єднання SSL",
    "Kontener plików": "Файловий контейнер",
    "Sortuj po": "Сортувати за",
    "Wybrana data jest mniejsza niż dozwolona %0.": "Вибрана дата менша ніж дозволена %0.",
    "Dodaj Koszt": "Додати Вартість",
    "Co drugi dzień": "Кожен другий день",
    "Lista plików": "Список файлів",
    "Ilość zadań": "Кількість завдань",
    "Ten miesiąc": "Цей місяць",
    "Dodanie": "Додавання",
    "Synchronizuj razem z anulowanymi": "Синхронізувати разом зі скасованими",
    "Notatka": "Примітка",
    "Trasa została zaktualizowana.": "Маршрут оновлено.",
    "Stwórz zadanie": "Створити завдання",
    "Zacznij pisać aby wyszukać tag nieruchomości...": "Почніть писати для пошуку по тегу нерухомості...",
    "Wynagrodzenie za przepracowane godziny": "Оплата за відпрацьовані години",
    "Dodaj do zespołu": "Додати в команду",
    "Łączny czas wykonywania zadań": "Загальний час виконання завдань",
    "Przypisane regiony:": "Приписане регіони:",
    "Data utworzenia wersji": "Дата створення версії",
    "Zadanie zostało ukończone przez kogoś z Twojego zespołu.": "Завдання виконав хтось із вашої команди.",
    "Raczej się zgadzam": "Скоріше погоджуюсь",
    "Użytkownik został usunięty.": "Користувача видалено.",
    "Plik został wysłany": "Файл надіслано",
    "Szczegóły rezerwacji": "Деталі бронювання",
    "Pole \"%0\" nie zawiera prawidłowego adresu URL.": "Поле «%0» не містить дійсної URL-адреси.",
    "Filtry systemowe": "Системні фільтри",
    "Słownik dowolny": "Будь-який словник",
    "Hasło zostało zmienione. Zaloguj się ponownie": "Пароль змінено. Увійти ще раз",
    "REGON": "REGON",
    "Ustawienia Domyślne": "Налаштування за замовчуванням",
    "Wzorzec": "Зразок",
    "Kreator trasy: ": "Майстер маршрутів: ",
    "Wstaw dwie kolumny 1/3 oraz 2/3": "Вставте два стовпця 1/3 і 2/3",
    "Ten użytkownik nie posiada jeszcze przypisanych miast.": "У цього користувача ще немає призначених міст.",
    "Nie udało się usunąć szablonu": "Не вдалося видалити шаблон",
    "Nazwa chatu": "Назва чату",
    "Data i czas": "Дата й час",
    "Zapisz zmiany": "Зберегти зміни",
    "Ta nieruchomość już posiada takie wyposażenie.": "Ця нерухомість вже має таке обладнання.",
    "Wersja utowrzona przez": "Версія створена",
    "Mapa": "Карта",
    "Magazyn": "Склад",
    "Włącz system powiadomień": "Включити систему повідомлень",
    "Szukaj": "Шукати"
  }
}