<template>
    <div class="form-group">
        <label :for="uid" v-if="label">{{ label }}</label>
        <editor :id="uid" :modelValue="modelValue" @update:modelValue="update"></editor>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';

@Options({
    name: 'field-editor'
})
export default class FieldEditor extends Vue
{
    @Prop() public label: string;
    @Prop() public modelValue: string;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    @Emit('update:modelValue')
    public update(value: string): string
    {
        return value;
    }
}
</script>
