<template>
    <teleport to="body">
        <div :class="['fixed d-flex align-items-center', positionY, positionX, { hidden }]">
            <button :title="hidden ? $t('[[[Pokaż przycisk]]]') : $t('[[[Ukryj przycisk]]]')" class="hide-btn" :class="{'dark': isDarkMode}" @click="toggleHidden">
                <i :class="`fa-solid fa-arrow-${hidden ? 'left' : 'right'}`" />
            </button>
            <slot />
        </div>
    </teleport>
</template>
<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { Vue } from 'vue-class-component';

export default class RenterFloatingButton extends Vue
{
    @Prop({ default: 'right' }) public positionX: 'left' | 'right' | 'center';
    @Prop({ default: 'bottom' }) public positionY: 'top' | 'bottom';

    public hidden: boolean = false;

    public toggleHidden(): void
    {
        this.hidden = !this.hidden;
    }

    public get isDarkMode(): boolean
    {
        return this.$store.state.common.theme.themeId === 1;
    }
}
</script>
<style lang="scss" scoped>
.fixed{
    margin: 0 !important;
    position: fixed;
    transition: transform .3s ease-in-out;

    &.bottom{
        bottom: 15px;
    }
    &.top{
        top: 120px;
    }

    &.right{
        right: 15px;
    }
    &.left{
        right: 15px;
    }

    &.center{
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &.hidden{
        transform: translateX(calc(100% - 5px));
    }
}
.hide-btn{
    padding: 5px;
    box-sizing: border-box;
    border: none;
    outline: none;
    background: transparent;
    height: 100%;
    transition: color .3s ease;

    &.dark {
        color: #fff;
    }
}
</style>
