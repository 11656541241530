import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';

export default {
    namespaced: true,
    state: {
        filter: Form.create({
            search: '',
            queue: null,
            stage: null,
            dateFromUtc: null,
            dateDueUtc: null
        }),
        pager: new Pager(1, 20, '@timestamp', 'DESC')
    }
};
