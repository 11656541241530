import { App, Plugin } from 'vue';
import { Router } from 'vue-router';
import { Store } from 'vuex';
import { FilterState } from '@/modules/core/common/services/FilterService';
import Pager, { instanceOfPagerContract, PagerContract } from '@/helpers/Pager';
import { FormType } from '@/helpers/Form';

let currentInstance: Filters | null = null;

export const restoreState = (filter: FormType<any>, pager: Pager, key?: string): void =>
{
    return currentInstance?.restore(filter, pager, key);
};

export const persistState = (filter: FormType<any>, pager: Pager, key?: string): void =>
{
    return currentInstance?.persist(filter, pager, key);
};

export const setState = (state: FilterState): void =>
{
    return currentInstance?.setState(state);
};

export const getState = (): FilterState =>
{
    return currentInstance?.getState();
};

interface Filters
{
    restore(filter: FormType<any>, pager: Pager, key?: string): void;
    persist(filter: FormType<any>, pager: Pager, key?: string): void;
    setState(state: FilterState): void;
    getState(): FilterState;
}

interface IStore
{
    filters: Record<string, FilterState>,
    pager: Record<string, PagerContract>;
}

export class FiltersOptions
{
    public router: Router;
    public store: Store<any>;
}

class FiltersHelper implements Filters
{
    private options: FiltersOptions;

    public constructor(app: App<any>, options: FiltersOptions)
    {
        this.options = options;
    }

    private routeKey(): string
    {
        return this.options.router.currentRoute.value.name.toString();
    }

    private get getRouteKey(): string
    {
        return this.options.store.state.common.routeKey || this.routeKey();
    }

    private store(): IStore
    {
        return this.options.store.state.common.filters;
    }

    private getFilterState(key?: string): FilterState
    {
        return this.store().filters[key || this.getRouteKey] || {
            activeTemplate: {
                name: '',
                id: 0
            },
            visibleFilters: false
        };
    }

    private setFilterState(filter: FilterState, key?: string): void
    {
        this.options.store.commit('common/filters/saveFilters', {
            filterId: key ?? this.routeKey(),
            content: filter
        });
    }

    private getPagerState(key?: string): PagerContract
    {
        return this.store().pager[key ?? this.getRouteKey];
    }

    private setPagerState(pager: PagerContract, key?: string): void
    {
        this.options.store.commit('common/filters/savePager', {
            pagerId: key ?? this.getRouteKey,
            content: pager
        });
    }

    public restore(filter: FormType<any>, pager: Pager, key?: string): void
    {
        this.options.store.commit('common/filters/saveRouteKey', key);

        if (this.getFilterState(key))
        {
            /* wczytanie danych z ostatnio zapisanego formularza lub z zapisanych szablonów */
            filter.withData(this.getFilterState(key).form || JSON.parse(this.getFilterState(key).activeTemplate?.contentJson || '{}'));
        }

        if (this.getPagerState(key) && instanceOfPagerContract(this.getPagerState(key)))
        {
            pager.apply(this.getPagerState(key));
        }
    }

    public persist(filter: FormType<any>, pager: Pager, key?: string): void
    {
        this.options.store.commit('common/filters/saveRouteKey', key);

        const state = this.getFilterState(key);

        this.setFilterState({
            activeTemplate: state.activeTemplate,
            visibleFilters: state.visibleFilters,
            form: filter.data()}, key);

        this.setPagerState(pager.data(), key);
    }

    public setState(state: FilterState): void
    {
        this.setFilterState(state);
    }

    public getState(): FilterState
    {
        return this.getFilterState();
    }
}

const FiltersPlugin: Plugin =
{
    install(app, options)
    {
        if (!options || !options.router)
        {
            throw new Error("FiltersOptions.router must be set.");
        }

        if (!options || !options.store)
        {
            throw new Error("FiltersOptions.store must be set.");
        }

        currentInstance = new FiltersHelper(app, options);
    }
};

export default FiltersPlugin;
