import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';

export default {
    namespaced: true,
    state: {
        filter: Form.create({
            name: '',
            symbol: '',
            dateFromUtc: null,
            dateDueUtc: null
        }),
        pager: new Pager(1, 20, 'Name', 'ASC')
    }
};
