import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    AggregateBlueprint,
    VisibleBlueprint,
    ReadonlyBlueprint,
    CustomErrorBlueprint,
    HasTitle,
    HasDescription,
    HasWidth,
    HasHelp,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice,
    Validatable,
    ValidationErrors,
    validateComponents
} from '../Types';

export interface SectionContract extends AggregateBlueprint, VisibleBlueprint, ReadonlyBlueprint, CustomErrorBlueprint, HasTitle, HasDescription, HasWidth, HasHelp
{
}

export class SectionType implements SectionContract, Validatable
{
    public id: string;
    public type: string;
    public name: string;
    public title: string;
    public showTitle: boolean;
    public description: string;
    public components: Blueprint[];
    public help: string;
    public width: number;
    public minWidth: number;
    public exceptWidth: number[];
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.SECTION;
        this.name = builder.name(Components.SECTION);
        this.title = '';
        this.showTitle = true;
        this.description = '';
        this.components = [];
        this.help = '';
        this.width = builder.space(parent);
        this.minWidth = 3;
        this.exceptWidth = [5];
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return validateComponents(this.components);
    }
}
