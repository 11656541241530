import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    AggregateBlueprint,
    VisibleBlueprint,
    HasWidth,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice,
    ValidationErrors,
    Validatable
} from '../Types';

export interface ContentContract extends Blueprint, VisibleBlueprint, HasWidth
{
    content: string;
}

export class ContentType implements ContentContract, Validatable
{
    public id: string;
    public type: string;
    public name: string;
    public width: number;
    public minWidth: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public content: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.CONTENT;
        this.name = builder.name(Components.CONTENT);
        this.width = builder.space(parent);
        this.minWidth = 1;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.content = '';
        this.errors = {};
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
