<template>
    <div>
        <field-choice v-model="blueprint.required" :options="requiredOptions" :label="label" />
        <field-condition v-model="blueprint.requiredWhen" :label="label" :show-label="false" :form="form" :when="blueprint.required" />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { RequiredBlueprint, AlwaysChoice, NeverChoice, WhenChoice } from '../Types';
import { FormBuilder } from '../Utils';
import { Option } from '@/helpers/Interfaces';
import FieldChoice from './FieldChoice.vue';
import FieldCondition from './FieldCondition.vue';

@Options({
    name: 'field-visible',
    components: {
        'field-choice': FieldChoice,
        'field-condition': FieldCondition
    }
})
export default class FieldVisible extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public blueprint!: RequiredBlueprint;
    @Prop() public label!: string;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public get requiredOptions(): Option<AlwaysChoice | NeverChoice | WhenChoice>[]
    {
        return [
            { value: AlwaysChoice.Always, text: '[[[Zawsze]]]' },
            { value: NeverChoice.Never, text: '[[[Nigdy]]]' },
            { value: WhenChoice.When, text: '[[[Warunkowo]]]' }
        ];
    }
}
</script>
