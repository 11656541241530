import axios from "axios";
import { Pagination, Resource, Option } from "@/helpers/Interfaces";
import { merge } from "lodash";
import Pager from "@/helpers/Pager";
import { DateTime } from "luxon";
import { AbsenceTypeEnum } from "./AbsencesService";
import { DepartmentEnum } from "@/modules/core/users/services/UsersService";
import { $t } from "@/plugins/localization";

export default class TimeSheetService
{
    /**
     *
     * @returns Promise<Resource<EmployeeItemModel[]>>
     */
    public static async getEmployees(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<EmployeeItemModel[]>>>
    {
        return (await axios.get<Pagination<Resource<Array<EmployeeItemModel>>>>(`admin/renters/salaries/timesheet/employees`, {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     *
     * @returns Promise<Resource<TaskItemModel[]>>
     */
    public static async getContent(dateFrom: string, dateDue: string, employeesIds: number[]): Promise<Resource<ContentModel>>
    {
        return (await axios.post<Resource<ContentModel>>(`admin/renters/salaries/timesheet/content?dateFromUtc=${dateFrom}T00:00:00&dateDueUtc=${dateDue}T00:00:00`, employeesIds)).data;
    }
}


export interface EmployeeItemModel {
    id: number;
    givenName: string;
    surname: string;
}

export interface ContentModel {
    tasks: TaskItemModel[];
    absences: AbsenceItemModel[];
}

export interface AbsenceItemModel {
    id: number;
    publicId: string;
    start: DateTime;
    end: DateTime;
    absenceType: AbsenceTypeEnum;
}

export interface TaskItemModel {
    id: number;
    publicId: string;
    templateChecklistPublicId: string;
    propertyAddress: string;
    propertyName: string;
    resourceId: number;
    start: DateTime;
    title: string;
    status: string;
    department: string[];
    dateStartedUtc: DateTime;
    dateCompletedUtc: DateTime;
    overallTime: string;
}

export interface RangeDate {
    start: string;
    end: string;
}

export interface ModalType {
    ref: any;
    publicId: string;
}

export interface FilterModel {
    regionPublicId: string;
    cityPublicId: string;
    userPublicId: string;
    department: DepartmentEnum.Cleaning | DepartmentEnum.Maintenance | DepartmentEnum.Inspection;
    districtIds: string[] | string;
    range: string;
}

export enum UserStatus {
    All = 'All',
    Admin = 'Admin',
    Inactive = 'Inactive',
    Active = 'Active'
}


export const UserStatusOptions = (): Option[] => ([
    { value: UserStatus.All, text: $t('[[[Wszyscy]]]') },
    { value: UserStatus.Admin, text: $t('[[[Admin]]]') },
    { value: UserStatus.Inactive, text: $t('[[[Nieaktywni]]]') },
    { value: UserStatus.Active, text: $t('[[[Aktywni]]]') }
]);