<script lang="ts" src="../blueprints/Section.ts"></script>

<template>
    <form-component-wrapper class="section-component" :form="form" :parent="parent" :blueprint="blueprint" :disabled="false">
        <template #default>
            <fieldset :disabled="readonly">
                <legend v-if="blueprint.title && blueprint.showTitle">{{ $t(blueprint.title) }}</legend>
                <p class="description" v-if="blueprint.description">{{ blueprint.description }}</p>
                <div class="rows">
                    <template v-for="(component, index) in rows" :key="component.id">
                        <design-ruller :title="$t('[[[Wstaw kontrolkę]]]')" :form="form" :before="component" @insert="addRow" @add="addComponent" @drop="dropComponent" v-if="component.components.length > 0" />
                        <component :is="form.designer(component.type)" :form="form" :parent="blueprint" :blueprint="rows[index]" :entry="entry"></component>
                    </template>
                </div>
            </fieldset>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <field-text v-model="blueprint.title" :label="$t('[[[Tytuł sekcji]]]')" />
            <field-checkbox v-model="blueprint.showTitle" :label="$t('[[[Pokaż tytuł sekcji]]]')" />
            <field-textarea v-model="blueprint.description" :label="$t('[[[Opis]]]')" />
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-readonly :form="form" :blueprint="blueprint" :label="$t('[[[Tylko do odczytu]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
.section-component {
    fieldset {
        border: 1px solid var(--border-color);
        padding: 1rem 1rem 0 1rem;
        margin-bottom: 1rem;

        > legend {
            width: auto;
            margin: 0 5px 0 5px;
            padding: 0 5px 0 5px;
            font-size: 1rem;
            font-weight: 400;
        }
        > p {
            margin: 10px;
        }
    }
}
</style>
