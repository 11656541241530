import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';

export default {
    namespaced: true,
    state: {
        filter: Form.create({
            visible: false,
            userName: '',
            ipAddress: '',
            dateFromUtc: null,
            dateDueUtc: null,
            result: null // [null,true,false]
        }),
        pager: new Pager(1, 20, 'Id', 'DESC')
    }
};
