<template>
    <div class="form-group">
        <label :for="uid" v-if="label">{{ $t(label) }}</label>
        <input :type="type" :id="uid" class="form-control" :class="{'form-control-sm': size == 'sm', 'form-control-lg': size == 'lg'}" :placeholder="placeholder" :pattern="pattern" :value="$t(modelValue)" @input="update">
        <small class="form-text text-danger" v-if="invalidFeedback && invalidFeedback(modelValue)">
            {{ $t(invalidFeedback(modelValue)) }}
        </small>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';

@Options({
    name: 'field-text'
})
export default class FieldText extends Vue
{
    @Prop() public label: string;
    @Prop() public modelValue: string;
    @Prop() public placeholder: string;
    @Prop() public invalidFeedback: (value: string) => string;
    @Prop({ default: 'text' }) public type: string;
    @Prop() public pattern: string;
    @Prop() public size: string;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public update(e: any): void
    {
        this.triggerInput(e.target.value);
    }

    @Emit('update:modelValue')
    public triggerInput(value: string): string
    {
        return value;
    }
}
</script>
