import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    AggregateBlueprint,
    HasTitle,
    HasDescription,
    Validatable,
    validateComponents,
    ValidationErrors,
    instanceOfBlueprint,
    ValidEntry,
    instanceOfValidEntry,
    setValidationErrors
} from '../Types';

export enum ProgressChoice {
    Steps = 'Steps',
    Bar = 'Bar',
    None = 'None'
}

export interface FormContract extends AggregateBlueprint, HasTitle, HasDescription
{
    sendButtonText?: string;
    showSendButton: boolean;
    showPageNumbers: boolean;
    showPageTitles: boolean;
    progressType: ProgressChoice;
    currentPage: number;
    category: number;
}

export class FormType implements FormContract, Validatable
{
    public id: string;
    public type: string;
    public name: string;
    public components: Blueprint[];
    public title: string;
    public showTitle: boolean;
    public description: string;
    public sendButtonText?: string;
    public showSendButton: boolean;
    public showPageNumbers: boolean;
    public showPageTitles: boolean;
    public progressType: ProgressChoice;
    public currentPage: number;
    public category: number;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.FORM;
        this.name = 'Form'; // nazwa musi być podana na sztywno
        this.components = [];
        this.title = '[[[Formularz]]]';
        this.showTitle = true;
        this.description = '';
        this.showSendButton = false;
        this.showPageNumbers = true;
        this.showPageTitles = true;
        this.progressType = ProgressChoice.Steps;
        this.currentPage = 1;
        this.category = null;
        this.errors = {};
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        const TITLE_LENGTH = 250;

        if (!this.title)
            this.errors.title = ['[[[Tytuł formularza jest wymagany]]]'];
        else if (this.title.length > TITLE_LENGTH)
            this.errors.title = [`[[[Tytuł formularza nie może być dłuższy niż %0 znaków|||${TITLE_LENGTH}]]]`];

        if (!this.category)
            this.errors.category = ['[[[Kategoria jest wymagana]]]'];

        const errors = {
            [this.name]: this.errors,
            ...validateComponents(this.components)
        };

        return errors;
    }

    public setErrors(errors: Record<string, string[]>): void
    {
        setValidationErrors(this, errors);
    }
}

export const instanceOfFormType = (object: any): object is FormType =>
{
    return object && typeof object === 'object' && 'type' in object && object.type === Components.FORM && instanceOfBlueprint(object);
};

export class FormEntry extends ValidEntry
{
    public type: string = Components.FORM;

    [prop: string]: any;

    public async collect(form: FormContract, builder: FormBuilder, preprocess: (name: string, entry: any) => Promise<void> = async (name: string, entry: any) => {}): Promise<any>
    {
        await preprocess(form.name, this);

        const result: any = {
            type: this.type
        };
        const properties = Object.keys(this);

        for (let i = 0; i < properties.length; i++)
        {
            const property = properties[i];

            if (instanceOfValidEntry(this[property]))
            {
                const entry = this[property] as ValidEntry;
                const blueprint = builder.find(property);

                result[property] = await entry.collect(blueprint, builder, preprocess);
            }
        }

        return result;
    }

    public validate(form: FormContract, builder: FormBuilder): boolean
    {
        this.errors = {};

        Object.keys(this).forEach(property =>
        {
            if (instanceOfValidEntry(this[property]))
            {
                const entry = this[property] as ValidEntry;
                const blueprint = builder.find(property);

                if (!entry.validate(blueprint, builder))
                {
                    Object.keys(entry.errors).forEach(key =>
                    {
                        this.errors[`${property}.${key}`] = entry.errors[key];
                    });
                }
            }
        });

        return this.valid();
    }

    public setErrors(errors: Record<string, string[]>): void
    {
        Object.keys(this).forEach(property =>
        {
            if (instanceOfValidEntry(this[property]))
            {
                const name = `${property.toLowerCase()}.`;
                const entry = this[property] as ValidEntry;

                Object.entries(errors).filter(([key]) => key.startsWith(name)).forEach(([key, messages]) =>
                {
                    entry.errors[key.substring(name.length)] = messages;
                });
            }
        });
    }

    public find(name: string): ValidEntry
    {
        if (name in this && instanceOfValidEntry(this[name]))
        {
            return this[name];
        }

        return null;
    }
}
