<template>
    <component :is="tagName" v-bind="attrs()" class="text-break" :class="classNames" @click="onClick">
        <div v-if="flex">
            <small>
                <i class="text-black-50" v-if="loaded">{{ title }}</i>
                <placeholder :width="title.length * 6" :height="10" v-else></placeholder>
            </small>
        </div>
        <slot name="default" v-if="loaded"></slot>
        <placeholder :width="[minWidth, maxWidth]" :height="14" v-else></placeholder>
    </component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Inject } from '@/helpers/Decorators';
import { only } from '@/helpers/Utils';

@Options({
    name: 'list-view-item',
    inheritAttrs: false
})
export default class ListViewItem extends Vue
{
    @Inject('loaded')
    public loaded: boolean;

    @Inject('layout')
    public layout: string;

    @Inject('row-click')
    public rowClick: (item: any) => void;

    @Inject('index')
    public index: number;

    @Inject('item')
    public item: any;

    public get table(): boolean
    {
        return this.layout == 'table';
    }

    public get flex(): boolean
    {
        return !this.table;
    }

    public get tagName(): string
    {
        return this.table ? 'td' : 'div';
    }

    public get classNames(): any
    {
        return this.table ? 'pointer' : this.colsClass;
    }

    public get title(): string
    {
        return this.$attrs.title as string || '';
    }

    public get cols(): string
    {
        return this.$attrs.cols as string;
    }

    public get colsXs(): string
    {
        return this.$attrs['cols-xs'] as string || this.cols;
    }

    public get colsSm(): string
    {
        return this.$attrs['cols-sm'] as string || this.colsXs;
    }

    public get colsMd(): string
    {
        return this.$attrs['cols-md'] as string || this.colsSm;
    }

    public get colsClass(): any
    {
        return {
            'mb-1': true,
            'col': this.colsXs == null && this.colsSm == null && this.colsMd == null,
            [`col-${this.colsXs}`]: this.colsXs != null,
            [`col-sm-${this.colsSm}`]: this.colsSm != null,
            [`col-md-${this.colsMd}`]: this.colsMd != null
        };
    }

    public get minWidth(): number
    {
        return parseInt(this.$attrs.width as string) || 100;
    }

    public get maxWidth(): number
    {
        return parseInt(this.$attrs.width as string) || 175;
    }

    public onClick(): void
    {
        if (this.rowClick && this.table)
        {
            this.rowClick(this.item);
        }
    }

    public attrs(): Record<string, any>
    {
        return only(this.$attrs, 'class', 'style');
    }
}
</script>
