import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';

export default {
    namespaced: true,
    state: {
        filter: Form.create({
            visible: false,
            trailId: 0,
            userName: '',
            ipAddress: '',
            dateFromUtc: null,
            dateDueUtc: null,
            objectType: null,
            objectId: 0,
            operationType: null
        }),
        pager: new Pager(1, 20, 'Id', 'DESC')
    }
};
