<script lang="ts" src="../blueprints/Likert.ts"></script>

<template>
    <form-component-wrapper class="likert-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <label :for="blueprint.id" v-if="blueprint.showLabel">{{ $t(blueprint.label) }} <var class="text-danger" v-if="required">*</var></label>
                <responsive v-if="!phone">
                    <table class="table table-bordered w-100 mb-0">
                        <thead>
                            <tr>
                                <th class="question"></th>
                                <th v-for="option in options" :key="option.value">
                                    {{ option.text }}
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="questions.length > 0">
                            <tr v-for="question in questions" :key="question.value">
                                <td class="question">
                                    {{ question.text }}
                                </td>
                                <td v-for="option in options" :key="option.value">
                                    <ideo-form-radio :name="`${blueprint.name}_${question.value}`" :value="option.value" :checked="checkedValue(question)" @change="change(question, $event)"></ideo-form-radio>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </responsive>
                <div v-if="phone">
                    <div v-for="(question, i) in questions" :key="question.value" class="mb-2">
                        <h6>{{ i + 1 }}. {{ question.text }}</h6>
                        <ideo-form-radio-group :name="`${blueprint.name}_${question.value}`" :options="options" :checked="checkedValue(question)" @change="change(question, $event)" stacked />
                    </div>
                </div>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <field-text v-model="blueprint.label" :label="$t('[[[Etykieta]]]')" />
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-options v-model="questions" :can-select="false" :has-value="false" :label="$t('[[[Pytania]]]')" :invalid-feedback="() => form.errorMessage(blueprint, 'questions')" />
            <field-select v-model="blueprint.fieldType" :options="fieldTypeOptions" :label="$t('[[[Rodzaj odpowiedzi]]]')" />
            <field-options v-model="choices" :can-select="false" :has-value="false" :label="$t('[[[Odpowiedzi]]]')" :invalid-feedback="() => form.errorMessage(blueprint, 'choices')" v-if="isCustom" />
            <field-checkbox v-model="blueprint.allowNoChoice" :label="$t('[[[Dopuść brak odpowiedzi]]]')" />
            <field-text v-model="blueprint.noChoiceLabel" v-if="blueprint.allowNoChoice" />
            <field-select v-model="blueprint.defaultValue" :options="defaultOptions" :label="$t('[[[Wartość domyślna]]]')" />
            <field-textarea v-model="blueprint.help" :label="$t('[[[Pomoc]]]')" />
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-readonly :form="form" :blueprint="blueprint" :label="$t('[[[Tylko do odczytu]]]')" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
.table {
    th, td {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 1%;
        padding: 8px;
        font-weight: normal;
        font-size: 0.85em;
        text-align: center;
    }
    th {
        background-color: $gray-100;
    }
    .question {
        width: auto;
        text-align: left;
        white-space: normal;
    }
    .custom-radio {
        display: inline-block !important;
        margin-left: 8px;
    }
}
</style>
