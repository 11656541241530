<template>
    <div class="form-group">
        <label :for="uid">{{ label }}</label>
        <div>
            <div v-for="(option, i) in value" :key="i">
                <div class="input-group mb-2">
                    <div class="input-group-prepend" v-if="canSelect">
                        <div class="input-group-text" v-if="multiple">
                            <span class="fa-stack" v-if="option.selected">
                                <i class="fas fa-square fa-stack-1x text-white"></i>
                                <i class="far fa-check-square fa-stack-1x text-primary" @click="onChange(option)"></i>
                            </span>
                            <span class="fa-stack" v-else>
                                <i class="fas fa-square fa-stack-1x text-white"></i>
                                <i class="far fa-square fa-stack-1x text-secondary" @click="onChange(option)"></i>
                            </span>
                        </div>
                        <div class="input-group-text" v-else>
                            <span class="fa-stack" v-if="option.selected">
                                <i class="fas fa-circle fa-stack-1x text-white"></i>
                                <i class="fas fa-dot-circle fa-stack-1x text-primary" @click="onChange(option)"></i>
                            </span>
                            <span class="fa-stack" v-else>
                                <i class="fas fa-circle fa-stack-1x text-white"></i>
                                <i class="far fa-circle fa-stack-1x text-secondary" @click="onChange(option)"></i>
                            </span>
                        </div>
                    </div>
                    <input type="text" v-model="option.text" @input="onInput" class="form-control text">
                    <input type="text" v-model="option.value" @input="onInput" class="form-control value" placeholder="#" @blur="onBlur(option)" v-if="hasValue">
                    <div class="input-group-append" id="button-addon4">
                        <button class="btn btn-outline-light" type="button" @click.stop.prevent="addOption(option)">
                            <i class="far fa-plus text-success"></i>
                        </button>
                        <button class="btn btn-outline-light" type="button" :disabled="modelValue.length <= 1" @click.stop.prevent="removeOption(option)">
                            <i class="far fa-trash text-danger"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <small class="form-text text-danger" v-if="invalidFeedback && invalidFeedback(modelValue)">
            {{ $t(invalidFeedback(modelValue)) }}
        </small>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { ChoiceOption } from '../Types';

@Options({
    name: 'field-options'
})
export default class FieldOptions extends Vue
{
    @Prop() public label: string;
    @Prop() public modelValue: ChoiceOption[];
    @Prop({ default: false }) public multiple: boolean;
    @Prop({ default: true }) public canSelect: boolean;
    @Prop({ default: true }) public hasValue: boolean;
    @Prop() public invalidFeedback: (value: string) => string;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public get value(): ChoiceOption[]
    {
        return this.modelValue;
    }

    @Emit('update:modelValue')
    public update(value: ChoiceOption[]): ChoiceOption[]
    {
        return value;
    }

    public addOption(option: ChoiceOption): void
    {
        const item = {
            value: (this.modelValue.length + 1).toString(),
            text: '',
            selected: false
        };

        this.modelValue.splice(this.modelValue.indexOf(option) + 1, 0, item);
        this.update(this.modelValue);
    }

    public removeOption(option: ChoiceOption): void
    {
        this.update(this.modelValue.filter(p => p != option));
    }

    public onChange(option: ChoiceOption): void
    {
        if (this.multiple == false)
        {
            this.modelValue.filter(p => p != option).forEach(p => { p.selected = false; });
        }

        option.selected = !option.selected;

        this.update(this.modelValue);
    }

    public onBlur(option: ChoiceOption): void
    {
        if (option.value === null || option.value == '')
        {
            option.value = (this.modelValue.indexOf(option) + 1).toString();
        }

        this.update(this.modelValue);
    }

    public onInput(): void
    {
        this.update(this.modelValue);
    }
}
</script>

<style lang="scss" scoped>
.text {
    border-color: var(--border-color) !important;
}
.value {
    flex: none !important;
    width: 50px !important;
    border-color: var(--border-color) !important;
}
.input-group-text {
    font-size: 16px;
    padding: 0px;

    .fa-stack {
        height: 31px;

        .text-secondary {
            color: $gray-500 !important;
        }
    }
}
.input-group-append {
    .btn {
        border: 1px solid var(--border-color);
    }
}
</style>
