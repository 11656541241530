<template>
    <div class="card-group mb-4">
        <div class="card p-2 pt-4 pb-4 mb-0 p-lg-4 gradient renter-view">
            <div class="card-body">
                <ideo-img :src="'/static/img/hirenterslogo.png'" alt="renters_logo" :class="'logo'" />
                <ideo-alert :show="message.length > 0" variant="danger">
                    {{ $t(message) }}
                </ideo-alert>
                <form @submit.prevent="onSubmit" @input="form.$errors.clear();">
                    <ideo-form-group :invalid-feedback="form.$errors.first('username')" :state="form.$errors.state('username')" :label="$t('[[[Nazwa użytkownika]]]')">
                        <ideo-form-input v-model="form.username" type="text" name="username" v-focus></ideo-form-input>
                    </ideo-form-group>
                    <ideo-form-group :invalid-feedback="form.$errors.first('password')" :state="form.$errors.state('password')" :label="$t('[[[Hasło]]]')">
                        <span class="password">
                            <ideo-form-input v-model="form.password" :type="passwordVisible ? 'text' : 'password'" name="password"></ideo-form-input>
                            <i :class="['fa-solid', passwordFieldIcon]" @click="passwordVisible = !passwordVisible" />
                        </span>
                    </ideo-form-group>
                    <!-- #130634 - opcja zapamiętania zawsze włączona
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <ideo-form-group>
                                <ideo-form-checkbox v-model="form.rememberMe">
                                    {{ $t('[[[Zapamiętaj logowanie]]]') }}
                                </ideo-form-checkbox>
                            </ideo-form-group>
                        </div>
                    </div>-->
                    <div class="row flex">
                        <ideo-button type="submit" variant="primary" :block="true" :disabled="!form.active()">
                            {{ $t('[[[Zaloguj się]]]') }}
                        </ideo-button>
                        <router-link :to="{name:'forgot-password'}">
                            {{ $t('[[[Zapomniałeś hasła?]]]') }}
                        </router-link>
                        <ideo-form-group v-if="alreadyLogged">
                            <ideo-form-checkbox v-model="form.forceLogin">
                                {{ $t('[[[Wyloguj zalogowanego obecnie użytkownika]]]') }}
                            </ideo-form-checkbox>
                        </ideo-form-group>
                        <ideo-button type="button" variant="secondary" v-if="azure" :block="true" :disabled="!form.active()" @click.prevent.stop="authAzure">
                            {{ $t('[[[Azure AD]]]') }}
                        </ideo-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import AuthService, { LoginModel } from '@/modules/core/auth/services/AuthService';
import {Form, FormType} from '@/helpers/Form';
import tap from 'lodash/tap';

@Options({})
export default class Login extends Vue
{
    public oneSignalLogoutInterval: any = null;

    public form: FormType<LoginModel> = Form.create<LoginModel>({
        username: '',
        password: '',
        rememberMe: false,
        forceLogin: false
    });

    public alreadyLogged: boolean = false;

    public get message(): string
    {
        return this.$store.state.core.auth.message;
    }

    public set message(value: string)
    {
        this.$store.state.core.auth.message = value;
    }

    public get azure(): boolean
    {
        return import.meta.env.VITE_APP_AZURE_AUTH == "true";
    }

    public get api(): string
    {
        return import.meta.env.VITE_APP_API_URL;
    }

    public mounted(): void
    {
        this.$i18n.detectLocale('pl-Pl');
    }

    public async onSubmit(): Promise<void>
    {
        this.message = '';

        clearInterval(this.oneSignalLogoutInterval);

        try
        {
            this.form.wait();
            this.$alert.close();
            this.message = '';
            this.alreadyLogged = false;

            await this.$auth.login(
                this.form.username,
                this.form.password,
                true,  //#130634 - opcja zapamiętania zawsze włączona
                this.form.forceLogin
            );
        }
        catch (ex)
        {
            // eslint-disable-next-line no-console
            console.log(ex);

            this.handleException(ex, {
                400: (ex: any) =>
                {
                    this.message = ex.message;
                    this.alreadyLogged = ex.data.alreadyLogged;
                },
                422: (ex: any) => { this.form.$errors.record(ex.data.errors); }
            });
        }
        finally
        {
            this.form.continue();
        }
    }

    public async authAzure(): Promise<void>
    {
        const redirectUrl = tap(new URL(location.href), (u) => { u.pathname = "/auth/azure"; });
        const device = await AuthService.getDeviceInfo();

        location.href = `${this.api}/auth/azure?redirect=${encodeURI(redirectUrl.href)}&id=${encodeURI(device.deviceId)}&name=${encodeURI(device.deviceName)}`;
    }

    public passwordVisible: boolean = false;

    public get passwordFieldIcon(): string
    {
        return this.passwordVisible ? 'fa-eye-slash' : 'fa-eye';
    }
}
</script>

<style scoped>
#login-page {
    margin-top: 69px;
}

input[type="text"], input[type="password"]{
    color: white;
}

.wrapper {
  overflow: hidden;
  border: 1px solid blue;
}

#ref {
  background: red;
  width: 100px;
  height: 100px;
}

#pop {
  background: green;
  width: 100px;
  height: 100px;
}
</style>

<style lang="scss">
/* overriding default login styles */
.c-dark-theme .renter-view, .renter-view{
span.password{
    position: relative;
    display: block;
    i{
        position: absolute;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
    }
}
&.card{
    background: none;
    border: none;
    color: $white;
    *{
        z-index: 1;
    }
    .logo{
        max-width: 250px;
        height: auto;
        margin: 10vh auto 10vh;
        display: flex;
    }
    .form-control{
        background-color: rgba(255, 255, 255, .2);
        border: 1px solid rgba(0, 0, 0, 0);
        &:focus{
            background-color: none;
            border: 1px solid var(--primary);
        }
    }
    .flex{
        justify-content: center;
        margin: 0;
        padding: 0;

        button.btn{
            width: 100%;
            margin-right: 0 !important;
        }
        a{
            margin: 15px 0;
            color: var(--primary);
        }
    }
}
&.gradient::before{
    content: '';
    width: calc(100% + 30px);
    max-width: 100vw;
    height: 100%;
    background: rgba(20, 20, 20, .6);
    box-shadow: 0px 0px 50px rgba(20, 20, 20, .5);
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
}
}
</style>
