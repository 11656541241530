/* eslint-disable no-console */
import * as signalR from "@aspnet/signalr";
import { App, Plugin } from "vue";
import { Vue, createDecorator } from 'vue-class-component';
import { EventEmitter } from "events";
import { convertDates } from '@/helpers/Utils';
import { Auth } from "./auth";
import * as Sentry from "@sentry/vue";

const OPTIONS_KEY = '__channel_calls__';

export const Listen = (channel: string, method?: string): any =>
{
    return (target: Vue, propertyKey: string, descriptor: PropertyDescriptor) =>
    {
        const methodName = method || propertyKey;
        const decorator = createDecorator((options, propName) =>
        {
            options[OPTIONS_KEY] = options[OPTIONS_KEY] || [];
            options[OPTIONS_KEY].push(`Listen.${channel}.${methodName}.${propName}`);
        });

        decorator(target, propertyKey);
    };
};

export const Invoke = (channel: string, method?: string): any =>
{
    return (target: Vue, propertyKey: string) =>
    {
        const methodName = method || propertyKey;
        const decorator = createDecorator((options, propName) =>
        {
            options[OPTIONS_KEY] = options[OPTIONS_KEY] || [];
            options[OPTIONS_KEY].push(`Send.${channel}.${methodName}.${propName}`);

            options.methods = options.methods || {};

            options.methods[propName] = function(...args: any)
            {
                return (this as Vue).$channels[channel].invoke(method || propertyKey, ...args).then(response =>
                {
                    return new Promise((resolve, reject) =>
                    {
                        try
                        {
                            resolve(convertDates(response));
                        }
                        catch (err)
                        {
                            reject(err);
                        }
                    });
                });
            };
        });

        decorator(target, propertyKey);
    };
};

export interface SignalROptions
{
    endpoint: string;
}

class Channel extends EventEmitter
{
    private auth: Auth = null;
    private connection: signalR.HubConnection = null;
    private started = false;
    private name: string;

    public constructor(name: string, auth: Auth, options: SignalROptions)
    {
        super();

        this.auth = auth;
        this.name = name;
        this.connection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Error)
            .withUrl(`${options.endpoint}/${name}`, {
                // accessTokenFactory: () => this.auth.token()
            })
            .build();

        this.connection
            .onclose((_: any) =>
            {
                console.log(`SignalR: Connection closed (${this.name}).`);

                setTimeout(() =>
                {
                    if (this.started)
                    {
                        this.start();
                    }
                },
                5000);
            });
    }

    public start(): Channel
    {
        this.connection
            .start()
            .then(() => { this.emit('started'); console.log(`SignalR: Connection started (${this.name}).`); })
            .catch((err: any) => console.log(`Error while establishing connection (${this.name}).`, err));

        this.started = true;

        return this;
    }

    public stop(): Channel
    {
        this.connection.stop();
        this.started = false;

        return this;
    }

    public register(type: string, method: string, callback: any): void
    {
        if (type == 'Listen')
        {
            this.proxy.on(method, callback);
        }
    }

    public unregister(type: string, method: string, callback: any): void
    {
        if (type == 'Listen')
        {
            this.proxy.off(method, callback);
        }
    }

    public invoke(method: string, ...args: any[]): Promise<any>
    {
        return this.proxy.invoke(method, ...args);
    }

    private get proxy(): signalR.HubConnection
    {
        const channel = this;

        return new Proxy(this.connection, {
            get(connection, property: string)
            {
                return function(...args: any[])
                {
                    if (connection.state == signalR.HubConnectionState.Connected)
                    {
                        return connection[property](...args);
                    }

                    return new Promise((resolve, reject) =>
                    {
                        try
                        {
                            return channel.once('started', () =>
                            {
                                const result = connection[property](...args);

                                if (result && result.then)
                                {
                                    result.then((data: any) =>
                                    {
                                        return resolve(data);
                                    }).catch((err: any) =>
                                    {
                                        if (args[0] === "getNotifications")
                                        {
                                            Sentry.withScope((scope) =>
                                            {
                                                const additionalData = args || 'undefined - nie został zinicjalizowany';

                                                scope.setExtra('additionalData', additionalData);
                                                Sentry.captureException(new Error('ERROR: Błąd z powiadomieniami!'));
                                            }
                                            );
                                        }
                                    });
                                }
                                else if (result)
                                {
                                    resolve(result);
                                }
                                else
                                {
                                    resolve(null);
                                }
                            });
                        }
                        catch (err)
                        {
                            reject(err);
                        }
                    });
                };
            }
        });
    }
}

class SignalRHelper
{
    private auth: Auth = null;
    private options: SignalROptions = null;
    private channels: Record<string, Channel> = {};
    private components: Record<string, Vue[]> = {};

    public constructor(auth: Auth, options: SignalROptions)
    {
        this.auth = auth;
        this.options = options;
    }

    public channel(name: string): Channel
    {
        return this.channels[name] || this.connect(name);
    }

    public register(component: Vue): void
    {
        this.components = this.components || {};

        const calls = component.$options[OPTIONS_KEY] || [];
        const channels: string[] = [];

        calls.forEach((entry: string) =>
        {
            const [type, channel, method, callback] = entry.split('.');

            this.channel(channel).register(type, method, component[callback]);

            if (!channels.includes(channel))
            {
                channels.push(channel);
            }
        });

        channels.forEach((channel) =>
        {
            this.push(channel, component);
        });
    }

    public unregister(component: Vue): void
    {
        this.components = this.components || {};

        const calls = component.$options[OPTIONS_KEY] || [];
        const channels: string[] = [];

        calls.forEach((entry: string) =>
        {
            const [type, channel, method, callback] = entry.split('.');

            this.channel(channel).unregister(type, method, component[callback]);

            if (!channels.includes(channel))
            {
                channels.push(channel);
            }
        });

        channels.forEach((channel) =>
        {
            this.pop(channel, component);
        });
    }

    private push(channel: string, component: Vue): void
    {
        this.components[channel] = this.components[channel] || [];
        this.components[channel].push(component);
    }

    private pop(channel: string, component: Vue): void
    {
        this.components[channel] = this.components[channel] || [];
        this.components[channel] = this.components[channel].filter(p => p !== component);

        if (this.components[channel].length == 0)
        {
            this.disconnect(channel);
        }
    }

    private connect(name: string): Channel
    {
        return (this.channels[name] = new Channel(name, this.auth, this.options).start());
    }

    private disconnect(name: string): void
    {
        if (this.channels[name])
        {
            this.channels[name].stop();
            delete this.channels[name];
        }
    }
}

const SignalRPlugin: Plugin =
{
    install(app: App<any>, options: SignalROptions)
    {
        if (!options || !options.endpoint)
        {
            throw new Error("SignalROptions.endpoint must be set.");
        }

        const vue = app.config.globalProperties;
        const helper = new SignalRHelper(vue.$auth, options);

        app.mixin({
            async created()
            {
                helper.register(this);
            },
            async unmounted()
            {
                helper.unregister(this);
            }
        });

        vue.$channels = new Proxy(helper, {
            get(helper, name: string)
            {
                return helper.channel(name);
            }
        });
    }
};

export default SignalRPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $channels: Record<string, Channel>;
    }
}
