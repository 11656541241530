import { FormBuilder } from '../Utils';
import {
    Components,
    Blueprint,
    VisibleBlueprint,
    ReadonlyBlueprint,
    RequiredBlueprint,
    CustomErrorBlueprint,
    HasLabel,
    HasHelp,
    HasWidth,
    AggregateBlueprint,
    AlwaysChoice,
    NeverChoice,
    InternallyChoice,
    WhenChoice,
    EntryFactory,
    ValidationErrors,
    Validatable,
    ValidEntry
} from '../Types';
import min from 'lodash/min';

export class AttachmentsEntry extends ValidEntry
{
    public type: string = Components.ATTACHMENTS;
    public values?: any[] = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.values = data.values;
        }
    }

    public async collect(blueprint: AttachmentsContract, form: FormBuilder, preprocess: (name: string, entry: any) => Promise<void>): Promise<any>
    {
        await preprocess(blueprint.name, this);

        return {
            type: this.type,
            values: this.values
        };
    }

    public validate(blueprint: AttachmentsContract, form: FormBuilder): boolean
    {
        this.errors = {};

        const values = this.values;

        if (!form.readonly(blueprint, true) && form.visible(blueprint, true))
        {
            if (form.required(blueprint) && (values == null || values.length == 0))
            {
                this.errors.values = [`[[[Pole "%0" jest wymagane.|||${blueprint.label}]]]`];
            }
            else if (form.customError(blueprint))
            {
                this.errors.custom = [form.customErrorMessage(blueprint)];
            }
        }

        return this.valid();
    }

    public async upload(storageService: (file: File, container: string, secured: boolean) => Promise<any>, container: string = "", secured: boolean = false): Promise<void>
    {
        const attachments = this.values || [];

        for (let i = 0; i < attachments.length; i++)
        {
            const attachment = attachments[i];

            if (attachment && attachment.upload)
            {
                attachments[i] = await storageService(attachment.upload, container, secured);
            }
        }

        this.values = attachments;
    }
}

export const instanceOfAttachmentsEntry = (object: any): object is AttachmentsEntry =>
{
    return object && 'type' in object && object.type === Components.ATTACHMENTS;
};

export interface AttachmentsContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasHelp, HasWidth
{
    extensions: string;
    maxFileSize: number;
    maxFiles: number;
}

export class AttachmentsType implements AttachmentsContract, Validatable, EntryFactory<AttachmentsEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public extensions: string;
    public maxFileSize: number;
    public maxFiles: number;
    public help: string;
    public width: number;
    public minWidth: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: string;
    public errors: ValidationErrors;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.ATTACHMENTS;
        this.name = builder.name(Components.ATTACHMENTS);
        this.label = '[[[Załączniki]]]';
        this.showLabel = true;
        this.extensions = '';
        this.maxFileSize = 10;
        this.maxFiles = 1;
        this.help = '';
        this.width = min([3, builder.space(parent)]);
        this.minWidth = 1;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = '';
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public createEntry(data: any): AttachmentsEntry
    {
        return new AttachmentsEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors
        };
    }
}
