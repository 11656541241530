<template>
    <div class="form-group">
        <label :for="uid">{{ label }}</label>
        <select class="form-control" :id="uid" :value="modelValue" @change="update">
            <option v-for="option in options" :key="option.value" :value="option.value">
                {{ $t(option.text) }}
            </option>
        </select>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { Option } from '@/helpers/Interfaces';

@Options({
    name: 'field-choice'
})
export default class FieldChoice extends Vue
{
    @Prop() public label: string;
    @Prop() public options: Option<any>[];
    @Prop() public modelValue: any;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public checked(value: any): boolean
    {
        return this.modelValue == value;
    }

    @Emit('update:modelValue')
    public update(e: any): any
    {
        return e.target.value;
    }
}
</script>
