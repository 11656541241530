<template>
    <div class="form-group">
        <input type="text" id="form-toolbox-name" class="form-control" spellcheck="false" :placeholder="$t('[[[Nazwa]]]')" v-model="buffer" @input.stop.prevent="onUpdate">
        <small class="form-text text-danger" v-if="!buffer">{{ $t('[[[Podanie nazwy jest wymagane.]]]') }}</small>
        <small class="form-text text-danger" v-if="!unique">{{ $t('[[[Podana nazwa jest zajęta.]]]') }}</small>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { Blueprint } from '../Types';
import { FormBuilder } from '../Utils';

@Options({
    name: 'field-name'
})
export default class FieldName extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public blueprint!: Blueprint;
    @Prop() public modelValue!: string;

    public buffer: string = '';
    public unique: boolean = true;

    public onUpdate(e: any): void
    {
        const value = e.target.value.replace(/[^A-Za-z0-9]/g, "");

        if (value && value.length > 0 && (this.unique = this.form.unique(value, this.blueprint)) == true)
        {
            this.triggerInput(value);
        }
    }

    @Emit('update:modelValue')
    public triggerInput(value: string): string
    {
        return value;
    }

    @Watch('modelValue', { immediate: true })
    public onValueChanged(value: string): void
    {
        this.buffer = value;
    }
}
</script>

<style lang="scss" scoped>
.form-control {
    height: auto !important;
    padding: 0 0 10px 0 !important;
    background: transparent !important;
    border: 1px solid transparent !important;
    border-bottom: 1px solid $secondary !important;
    border-radius: 0 !important;
    font-weight: 500 !important;
    font-size: 22.75px !important;
    color: $gray-900;

    &:focus {
        background: transparent !important;
        border: 1px solid transparent !important;
        border-bottom: 1px solid $primary !important;
        color: $primary !important;
    }
}
</style>
