import Errors from './Errors';
import ValidationSchema from './Validation';
import { only } from './Utils';
import { ValidationError } from 'yup';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import dot from 'dot-object';
import * as yup from 'yup';
import { root } from '@/main';
import { isEqual } from 'lodash';
export { Validator } from './Validation';

export type ValidationSchematic = Record<string, yup.AnySchema>;
export type FormError = Record<string, any>;

class FormHelper<T extends Record<string, any>>
{
    private $initial = {};

    public $validators: ValidationSchematic = {};
    public $loading: boolean;
    public $loaded: boolean;
    public $errors: Errors;

    [prop: string]: any;

    /**
     * Create a new Form instance.
     */
    public constructor(data: T, validationSchema: ValidationSchematic)
    {
        this.$loaded = false;
        this.$loading = false;
        this.$errors = new Errors();
        this.setInitialValues(data);
        this.withData(data)
            .withErrors({});

        if (validationSchema)
        {
            this.$validators = validationSchema;
        }
    }

    private initializeValidationSchema(schema: ValidationSchematic): void
    {
        if (schema)
        {
            this.$validators = schema;
        }
    }

    public withData(data: T): FormHelper<T>
    {
        for (const field in data)
        {
            this[field as string] = data[field];
        }

        return this;
    }

    public withErrors(errors: Record<string, string[]>): FormHelper<T>
    {
        this.$errors.record(errors);

        return this;
    }

    /**
     * Fetch all relevant data for the form.
     */
    public data(): T
    {
        const data = {} as any;

        for (const property in this.$initial)
        {
            data[property] = this[property] as any;
        }

        return data as T;
    }

    /**
     * Fetch only selected data for the form.
     */
    public only(props: string[]): any
    {
        const data = {} as any;

        for (const property in this.$initial)
        {
            if (props.includes(property))
            {
                data[property] = this[property] as any;
            }
        }

        return data;
    }

    /**
     * Fetch data for the form except selected.
     */
    public except(props: string[]): any
    {
        const data = {} as any;

        for (const property in this.$initial)
        {
            if (!props.includes(property))
            {
                data[property] = this[property] as any;
            }
        }

        return data;
    }

    /**
     * Reset the form fields.
     */
    public reset(): void
    {
        merge(this, cloneDeep(this.$initial));

        this.$errors.clear();
        this.complete(true);
    }

    public setInitialValues(values: any): void
    {
        this.$initial = {};

        merge(this.$initial, cloneDeep(values));
    }

    /**
     * Clear the form fields.
     */
    public clear(): void
    {
        for (const field in this.$initial)
        {
            this[field] = cloneDeep(this.$initial[field]);
        }

        this.$errors.clear();
    }

    public loading(): boolean
    {
        return !this.$loaded && this.$loading;
    }

    public loaded(): boolean
    {
        return !this.loading();
    }

    public wait($forceLoading: boolean = false): void
    {
        if ($forceLoading)
            this.$loaded = false;

        this.$loading = true;
    }

    public changed(): number
    {
        const fields = Object.keys(this.data());

        const changed = fields?.filter(key => !isEqual(this.$initial[key], this.data()[key]));

        return changed?.length || 0;
    }

    public continue(): void
    {
        this.complete(this.valid());
    }

    public complete(status: boolean = true): void
    {
        if (status == true)
        {
            this.$errors.clear();
        }

        this.$loading = false;
        this.$loaded = true;
    }

    public async ready(values: Promise<boolean>[]): Promise<void>
    {
        this.wait();

        const result = await Promise.all(values);

        this.complete(result.every(p => p === true));
    }

    public valid(): boolean
    {
        return !this.$errors.any();
    }

    public active(): boolean
    {
        return !this.$loading && this.valid();
    }

    private rebuildValuesModel(): T
    {
        const values: Record<string, unknown> = {};

        for (const field in this.$initial)
        {
            values[field] = this[field];
        }

        return values as T;
    }

    /**
     * @param { string } field Validate value of specific field synchronously
     * @returns { void }
     */
    public validateFieldFromSchema(field: string): void
    {
        const values: T = this.rebuildValuesModel();

        try
        {
            this.$errors.clear(field);
            yup.object(this.$validators).validateSyncAt(field, values, { abortEarly: false });
        }
        catch (e)
        {
            (e as FormError).errors.forEach((message: string) => this.$errors.push(field, root.$i18n.$t(message)));
        }
    }

    /**
     * @param { string } field Validate value of specific field asynchronously
     * @returns { Promise<void> }
     */
    public async validateFieldFromSchemaAsync(field: string): Promise<void>
    {
        const values: T = this.rebuildValuesModel();

        try
        {
            this.$loading = true;
            this.$errors.clear(field);
            await yup.object(this.$validators).validateAt(field, values, { abortEarly: false });
        }
        catch (e)
        {
            (e as FormError).errors.forEach((message: string) => this.$errors.push(field, root.$i18n.$t(message)));
        }
        finally
        {
            this.$loading = false;
        }
    }

    /**
     * @description Validate all fields synchronously
     * @description Use this method if you want to use validators passed in the constructor
     * @returns {boolean} isFormValid state
     */
    public validateFromSchema(): boolean
    {
        for (const field in this.$initial)
        {
            this.validateFieldFromSchema(field);
        }

        return this.$errors.any();
    }

    /**
     * @description Validate all fields asynchronously
     * @description Use this method if you want to use validators passed in the constructor
     * @returns {boolean} isFormValid state
     */
    public validateFromSchemaAsync(): boolean
    {
        for (const field in this.$initial)
        {
            this.validateFieldFromSchemaAsync(field);
        }

        return this.$errors.any();
    }

    public validate(rules: (schema: ValidationSchema) => Record<string, any>): void
    {
        const schema = new ValidationSchema();
        const ruleset = rules(schema);
        const validator = schema.object(ruleset);
        const flatten = dot.dot(this.data());
        const data = only(flatten, ...Object.keys(ruleset));

        try
        {
            validator.validateSync(data);
        }
        catch (ex)
        {
            const validationException: ValidationError = ex as ValidationError;
            const exceptions = validationException.inner.length > 0 ? validationException.inner : [validationException];
            const errors = Object.assign({}, ...exceptions.map((err: ValidationError) => ({
                [err.path.replace('["', '').replace('"]', '')]: err.errors
            })));

            throw (() =>
            {
                return {
                    code: 422,
                    message: null as string,
                    data: { errors: errors },
                    inner: null as any
                };
            })();
        }
    }

    /**
     * @description Validate form field live
     * @description Use this method if you want to use live validation in form
     * @requires ValidationSchema You need to pass validation schema to constructor before
     * @requires Name You need to pass name property to input
     * @param {Event} event form tag @focusout event handler
     */
    public handleFocusOut(event: Record<string, any>): void
    {
        const field: string = event?.target?.name;

        if (field)
        {
            this.validateFieldFromSchemaAsync(field);
        }
    }

    /**
     * @description Clear form field errors live
     * @description Use this method if you want to use live validation in form
     * @requires ValidationSchema You need to pass validation schema to constructor before
     * @requires Name You need to pass name property to input
     * @param {Event} event form tag @focusin event handler
     */
    public handleFocusIn(event: Record<string, any>): void
    {
        const field: string = event?.target?.name;

        if (field)
        {
            this.$errors.clear(field);
        }
    }
}

export type FormType<T> = FormHelper<T> & T;

export class Form
{
    public static create<T>(data: T, validationSchema?: ValidationSchematic): FormType<T>
    {
        return new FormHelper<T>(data, validationSchema) as FormType<T>;
    }
}
