<template>
    <div>
        <field-choice v-model="blueprint.customError" :options="customErrorOptions" :label="label" />
        <field-condition v-model="blueprint.customErrorWhen" :label="label" :show-label="false" :form="form" :when="blueprint.customError" />
        <field-text v-model="blueprint.customErrorMessage" :placeholder="$t('[[[Komunikat błędu]]]')" v-if="isWhenCondition" />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { CustomErrorBlueprint, NeverChoice, WhenChoice } from '../Types';
import { FormBuilder } from '../Utils';
import { Option } from '@/helpers/Interfaces';
import FieldChoice from './FieldChoice.vue';
import FieldCondition from './FieldCondition.vue';
import FieldText from './FieldText.vue';

@Options({
    name: 'field-error',
    components: {
        'field-choice': FieldChoice,
        'field-condition': FieldCondition,
        'field-text': FieldText
    }
})
export default class FieldError extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public blueprint!: CustomErrorBlueprint;
    @Prop() public label!: string;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public get customErrorOptions(): Option<NeverChoice | WhenChoice>[]
    {
        return [
            { value: NeverChoice.Never, text: '[[[Nigdy]]]' },
            { value: WhenChoice.When, text: '[[[Warunkowo]]]' }
        ];
    }

    public get isWhenCondition(): boolean
    {
        return this.blueprint.customError == WhenChoice.When;
    }
}
</script>
