<template>
    <component :is="tagName" class="pointer text-break text-link" :class="classNames" @click="onClick">
        <slot name="default" v-if="loaded"></slot>
        <placeholder :width="[minWidth, maxWidth]" :height="14" v-else></placeholder>
    </component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Inject } from '@/helpers/Decorators';

@Options({
    name: 'list-view-header',
    inheritAttrs: false
})
export default class ListViewHeader extends Vue
{
    @Inject('loaded')
    public loaded: boolean;

    @Inject('layout')
    public layout: string;

    @Inject('row-click')
    public rowClick: (item: any) => void;

    @Inject('index')
    public index: number;

    @Inject('item')
    public item: any;

    public get table(): boolean
    {
        return this.layout == 'table';
    }

    public get tagName(): string
    {
        return this.table ? 'td' : 'h5';
    }

    public get classNames(): string
    {
        return this.table ? '' : 'mb-0';
    }

    public get minWidth(): number
    {
        return parseInt(this.$attrs.width as string) || 100;
    }

    public get maxWidth(): number
    {
        return parseInt(this.$attrs.width as string) || 175;
    }

    public onClick(): void
    {
        if (this.rowClick)
        {
            this.rowClick(this.item);
        }
    }
}
</script>
