<template>
    <div class="app d-flex flex-row">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-sm-10 col-md-7 col-lg-5 col-xl-4">
                    <router-view />
                    <p class="text-center copy mb-4">
                        &copy; {{ year }} - Ideo CORE
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class AuthLayout extends Vue
{
    public year: number = 0;

    public mounted(): void
    {
        this.year = (new Date()).getFullYear();
    }
}
</script>

<style lang="scss" scoped>
.app {
    min-height: 100vh;
    background: url(/static/img/renters_bg.webp) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.copy {
    color: #FFFFFF;
    text-shadow: 1px 1px 5px #333;
}
</style>
