import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint } from '../Types';
import { FormEntry } from '../types/Form';
import { AttachmentsContract, AttachmentsEntry, instanceOfAttachmentsEntry } from '../types/Attachments';
import properties from '../properties';

@Options({
    name: 'attachments-blueprint',
    components: {
        ...properties
    }
})
export default class AttachmentsBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: AttachmentsContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public entryData: AttachmentsEntry = new AttachmentsEntry();

    public created(): void
    {
        this.entryData = this.form.initEntry(this.blueprint, this.entryData, instanceOfAttachmentsEntry);
    }

    public get value(): any[]
    {
        return this.entryData.values;
    }

    public set value(values: any[])
    {
        if (values == null)
            values = [];

        if (!Array.isArray(values))
            values = [values];

        this.entryData.values = values;
    }

    public get required(): boolean
    {
        return this.form.required(this.blueprint);
    }

    public get extensions(): string[]
    {
        return this.blueprint.extensions.split(',').map(p => p.trim()).filter(p => !!p);
    }
}
