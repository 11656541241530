import { FormBuilder } from '../Utils';
import { Components, AggregateBlueprint, Blueprint, HasWidth } from '../Types';

export interface LineContract extends Blueprint, HasWidth
{
}

export class LineType implements LineContract
{
    public id: string;
    public type: string;
    public name: string;
    public width: number;
    public minWidth: number;

    public constructor(builder: FormBuilder, parent: AggregateBlueprint)
    {
        this.id = builder.newId();
        this.type = Components.LINE;
        this.name = builder.name(Components.LINE);
        this.width = 6;
        this.minWidth = 6;
    }
}
