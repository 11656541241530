<template>
    <ul class="menu" :class="{'collapsed': collapsed}">
        <li v-for="(node, i) in root()" :key="i" :class="{'active': active(node)}">
            <router-link :to="url(node)" @click.prevent="toggle(node)" :aria-label="$t(node.name)">
                <i :class="node.icon"></i>
                <span>{{ $t(node.name) }}</span>
                <b v-if="children(node).length > 0 && !expanded(node)"><i class="fas fa-chevron-left"></i></b>
                <b v-else-if="children(node).length > 0 && expanded(node)"><i class="fas fa-chevron-down"></i></b>
                <b v-else></b>
            </router-link>
            <ul :class="{'expanded': expanded(node)}" v-if="children(node).length > 0">
                <li v-for="(node, i) in children(node)" :key="i" :class="{'active': active(node)}">
                    <router-link :class="{'second-level-item': !children(node).length}" :to="url(node)" @click.prevent="toggle(node)" :aria-label="$t(node.name)">
                        <span>{{ $t(node.name) }}</span>
                        <b v-if="children(node).length > 0 && !expanded(node)"><i class="fas fa-chevron-left"></i></b>
                        <b v-else-if="children(node).length > 0 && expanded(node)"><i class="fas fa-chevron-down"></i></b>
                        <b v-else></b>
                    </router-link>
                    <ul :class="{'expanded': expanded(node)}" v-if="children(node).length > 0">
                        <li v-for="(node, i) in children(node)" :key="i" :class="{'active': active(node)}">
                            <router-link :to="url(node)" @click.prevent="toggle(node)" :aria-label="$t(node.name)">
                                <span>{{ $t(node.name) }}</span>
                                <b></b>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';

@Options({
    name: 'partials-navigation-menu'
})
export default class Menu extends Vue
{
    @Prop({ default: false })
    public collapsed: boolean;

    public sitemap: any[] = [];
    public toggled: any[] = [];

    public async mounted(): Promise<void>
    {
        this.sitemap = await this.$sitemap.all();
        this.toggled = await this.$sitemap.crumbs(this.$route);
    }

    public unmounted(): void
    {
        this.$sitemap.purge();
    }

    public active(node: any): boolean
    {
        return this.$sitemap.active(node, this.$route, true);
    }

    public url(node: any): any
    {
        return this.$sitemap.url(node, this.$route);
    }

    public root(): any[]
    {
        return this.sitemap.filter((p: any) => p.visible);
    }

    public children(node: any): any[]
    {
        return node.children.filter((p: any) => p.visible);
    }

    public toggle(node: any): void
    {
        if (this.children(node).length > 0)
        {
            if (this.expanded(node))
            {
                this.toggled = this.toggled.filter(p => p !== node);
            }
            else
            {
                this.toggled = [];
                this.$sitemap.path(node).forEach(p =>
                    this.toggled.push(p)
                );
            }
        }
        else
        {
            this.redirect(this.url(node));
        }
    }

    public expanded(node: any): boolean
    {
        return this.collapsed ? false : this.toggled.includes(node);
    }
}
</script>

<style lang="scss" scoped>
$sidemenu-width: 256px;
$accent-color: #FFFFFF;
$icon-color: #A2A9B9;
$font-color: #A2A9B9;

// pierwszy poziom
ul {
    padding: 0px;
    list-style-type: none;
    background-color: var(--secondary-menu-color);

    &.menu {
        width: $sidemenu-width;
        padding: 0 0 48px 0;
        margin-bottom: 0;
        background-color: var(--primary-menu-color);

        > li {
            &.expanded {
                background: var(--secondary-menu-color);
                span {
                    color: $white;
                }
            }
            &.active {
                &:not(.expanded) {
                    background: rgba($white,.1);
                    > a {
                        color: $white;
                    }
                }
            }
        }
    }

    li {
        > a {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            padding: 9px 0;
            text-decoration: none;
            color: rgba(255,255,255,.8);
            font-size: 14px;

            > i, svg {
                margin: 0px 10px 0 18px;
                min-width: 20px;
                color: $icon-color;
                line-height: 1.5em;
            }
            > i {
                position: relative;
                top: 1px;
            }
            > span {
                flex-grow: 1;
            }
            > b {
                margin: 0 15px 0 10px;
                min-width: 10px;
                text-align: center;
                font-size: 12px;
            }

            &:hover {
                background-color: var(--hover-color);
                span {
                    color: $white;
                }
                i {
                    color: $white;
                }
                > svg {
                    fill: $white;
                }
            }
        }

        &.active {
            &:not(.expanded)
            {
                a > svg {
                    fill: $white;
                }
            }
        }
    }

    // drugi poziom
    ul {
        padding: -10px 0;
        height: 0;
        overflow: hidden;
        transition: all .5s cubic-bezier(0, 1, 0.5, 1);

        &.expanded {
            padding: 0;
            height: auto;
        }

        > li {
            > a {
                padding-left: rem-calc(57);
            }
            &.active > a.second-level-item {
                background: rgba($white,.05);
                color: $white;

                &:hover {
                    background-color: var(--hover-color);
                    span {
                        color: $white;
                    }
                    i {
                        color: $white;
                    }
                }
            }
        }

        // trzeci poziom
        ul {
            &.expanded {
                padding: 0;
            }

            > li {
                > a {
                    margin: 0;
                    padding: 6px 0 6px 65px;
                    border: none;
                    font-size: 12px;
                }
                &.active > a {
                    border: none;
                    background: rgba($white,.05);
                    color: $white;
                }
            }
        }
    }

    &.collapsed {
        li > a {
            span, b {
                visibility: hidden;
            }
        }
    }
}
</style>
