import { Plugin } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { DateTime } from 'luxon';
import { formatDateTime, baseurl } from '@/helpers/Utils';

interface Filters
{
    datetime(value: DateTime|Date|string, format?: string, zone?: string): string;
    date(value: DateTime|Date|string, format?: string, zone?: string): string;
    time(value: DateTime|Date|string, format?: string, zone?: string): string;
    baseurl(value: string): string;
    image(value: string, presetName: string): string;
    filesize(size: number): string;
    default(value: string, defaultValue: string): string;
}

class FiltersHelper implements Filters
{
    public datetime(value: DateTime|Date|string, format: string = 'yyyy-MM-dd HH:mm:ss', zone: string = null): string
    {
        return formatDateTime(value, format, zone);
    }

    public date(value: DateTime|Date|string, format: string = 'yyyy-MM-dd', zone: string = null): string
    {
        return formatDateTime(value, format, zone);
    }

    public time(value: DateTime|Date|string, format: string = 'HH:mm:ss', zone: string = null): string
    {
        return formatDateTime(value, format, zone);
    }

    public baseurl(value: string): string
    {
        return baseurl(value);
    }

    public image(value: string, presetName: string): string
    {
        return baseurl(`${value}?p=${presetName}`);
    }

    public filesize(size: number): string
    {
        const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let i = 0;

        for (i = 0; size > 1024; i++)
        {
            size = size / 1024;
        }

        return `${size.toFixed(2)} ${units[i]}`;
    }

    public default(value: string, defaultValue: string): string
    {
        return value || defaultValue;
    }
}

const MixinsPlugin: Plugin =
{
    install(app, options)
    {
        const vue = app.config.globalProperties;

        // Computed properties
        app.mixin({
            computed: {
                phone()
                {
                    return ['xs'].includes(this.$mq as string);
                },
                phablet()
                {
                    return ['xs', 'sm'].includes(this.$mq as string);
                },
                tablet()
                {
                    return ['sm', 'md'].includes(this.$mq as string);
                },
                mobile()
                {
                    return ['xs', 'sm', 'md'].includes(this.$mq as string);
                },
                laptop()
                {
                    return ['lg'].includes(this.$mq as string);
                },
                desktop()
                {
                    return ['xl'].includes(this.$mq as string);
                }
            }
        });

        // Helpers
        vue.abort = (code: number, message: string): void =>
        {
            throw (() =>
            {
                return {
                    code: code,
                    message: message,
                    data: null as any,
                    inner: null as any
                };
            })();
        };

        vue.redirect = (location: RouteLocationRaw, onComplete?: () => void): void =>
        {
            vue.$router.push(location).catch(() => {}).finally(() => onComplete ? onComplete() : null);
        };

        vue.silentRedirect = (location: RouteLocationRaw): void =>
        {
            const result = vue.$router.resolve(location);

            history.replaceState(history.state, '', result.href);
        };

        vue.handleException = (ex: any, rules: Record<number, (ex: any) => void>): void =>
        {
            const code = String(ex.code || 0);
            const codes = Object.keys(rules);

            if (codes.indexOf(code) >= 0)
            {
                rules[code](ex);
            }
            else if (codes.indexOf("0") >= 0)
            {
                rules["0"](ex);
            }

            if (!ex.code || ex.code >= 500)
            {
                vue.$log.debug(ex);
            }
        };

        // Filters
        vue.$filters = new FiltersHelper();
    }
};

export default MixinsPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        // Computed properties
        phone: boolean;
        phablet: boolean;
        tablet: boolean;
        mobile: boolean;
        laptop: boolean;
        desktop: boolean;

        // Helpers
        abort(code: number, message: string): void;
        redirect(location: RouteLocationRaw, onComplete?: () => void): void;
        silentRedirect(location: RouteLocationRaw): void;
        handleException(ex: any, rules: Record<number, (ex: any) => void>): void;

        // Filters
        $filters: Filters;
    }
}
