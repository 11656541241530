<template>
    <component :is="tagName" v-bind="attrs()" :class="classNames">
        <ideo-dropdown variant="light" :text="$t('[[[Akcje]]]')" dropleft boundary="body" no-flip no-caret v-if="loaded">
            <template #button-content>
                <i class="fal fa-ellipsis-v-alt"></i>
            </template>
            <slot name="default"></slot>
        </ideo-dropdown>
        <placeholder :width="37" :height="32" v-else></placeholder>
    </component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Inject } from '@/helpers/Decorators';
import { only } from '@/helpers/Utils';

@Options({
    name: 'list-view-buttons',
    inheritAttrs: false
})
export default class ListViewButtons extends Vue
{
    @Inject('loaded')
    public loaded: boolean;

    @Inject('layout')
    public layout: string;

    public get table(): boolean
    {
        return this.layout == 'table';
    }

    public get tagName(): string
    {
        return this.table ? 'td' : 'div';
    }

    public get classNames(): string
    {
        return this.table ? 'actions-column text-end' : '';
    }

    public attrs(): Record<string, any>
    {
        return only(this.$attrs, 'class', 'style');
    }
}
</script>
