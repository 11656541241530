<script lang="ts" src="../blueprints/Address.ts"></script>

<template>
    <form-component-wrapper class="address-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <label :for="blueprint.id" v-if="blueprint.showLabel">{{ $t(blueprint.label) }} <var class="text-danger" v-if="required">*</var></label>
                <div class="row g-0 mb-2 me-n2" v-if="blueprint.hasStreet || blueprint.hasBuilding || blueprint.hasApartment">
                    <div class="col pe-2" v-if="blueprint.hasStreet">
                        <input type="text" class="form-control" v-model="street" :placeholder="$t(blueprint.getStreetLabel())" :readonly="readonly">
                    </div>
                    <div class="col-2 pe-2" v-if="blueprint.hasBuilding">
                        <input type="text" class="form-control" v-model="building" :placeholder="$t(blueprint.getBuildingLabel())" :readonly="readonly">
                    </div>
                    <div class="col-2 pe-2" v-if="blueprint.hasApartment">
                        <input type="text" class="form-control" v-model="apartment" :placeholder="$t(blueprint.getApartmentLabel())" :readonly="readonly">
                    </div>
                </div>
                <div class="row g-0 mb-2 me-n2" v-if="blueprint.hasCity || blueprint.hasZip">
                    <div class="col pe-2" v-if="blueprint.hasCity">
                        <input type="text" class="form-control" v-model="city" :placeholder="$t(blueprint.getCityLabel())" :readonly="readonly">
                    </div>
                    <div class="col-4 pe-2" v-if="blueprint.hasZip">
                        <input type="text" class="form-control" v-model="zip" :placeholder="$t(blueprint.getZipLabel())" :readonly="readonly">
                    </div>
                </div>
                <div class="row g-0 mb-2 me-n2" v-if="blueprint.hasProvince || blueprint.hasCountry">
                    <div class="col pe-2" v-if="blueprint.hasProvince">
                        <input type="text" class="form-control" v-model="province" :placeholder="$t(blueprint.getProvinceLabel())" :readonly="readonly">
                    </div>
                    <div class="col pe-2" v-if="blueprint.hasCountry">
                        <input type="text" class="form-control" v-model="country" :placeholder="$t(blueprint.getCountryLabel())" :readonly="readonly">
                    </div>
                </div>
                <form-error-message :entry="entryData" name="street" />
                <form-error-message :entry="entryData" name="building" />
                <form-error-message :entry="entryData" name="apartment" />
                <form-error-message :entry="entryData" name="city" />
                <form-error-message :entry="entryData" name="zip" />
                <form-error-message :entry="entryData" name="province" />
                <form-error-message :entry="entryData" name="country" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <field-text v-model="blueprint.label" :label="$t('[[[Etykieta]]]')" />
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <div class="row g-0">
                <div class="col-6">
                    <h6>{{ $t('[[[Pole zawiera]]]') }}</h6>
                    <field-checkbox v-model="blueprint.hasCountry" :label="$t('[[[Kraj]]]')" :disabled="fieldsDisabled(blueprint.hasCountry)" class="subfield" />
                    <field-checkbox v-model="blueprint.hasProvince" :label="$t('[[[Województwo]]]')" :disabled="fieldsDisabled(blueprint.hasProvince)" class="subfield" />
                    <field-checkbox v-model="blueprint.hasCity" :label="$t('[[[Miejscowość]]]')" :disabled="fieldsDisabled(blueprint.hasCity)" class="subfield" />
                    <field-checkbox v-model="blueprint.hasZip" :label="$t('[[[Kod pocztowy]]]')" :disabled="fieldsDisabled(blueprint.hasZip)" class="subfield" />
                    <field-checkbox v-model="blueprint.hasStreet" :label="$t('[[[Ulica]]]')" :disabled="fieldsDisabled(blueprint.hasStreet)" class="subfield" />
                    <field-checkbox v-model="blueprint.hasBuilding" :label="$t('[[[Numer domu]]]')" :disabled="fieldsDisabled(blueprint.hasBuilding)" class="subfield" />
                    <field-checkbox v-model="blueprint.hasApartment" :label="$t('[[[Numer mieszkania]]]')" :disabled="fieldsDisabled(blueprint.hasApartment)" class="subfield" />
                </div>
                <div class="col-6">
                    <h6>{{ $t('[[[Etykiety]]]') }}</h6>
                    <field-text v-model="blueprint.countryLabel" :placeholder="$t(blueprint.getCountryLabel())" size="sm" class="subfield" />
                    <field-text v-model="blueprint.provinceLabel" :placeholder="$t(blueprint.getProvinceLabel())" size="sm" class="subfield" />
                    <field-text v-model="blueprint.cityLabel" :placeholder="$t(blueprint.getCityLabel())" size="sm" class="subfield" />
                    <field-text v-model="blueprint.zipLabel" :placeholder="$t(blueprint.getZipLabel())" size="sm" class="subfield" />
                    <field-text v-model="blueprint.streetLabel" :placeholder="$t(blueprint.getStreetLabel())" size="sm" class="subfield" />
                    <field-text v-model="blueprint.buildingLabel" :placeholder="$t(blueprint.getBuildingLabel())" size="sm" class="subfield" />
                    <field-text v-model="blueprint.apartmentLabel" :placeholder="$t(blueprint.getApartmentLabel())" size="sm" class="subfield" />
                </div>
            </div>
            <field-textarea v-model="blueprint.help" :label="$t('[[[Pomoc]]]')" />
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-readonly :form="form" :blueprint="blueprint" :label="$t('[[[Tylko do odczytu]]]')" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
.subfield {
    margin: 0;
    height: 36px;
    display: flex;
    align-items: center;
}
</style>
