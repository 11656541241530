import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint } from '../Types';
import { FormEntry } from '../types/Form';
import { NumericContract, NumericFieldTypes, NumericEntry, instanceOfNumericEntry } from '../types/Numeric';
import properties from '../properties';
import { Option } from '@/helpers/Interfaces';

@Options({
    name: 'numeric-blueprint',
    components: {
        ...properties
    }
})
export default class NumericBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: NumericContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public focused: boolean = false;
    public entryData: NumericEntry = new NumericEntry();

    public created(): void
    {
        this.entryData = this.form.initEntry(this.blueprint, this.entryData, instanceOfNumericEntry);
    }

    public get value(): number
    {
        return this.entryData.value === null
            ? this.form.executeExpression(this.blueprint.defaultValue)
            : this.entryData.value;
    }

    public set value(value: number)
    {
        this.entryData.value = value;
    }

    public get display(): string
    {
        return this.value !== null ? this.value.toFixed(this.precision) : null;
    }

    public get min(): number
    {
        return this.blueprint.range.min;
    }

    public get max(): number
    {
        return this.blueprint.range.max;
    }

    public get step(): number
    {
        return this.blueprint.step;
    }

    public get precision(): number
    {
        return this.isInteger ? 0 : this.blueprint.precision;
    }

    public get prepend(): string
    {
        return this.blueprint.affix.prepend;
    }

    public get append(): string
    {
        return this.isPercent ? '%' : this.blueprint.affix.append;
    }

    public get fieldTypeOptions(): Option<NumericFieldTypes>[]
    {
        return [
            { value: NumericFieldTypes.Integer, text: '[[[Liczba całkowita]]]' },
            { value: NumericFieldTypes.Decimal, text: '[[[Liczba dziesiętna]]]' },
            { value: NumericFieldTypes.Percent, text: '[[[Procent]]]' }
        ];
    }

    public get fieldType(): typeof NumericFieldTypes
    {
        return NumericFieldTypes;
    }

    public get isInteger(): boolean
    {
        return this.blueprint.fieldType == NumericFieldTypes.Integer;
    }

    public get isDecimal(): boolean
    {
        return this.blueprint.fieldType == NumericFieldTypes.Decimal;
    }

    public get isPercent(): boolean
    {
        return this.blueprint.fieldType == NumericFieldTypes.Percent;
    }

    public get readonly(): boolean
    {
        return this.form.readonly(this.blueprint);
    }

    public get required(): boolean
    {
        return this.form.required(this.blueprint);
    }

    public update(e: any): void
    {
        this.value = parseFloat(e.target.value || 0);
    }

    public focus(e: any): void
    {
        this.focused = true;
    }

    public blur(e: any): void
    {
        this.focused = false;
        this.update(e);
    }
}
