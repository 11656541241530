<template>
    <div>
        <div class="input-group">
            <picker
                class="form-control"
                @update:modelValue="updateModel"
                :modelValue="datetimeValue"
                :input-id="name"
                :name="name"
                :type="type"
                :zone="zone"
                :format="datetimeFormat"
                :disabled="disabled"
                :theme="themeStyle"
                :minute-step="minuteStep"
                :phrases="{ok: $t('[[[Ok]]]'), cancel: $t('[[[Anuluj]]]')}"
                :days-with-task="daysWithTask"
                :days-with-reservations="daysWithReservations"
            />
            <ideo-button v-if="!preventClear" variant="secondary" :title="$t('[[[Usuń datę]]]')" @click.prevent="clear()" :disabled="disabled">
                <i class="fas fa-fw fa-times text-white"></i>
            </ideo-button>
        </div>
        <div class="text-center small" v-if="datetimeValue && zone != 'UTC' && type !== 'time'">
            {{ $filters.date(datetimeValue, `yyyy-MM-dd ${endOfDay ? '23:59' : 'HH:mm'}`, 'UTC') }} (UTC)
        </div>
        <div class="text-center small" v-if="datetimeValue && zone != 'UTC' && type === 'time'">
            {{ $filters.date(datetimeValue, 'HH:mm', 'UTC') }} (UTC)
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { Datetime } from '@/components/datetime';
import { DateTime } from 'luxon';

@Options({
    name: 'ideo-datetime',
    components: {
        picker: Datetime
    }
})
export default class IdeoDateTime extends Vue
{
    @Prop({ default: '' }) public name: string;
    @Prop({ default: 'date' }) public type: string;
    @Prop({ default: null }) public modelValue: DateTime;
    @Prop({ default: 'local' }) public zone: string;
    @Prop({ default: false }) public disabled: boolean;
    @Prop({ default: 5 }) public minuteStep: number;
    @Prop({ default: false }) public preventClear: boolean;
    @Prop({ default: null }) public daysWithTask: [];
    @Prop({ default: null }) public daysWithReservations: [];
    @Prop({ default: false }) public endOfDay: boolean;

    public get datetimeValue(): string
    {
        return this.modelValue instanceof DateTime ? this.modelValue.toISO() : '';
    }

    public get datetimeFormat(): string
    {
        switch (this.type)
        {
            case 'datetime':
                return 'yyyy-MM-dd HH:mm';
            case 'time':
                return 'HH:mm';
            default:
                return 'yyyy-MM-dd';
        }
    }

    @Emit('update:modelValue')
    public updateModel(value: string): DateTime | null
    {
        return value ? DateTime.fromISO(value).setZone('UTC') : null;
    }

    @Emit('update:modelValue')
    public clear(): null
    {
        const element = this.$el.querySelector('.vdatetime-input');

        element.dispatchEvent(new CustomEvent('input', { bubbles: true }));

        return null;
    }

    public themeStyles: string[] = ['default-theme', 'c-dark-theme'];

    public get themeStyle(): string
    {
        const id = this.$store.state.common.theme.themeId;

        return this.themeStyles[id];
    }
}
</script>

<style lang="scss">
    .vdatetime {
        padding: 0 !important;

        > input {
            border: none;
            padding: 6px 12px;
            width: 100%;
            height: 100%;

            &:disabled {
                background-color: #e9ecef;
            }
        }

        .vdatetime-popup__header,
        .vdatetime-calendar__month__day--selected > span > span,
        .vdatetime-calendar__month__day--selected:hover > span > span {
            background: var(--hover-color);
        }

        .vdatetime-year-picker__item--selected,
        .vdatetime-time-picker__item--selected,
        .vdatetime-popup__actions__button {
            color: #20a8d8;
        }
    }
    .vdatetime-popup {
        z-index: 10013;
    }
    .vdatetime-overlay {
        z-index: 10012;
    }
    .vdatetime-wrapper {
        .input-group-append {
            .input-group-text {
                border-color: #e4e7ea;
                border-radius: 0 4px 4px 0;
                &:hover {
                    background: #e4e7ea;
                    transition: background .3s;
                }
            }
        }
    }

    .c-dark-theme {
        .vdatetime input.vdatetime-input {
            background-color: hsla(0deg, 0%, 100%, 0.1);
        }
    }
</style>
