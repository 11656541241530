import auth from '@/modules/core/auth/store';
import authlog from '@/modules/core/authlog/store';
import changelog from '@/modules/core/changelog/store';
import roles from '@/modules/core/roles/store';
import scheduler from '@/modules/core/scheduler/store';
import teams from '@/modules/core/teams/store';
import trash from '@/modules/core/trash/store';
import users from '@/modules/core/users/store';
import substitutions from '@/modules/core/substitutions/store';
import dataSources from '@/modules/core/data-sources/store';
import forms from '@/modules/core/forms/store';
import messages from '@/modules/core/queues/store';
import pats from '@/modules/core/pat/store';
import theme from './theme';
import filters from './filters';
import notifications from '@/modules/core/notifications/store';

export default {
    core: {
        namespaced: true,
        modules: {
            auth: auth,
            authlog: authlog,
            changelog: changelog,
            roles: roles,
            scheduler: scheduler,
            teams: teams,
            trash: trash,
            users: users,
            substitutions: substitutions,
            dataSources: dataSources,
            forms: forms,
            messages: messages,
            notifications: notifications,
            pats: pats
        }
    },
    common: {
        namespaced: true,
        modules: {
            theme: theme,
            filters: filters
        }
    }
};
