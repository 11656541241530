<template>
    <div class="form-group mb-3">
        <label v-if="label">{{ label }} <var v-if="flag(required)">*</var></label>
        <slot name="default"></slot>
        <small class="form-text text-danger" v-if="state === false">{{ invalidFeedback }}</small>
        <small class="form-text text-muted" v-if="$slots.description"><slot name="description"></slot></small>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';

@Options({
    name: 'ideo-form-group'
})
export default class IdeoFormGroup extends Vue
{
    @Prop()
    public label: string;

    @Prop()
    public invalidFeedback: string;

    @Prop({ default: null })
    public state: boolean | null;

    @Prop({ default: false })
    public required: boolean;

    public flag(value: any): boolean
    {
        return value !== false;
    }
}
</script>
