import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';

export default {
    namespaced: true,
    state: {
        filter: Form.create({
            categoryId: null,
            userId: 0,
            status: '',
            title: '',
            dateFromUtc: null,
            dateDueUtc: null,
            showArchived: false,
            onlyMine: false
        }),
        pager: new Pager(1, 20, 'DateCreatedUtc', 'DESC')
    }
};
