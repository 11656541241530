<template>
    <div class="card gradient renter-view">
        <ideo-img :src="'/static/img/hirenterslogo.png'" alt="renters_logo" :class="'logo'" />
        <h4 class="card-header">
            {{ $t('[[[Zapomniałeś hasła?]]]') }}
        </h4>
        <div class="card-body">
            <ideo-alert :show="message.length > 0" :variant="messageVariant">
                {{ message }}
            </ideo-alert>
            <h5>{{ $t('[[[Podaj swój adres email]]]') }}</h5>
            <form @submit.prevent="onSubmit" @input="form.$errors.clear($event.target.name);">
                <ideo-form-group :invalid-feedback="form.$errors.first('email')" :state="form.$errors.state('email')">
                    <ideo-form-input v-model="form.email" type="text" name="email" :placeholder="$t('[[[Adres email]]]')" v-focus></ideo-form-input>
                </ideo-form-group>
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <ideo-button type="submit" variant="primary" :block="true" :disabled="!form.active()">
                            {{ $t('[[[Wyślij link]]]') }}
                        </ideo-button>
                    </div>
                    <div class="col-12 col-sm-6">
                        <router-link :to="{name:'login'}" class="btn btn-link w-100">
                            {{ $t('[[[Wróć do logowania]]]') }}
                        </router-link>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {Form, FormType} from '@/helpers/Form';
import AuthService from '@/modules/core/auth/services/AuthService';

@Options({})
export default class ForgotPassword extends Vue
{
    public message: string = '';
    public messageVariant: string = 'danger';
    public callbackUrl: string = '';
    public form: FormType<any> = Form.create<any>({
        email: ''
    });

    public created(): void
    {
        this.callbackUrl = this.getAbsoluteUrl(this.$router.resolve({
            name: 'reset-password',
            params: { token: ':token' }
        }).href);
    }

    public async onSubmit(): Promise<void>
    {
        this.message = '';

        try
        {
            this.form.wait();

            const result = await AuthService.resetPassword(
                this.form.email,
                this.callbackUrl
            );

            this.messageVariant = 'success';
            this.message = result;
            this.form.email = '';
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => { this.messageVariant = 'danger'; this.message = ex.message; },
                422: (ex: any) => this.form.$errors.record(ex.data.errors)
            });
        }
        finally
        {
            this.form.continue();
        }
    }

    public getAbsoluteUrl(href: string): string
    {
        const defaultPorts: any = { "http:": 80, "https:": 443 };

        return window.location.protocol + "//" + window.location.hostname + (
            ((window.location.port) && (window.location.port !== defaultPorts[window.location.protocol])) ? (":" + window.location.port) : ""
        ) + href;
    }
}
</script>

<style scoped>
input[type="text"], input[type="password"]{
    color: white;
}
</style>
