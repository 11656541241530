export default {
    i18n: {
        locale: "pl-PL",
        languages: {
            "pl-PL": "[[[Polski]]]",
            "en-US": "[[[Angielski]]]",
            "uk-UA": "[[[Ukraiński]]]"
        }
    }
};
