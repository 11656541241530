<template>
    <div v-bind="attrs()">
        <list-view-utils-card v-for="(item, index) in items" :key="`row-${index}`" :index="index" :item="item">
            <slot name="row" :item="item" :index="index"></slot>
        </list-view-utils-card>
        <div v-if="items.length == 0">
            <div class="text-center p-3 mb-3 bg-light text-muted">{{ $t(emptyLabel) }}</div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Inject, Prop } from '@/helpers/Decorators';
import Pager from '@/helpers/Pager';
import Card from '../utils/Card.vue';
import { only } from '@/helpers/Utils';

@Options({
    name: 'list-view-card-layout',
    inheritAttrs: false,
    components: {
        'list-view-utils-card': Card
    }
})
export default class ListViewCardLayout extends Vue
{
    @Inject('loaded')
    public loaded: boolean;

    @Inject('empty-label')
    public emptyLabel: boolean;

    @Prop()
    public pager!: Pager;

    @Prop()
    public headers!: any[];

    @Prop()
    public rows!: any[];

    public get items(): any[]
    {
        if (this.loaded)
            return this.rows;

        return [];
    }

    public attrs(): Record<string, any>
    {
        return only(this.$attrs, 'class', 'style');
    }
}
</script>
