import { axios } from '@/plugins/axios';
import assign from 'lodash/assign';
import Pager from '@/helpers/Pager';
import { Statement, Pagination } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';

/**
 * StorageService
 */
export default class StorageService
{
    public static uploadUrl = (): string => `${axios.defaults.baseURL}/admin/storage/upload`;

    /**
     * @param file File
     * @param container string
     * @param secured boolean
     *
     * @returns Promise<Resource>
     */
    public static async upload(file: File, container: string, payload: any = null, secured: boolean = false): Promise<Resource>
    {
        const data = new FormData();

        data.append('container', container);
        data.append('payload', JSON.stringify(payload));
        data.append('secured', secured ? "true" : "false");
        data.append('file', file);

        const result = await axios.post<Resource>('admin/storage/upload', data);

        return result.data;
    }

    /**
     * @param files File[]
     * @param container string
     * @param secured boolean
     *
     * @returns Promise<Resource>
     */
    public static async uploadMany(files: File[], container: string, payload: string = null, secured: boolean = false): Promise<Resource[]>
    {
        const result: Resource[] = [];

        for (let i = 0; i < files.length; i++)
        {
            result.push(await StorageService.upload(files[i], container, payload, secured));
        }

        return result;
    }

    /**
     * @param publicId string
     *
     * @returns string
     */
    public static previewResource(publicId: string): string
    {
        return `${axios.defaults.baseURL}/admin/storage/resources/preview/${publicId}`;
    }

    /**
     * @param publicId string
     *
     * @returns Promise<Resource>
     */
    public static async fetchResource(publicId: string): Promise<Resource>
    {
        return (await axios.get<Resource>(`admin/storage/resources/${publicId}`)).data;
    }

    /**
     * @param scope string
     * @param folder number
     * @param filter string
     * @param all boolean
     * @param pager Pager
     *
     * @returns Promise<Resources>
     */
    public static async getResources(scope: string, folder: number, filter: string, all: boolean, pager: Pager): Promise<Resources>
    {
        const data = pager.data();

        assign(data, {
            scope: scope,
            folder: folder,
            filter: filter,
            all: all
        });

        const result = await axios.get<Resources>(`admin/storage/resources`, {
            params: data
        });

        return result.data;
    }

    /**
     * @param items Resource[]
     *
     * @returns number[]
     */
    public static getFolders(items: Resource[]): number[]
    {
        return items
            .filter(function(item)
            {
                return item.resourceType === 'folder';
            })
            .map(function(item)
            {
                return item.id;
            });
    }

    /**
     * @param items Resource[]
     *
     * @returns number[]
     */
    public static getFiles(items: Resource[]): number[]
    {
        return items
            .filter(function(item)
            {
                return item.resourceType === 'file';
            })
            .map(function(item)
            {
                return item.id;
            });
    }

    /**
     * @param items Resource[]
     *
     * @returns Promise<Statement>
     */
    public static async deleteResources(items: Resource[]): Promise<Statement>
    {
        const folders = StorageService.getFolders(items);
        const files = StorageService.getFiles(items);

        const result = await axios.delete(`admin/storage/resources`, {
            params: {
                folders: folders.join(','),
                files: files.join(',')
            }
        });

        return result.data as Statement;
    }

    /**
     * @param scope string
     * @param destination number
     * @param items Resource[]
     *
     * @returns Promise<MoveResources>
     */
    public static async moveResources(scope: string, destination: number, items: Resource[]): Promise<MovedResources>
    {
        const folders = StorageService.getFolders(items);
        const files = StorageService.getFiles(items);

        const result = await axios.put<MovedResources>(`admin/storage/resources/move`, {
            scope: scope,
            destination: destination,
            folders: folders,
            files: files
        });

        return result.data;
    }

    /**
     * @param model FolderModel
     *
     * @returns Promise<FolderModel>
     */
    public static async createFolder(model: FolderModel): Promise<FolderModel>
    {
        return (await axios.post<FolderModel>(`admin/storage/folders`, model)).data;
    }

    /**
     * @param id number
     * @param name string
     *
     * @returns Promise<Statement>
     */
    public static async renameFolder(id: number, name: string): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/storage/folders/${id}/rename`, { folderName: name })).data;
    }

    /**
     * @param id number
     * @param name string
     *
     * @returns Promise<Statement>
     */
    public static async renameFile(id: number, name: string): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/storage/files/${id}/rename`, { fileName: name })).data;
    }

    /**
     *
     * @param id number
     * @param model Record<string, Content>
     *
     * @returns Promise<Statement>
     */
    public static async saveContent(id: number, model: Record<string, Content>): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/storage/files/${id}/content`, { content: model })).data;
    }

    /**
     *
     * @param id number
     * @param model CropModel
     *
     * @returns Promise<Resource>
     */
    public static async cropImage(id: number, model: CropModel): Promise<Resource>
    {
        return (await axios.post<Resource>(`admin/storage/files/${id}/crop`, model)).data;
    }

    /**
     * @param id number
     * @param model ResizeModel
     *
     * @returns Promise<Resource>
     */
    public static async resizeImage(id: number, model: ResizeModel): Promise<Resource>
    {
        return (await axios.post<Resource>(`admin/storage/files/${id}/resize`, model)).data;
    }
}

interface UploadedResource
{
    upload?: File;
}

export interface Resource extends UploadedResource
{
    id: number;
    resourceType: string;
    dateCreatedUtc: DateTime;
    dateModifiedUtc: DateTime;
    createdBy: string;
    modifiedBy: string;
    scope: string;
    folderId?: number;
    name: string;
    contentType: string;
    contentLength: number;
    url: string;
    isImage: boolean;
    isSecured: boolean;
    meta: Record<string, string>;
    content: Record<string, Content>;
    media: string[];
}

export interface Resources extends Pagination<Resource>
{
    breadcrumbs: Record<number, string>;
}

export interface Content
{
    name: string;
    lead: string;
    alt: string;
}

export interface MovedResources
{
    destination: number;
    folders: number[];
    files: number[];
}

export interface FolderModel
{
    scope: string;
    parentId?: number;
    folderName: string;
}

export interface CropModel
{
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface ResizeModel
{
    width: number;
    height: number;
}
