<template>
    <div class="row">
        <div class="col-6">
            <field-text v-model="modelValue.prepend" :label="$t('[[[Przedrostek]]]')" v-if="prepend" />
        </div>
        <div class="col-6">
            <field-text v-model="modelValue.append" :label="$t('[[[Przyrostek]]]')" v-if="append" />
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { AffixValue } from '../Types';
import FieldText from '../properties/FieldText.vue';

@Options({
    name: 'field-affix',
    components: {
        'field-text': FieldText
    }
})
export default class FieldAffix extends Vue
{
    @Prop() public label!: string;
    @Prop() public modelValue!: AffixValue;
    @Prop({ default: true }) public prepend: boolean;
    @Prop({ default: true }) public append: boolean;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public updatePrepend(e: any): void
    {
        this.update({
            prepend: e.target.value,
            append: this.modelValue.append
        });
    }

    public updateAppend(e: any): void
    {
        this.update({
            prepend: this.modelValue.prepend,
            append: e.target.value
        });
    }

    @Emit('update:modelValue')
    public update(value: AffixValue): AffixValue
    {
        return value;
    }
}
</script>
