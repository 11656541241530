import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint, ChoiceOption } from '../Types';
import { FormEntry } from '../types/Form';
import { LikertContract, LikertTypes, LikertEntry, instanceOfLikertEntry } from '../types/Likert';
import properties from '../properties';
import { Option } from '@/helpers/Interfaces';

@Options({
    name: 'likert-blueprint',
    components: {
        ...properties
    }
})
export default class LikertBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: LikertContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public entryData: LikertEntry = new LikertEntry();

    public created(): void
    {
        this.entryData = this.form.initEntry(this.blueprint, this.entryData, instanceOfLikertEntry);
    }

    public get value(): Record<number, number>
    {
        return this.entryData.values;
    }

    public set value(values: Record<number, number>)
    {
        this.entryData.values = values;
    }

    public get questions(): ChoiceOption[]
    {
        return this.blueprint.questions.map((value, i) =>
        {
            return { value: i.toString(), text: this.$t(value), selected: false };
        });
    }

    public set questions(value: ChoiceOption[])
    {
        this.blueprint.questions = value.map(p => p.text);
    }

    public get choices(): ChoiceOption[]
    {
        return this.blueprint.choices.map((value, i) =>
        {
            return { value: i.toString(), text: this.$t(value), selected: false };
        });
    }

    public set choices(value: ChoiceOption[])
    {
        this.blueprint.choices = value.map(p => p.text);
    }

    public get options(): Option[]
    {
        const options = this.blueprint.choices.map((value, i) =>
        {
            return { value: (i + 1).toString(), text: this.$t(value) };
        });

        if (this.blueprint.allowNoChoice)
        {
            options.push({ value: "0", text: this.$t(this.blueprint.noChoiceLabel) });
        }

        return options;
    }

    public get defaultOptions(): Option[]
    {
        return [{ value: null, text: '' }, ...this.options];
    }

    public get fieldTypeOptions(): Option<LikertTypes>[]
    {
        return [
            { value: LikertTypes.Satisfaction, text: '[[[Satysfakcja]]]' },
            { value: LikertTypes.Agreement, text: '[[[Zgoda]]]' },
            { value: LikertTypes.Importance, text: '[[[Ważność]]]' },
            { value: LikertTypes.Comparison, text: '[[[Porównanie]]]' },
            { value: LikertTypes.Statement, text: '[[[Stwierdzenie]]]' },
            { value: LikertTypes.Scale, text: '[[[Skala 1-5]]]' },
            { value: LikertTypes.Custom, text: '[[[Dowolny]]]' }
        ];
    }

    public get fieldType(): typeof LikertTypes
    {
        return LikertTypes;
    }

    public get isCustom(): boolean
    {
        return this.blueprint.fieldType == LikertTypes.Custom;
    }

    public get required(): boolean
    {
        return this.form.required(this.blueprint);
    }

    public checkedValue(question: ChoiceOption): number
    {
        if (this.value !== null && question.value in this.value)
        {
            return this.value[question.value];
        }

        return this.blueprint.defaultValue || null;
    }

    public change(question: ChoiceOption, answer: string): void
    {
        const values = { ...this.value };

        values[question.value] = answer;
        this.update(values);
    }

    protected update(value: Record<number, number>): void
    {
        this.value = value;
    }

    @Watch('blueprint.fieldType')
    protected onFieldTypeChanged(value: LikertTypes): void
    {
        this.blueprint.defaultChoices(value);
    }

    @Watch('blueprint.allowNoChoice')
    protected onDefaultValueChanged(value: string, old: string): void
    {
        if (this.blueprint.defaultValue == -1)
        {
            this.blueprint.defaultValue = null;
        }
    }
}
