import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { AggregateBlueprint } from '../Types';
import { FormEntry } from '../types/Form';
import { DictionaryContract, DictionaryEntry, instanceOfDictionaryEntry } from '../types/Dictionary';
import properties from '../properties';
import { Option } from '@/helpers/Interfaces';
import DataSourcesService from '@/modules/core/data-sources/services/DataSourcesService';
import Pager from '@/helpers/Pager';
import Multiselect from '@suadelabs/vue3-multiselect/src/Multiselect.vue';

@Options({
    name: 'dictionary-blueprint',
    components: {
        'multiselect': Multiselect,
        ...properties
    }
})
export default class DictionaryBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: DictionaryContract;
    @Prop({ default: null }) public entry!: FormEntry;

    public entryData: DictionaryEntry = new DictionaryEntry({ value: 1, text: 'test' });
    public dictionaries: Option[] = [];
    public limit: number = 10;

    public get uid(): string
    {
        return `form-control-${this.$.uid}`;
    }

    public async created(): Promise<void>
    {
        this.entryData = this.form.initEntry(this.blueprint, this.entryData, instanceOfDictionaryEntry);

        if (this.form.designMode())
        {
            this.dictionaries = await DataSourcesService.getDictionaries();

            if (this.blueprint.sourceId === null && this.dictionaries.length > 1)
            {
                this.blueprint.sourceId = parseInt(this.dictionaries[0].value);
            }
        }
    }

    public get model(): Option
    {
        return {
            value: this.entryData.value,
            text: this.entryData.text
        };
    }

    public set model(model: Option)
    {
        this.entryData.value = model.value;
        this.entryData.text = model.text;
    }

    public get placeholder(): string
    {
        return this.blueprint.placeholder || this.$t('[[[wybierz...]]]');
    }

    public get required(): boolean
    {
        return this.form.required(this.blueprint);
    }

    public async fetch(value: string): Promise<Option>
    {
        return new Promise(resolve => resolve(this.model));
    }

    public async search(query: string): Promise<Option[]>
    {
        if (query && query.length > 0)
        {
            const pager = new Pager(1, this.limit, 'Position', 'ASC');
            const response = await DataSourcesService.getOptions(this.blueprint.sourceId, query, pager);

            return response.items.map(p =>
            {
                return {
                    value: p.value.toString(),
                    text: p.text
                };
            });
        }

        return [];
    }

    public onChanged(model: Option): void
    {
        this.model = model;
    }
}
