<template>
    <div class="form-group" v-if="visible">
        <label :for="uid" v-if="showLabel">{{ label }}</label>
        <div class="input-group">
            <input type="text" class="form-control pointer" readonly="readonly" :id="uid" :value="modelValue" :placeholder="showLabel ? '' : label" @click="openEditor()">
            <span class="input-group-text pointer" @click="openEditor()">
                <i class="fas fa-cog"></i>
            </span>
        </div>
        <small class="form-text text-danger" v-if="errorMessage">
            {{ $t(errorMessage) }}
        </small>
        <ideo-modal id="field-condition-modal" v-model="modal" :title="label" size="xl" centered scrollable>
            <template #default>
                <div class="row">
                    <div class="col-lg-7 mb-3 mb-lg-0">
                        <textarea v-model="buffer" class="form-control h-100" :class="{'border-danger': !valid}" ref="editor" :rows="5" spellcheck="false"></textarea>
                    </div>
                    <div class="col-lg-5">
                        <div class="legend" v-if="type == 'text'">
                            <h5 class="font-weight-bold2 mb-3">{{ $t('[[[Podstawowe operacje tekstowe]]]') }}</h5>
                            <p class="mb-0">{{ $t('[[[Definicja formularza znajduje się w obiekcie Form.]]]') }}</p>
                            <p class="mb-0">{{ $t('[[[Dane formularza są przechowywane w obiekcie Entry.]]]') }}</p>
                            <p class="">{{ $t('[[[Zacznik pisać Form lub Entry aby uruchomić podpowiadanie.]]]') }}</p>
                            <div>
                                <h6>{{ $t('[[[Stała wartość]]]') }}</h6>
                                <pre>"Lorem ipsum..."</pre>
                                <h6>{{ $t('[[[Łączenie treści]]]') }}</h6>
                                <pre>Entry.Personal1.givenName + " " + Entry.Personal1.surname</pre>
                                <h6>{{ $t('[[[Wyrażenia warunkowe]]]') }}</h6>
                                <pre>Entry.Personal1.middleName != null ? "Yes" : "No"</pre>
                                <h6>{{ $t('[[[Sprawdzenie czy pusty]]]') }}</h6>
                                <pre>Entry.Personal1.givenName == null</pre>
                                <h6>{{ $t('[[[Zaczyna sie od]]]') }}</h6>
                                <pre>Entry.Personal1.givenName.startsWith("A")</pre>
                                <h6>{{ $t('[[[Zawiera]]]') }}</h6>
                                <pre>Entry.Personal1.surname.includes("ow")</pre>
                                <h6>{{ $t('[[[Małe znaki]]]') }}</h6>
                                <pre>Entry.Personal1.givenName.toLowerCase()</pre>
                                <h6>{{ $t('[[[Usuń spacje]]]') }}</h6>
                                <pre>Entry.Personal1.givenName.trim()</pre>
                            </div>
                        </div>
                        <div class="legend" v-if="type == 'number'">
                            <h5 class="font-weight-bold2 mb-3">{{ $t('[[[Podstawowe operacje na liczbach]]]') }}</h5>
                            <p class="mb-0">{{ $t('[[[Definicja formularza znajduje się w obiekcie Form.]]]') }}</p>
                            <p class="mb-0">{{ $t('[[[Dane formularza są przechowywane w obiekcie Entry.]]]') }}</p>
                            <p class="">{{ $t('[[[Zacznik pisać Form lub Entry aby uruchomić podpowiadanie.]]]') }}</p>
                            <div>
                                <h6>{{ $t('[[[Dodawanie]]]') }}</h6>
                                <pre>Numeric1.value + Numeric2.value</pre>
                                <h6>{{ $t('[[[Odejmowanie]]]') }}</h6>
                                <pre>Numeric1.value - Numeric2.value</pre>
                                <h6>{{ $t('[[[Mnożenie]]]') }}</h6>
                                <pre>Numeric1.value * Numeric2.value</pre>
                                <h6>{{ $t('[[[Dzielenie]]]') }}</h6>
                                <pre>Numeric1.value / Numeric2.value</pre>
                                <h6>{{ $t('[[[Wyrażenia warunkowe]]]') }}</h6>
                                <pre>Numeric1.value > 10 ? 4.95 : 5.00</pre>
                                <h6>{{ $t('[[[Minimum]]]') }}</h6>
                                <pre>Math.min(Numeric1.value, Numeric2.value)</pre>
                                <h6>{{ $t('[[[Maksimum]]]') }}</h6>
                                <pre>Math.max(Numeric1.value, Numeric2.value)</pre>
                                <h6>{{ $t('[[[Zaokrąglenie]]]') }}</h6>
                                <pre>Math.round(Numeric1.value)</pre>
                            </div>
                        </div>
                        <div class="legend" v-if="type == 'bool'">
                            <h5 class="font-weight-bold2 mb-3">{{ $t('[[[Podstawowe operacje warunkowe]]]') }}</h5>
                            <p class="mb-0">{{ $t('[[[Definicja formularza znajduje się w obiekcie Form.]]]') }}</p>
                            <p class="mb-0">{{ $t('[[[Dane formularza są przechowywane w obiekcie Entry.]]]') }}</p>
                            <p class="">{{ $t('[[[Zacznik pisać Form lub Entry aby uruchomić podpowiadanie.]]]') }}</p>
                            <div>
                                <h6>{{ $t('[[[Stała wartość]]]') }}</h6>
                                <pre>true</pre>
                                <h6>{{ $t('[[[Warunki logiczne]]]') }}</h6>
                                <pre>Entry.Boolean1.value || Entry.Boolean2.value</pre>
                                <pre>Entry.Boolean1.value && Entry.Boolean2.value</pre>
                                <h6>{{ $t('[[[Porównania]]]') }}</h6>
                                <pre>Entry.Numeric1.value &gt; 10</pre>
                                <pre>Entry.Numeric1.value &lt; 8</pre>
                                <h6>{{ $t('[[[Równość]]]') }}</h6>
                                <pre>Entry.Numeric1.value == 5</pre>
                                <h6>{{ $t('[[[Kontrolka wyboru (pojedynczy wybór)]]]') }}</h6>
                                <pre>Entry.Choice1.value == '1'</pre>
                                <h6>{{ $t('[[[Kontrolka wyboru (wybór wielokrotny)]]]') }}</h6>
                                <pre>Entry.Choice1.values.includes('1')</pre>
                            </div>
                        </div>
                        <div class="legend" v-if="type == 'date'">
                            <h5 class="font-weight-bold2 mb-3">{{ $t('[[[Podstawowe operacje na datach]]]') }}</h5>
                            <p class="mb-0">{{ $t('[[[Definicja formularza znajduje się w obiekcie Form.]]]') }}</p>
                            <p class="mb-0">{{ $t('[[[Dane formularza są przechowywane w obiekcie Entry.]]]') }}</p>
                            <p class="">{{ $t('[[[Zacznik pisać Form lub Entry aby uruchomić podpowiadanie.]]]') }}</p>
                            <div>
                                <h6>{{ $t('[[[Stała wartość]]]') }}</h6>
                                <pre>"2020-01-01"</pre>
                                <h6>{{ $t('[[[Teraz]]]') }}</h6>
                                <pre>DateTime.utc()</pre>
                                <h6>{{ $t('[[[Czas pomiędzy]]]') }}</h6>
                                <pre>(Date1.value - Date2.value).days</pre>
                                <pre>(Date1.value - Date2.value).hours</pre>
                                <h6>{{ $t('[[[Porównania]]]') }}</h6>
                                <pre>Date1.value &lt; Date2.value ? Date1.value : Date2.value</pre>
                                <h6>{{ $t('[[[Dodaj dni]]]') }}</h6>
                                <pre>Date1.value.plus({ days: 1 })</pre>
                                <h6>{{ $t('[[[W przyszłości]]]') }}</h6>
                                <pre>Date1.value &gt; Date2.value</pre>
                                <h6>{{ $t('[[[Rok]]]') }}</h6>
                                <pre>Date1.value.year</pre>
                                <h6>{{ $t('[[[Nowa data]]]') }}</h6>
                                <pre>DateTime.fromObject({ year: 2020, month: 1, day: 1})</pre>
                                <pre>DateTime.fromISO('2016-05-25T09:08:34.123')</pre>
                                <pre>DateTime.utc(2020, 1, 1, 8, 45, 15)</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #modal-footer>
                <button class="btn btn-primary" @click.stop.prevent="saveExpression()" :disabled="!valid">
                    {{ $t('[[[Zapisz]]]') }}
                </button>
                <ideo-button variant="light" @click.stop.prevent="closeEditor()">
                    {{ $t('[[[Anuluj]]]') }}
                </ideo-button>
            </template>
        </ideo-modal>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { FormBuilder } from '../Utils';
import { Textcomplete, Textarea } from 'textcomplete';
import { AlwaysChoice, NeverChoice, InternallyChoice, WhenChoice } from '../Types';
import { FormEntry } from '../types/Form';
import { DateTime } from 'luxon';
import { isBoolean, isString, isNumber } from 'lodash';

@Options({
    name: 'field-condition'
})
export default class FieldCondition extends Vue
{
    @Prop({ default: null }) public form!: FormBuilder;
    @Prop({ default: null }) public entry!: FormEntry;
    @Prop({ default: () => WhenChoice.When }) public when: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    @Prop({ default: true }) public showLabel: boolean;
    @Prop() public label: string;
    @Prop() public modelValue: string;
    @Prop({ default: 'bool' }) public type: string; // text, number, bool, date

    public modal: boolean = false;
    public sidebar: boolean = false;
    public buffer: string = null;
    public errorMessage: string = null;
    public textcomplete: any = null;

    public mounted(): void
    {
        this.buffer = this.modelValue;
    }

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public get visible(): boolean
    {
        return this.when == WhenChoice.When;
    }

    public get valid(): boolean
    {
        if (this.buffer == null || this.buffer == "")
        {
            return true;
        }

        let valid = this.form.checkExpression(this.buffer);
        const value = this.form.executeExpression(this.buffer);

        switch (this.type)
        {
            case "number":
                valid = valid && isNumber(value);
                break;
            case "bool":
                valid = valid && isBoolean(value);
                break;
            case "date":
                valid = valid && DateTime.isDateTime(value);
                break;
            default:
                valid = valid && isString(value);
        }

        return valid;
    }

    @Emit('update:modelValue')
    public triggerInput(value: string): string
    {
        return value;
    }

    public saveExpression(): void
    {
        this.triggerInput(this.buffer);
        this.closeEditor();
    }

    public openEditor(): void
    {
        this.buffer = this.modelValue;
        this.modal = true;

        this.$nextTick(() =>
        {
            const editor = new Textarea(this.$refs.editor);
            const types = ['Form', 'Entry', 'Math', 'DateTime'];

            this.textcomplete = new Textcomplete(editor, {
                dropdown: {
                    maxCount: 1000000,
                    className: 'dropdown-menu textcomplete-dropdown scroll',
                    style: {
                        zIndex: 65538
                    },
                    item: {
                        className: 'dropdown-item'
                    }
                }
            });
            this.textcomplete.on('rendered', () =>
            {
                if (this.textcomplete.dropdown.items.length > 0)
                {
                    this.textcomplete.dropdown.items[0].activate();
                }
            });
            this.textcomplete.register([
                {
                    match: new RegExp("(^|\\s)(\\w+)$", "i"),
                    search: (term: string, callback: (value: string[]) => void) =>
                    {
                        callback(types.filter(value =>
                        {
                            return value.toLowerCase().startsWith(term.toLowerCase());
                        }));
                    },
                    replace: function(value: string)
                    {
                        return '$1' + value;
                    }
                },
                {
                    index: 0,
                    match: new RegExp("((" + types.join("|") + ")\\.([a-z0-9]+\\.)*)([a-z0-9]*)$", "i"),
                    search: (term: string, callback: (value: string[]) => void) =>
                    {
                        let type: string = null;
                        let name: string = null;
                        let properties: string[] = [];
                        let result: string[] = [];

                        [type, name, ...properties] = term.split('.');

                        if (type == 'Form')
                        {
                            result = this.getFormIntellisense(name, properties);
                        }

                        if (type == 'Entry')
                        {
                            properties.unshift(name);
                            result = this.getIntellisense(this.form.getEntry(), properties, false, true);
                        }

                        if (type == 'Math')
                        {
                            properties.unshift(name);
                            result = this.getIntellisense(Math, properties, true, true);
                        }

                        if (type == 'DateTime')
                        {
                            properties.unshift(name);
                            result = this.getIntellisense(DateTime, properties, true, false);
                        }

                        result.sort();

                        callback(result);
                    },
                    replace: function(value: string)
                    {
                        return '$1' + value;
                    }
                }
            ]);
        });
    }

    public getFormIntellisense(name: string, properties: string[]): string[]
    {
        if (properties.length == 0)
        {
            return this.form.names().filter(value =>
            {
                return value.toLowerCase().startsWith(name.toLowerCase());
            });
        }
        else
        {
            return this.getIntellisense(this.form.find(name), properties, false, true, ['defaultValue']);
        }
    }

    public getIntellisense(entry: any, properties: string[], withFunctions: boolean, withProperties: boolean, except: string[] = []): string[]
    {
        let result: string[] = [];

        properties = properties.reverse();

        let property = properties.pop();

        if (entry != null)
        {
            let item: any = entry;

            while (item != null && property != null && property != "")
            {
                if (item.hasOwnProperty(property) && properties.length > 0)
                {
                    item = item[property] as any;
                    property = properties.pop();
                }
                else
                {
                    break;
                }
            }

            if (item != null)
            {
                result = this.getMembers(item, withFunctions, withProperties, except).filter(value =>
                {
                    return value.toLowerCase().startsWith(property.toLowerCase());
                });
            }
        }

        return result;
    }

    public getMembers(value: any, withFunctions: boolean, withProperties: boolean, except: string[]): string[]
    {
        let current = value;
        const members: Record<string, any> = {};
        const type = Array.isArray(current) ? 'array' : typeof current;

        except = ['constructor', 'caller', 'callee', 'arguments', 'prototype', 'apply', 'bind', 'call', ...except];

        do
        {
            Object.getOwnPropertyNames(current).map(name =>
            {
                if (!except.includes(name) && !name.startsWith('__'))
                {
                    if (!members[name])
                    {
                        members[name] = {
                            name: name,
                            type: Array.isArray(current[name]) ? 'array' : typeof current[name],
                            body: current[name],
                            descr: Object.getOwnPropertyDescriptor(current, name)
                        };
                    }
                }
            });
        }
        while ((current = Object.getPrototypeOf(current)));

        return Object.values(members)
            .filter(member =>
            {
                const types = { object: 'object', array: 'array', string: 'string', function: 'function' };

                switch (type)
                {
                    case types.object:
                    case types.function:
                        if (member.type === 'function') return withFunctions;
                        else if (member.type !== 'function') return withProperties;
                        else return false;
                    case types.array:
                        return member.type === 'function' || member.name == 'length';
                    case types.string:
                        return member.type === 'function';
                    default:
                        return false;
                }
            })
            .map(member => member.name + (member.type === 'function' ? `(${this.methodDescription(member.body)})` : ''));
    }

    public methodDescription(body: any): string
    {
        const bodyRegex = new RegExp("\\((.*)\\)");
        const match = bodyRegex.exec(body.toString());

        return match != null ? match[1] : '()';
    }

    public closeEditor(): void
    {
        this.textcomplete.destroy(true);
        this.buffer = this.modelValue;
        this.modal = false;
    }

    @Watch('value')
    public onValueChanged(value: string): void
    {
        this.buffer = value;
    }
}
</script>

<style lang="scss" scoped>
.form-group {
    .form-control {
        background-color: var(--form-control-bg) !important;
        border: 1px solid var(--form-control-border) !important;
        border-right: none !important;
    }
    .input-group-text {
        background-color: var(--form-control-bg) !important;
        border: 1px solid var(--form-control-border) !important;
        border-left: none !important;
        color: var(--form-control-border) !important;
    }
}
.legend {
    h6 {
        font-style: italic;
        padding-left: 10px;
        padding-bottom: 2px;
        margin-bottom: 0;
        border-left: 2px solid $primary;
    }
    pre {
        white-space: pre-line;
        margin-bottom: 0;
        padding-left: 12px;
    }
    pre + h6 {
        margin-top: 1rem !important;
    }
}
</style>

<style lang="scss">
.textcomplete-dropdown {
    max-height: 160px;
    padding: 0 !important;
    box-shadow: 3px 3px 0px 0px rgba(0,0,0,0.05);
}
</style>
